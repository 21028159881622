import { t } from 'i18next';
import AnimationIcon from '../../Generics/AnimationIcon';

const CustomFacilityBox = ({clickFunction, size = 60}) => {

  return (
    <div onClick={clickFunction} className={`flex flex-col hover:bg-blue-100 bg-white hover:shadow-lg cursor-pointer justify-center items-center border-2 border-allimmoDark active:shadow-none p-1 aspect-square h-32 rounded-xl`}>
        <AnimationIcon _color={false} _iconSize={size} iconSrc={require(`../../../assets/animations/objectCreation/facilities/plus.json`)}/>
        <p className='text-allimmoDark w-full text-center text-12 md:text-14'>{t(`Facilities.plus`)}</p>
    </div>
  )
}

export default CustomFacilityBox