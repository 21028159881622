import { CircularProgress } from '@mui/material';
import { Timestamp, collection, doc, getCountFromServer, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { firestore } from '../firebase.config';
import { Reload } from '../helper/icons';

const Stats2 = () => {

    const { adminEmail } = useStateContext();

    const [stats, setStats] = useState({
        objects: 0,
        searches: 0,
        customers: 0,
        activeSearches: 0,
        activeObjects: 0,
        newToday: 0,
        newYesterday: 0,
        newBeforeYesterday: 0,
        newSearchesToday: 0,
        newSearchesYesterday: 0,
        newSearchesBefore: 0,
        newObjectsToday: 0,
        newObjectsYesterday: 0,
        newObjectsBefore: 0,
        payments: 0,
        objectStats: {
            apartment: 0,
            house: 0,
            land: 0,
            rent: 0,
            sale: 0,
        },
        searchStats: {
            apartment: 0,
            house: 0,
            land: 0,
            rent: 0,
            sale: 0,
        },
        interfaceObjects: 0,
    });
    const [dbStats, setDbStats] = useState({
        matches: 0,
        contacted: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [menuState, setMenuState] = useState("user");

    const midnight = new Date();
    midnight.setHours(0,0,0,0);

    const yesterday = new Date(midnight);
    yesterday.setDate(yesterday.getDate() - 1);

    const dayBefore = new Date(midnight);
    dayBefore.setDate(dayBefore.getDate() - 2);

    useEffect(() => {
        getStats();
    }, []);

    const getStats = async () => {
        setIsLoading(true);
        const objectsRef = collection(firestore, "objects");
        const objectsSnap = await getCountFromServer(objectsRef);

        const searchesRef = collection(firestore, "searches");
        const searchesSnap = await getCountFromServer(searchesRef);

        const paymentsRef = collection(firestore, "payments");
        const paymentsSnap = await getCountFromServer(paymentsRef);

        const aObjectsQuery = query(objectsRef, where("active", "==", true));
        const aObjectsSnap = await getCountFromServer(aObjectsQuery);

        const openimmoRef = collection(firestore, "openimmoObjects");
        const interfaceObjectsQuery = query(openimmoRef);
        const interfaceObjectsSnap = await getCountFromServer(interfaceObjectsQuery);

        const apartmentObjectsQuery = query(objectsRef, where("type", "==", "apartment"));
        const apartmentObjectsSnap = await getCountFromServer(apartmentObjectsQuery);

        const apartmentSearchesQuery = query(searchesRef, where("type", "==", "apartment"));
        const apartmentSearchesSnap = await getCountFromServer(apartmentSearchesQuery);

        const houseObjectsQuery = query(objectsRef, where("type", "==", "house"));
        const houseObjectsSnap = await getCountFromServer(houseObjectsQuery);

        const houseSearchesQuery = query(searchesRef, where("type", "==", "house"));
        const houseSearchesSnap = await getCountFromServer(houseSearchesQuery);

        const landObjectsQuery = query(objectsRef, where("type", "==", "land"));
        const landObjectsSnap = await getCountFromServer(landObjectsQuery);

        const landSearchesQuery = query(searchesRef, where("type", "==", "land"));
        const landSearchesSnap = await getCountFromServer(landSearchesQuery);

        const saleObjectsQuery = query(objectsRef, where("deal", "==", "sale"));
        const saleObjectsSnap = await getCountFromServer(saleObjectsQuery);

        const saleSearchesQuery = query(searchesRef, where("deal", "==", "sale"));
        const saleSearchesSnap = await getCountFromServer(saleSearchesQuery);

        const rentObjectsQuery = query(objectsRef, where("deal", "==", "rent"));
        const rentObjectsSnap = await getCountFromServer(rentObjectsQuery);

        const rentSearchesQuery = query(searchesRef, where("deal", "==", "rent"));
        const rentSearchesSnap = await getCountFromServer(rentSearchesQuery);

        const newObjectsTodayQuery = query(objectsRef, where("created", ">=", Timestamp.fromDate(midnight)));
        const newObjectsYesterdayQuery = query(objectsRef, where("created", ">=", Timestamp.fromDate(yesterday), where("created", "<", Timestamp.fromDate(midnight))));
        const newObjectsBeforeQuery = query(objectsRef, where("created", ">=", Timestamp.fromDate(dayBefore), where("created", "<", Timestamp.fromDate(yesterday))));
        
        const newObjectsTodaySnap = await getCountFromServer(newObjectsTodayQuery);
        const newObjectsYesterdaySnap = await getCountFromServer(newObjectsYesterdayQuery);
        const newObjectsBeforeSnap = await getCountFromServer(newObjectsBeforeQuery);

        const aSearchesQuery = query(searchesRef, where("active", "==", true));
        const aSearchesSnap = await getCountFromServer(aSearchesQuery);

        const newSearchesTodayQuery = query(searchesRef, where("created", ">=", Timestamp.fromDate(midnight)));
        const newSearchesYesterdayQuery = query(searchesRef, where("created", ">=", Timestamp.fromDate(yesterday), where("created", "<", Timestamp.fromDate(midnight))));
        const newSearchesBeforeQuery = query(searchesRef, where("created", ">=", Timestamp.fromDate(dayBefore), where("created", "<", Timestamp.fromDate(yesterday))));
        
        const newSearchesTodaySnap = await getCountFromServer(newSearchesTodayQuery);
        const newSearchesYesterdaySnap = await getCountFromServer(newSearchesYesterdayQuery);
        const newSearchesBeforeSnap = await getCountFromServer(newSearchesBeforeQuery);

        const customersRef = collection(firestore, "customers");
        const customerQuery = query(customersRef, where("email", "!=", adminEmail));
        const newCustomersQuery = query(customersRef, where("created", ">=", Timestamp.fromDate(midnight)));
        const newCustomersYQuery = query(customersRef, where("created", ">=", Timestamp.fromDate(yesterday)), where("created", "<", Timestamp.fromDate(midnight)));
        const newCustomersBYQuery = query(customersRef, where("created", ">=", Timestamp.fromDate(dayBefore)), where("created", "<", Timestamp.fromDate(yesterday)));

        const customersSnap = await getCountFromServer(customerQuery);
        const newCustomersSnap = await getCountFromServer(newCustomersQuery);
        const newCustomersYSnap = await getCountFromServer(newCustomersYQuery);
        const newCustomersBYSnap = await getCountFromServer(newCustomersBYQuery);

        onSnapshot(doc(firestore, "platform", "stats"), (result) => {
            setDbStats(result.data());
        });

        setStats({
            ...stats, 
            objects: objectsSnap.data().count,
            activeObjects: aObjectsSnap.data().count,
            searches: searchesSnap.data().count,
            activeSearches: aSearchesSnap.data().count,
            customers: customersSnap.data().count,
            newToday: newCustomersSnap.data().count,
            newYesterday: newCustomersYSnap.data().count,
            newBeforeYesterday: newCustomersBYSnap.data().count,
            newSearchesToday: newSearchesTodaySnap.data().count,
            newSearchesYesterday: newSearchesYesterdaySnap.data().count,
            newSearchesBefore: newSearchesBeforeSnap.data().count,
            newObjectsToday: newObjectsTodaySnap.data().count,
            newObjectsYesterday: newObjectsYesterdaySnap.data().count,
            newObjectsBefore: newObjectsBeforeSnap.data().count,
            payments: paymentsSnap.data().count,
            objectStats: {
                apartment: apartmentObjectsSnap.data().count,
                house: houseObjectsSnap.data().count,
                land: landObjectsSnap.data().count,
                sale: saleObjectsSnap.data().count,
                rent: rentObjectsSnap.data().count,
            },
            searchStats: {
                apartment: apartmentSearchesSnap.data().count,
                house: houseSearchesSnap.data().count,
                land: landSearchesSnap.data().count,
                sale: saleSearchesSnap.data().count,
                rent: rentSearchesSnap.data().count,
            },
            interfaceObjects: interfaceObjectsSnap.data().count,
        });

        setIsLoading(false);
    };

    const getRatioColor = (value) => {
        if(value >= 4 && value <= 5) return 'text-green-500';
        if((value < 4 && value >= 2.5) || (value > 5 && value <= 6.5)) return 'text-orange-400';
        if(value < 2.5 || value > 6.5) return 'text-red-500';
    };

    const getActionColor = (value) => {
        if(value >= 90) return 'text-green-500';
        if(value < 90 && value >= 60) return 'text-orange-400';
        if(value < 60) return 'text-red-500';
    };

    const getText = (value) => {
        if(value >= 4 && value <= 5) return 'Gute Balance zwischen Anzahl an Objekten und Suchprofilen.';
        if(value < 4 && value >= 2.5) return 'Zu viele Objekte für die Anzahl an Suchen.';
        if(value > 5 && value <= 6.5) return 'Zu wenig Objekte für die Anzahl an Suchen.';
        if(value < 2.5) return 'Zu viele Suchen für die Anzahl an Objekten.';
        if(value > 6.5) return 'Zu wenig Suchen für die Anzahl an Objekten.';
    };

  return (
    <div className={`flex flex-col items-center w-full max-w-1000 gap-1 md:pt-10 px-3`}>
        <div className='flex flex-row items-center gap-4 w-full rounded border border-allimmoDark p-2 bg-white'>
            {isLoading ? <CircularProgress sx={{color: "#00547B"}} size={25} /> :
            <Reload className='cursor-pointer hover:text-slate-400 active:text-allimmoDark text-allimmoDark' size={25} onClick={getStats}/>}
            <div className='grow'/>
            <OverviewElement label="User" value={stats.customers} change={stats.newToday} endDivider />
            <OverviewElement label="Objekte" value={stats.activeObjects} change={stats.newObjectsToday} endDivider />
            <OverviewElement label="Suchen" value={stats.activeSearches} change={stats.newSearchesToday} />
        </div>

        <div className='flex flex-row gap-2 items-center w-full'>
            <p onClick={() => setMenuState("user")} className={`border border-allimmoDark w-100 py-1 px-2 rounded ${menuState === "user" ? "bg-allimmoDark/60 text-white" : "bg-white hover:bg-allimmoDark/10"} text-allimmoDark text-center  cursor-pointer`}>USER</p>
            <p onClick={() => setMenuState("objects")} className={`border border-allimmoDark w-100 py-1 px-2 rounded ${menuState === "objects" ? "bg-allimmoDark/60 text-white" : "bg-white hover:bg-allimmoDark/10"} text-allimmoDark text-center  cursor-pointer`}>OBJEKTE</p>
            <p onClick={() => setMenuState("searches")} className={`border border-allimmoDark w-100 py-1 px-2 rounded ${menuState === "searches" ? "bg-allimmoDark/60 text-white" : "bg-white hover:bg-allimmoDark/10"} text-allimmoDark text-center  cursor-pointer`}>SUCHEN</p>
            <p onClick={() => setMenuState("platform")} className={`border border-allimmoDark w-100 py-1 px-2 rounded ${menuState === "platform" ? "bg-allimmoDark/60 text-white" : "bg-white hover:bg-allimmoDark/10"} text-allimmoDark text-center  cursor-pointer`}>PLATFORM</p>
        </div>

        {menuState === "user" && <UserStats />}
        {menuState === "objects" && <ObjectStats data={stats} />}
        {menuState === "searches" && <SearchesStats data={stats} />}
        {menuState === "platform" && <PlatformStats />}
        
    </div>
  )
};

const UserStats = () => {
    return (
        <div className='flex flex-row items-center justify-end gap-2 w-full rounded border border-allimmoDark py-1 px-2 bg-white shadow-lg'>
            
        </div>
    )
};

const ObjectStats = ({data}) => {
    return (
        <div className='flex flex-row items-center gap-2 w-full rounded border border-allimmoDark py-1 px-2 bg-white shadow-lg'>
            <div className='flex flex-col'>
                <p>Wohnung: {data.objectStats.apartment}</p>
                <p>Haus: {data.objectStats.house}</p>
                <p>Grundstück: {data.objectStats.land}</p>
                <p>Miete: {data.objectStats.rent}</p>
                <p>Kauf: {data.objectStats.sale}</p>
                <p className='mt-3'>Interface: {data.interfaceObjects}</p>
            </div>
        </div>
    )
};

const SearchesStats = ({data}) => {
    return (
        <div className='flex flex-row items-center gap-2 w-full rounded border border-allimmoDark py-1 px-2 bg-white shadow-lg'>
            <div className='flex flex-col'>
                <p>Wohnung: {data.searchStats.apartment}</p>
                <p>Haus: {data.searchStats.house}</p>
                <p>Grundstück: {data.searchStats.land}</p>
                <p>Miete: {data.searchStats.rent}</p>
                <p>Kauf: {data.searchStats.sale}</p>
            </div>
        </div>
    )
};

const PlatformStats = () => {
    return (
        <div className='flex flex-row items-center justify-end gap-2 w-full rounded border border-allimmoDark py-1 px-2 bg-white shadow-lg'>

        </div>
    )
};

const OverviewElement = ({label, value, change, endDivider = false}) => {
    return (
        <div className='flex flex-row items-center'>
            <p className='text-allimmoDark font-semibold'>{`${value} ${label}`}</p>
            <p className='text-allimmoDark text-xs ml-2 mr-4'>{`+${change}`}</p>
            {endDivider && <p className='text-allimmoDark'>|</p>}
        </div>
    )
};

export default Stats2
