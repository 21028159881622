import { TextField } from "@mui/material";
import { Icon } from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents
} from "react-leaflet";
import { useCreationContext } from "../../../contexts/CreationProvider";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import Button from "../../Generics/Button";
import { t } from "i18next";
import NavMenu from "../../Generics/NavMenu";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const ObjectMap = ({next, back}) => {
  const { objectData, setObjectData } = useCreationContext();

  const objectLocation = [objectData.location.lat, objectData.location.lng];

  const locations = {
    at: [47.59215925257782, 14.265993260120704],
    de: [51.1257904790071, 10.00878689614948],
  };

  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState([
    [
      locations[objectData.country.toLowerCase()][0],
      locations[objectData.country.toLowerCase()][1],
    ],
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const mapRef = useRef(null);
  const [goto, setGoto] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  const clickMarker = new Icon({
    iconUrl: require('../../../assets/location-pin.png'),
    iconSize: [36, 36],
    iconAnchor: [16, 36],
  });

  const ZoomHandler = () => {
    const map = useMap();

    useEffect(() => {
      if(goto) {
        flyToPosition(objectLocation, 18);
        setGoto(false);
      }
    
    }, [goto])

    useEffect(() => {
      if(!objectLocation.some(el => el === 0)) {
        flyToPosition(objectLocation, 18);
      }
    
    }, [objectLocation])

    useMapEvents({
      click(e) {
        setObjectData({...objectData, location: {
          lat: e.latlng.lat, 
          lng: e.latlng.lng
          }
        });
        flyToPosition([e.latlng.lat, e.latlng.lng], 18);
      }
    });

    const flyToPosition = (coordinates, zoom) => {
        map.flyTo(coordinates, zoom, {
          animate: true,
          duration: 2,
        });
    };

    if(!objectLocation.some(el => el === 0)) {
      return  <Marker position={objectLocation} icon={clickMarker}/>
    } else {
      return null;
    }
  };

  const searchLocation = async () => {
    if (searchInput === "") return;
    setSearchResult([]);
    setIsLoading(true);
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?addressdetails=1&q=${searchInput}&format=jsonv2&polygon_geojson=1&extratags=1&namedetails=1`
    );
    const result = await response.json();
    setSearchResult(result);
    setIsLoading(false);
  };

  const handleEnterPress = (event) => {
    if (event.keyCode === 13 || event.which === 11) {
      searchLocation();
    }
  };

  const handleItemClick = (res) => {
    setObjectData({...objectData, location: {
      lat: res?.lat, 
      lng: res?.lon
      }
    })
  };

  return (
    <div className="flex flex-col w-full md:w-2/3 items-center">
      <p className="w-full sm:text-lg text-center px-1 font-bold">{t('CreateObject.MapLabel')}</p>
      <p className="text-allimmoDark w-full text-center mb-2 md:mb-7 px-3 italic text-sm">{t('CreateObject.MapSub')}</p>
      <NavMenu marginTop={0} _back={back} nextBtn _next={next} nextDisabled={objectData.location.lat === 0 || objectData.location.lng === 0}/>
      <div className="w-full h-50vh border-2 border-allimmoDark rounded-2xl overflow-hidden mt-2 z-10">
          <MapContainer center={locations.at} zoom={6} ref={mapRef} zoomControl={false}>
            <TileLayer
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ZoomHandler />
          </MapContainer>
      </div>
      <div className="flex flex-col gap-1 w-full mt-2.5">
        <div className="flex flex-row gap-2 items-center ">
          <TextField
            autoComplete="off"
            onKeyDown={(e) => handleEnterPress(e)}
            className="w-full bg-white rounded"
            size="small"
            label={t('CreateObject.AdresseEingeben')}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
            <Button
              text="OK"
              colorSetting="bg-white hover:bg-slate-200 active:bg-white"
              size="xl"
              borderColor="border-slate-500"
              clickFunction={searchLocation}
              loading={isLoading}
              width="20%"
            />
        </div>
        <div className="flex flex-col items-center w-full overflow-auto">
          {searchResult.filter(el =>
                !["railway", "highway", "natural"].includes(el.category) &&
                !["city", "village", "town", "county", "state", "district", "country", "suburb", "quarter"].includes(el.addresstype) &&
                ["at", "de", "ch"].includes(el.address?.country_code)
            )
            .map((res, i) => {
              return (
                <div key={i} className={`w-full border-b border-black flex flex-col hover:bg-slate-100 cursor-pointer px-2 py-1 text-sm`}>
                  <div className="flex flex-col" onClick={(e) => handleItemClick(res)}>
                    <p className="grow italic font-bold">{res?.address?.amenity !== undefined ? res?.address?.amenity : ""}</p>
                    <p className="grow">{res?.address?.road} {res?.address?.house_number !== undefined ? res?.address?.house_number + ", " : ""} {res?.address?.postcode} {res?.address?.city} {res?.address?.village} {res?.address?.town}</p>
                    <p className="grow">{res?.address?.county} {res?.address?.municipality} {res?.address?.city_district} {res?.address?.state}</p>
                    <p className="grow">{res?.address?.country}</p>
                  </div>
                  <div className="flex flex-row grow">
                    {!["state", "building"].includes(res?.addresstype) && <p className="grow"></p>}
                    {res?.addresstype === "state" && <p className="italic text-xs grow">Bundesland</p>}
                    {res?.addresstype === "building" && <p className="italic text-xs grow">Gebäude</p>}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ObjectMap;
