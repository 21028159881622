import { t } from 'i18next';
import Typewriter from 'typewriter-effect';
import { auth } from '../firebase.config';

export const TypewriterSlogans = () => {

    const numberOfSlogans = 48;
    let slogans = [];

    for (let i = 1; i <= numberOfSlogans; i++) {
        slogans.push(`<span class='typewriter'>${t(`Slogans.${i}`)}</span>`);
    }

    for (let i = numberOfSlogans - 1; i > 0; i--) {
        // Generate a random index from 0 to i
        const j = Math.floor(Math.random() * (i + 1));
        
        // Swap elements at indices i and j
        [slogans[i], slogans[j]] = [slogans[j], slogans[i]];
    }

    if(!auth.currentUser) {
        slogans.unshift(`<span class='typewriter'>${t(`Slogans.000`)}</span>`)
        slogans.unshift(`<span class='typewriter'>${t(`Slogans.00`)}</span>`)
        slogans.unshift(`<span class='typewriter'>${t(`Slogans.0`)}</span>`)
    }

  return (
    <div className='flex flex-col justify-center items-center'>
        <img src={require('../assets/match.png')} alt='match' className='w-150 mmd:w-200 h-auto bg-orange-500'/>
        <div className=''>
            <Typewriter onInit={(typewriter) => {
                typewriter.typeString()
                .pauseFor(3000)
                .deleteAll()
                .start();
                }}
                
                options={{
                    strings: slogans,
                    delay: 50,
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 20
                }}
            />
        </div>
    </div>
  )
};

export default TypewriterSlogans
