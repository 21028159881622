import { CircularProgress, TextField } from '@mui/material';
import { useStateContext } from '../../contexts/ContextProvider';
import { Checkmark, CloseX, Pen } from '../../helper/icons';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { updateFirestoreDoc } from '../../helper/functions';
import { auth } from '../../firebase.config';

const Username = () => {

    const { customer } = useStateContext();
    const [newUsername, setNewUsername] = useState(customer?.name);
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const updateUsername = async () => {
        if(newUsername === "") return;
        if(newUsername.length > 50) return;
        if(newUsername === customer?.name) return setEditMode(false);
        setIsLoading(true);
        await updateFirestoreDoc('customers', auth.currentUser.uid, {name: newUsername})
        .then(() => {
            setIsLoading(false);
            setEditMode(false);
        })
        .catch((error) => {
            console.error("Error updating document: ", error);
            setIsLoading(false);
        });
    };

  return (
    <div>
        {editMode ?
        <div className='flex flex-row justify-center items-center gap-1 mt-2 px-3 py-2 bg-white rounded border mb-1 shadow-lg border-allimmoDark/50'>
            <input className='bg-transparent text-lg outline-none' value={newUsername} onChange={(e) => setNewUsername(e.target.value)}/>
            <Checkmark onClick={updateUsername} size={20} className='text-green-400 hover:text-green-600 bg-green-100 rounded border-2 border-green-500 cursor-pointer'/>
            <CloseX onClick={() => setEditMode(false)} size={20} className='text-red-400 hover:text-red-600 bg-red-100 rounded border-2 border-red-500 cursor-pointer'/>
        </div> :
        <>
            {isLoading ? <CircularProgress size={20} className='text-allimmoDark'/> :
            <div className='flex flex-row justify-center items-center gap-3 mt-2'>
                <p className='text-3xl text-allimmoDark'>{customer?.name === ""  ? t('Account.KeinUsername') : customer?.name}</p>
                {/* <Pen onClick={() => setEditMode(true)} size={20} className='text-allimmoDark/20 hover:text-allimmoDark/80 cursor-pointer'/> */}
            </div>}
        </>}
    </div>
  )
}

export default Username