import { t } from "i18next";
import { Checkmark, CloseX, Delete, DotOpen, EditPen, PlusCircle } from "../../helper/icons";
import { updateFirestoreDoc } from "../../helper/functions";
import { useEffect, useState } from "react";
import { Collapse } from "@mui/material";

const OpenAreas = ({ _objectData, editable }) => {

  const [addNew, setAddNew] = useState(false);
  const [newData, setNewData] = useState({
      type: "",
      size: "",
      id: Math.floor((Math.random() * 100000) + 1),
      public: false,
  });

  useEffect(() => {
    setNewData({
      type: "",
      size: "",
      id: Math.floor((Math.random() * 100000) + 1),
      public: false,
    });
  }, [addNew]);

  // useEffect(() => {
  //   console.log(newData);
  // }, [newData]);

  const deleteArea = async (index) => {
    await updateFirestoreDoc("objects", _objectData?.id, {openAreas: _objectData?.openAreas.filter((area) => area.id !== index)});
  };

  const createArea = async () => {
    await updateFirestoreDoc("objects", _objectData?.id, {openAreas: [..._objectData?.openAreas, newData]});
  };

  return (
    <div className="bg-white rounded flex flex-col w-full pb-2 border border-allimmoDark/70">
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 px-1.5 py-0.5 rounded-t">
        <p>{t("OpenAreas.Freiflächen")}</p>
        {editable && <PlusCircle onClick={() => setAddNew(!addNew)} size={18} className="cursor-pointer text-allimmoDark/50 hover:text-green-500 mr-0.5 active:text-allimmoDark/50" />}
      </div>
      {_objectData?.openAreas?.map((a, i) => {
        return (
          <div key={i} className="flex flex-row items-center px-2">
            <DotOpen size={15} className="text-allimmoDark mr-2" />
            <p className="grow">{t(`OpenAreas.AreaTypes.${a.type}`)}</p>
            <p>{a.size === "undefined" ? "? " : a.size}{a.type !== "parking" ? "m²" : ""}</p>
            {editable && <EditPen onClick={() => {}} size={14} className="cursor-pointer text-allimmoDark/20 hover:text-blue-400 ml-3 active:text-allimmoDark/20" />}
            {editable && <Delete onClick={() => deleteArea(a.id)} size={18} className="cursor-pointer text-allimmoDark/20 hover:text-red-400 ml-0.5 active:text-allimmoDark/20" />}
          </div>
        )
      })}
      {addNew &&
      <Collapse in={addNew} className="w-full">
        <div className="flex flex-col p-2 w-full">
          <div className="w-full flex flex-row items-center gap-1">
            <select onChange={(e) => setNewData({...newData, type: e.target.value})} defaultValue={"x"} placeholder={t("OpenAreas.AreaTypes.placeholder")} className="w-full border rounded p-1 border-allimmoDark/70">
              <option disabled value="x">Typ wählen</option>
              <option value="garden">{t("OpenAreas.AreaTypes.garden")}</option>
              <option value="balcony">{t("OpenAreas.AreaTypes.balcony")}</option>
              <option value="terrace">{t("OpenAreas.AreaTypes.roofTop")}</option>
              {/* <option value="parking">{t("OpenAreas.AreaTypes.parking")}</option> */}
              {/* <option value="loggia">{t("OpenAreas.AreaTypes.loggia")}</option> */}
            </select>
            <input onChange={(e) => setNewData({...newData, size: e.target.value})} value={newData.size} type="number" placeholder="Größe in m²" className="w-full border rounded p-1 border-allimmoDark/70" />
            <select onChange={(e) => setNewData({...newData, public: e.target.value})} defaultValue={"x"} placeholder={t("OpenAreas.AreaTypes.placeholder")} className="w-full border rounded p-1 border-allimmoDark/70">
              <option disabled value="x">Nutzungsart</option>
              <option value={true}>Allgemeinfläche</option>
              <option value={false}>Privatnutzung</option>
            </select>
            <div className="flex flex-row items-center gap-1">
              <Checkmark onClick={createArea} size={18} className="cursor-pointer text-allimmoDark/20 hover:text-green-500 ml-1 active:text-allimmoDark/20" />
              <CloseX onClick={() => setAddNew(false)} size={20} className="cursor-pointer text-allimmoDark/20 hover:text-red-500 ml-0.5 active:text-allimmoDark/20" />
            </div>
          </div>
        </div>
      </Collapse>}
    </div>
  );
};

export default OpenAreas;
