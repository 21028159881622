import { t } from "i18next";

const SquareButton = ({soon = false, label, clickFunction, icon, selected, color = 'allimmoDark', hoverEffect = true, subText = ""}) => {
    
    return (
        <div onClick={!soon ? clickFunction : null} className={`flex flex-col ${selected ? 'bg-sky-200' : 'bg-white'} ${hoverEffect && 'cursor-pointer'} ${(!selected && hoverEffect) && 'hover:bg-sky-50'} ${!soon ? 'active:bg-sky-100' : 'opacity-40 border-dashed'} justify-center items-center border-2 border-opacity-20 shadow-2xl border-allimmoDark/20 bg-opacity-50 border-${color} text-${color} aspect-square h-36 rounded-xl`}>
            <div>{icon}</div>
            <p className={`w-full overflow-hidden text-center mt-2 text-16`}>{label}</p>
            {soon && <p className="text-sm italic font-semibold">{t('General.Bald')}</p>}
            {subText !== "" && <p className="text-xs italic text-center w-full text-allimmoDark/60">{subText}</p>}
        </div>
    )
};

export default SquareButton
