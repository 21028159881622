import { green } from '@mui/material/colors';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Input from '../../Generics/Input';
import NavMenu from '../../Generics/NavMenu';
import AnimationIcon from '../../Generics/AnimationIcon';
import StageLabel from '../../Objects/Creation/StageLabel';
import SearchCheckBox from './SearchCheckBox';

const Name = ({next, back}) => {

    const { searchData, setSearchData } = useCreationContext();
    const { customerSearches } = useStateContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full md:w-1/2">
      <StageLabel color={false} stageIcon={require('../../../assets/animations/searchCreation/name.json')} labelText={t('CreateSearch.NameLabel')}/>
      <Input
          borderColor="allimmoDark"
          inputValue={searchData.name}
          changeInputValue={(e) => setSearchData({...searchData, name: e})}
          isValid={searchData.name !== "" && !customerSearches.some(s => s.name === searchData.name)}
          width={'w-full'}
      />
      <p className="text-allimmoDark text-xs w-full ml-2 mb-6">{searchData.name.length + "/" + t('CreateSearch.NameRestriction')}</p>
      <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded pr-2 ssm:pl-2 ssm:pr-3 h-14`}>
        <SearchCheckBox _size={1.3} name="searchOrder" _color={green["A700"]}/>
        <div className='grow'/>
        {searchData.searchOrder && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/searchOrder.json`)}/>}
      </div>

      <NavMenu nextBtn _next={next} _back={back} nextDisabled={searchData.name === "" || searchData.name.length > 30} />
    </div>
  )
};

export default Name
