import { t } from 'i18next';
import Button from '../../Generics/Button';
import { useCreationContext } from '../../../contexts/CreationProvider';
import SquareButton from '../../Generics/SquareButton';
import StageLabel from '../../Objects/Creation/StageLabel';
import AnimationIcon from '../../Generics/AnimationIcon';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useEffect } from 'react';
import NavMenu from '../../Generics/NavMenu';

const Style = ({next, back}) => {

    const { searchData, setSearchData } = useCreationContext();
    const { screenSize } = useStateContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleStyle = (newStyle) => {
        setSearchData({...searchData, building: newStyle});
        setTimeout(() => {
            next();
        }, "150");
    };

  return (
    <div className='w-full flex flex-col items-center gap-2.5 mt-10'>
        <StageLabel labelText={t('CreateSearch.StyleLabel')}/>
        <div className={`grid grid-cols-2 ssm:grid-cols-3 gap-2`}>
            <SquareButton lowerLineHeight dynamic selected={searchData.building === "new"} clickFunction={() => handleStyle("new")} icon={<AnimationIcon _color={false} _iconSize={screenSize.width > 500 ? 70 : 60} iconSrc={require('../../../assets/animations/searchCreation/style_new.json')}/>} label={t('Facilities.isNewBuilding')}/>
            <SquareButton lowerLineHeight dynamic selected={searchData.building === "old"} clickFunction={() => handleStyle("old")} icon={<AnimationIcon _color={false} _iconSize={screenSize.width > 500 ? 70 : 60} iconSrc={require('../../../assets/animations/searchCreation/style_old.json')}/>} label={t('Facilities.isOldBuilding')}/>
            <div className="col-span-2 ssm:col-span-1 place-self-center">
                <SquareButton lowerLineHeight dynamic selected={searchData.building === "-"} clickFunction={() => handleStyle("-")} icon={<AnimationIcon _color={false} _iconSize={screenSize.width > 500 ? 70 : 60} iconSrc={require('../../../assets/animations/searchCreation/style_no.json')}/>} label={t('CreateSearch.Egal')}/>
            </div>
        </div>
        <NavMenu _back={back} />
    </div>
  )
}

export default Style
