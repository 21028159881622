const serachesData = [
    // 1
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: false, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: true, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        created: new Date(),
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,180000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 2
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: false, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        country: "AT",
        created: new Date(),
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,180000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false,
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 3
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: false, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: true, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        country: "AT",
        created: new Date(),
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,200000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 4
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: true, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        country: "AT",
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,149000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 5
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: false, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        created: new Date(),
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,159000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 6
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: false, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        created: new Date(),
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,210000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 7
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: false, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,130000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 8
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: false, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        created: new Date(),
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,158000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 9
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: false, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        created: new Date(),
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,167000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 10
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: true, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,170000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 11
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: false, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,160000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
    // 12
    {
        accessible: false, 
        activeBusiness: "-", 
        airCondition: false, 
        areas: [1,100], 
        areasUnlimited: false, 
        attic: "-", 
        availableFrom: "",
        avoidCompensation: false, 
        balcony: false, 
        bathtub: false, 
        bike: false, 
        blinds: false, 
        building: "-", 
        buildingSite: false, 
        cellar: false, 
        chimney: false, 
        comission: false, 
        commercialType: "", 
        cooling: false, 
        deal: "sale", 
        developed: false, 
        elevator: false, 
        first: false, 
        fitness: false, 
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false, 
        floorUnlimited: false,
        furnished: "-", 
        garage: false, 
        garden: false, 
        groundFloor: "-", 
        heatingMulti: { 
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false, 
        isolated: "", 
        kitchen: false, 
        land: [0,30000], 
        landUnlimited: false, 
        laundry: false, 
        licensing: [], 
        limited: false, 
        minimumFloor: 0, 
        name: `Suche ${Math.floor((Math.random() * 100000) + 1)}`, 
        new: false, 
        noGas: false, 
        noOldGrowth: false, 
        noPublicAreas: false, 
        openAreas: "-", 
        pets: false, 
        params: 0,
        parkingLots: false, 
        parkingLotsMin: 1, 
        polygon: [], 
        pool: false, 
        presentIndustry: "?", 
        price: [0,155000], 
        priceUnlimited: false, 
        project: false,
        renovation: false, 
        renovationRatio: [0,100], 
        roofTop: false, 
        rooms: [1,5], 
        roomsUnlimited: false, 
        searchOrder: false, 
        sauna: false,
        size: [0,100], 
        sizeUnlimited: false, 
        shortTermRent: false, 
        storageRoom: false, 
        type: "apartment", 
        units: [2,10], 
        unitsUnlimited: false, 
        unlimited: false, 
        urgency: "normal", 
        vacancy: ["0", "100"], 
        vacant: false, 
    },
];

const maxSearchAgeInWeeks = 8;

export const matchingAnalysis = async (object) => {
    
    console.log("ANALYSIS STARTED")
    const startTime = new Date().getTime();
    const searches = serachesData.filter(s => s.deal === object.deal && s.type === object.type && ((new Date() - s.created) < (maxSearchAgeInWeeks * 604800000)))
    let analysisCounts = {
        original: object.matches.length,
        priceMinus1: 0,
        priceMinus3: 0,
        priceMinus5: 0,
        priceMinus7: 0,
        priceMinus10: 0,
        priceMinus12: 0,
        priceMinus15: 0,
        notFurnished: 0,
        furnished: 0,
        facilities: {
            airCondition: 0,
            bathtub: 0,
            blinds: 0,
            kitchen: 0,
            cooling: 0,
        },
        noGas: 0,
        renovated: 0,
        noCompensation: 0,
        parking: {
            private1: 0,
            private2: 0,
            private5: 0,
            public1: 0,
            public2: 0,
            public5: 0,
        },
        searches: searches.length,
        duration: 0,
    }
    const objectPriceMinus1 = {...object, price: parseFloat(object.price) * 0.99};
    const objectPriceMinus3 = {...object, price: parseFloat(object.price) * 0.97};
    const objectPriceMinus5 = {...object, price: parseFloat(object.price) * 0.95};
    const objectPriceMinus7 = {...object, price: parseFloat(object.price) * 0.93};
    const objectPriceMinus10 = {...object, price: parseFloat(object.price) * 0.9};
    const objectPriceMinus12 = {...object, price: parseFloat(object.price) * 0.88};
    const objectPriceMinus15 = {...object, price: parseFloat(object.price) * 0.85};

    if(object.type !== "land" && !object.airCondition) {
        const objectAirCondition = {...object, airCondition: true};
        for (const search of searches) {
            if(await matching(objectAirCondition, search)) {
                analysisCounts = {...analysisCounts, facilities: {...analysisCounts.facilities, airCondition: analysisCounts.facilities.airCondition + 1}};
            }
        }
    }

    if((["apartment", "house"].includes(object.type) || (object.type === "commercial" && ["apartment", "salesroom"].includes(object.commercialType))) && !object.blinds) {
        const objectBlinds = {...object, blinds: true};
        for (const search of searches) {
            if(await matching(objectBlinds, search)) {
                analysisCounts = {...analysisCounts, facilities: {...analysisCounts.facilities, blinds: analysisCounts.facilities.blinds + 1}};
            }
        }
    }

    if((["apartment", "house"].includes(object.type) || (object.type === "commercial" && object.commercialType !== "hall")) && !object.kitchen) {
        const objectKitchen = {...object, kitchen: true};
        for (const search of searches) {
            if(await matching(objectKitchen, search)) {
                analysisCounts = {...analysisCounts, facilities: {...analysisCounts.facilities, kitchen: analysisCounts.facilities.kitchen + 1}};
            }
        }
    }

    if(["apartment", "house"].includes(object.type) && !object.bathtub) {
        const objectBathtub = {...object, bathtub: true};
        for (const search of searches) {
            if(await matching(objectBathtub, search)) {
                analysisCounts = {...analysisCounts, facilities: {...analysisCounts.facilities, bathtub: analysisCounts.facilities.bathtub + 1}};
            }
        }
    }

    if(object.type !== "land" && object.heating === "gas") {
        const objectNoGas = {...object, heating: "fern"};
        for (const search of searches) {
            if(await matching(objectNoGas, search)) {
                analysisCounts = {...analysisCounts, noGas: analysisCounts.noGas + 1};
            }
        }
    }

    if(parseFloat(object.compensation) > 0) {
        const objectNoCompensation = {...object, compensation: 0};
        for (const search of searches) {
            if(await matching(objectNoCompensation, search)) {
                analysisCounts = {...analysisCounts, noCompensation: analysisCounts.noCompensation + 1};
            }
        }
    }

    if(object.type === "commercial" && !object.cooling) {
        const objectCooling = {...object, cooling: true};
        for (const search of searches) {
            if(await matching(objectCooling, search)) {
                analysisCounts = {...analysisCounts, facilities: {...analysisCounts.facilities, cooling: analysisCounts.facilities.cooling + 1}};
            }
        }
    }

    // PARKING
    if(object.type === "commercial" && !object.openAreas.some(e => e.type === "parking")) {
        const objectParking = {...object, openAreas: [...object.openAreas, {id: 1, public: false, size: 1, type: "parking"}]};
        for (const search of searches) {
            if(await matching(objectParking, search)) {
                analysisCounts = {...analysisCounts, parking: {...analysisCounts.parking, private1: analysisCounts.parking.private1 + 1}};
            }
        }
    }

    if(object.type === "commercial" && (!object.openAreas.some(e => e.type === "parking") || object.openAreas.some(e => e.type === "parking" && e.size < 2))) {
        const objectParking = {...object, openAreas: [...object.openAreas, {id: 1, public: false, size: 2, type: "parking"}]};
        for (const search of searches) {
            if(await matching(objectParking, search)) {
                analysisCounts = {...analysisCounts, parking: {...analysisCounts.parking, private2: analysisCounts.parking.private2 + 1}};
            }
        }
    }

    if(object.type === "commercial" && (!object.openAreas.some(e => e.type === "parking") || object.openAreas.some(e => e.type === "parking" && e.size < 3))) {
        const objectParking = {...object, openAreas: [...object.openAreas, {id: 1, public: false, size: 5, type: "parking"}]};
        for (const search of searches) {
            if(await matching(objectParking, search)) {
                analysisCounts = {...analysisCounts, parking: {...analysisCounts.parking, private5: analysisCounts.parking.private5 + 1}};
            }
        }
    }

    if(object.type === "commercial" && !object.openAreas.some(e => e.type === "parking")) {
        const objectParking = {...object, openAreas: [...object.openAreas, {id: 1, public: true, size: 1, type: "parking"}]};
        for (const search of searches) {
            if(await matching(objectParking, search)) {
                analysisCounts = {...analysisCounts, parking: {...analysisCounts.parking, public1: analysisCounts.parking.public1 + 1}};
            }
        }
    }

    if(object.type === "commercial" && (!object.openAreas.some(e => e.type === "parking") || object.openAreas.some(e => e.type === "parking" && e.size < 2))) {
        const objectParking = {...object, openAreas: [...object.openAreas, {id: 1, public: true, size: 2, type: "parking"}]};
        for (const search of searches) {
            if(await matching(objectParking, search)) {
                analysisCounts = {...analysisCounts, parking: {...analysisCounts.parking, public2: analysisCounts.parking.public2 + 1}};
            }
        }
    }

    if(object.type === "commercial" && (!object.openAreas.some(e => e.type === "parking") || object.openAreas.some(e => e.type === "parking" && e.size < 3))) {
        const objectParking = {...object, openAreas: [...object.openAreas, {id: 1, public: true, size: 5, type: "parking"}]};
        for (const search of searches) {
            if(await matching(objectParking, search)) {
                analysisCounts = {...analysisCounts, parking: {...analysisCounts.parking, public5: analysisCounts.parking.public5 + 1}};
            }
        }
    }

    if(object.type !== "land" && object.furnished === "furnished") {
        const objectNotFurnished = {...object, furnished: "-"};
        for (const search of searches) {
            if(await matching(objectNotFurnished, search)) {
                analysisCounts = {...analysisCounts, notFurnished:  analysisCounts.notFurnished + 1};
            }
        }
    }

    if(object.type !== "land" && ["-", "notFurnished", "notfurnished"].includes(object.furnished)) {
        const objectFurnished = {...object, furnished: "furnished"};
        for (const search of searches) {
            if(await matching(objectFurnished, search)) {
                analysisCounts = {...analysisCounts, furnished:  analysisCounts.furnished + 1};
            }
        }
    }

    if(object.type !== "land" && object.condition === "renovation") {
        const objectRenovated = {...object, condition: "new"};
        for (const search of searches) {
            if(await matching(objectRenovated, search)) {
                analysisCounts = {...analysisCounts, renovated:  analysisCounts.renovated + 1};
            }
        }
    }
    
    // original object matches
    // for (const search of searches) {
    //     if(await matching(object, search)) {
    //         analysisCounts = {...analysisCounts, original: analysisCounts.original + 1};
    //     }
    // }

    // price minus 1%
    for (const search of searches) {
        if(await matching(objectPriceMinus1, search)) {
            analysisCounts = {...analysisCounts, priceMinus1: analysisCounts.priceMinus1 + 1};
        }
    }

    // price minus 3%
    for (const search of searches) {
        if(await matching(objectPriceMinus3, search)) {
            analysisCounts = {...analysisCounts, priceMinus3: analysisCounts.priceMinus3 + 1};
        }
    }

    // price minus 5%
    for (const search of searches) {
        if(await matching(objectPriceMinus5, search)) {
            analysisCounts = {...analysisCounts, priceMinus5: analysisCounts.priceMinus5 + 1};
        }
    }

    // price minus 7%
    for (const search of searches) {
        if(await matching(objectPriceMinus7, search)) {
            analysisCounts = {...analysisCounts, priceMinus7: analysisCounts.priceMinus7 + 1};
        }
    }

    // price minus 10%
    for (const search of searches) {
        if(await matching(objectPriceMinus10, search)) {
            analysisCounts = {...analysisCounts, priceMinus10: analysisCounts.priceMinus10 + 1};
        }
    }

    //  price minus 12%
    for (const search of searches) {
        if(await matching(objectPriceMinus12, search)) {
            analysisCounts = {...analysisCounts, priceMinus12: analysisCounts.priceMinus12 + 1};
        }
    }

    // price minus 15%
    for (const search of searches) {
        if(await matching(objectPriceMinus15, search)) {
            analysisCounts = {...analysisCounts, priceMinus15: analysisCounts.priceMinus15 + 1};
        }
    }
    
    // console.log("SUCHEN ANALYSIERT: ", searches.length);
    // console.log("MATCHES: ", analysisCounts.original);
    // console.log("PRICE -1% ERGEBNIS: +", analysisCounts.priceMinus1 - analysisCounts.original);
    // console.log("PRICE -3% ERGEBNIS: +", analysisCounts.priceMinus3 - analysisCounts.original);
    // console.log("PRICE -5% ERGEBNIS: +", analysisCounts.priceMinus5 - analysisCounts.original);
    // console.log("PRICE -7% ERGEBNIS: +", analysisCounts.priceMinus7 - analysisCounts.original);
    // console.log("PRICE -10% ERGEBNIS: +", analysisCounts.priceMinus10 - analysisCounts.original);
    // console.log("PRICE -12% ERGEBNIS: +", analysisCounts.priceMinus12 - analysisCounts.original);
    // console.log("PRICE -15% ERGEBNIS: +", analysisCounts.priceMinus15 - analysisCounts.original);

    // if((["apartment", "house"].includes(object.type) || (object.type === "commercial" && object.commercialType !== "hall")) && !object.kitchen) {
    //     console.log("KÜCHE ERGEBNIS: +", analysisCounts.facilities.kitchen - analysisCounts.original);
    // }

    // if(object.type !== "land" && !object.airCondition) {
    //     console.log("KLIMANLAGE ERGEBNIS: +", analysisCounts.facilities.airCondition - analysisCounts.original);
    // }

    // if((["apartment", "house"].includes(object.type) || (object.type === "commercial" && ["apartment", "salesroom"].includes(object.commercialType))) && !object.blinds) {
    //     console.log("VERSCHATTUNG ERGEBNIS: +", analysisCounts.facilities.blinds - analysisCounts.original);
    // }

    // if(["apartment", "house"].includes(object.type) && !object.bathtub) {
    //     console.log("BADEWANNE ERGEBNIS: +", analysisCounts.facilities.bathtub - analysisCounts.original);
    // }

    // if(object.type !== "land" && object.furnished === "furnished") {
    //     console.log("OHNE MÖBLIERUNG ERGEBNIS: +", analysisCounts.notFurnished - analysisCounts.original);
    // }

    // if(object.type !== "land" && ["-", "notFurnished", "notfurnished"].includes(object.furnished)) {
    //     console.log("MIT MÖBLIERUNG ERGEBNIS: +", analysisCounts.furnished - analysisCounts.original);
    // }

    // if(object.type !== "land" && object.heating === "gas") {
    //     console.log("KEIN GAS ERGEBNIS: +", analysisCounts.noGas - analysisCounts.original);
    // }

    // if(object.type !== "land" && object.condition === "renovation") {
    //     console.log("RENOVATION ERGEBNIS: +", analysisCounts.renovated - analysisCounts.original);
    // }

    // if(object.type === "commercial" && !object.cooling) {
    //     console.log("COOLING ERGEBNIS: +", analysisCounts.facilities.cooling - analysisCounts.original);
    // }

    // if(parseFloat(object.compensation) > 0) {
    //     console.log("NO COMPENSATION ERGEBNIS: +", analysisCounts.noCompensation - analysisCounts.original);
    // }

    const endTime = new Date().getTime();
    analysisCounts = {...analysisCounts, duration: (endTime - startTime)};
    // console.log("ANALYSE DAUER:", endTime - startTime, "ms");

    return {...analysisCounts};
};

const matching = async (obj, search) => {
    const areasPublic = obj.openAreas.filter((area) => area?.public);
    const areasPrivate = obj.openAreas.filter((area) => !area?.public);
    //####################
    const objectVacancyRatio = obj.type === "multi" ? (parseInt(obj.unitsState.vacant) / parseInt(obj.units)) * 100 : 0;
    const objectRenovationRatio = obj.type === "multi" ? (parseInt(obj.unitsCondition.renovation) / parseInt(obj.units)) * 100 : 0;
    //####################

    const publicSize = areasPublic.reduce((acc, o) => {
        return acc + parseFloat(o.size);
    }, 0);

    const privateSize = areasPrivate.reduce((acc, o) => {
        return acc + parseFloat(o.size);
    }, 0);

    return (
        (search.type === obj.type) &&
        (search.deal === obj.deal) &&
        (search.openAreas === "no" ? areasPrivate.length === 0 : true) &&
        (search.openAreas === "yes" ? obj.openAreas.length > 0 : true) &&
        ((search.balcony && !search.garden && !search.roofTop) ? obj.openAreas.some((a) => a.type === "balcony") : true) &&
        ((search.garden && !search.balcony && !search.roofTop) ? obj.openAreas.some((a) => a.type === "garden") : true) &&
        ((search.roofTop && !search.balcony && !search.garden) ? obj.openAreas.some((a) => a.type === "roofTop") : true) &&
        ((search.balcony && search.garden && !search.roofTop) ? obj.openAreas.some((a) => ["balcony", "garden"].includes(a.type)) : true) &&
        ((search.garden && !search.balcony && search.roofTop) ? obj.openAreas.some((a) => ["garden", "roofTop"].includes(a.type)) : true) &&
        ((search.roofTop && search.balcony && !search.garden) ? obj.openAreas.some((a) => ["balcony", "roofTop"].includes(a.type)) : true) &&
        (search.openAreas === "yes" ? (search.noPublicAreas ? privateSize >= Math.min(...search.areas) : privateSize + publicSize >= Math.min(...search.areas)) : true) &&
        ((search.openAreas === "yes" && !search.areasUnlimited) ? (search.noPublicAreas ? privateSize <= Math.max(...search.areas) : privateSize + publicSize <= Math.max(...search.areas)) : true) &&
        (parseFloat(Math.min(...search.price)) <= parseFloat(obj.price)) &&
        (!search.priceUnlimited ? parseFloat(Math.max(...search.price)) >= parseFloat(obj.price) : true) &&
        (!search.roomsUnlimited ? parseFloat(Math.max(...search.rooms)) >= parseFloat(obj.rooms) : true) &&
        (parseFloat(Math.min(...search.rooms)) <= parseFloat(obj.rooms)) &&
        (parseFloat(Math.min(...search.size)) <= parseFloat(obj.size)) &&
        (!search.sizeUnlimited ? parseFloat(Math.max(...search.size)) >= parseFloat(obj.size) : true) &&
        (parseFloat(Math.min(...search.land)) <= parseFloat(obj.land)) &&
        (!search.landUnlimited ? parseFloat(Math.max(...search.land)) >= parseFloat(obj.land) : true) &&
        (parseFloat(obj.compensation) > 0 ? !search.avoidCompensation : true) &&

        (search.type === "multi" ? (
            (parseInt(Math.min(...search.units)) <= parseInt(obj.units)) &&
            (!search.unitsUnlimited ? parseInt(Math.max(...search.units)) >= parseInt(obj.units) : true) &&
            // (parseInt(Math.min(...search.vacancy)) <= objectVacancyRatio) &&
            // (parseInt(Math.max(...search.vacancy)) >= objectVacancyRatio) &&
            (parseInt(Math.min(...search.renovationRatio)) <= objectRenovationRatio) &&
            (parseInt(Math.max(...search.renovationRatio)) >= objectRenovationRatio) &&
            (obj.heating === "gas" ? search.heatingMulti.gas : true) &&
            (obj.heating === "fern" ? search.heatingMulti.fern : true) &&
            (obj.heating === "electric" ? search.heatingMulti.electric : true) &&
            (obj.heating === "oil" ? search.heatingMulti.oil : true) &&
            (obj.heating === "pump" ? search.heatingMulti.pump : true) &&
            (obj.heating === "pellets" ? search.heatingMulti.pellets : true) &&
            (obj.heating === "otherHeating" ? search.heatingMulti.otherHeating : true)
        ) : true) &&

        (search.type === "commercial" ? (
            (obj.commercialType === search.commercialType) && //
            (search.presentIndustry === "?" ? true : obj.presentIndustry === search.presentIndustry) && //
            (obj.cooling ? true : !search.cooling) && //
            (obj.industrialLicense ? true : !search.industrialLicense) && //
            (search.licensing.every(e => obj.licensing.includes(e))) && //
            (obj.activeBusiness ? search.activeBusiness !== "no" : search.activeBusiness !== "yes") && //
            (search.parkingLots ? obj.openAreas.some((a) => a.type === "parking" && a.size >= parseInt(search.parkingLotsMin)) : true) //
        ) : true) &&


        (["multi", "commercial"].includes(search.type) ? (
            (parseInt(Math.min(...search.vacancy)) <= objectVacancyRatio) &&
            (parseInt(Math.max(...search.vacancy)) >= objectVacancyRatio)
        ) : true) &&

        (search.type === "land" ? (
            (obj.buildingSite ? true : !search.buildingSite) &&
            (obj.developed ? search : !search.developed) &&
            (obj.oldGrowth ? !search.noOldGrowth : true)
        ) : true) &&

        (search.type === "apartment" ? (
            (obj.floor === "attic" ? search.attic !== "no" : true) &&
            (obj.floor === "groundFloor" ? search.groundFloor !== "no" : true) &&
            (("exactFloor" in search && "floor" in search) ?
            (search.exactFloor === true ? true : (
                (!search.floorUnlimited ? parseFloat(Math.max(...search.floor)) >= parseFloat(obj.floor) : true) &&
                (parseFloat(Math.min(...search.floor)) <= parseFloat(obj.floor)))
            ) : true)
        ) : true) &&

        (search.type !== "land" ? (
            (obj.bike ? true : !search.bike) &&
            (obj.fitness ? true : !search.fitness) &&
            (obj.laundry ? true : !search.laundry) &&
            (obj.airCondition ? true : !search.airCondition) &&
            (obj.bathtub ? true : !search.bathtub) &&
            (obj.blinds ? true : !search.blinds) &&
            (obj.cellar ? true : !search.cellar) &&
            (obj.sauna ? true : !search.sauna) &&
            (obj.elevator ? search : !search.elevator) &&
            (obj.chimney ? true : !search.chimney) &&
            (obj.accessible ? true : !search.accessible) &&
            (obj.garage ? true : !search.garage) &&
            (obj.kitchen ? true : !search.kitchen) &&
            (obj.floorHeating ? true : !search.floorHeating) &&
            (obj.pets ? true : !search.pets) &&
            (obj.storageRoom ? true : !search.storageRoom) &&
            (obj.shortTermRent ? true : !search.shortTermRent) &&
            (obj.pool ? true : !search.pool) &&
            (obj.furnished !== "-" ? search.furnished !== "no" : search.furnished !== "yes") &&
            (search.building !== "-" ? obj.building === search.building : true) &&
            (search.noGas ? obj.heating !== "gas" : true) &&
            (search.isolated === "yes" ? obj.houseType === "isolated" : true) &&
            ((search.new && !search.first && !search.renovation) ? obj.condition !== "renovation" : true) &&
            ((!search.new && search.first && !search.renovation) ? obj.condition === "first" : true) &&
            ((!search.new && !search.first && search.renovation) ? obj.condition === "renovation" : true) &&
            ((search.new && search.first && !search.renovation) ? obj.condition !== "renovation" : true) &&
            ((!search.new && search.first && search.renovation) ? obj.condition !== "new" : true) &&
            ((search.new && !search.first && search.renovation) ? obj.condition !== "first" : true) &&
            ((search.vacant && !search.limited && !search.unlimited) ? obj.vacancy === "free" : true) &&
            ((!search.vacant && search.limited && !search.unlimited) ? obj.vacancy === "limited" : true) &&
            ((!search.vacant && !search.limited && search.unlimited) ? obj.vacancy === "unlimited" : true) &&
            ((!search.vacant && search.limited && search.unlimited) ? obj.vacancy !== "free" : true) &&
            ((search.vacant && !search.limited && search.unlimited) ? obj.vacancy !== "limited" : true) &&
            ((search.vacant && search.limited && !search.unlimited) ? obj.vacancy !== "unlimited" : true)
        ) : true)
    );
};
