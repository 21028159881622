import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react'

const Team = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`flex flex-col justify-center items-center w-screen md:w-3/4 mdd:w-1100 px-2 ssm:px-5 pb-20 text-justify`}>
      <h1 className='text-2xl ssm:text-4xl my-5'>{t('Team.Header')}</h1>
      <p className='mb-3'>{t('Team.Start')}</p>
      
      <div className='flex flex-row justify-center items-center gap-1 ssm:gap-2 md:gap-3 mt-10 mb-3'>
        <TeamMember image={"https://firebasestorage.googleapis.com/v0/b/allimmo-fb343.appspot.com/o/team%2FIMG_1513.JPG?alt=media&token=980eb545-8071-4269-8c91-c487e22ee498"} name={"Joshua Krick"}/>
        <TeamMember image={"https://firebasestorage.googleapis.com/v0/b/allimmo-fb343.appspot.com/o/team%2FIMG_1506%202.JPG?alt=media&token=b081cc19-2049-475a-af3e-34fc06d5224e"} name={"DI Vivienne Pour"}/>
        <TeamMember image={"https://firebasestorage.googleapis.com/v0/b/allimmo-fb343.appspot.com/o/team%2FIMG_1510.JPG?alt=media&token=73a050e4-2b98-4edf-ac96-7643e36c62df"} name={"Julian Wimmer"}/>
      </div>
      <h3 className='italic text-13 w-full md:w-2/3 text-center'>{t('Team.Team')}</h3>
      <p className='mb-3 mt-10'>{t('Team.Middle')}</p>
      <p className='mb-5'>{t('Team.Text')}</p>
      <p className='text-lg md:text-xl text-center'>{t('Team.End')}</p>
    </div>
  )
};

const TeamMember = ({name, image}) => {

    const [loading, setLoading] = useState(true);
    const [imageSrc, setImageSrc] = useState();

    useEffect(() => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          setLoading(false); // Ladezustand beenden
          setImageSrc(image); // Aktualisieren Sie den Bildquellpfad auf das eigentliche Bild
        };
      }, [image]);

  return (
    <div className={`${!loading ? 'relative w-1/3 mdd:w-1/4 lg:w-1/6 h-auto rounded-lg shadow-lg border-2 border-white' : ''} flex flex-row justify-center items-center`}>
        {loading ? <div className='p-5 opacity-60'><CircularProgress size={50}/></div> :
        <>
        <img className={`rounded-lg w-full h-auto`} src={image} alt={name}/>
        <p className='absolute bottom-0 z-10 text-center text-sm ssm:text-base md:text-lg w-full bg-white bg-opacity-60 rounded-b-lg'>{name}</p>
        </>}
    </div>
  )
}

export default Team