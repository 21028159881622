import { t } from 'i18next';
import { useStateContext } from '../contexts/ContextProvider';
import { auth } from '../firebase.config';
import { convertShortTimestamp } from '../helper/functions';
import { Checkmark, Plus, Upgrade } from '../helper/icons';
import { useEffect } from 'react';

const Updates = () => {

    const { updates } = useStateContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='flex flex-col items-center px-1 sm:px-3 select-none gap-2 w-full py-10'>
        {/* <p className='text-xl text-allimmoDark pb-2 text-center'>{t('Updates.Header')}</p> */}
        {updates?.sort((a,b) => b.created - a.created).map((u,i) => {
            return (
                <div key={i} className='flex flex-col border border-allimmoDark rounded-lg w-full mmd:w-600 px-3 p-2 sm:px-4 sm:p-3 bg-white shadow-xl'>
                    <Update data={u} />
                </div>
            )
        })}
    </div>
  )
};

const Update = ({data}) => {

    const updateDay = new Date(data.created.toDate()).getDate();
    const updateMonth = new Date(data.created.toDate()).getMonth();
    const updateYear = new Date(data.created.toDate()).getFullYear();

    const lastloginDay = new Date(auth.currentUser.metadata.lastSignInTime).getDate();
    const lastLoginMonth = new Date(auth.currentUser.metadata.lastSignInTime).getMonth();
    const lastLoginYear = new Date(auth.currentUser.metadata.lastSignInTime).getFullYear();

    const isNew = lastLoginYear <= updateYear && lastLoginMonth <= updateMonth && lastloginDay <= updateDay; 

    return (
        <div className={`flex flex-col ${isNew ? "opacity-100" : "opacity-50"}`}>
          <div className='flex flex-row items-center gap-2'>
            {isNew && <Upgrade size={15} className='text-orange-400' />}
            <p className='text-xl text-allimmoDark'>{data?.title !== "" ? data?.title : t('Updates.NoTitleHeader')}</p>
          </div>
          <p className='text-sm italic -mt-1 mb-2'>{`${t('Updates.Version')} ${data.version}`}</p>
          <p className='mt-2 font-semibold'>{t('Updates.Features')}</p>
          {data.features.map((f,i) => {
              return(
                  <div key={i} className='flex flex-row gap-2 items-center'>
                      <Plus size={15} />
                      <div className='flex flex-col'>
                          <p>{f}</p>
                      </div>
                  </div>
              )
          })}
          <p className='mt-3 font-semibold'>{t('Updates.Fixes')}</p>
          {data.bugfixes.map((f,i) => {
              return(
                  <div key={i} className='flex flex-row gap-2 items-center'>
                      <Checkmark size={15} />
                      <div className='flex flex-col'>
                          <p>{f}</p>
                      </div>
                  </div>
              )
          })}
          <p className='text-xs italic w-full text-end mt-5'>{convertShortTimestamp(data.created.toDate())}</p>
        </div>
    )
};

export default Updates
