import { MenuItem, TextField } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { industries } from '../../../helper/arrays';
import { DeleteIcon } from '../../../helper/icons';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from '../../Objects/Creation/StageLabel';

const SearchLicensing = ({back, next}) => {

    const { searchData, setSearchData } = useCreationContext();

    const [showOther, setShowOther] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const addLicense = (license) => {
        setShowOther(false);
        if(license === "") return;
        if(searchData?.licensing?.some(el => el.name === license)) return;

        setSearchData({...searchData, licensing: [...searchData?.licensing, license]});
    };

    const deleteLicense = (license) => {
        setSearchData({...searchData, licensing: searchData?.licensing.filter(item => item !== license)});
    };

    const resetLicenses = () => {
        setSearchData({...searchData, licensing: []});
    };
  
  return (
    <div className='w-full ssm:w-2/3 md:w-1/2 flex flex-col justify-center items-center py-2 sm:py-5 gap-2'>
        <StageLabel headline={t('CreateSearch.Licensing.Headline')} color={false} labelText={t('CreateSearch.Licensing.Label')}/>
        <div className='w-full px-0.5'>
            <TextField disabled={searchData?.licensing.length === industries.length} value="-" select className='w-full bg-white' onChange={(e) => addLicense(e.target.value)} label={t('CreateObject.Licensing.SelectLicense')}>
                <MenuItem value="-"><p className='font-semibold'>{t('CreateObject.Licensing.SelectLicense')}</p></MenuItem>
                {industries.map((item, index) => {
                    if(searchData?.licensing.some(el => el === item.name)) return null;
                    return (
                        <MenuItem key={index} value={item.name} >
                            <div className={`flex flex-row gap-3 items-center`}>
                                <div className='text-2xl'>{item.icon}</div>
                                <p className='text-allimmoDark'>{t(`CreateObject.Industries.${item.name}`)}</p>
                            </div>
                        </MenuItem>
                    )
                })}
            </TextField>
            {(searchData?.licensing.length === 0 && !showOther) && <p className='text-allimmoDark text-lg w-full text-center mt-3'>{t('CreateObject.Licensing.NoLicense')}</p>}
            <div className='flex flex-col w-full mt-3 gap-2'>
                {searchData?.licensing.map((item, index) => (
                    <div key={index} className='flex flex-row items-center gap-3 px-1'>
                        {industries.find(el => el.name === item).icon}
                        <p className='grow'>{t(`CreateObject.Industries.${item}`)}</p>
                        <DeleteIcon className='text-red-500 cursor-pointer hover:text-red-300' size={20} onClick={() => deleteLicense(item)}/>
                    </div>
                    )
                )}
            </div>
            
            {searchData?.licensing.length > 0 && <p className='text-red-500 cursor-pointer hover:text-red-300 mt-3' onClick={resetLicenses}>{t('CreateObject.Licensing.Reset')}</p>}
        </div>
        <NavMenu _back={back} nextBtn _next={next} />
    </div>
  )
};

const MenuItemElement = ({icon, label}) => {
    return (
        <div className={`flex flex-row gap-5 items-center`}>
            {icon}
            <p className='text-allimmoDark'>{t(`CreateObject.Industries.${label}`)}</p>
        </div>
    )
};

export default SearchLicensing
