import { CircularProgress } from "@mui/material";

const Button = ({
  useMobile = true,
  txColor = "black",
  loading = false,
  size,
  text,
  borderRadius,
  clickFunction,
  width,
  marginTop,
  icon = null,
  isDisabled = false,
  buttonType = "button",
  colorSetting = "bg-blue-100 hover:bg-blue-200 active:bg-blue-100",
  borderColor = "border-blue-400",
}) => {

  const handleTouch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      clickFunction();
    }, "50");
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      clickFunction();
    }, "50");
  };

  return (
    <button
      onTouchEnd={handleTouch}
      type={buttonType}
      disabled={loading || isDisabled}
      onClick={handleClick}
      style={{ borderRadius, marginTop, width }}
      className={`flex flex-row justify-center ${
        isDisabled || loading ? "pointer-events-none" : ""
      } items-center text-${size} px-5 py-1 ${
        !isDisabled
          ? `cursor-pointer ${colorSetting}`
          : "bg-slate-100 text-slate-400"
      } border ${borderColor} rounded select-none`}
    >
      {loading ? (
        <CircularProgress className="p-1" size={25} />
      ) : icon === null ? (
        <p className={`text-${txColor}`}>{text}</p>
      ) : (
        icon
      )}
    </button>
  );
};

export default Button;
