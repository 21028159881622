import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import StageLabel from './StageLabel';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import validator from 'validator';
import NavMenu from '../../Generics/NavMenu';

const UnitsCondition = ({next, back}) => {

    const {objectData, setObjectData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  const resetZeroInput = (prop) => {
    if([NaN, "NaN", "", 0, "0"].includes(objectData.unitsCondition[prop])) {
      setObjectData({...objectData, unitsCondition: {...objectData.unitsCondition, [prop]: ""}});
    }
  };

  const resetOnBlur = (prop) => {
    if([NaN, "NaN", "", 0, "0"].includes(objectData.unitsCondition[prop])) {
      setObjectData({...objectData, unitsCondition: {...objectData.unitsCondition, [prop]: "0"}});
    }
  };

  const sum = (validator.isInt(objectData?.unitsCondition?.first) ? parseInt(objectData?.unitsCondition?.first) : 0) + (validator.isInt(objectData?.unitsCondition?.new) ? parseInt(objectData?.unitsCondition?.new) : 0) + (validator.isInt(objectData?.unitsCondition?.renovation) ? parseInt(objectData?.unitsCondition?.renovation) : 0)

  return (
    <div className='w-full sm:w-2/3 md:w-1/2 flex flex-col justify-center items-center py-2 sm:py-5'>
        <StageLabel color={false} stageIcon={require('../../../assets/animations/objectCreation/paperBoat.json')} labelText={t('CreateObject.UnitsConditionLabel')}/>
        <div className='flex flex-col items-center my-3'>
          <p>{t("CreateObject.VerfügbareWohneinheiten")}</p>
          <p className={`text-xl ${sum <= objectData.units ? 'text-allimmoDark' : 'text-red-500 text-base'} -mt-1`}>{objectData.units - sum >= 0 ? `${objectData.units - sum}/${objectData.units}` : "zu viele Einheiten zugeteilt"}</p>
        </div>
        
        <div className='flex flex-col gap-3 mt-3 w-full'>
          <TextField onBlur={() => resetOnBlur("first")} onClick={() => resetZeroInput("first")} autoComplete='off' className='w-full rounded bg-white' size='small' label={t('Zustände.Erstbezug')} type='number' value={objectData?.unitsCondition.first} onChange={(e) => setObjectData({...objectData, unitsCondition: {...objectData.unitsCondition, first: e.target.value}})}/>
          <TextField onBlur={() => resetOnBlur("new")} onClick={() => resetZeroInput("new")} autoComplete='off' className='w-full rounded bg-white' size='small' label={t('Zustände.Neuwertig')} type='number' value={objectData?.unitsCondition.new} onChange={(e) => setObjectData({...objectData, unitsCondition: {...objectData.unitsCondition, new: e.target.value}})}/>
          <TextField onBlur={() => resetOnBlur("renovation")} onClick={() => resetZeroInput("renovation")} autoComplete='off' className='w-full rounded bg-white' size='small' label={t('Zustände.Sanierungsbedürftig')} type='number' value={objectData?.unitsCondition.renovation} onChange={(e) => setObjectData({...objectData, unitsCondition: {...objectData.unitsCondition, renovation: e.target.value}})}/>
        </div>

        <NavMenu _back={back} nextBtn _next={next} nextDisabled={sum > objectData.units} />
    </div>
  )
}

export default UnitsCondition