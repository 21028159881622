import FacilityCheck from "../FacilityCheck";
import CustomFacilityCheck from "../CustomFacilityCheck";
import { CloseX, EditPen } from "../../helper/icons";
import { t } from "i18next";
import { useState } from "react";
import FacilityEdit from "../FacilityEdit";

const Facilities = ({ _objectData, editable = true }) => {

  const [editMode, setEditMode] = useState(false);

  return (
    <div className="bg-white rounded flex flex-col w-full pb-2 border border-allimmoDark/70">
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 pl-1.5 py-0.5 rounded-t">
        <p>{t('General.Ausstattung')}</p>
        {(!editMode && editable) && <EditPen onClick={() => setEditMode(true)} size={14} className="cursor-pointer mr-1.5 text-allimmoDark/60 hover:text-allimmoDark/30" />}
        {(editMode && editable) && <CloseX onClick={() => setEditMode(false)} size={20} className="cursor-pointer mr-1 text-red-600 hover:bg-red-200 rounded" />}
      </div>
      {!editMode &&
      <div className="flex flex-col px-2">
        <FacilityCheck facility={"cellar"} state={_objectData?.cellar} />
        <FacilityCheck facility={"airCondition"} state={_objectData?.airCondition} />
        <FacilityCheck facility={"bathtub"} state={_objectData?.bathtub} />
        <FacilityCheck facility={"elevator"} state={_objectData?.elevator} />
        <FacilityCheck facility={"garage"} state={_objectData?.garage} />
        <FacilityCheck facility={"kitchen"} state={_objectData?.kitchen} />
        <FacilityCheck facility={"blinds"} state={_objectData?.blinds} />
        <FacilityCheck facility={"pool"} state={_objectData?.pool} />
        <FacilityCheck facility={"storageRoom"} state={_objectData?.storageRoom} />
        <FacilityCheck facility={"developed"} state={_objectData?.developed} />
        <FacilityCheck facility={"noOldGrowth"} state={_objectData?.noOldGrowth} />
        <FacilityCheck facility={"buildingSite"} state={_objectData?.buildingSite} />
        <FacilityCheck facility={"floorHeating"} state={_objectData?.floorHeating} />
        <FacilityCheck facility={"solar"} state={_objectData?.solar} />
        <FacilityCheck facility={"pets"} state={_objectData?.pets} />
        <FacilityCheck facility={"shortTermRent"} state={_objectData?.shortTermRent} />
        <FacilityCheck facility={"chimney"} state={_objectData?.chimney} />
        <FacilityCheck facility={"fitness"} state={_objectData?.fitness} />
        <FacilityCheck facility={"alarm"} state={_objectData?.alarm} />
        <FacilityCheck facility={"door"} state={_objectData?.door} />
        <FacilityCheck facility={"laundry"} state={_objectData?.laundry} />
        <FacilityCheck facility={"bike"} state={_objectData?.bike} />
        <FacilityCheck facility={"accessible"} state={_objectData?.accessible} />
        <FacilityCheck facility={"sauna"} state={_objectData?.sauna} />
        {_objectData?.customFacilities?.map((f, i) => {
          return <CustomFacilityCheck key={i} name={f.name} />;
        })}
      </div>}

      {editMode &&
      <div className="flex flex-col px-2 max-h-36 overflow-auto">
        {!["land", "house"].includes(_objectData?.type) && <>
        <FacilityEdit facility={"laundry"} state={_objectData?.laundry} objectId={_objectData?.id}/>
        <FacilityEdit facility={"bike"} state={_objectData?.bike} objectId={_objectData?.id}/>
        <FacilityEdit facility={"fitness"} state={_objectData?.fitness} objectId={_objectData?.id}/>
        <FacilityEdit facility={"elevator"} state={_objectData?.elevator} objectId={_objectData?.id}/>
        </>}
        {_objectData?.type !== "land" && <>
        <FacilityEdit facility={"accessible"} state={_objectData?.accessible} objectId={_objectData?.id}/>
        <FacilityEdit facility={"cellar"} state={_objectData?.cellar} objectId={_objectData?.id}/>
        <FacilityEdit facility={"garage"} state={_objectData?.garage} objectId={_objectData?.id}/>
        <FacilityEdit facility={"solar"} state={_objectData?.solar} objectId={_objectData?.id}/>
        <FacilityEdit facility={"blinds"} state={_objectData?.blinds} objectId={_objectData?.id}/>
        <FacilityEdit facility={"pool"} state={_objectData?.pool} objectId={_objectData?.id}/>
        {_objectData?.deal === "sale" && <FacilityEdit facility={"shortTermRent"} state={_objectData?.shortTermRent} objectId={_objectData?.id}/>}
        {_objectData?.deal === "rent" && <FacilityEdit facility={"pets"} state={_objectData?.pets} objectId={_objectData?.id}/>}
        </>}
        {_objectData?.type === "land" && <>
        <FacilityEdit facility={"developed"} state={_objectData?.developed} objectId={_objectData?.id}/>
        <FacilityEdit facility={"noOldGrowth"} state={_objectData?.noOldGrowth} objectId={_objectData?.id}/>
        <FacilityEdit facility={"buildingSite"} state={_objectData?.buildingSite} objectId={_objectData?.id}/>
        </>}
        {_objectData?.type === "apartment" && <>
        <FacilityEdit facility={"storageRoom"} state={_objectData?.storageRoom} objectId={_objectData?.id}/>
        <FacilityEdit facility={"kitchen"} state={_objectData?.kitchen} objectId={_objectData?.id}/>
        <FacilityEdit facility={"bathtub"} state={_objectData?.bathtub} objectId={_objectData?.id}/>
        </>}
        {!["multi", "land"].includes(_objectData?.type) && <>
        <FacilityEdit facility={"airCondition"} state={_objectData?.airCondition} objectId={_objectData?.id}/>
        <FacilityEdit facility={"alarm"} state={_objectData?.alarm} objectId={_objectData?.id}/>
        <FacilityEdit facility={"door"} state={_objectData?.door} objectId={_objectData?.id}/>
        <FacilityEdit facility={"chimney"} state={_objectData?.chimney} objectId={_objectData?.id}/>
        <FacilityEdit facility={"floorHeating"} state={_objectData?.floorHeating} objectId={_objectData?.id}/>
        </>}
        {!["commercial", "land"].includes(_objectData?.type) &&
        <FacilityEdit facility={"sauna"} state={_objectData?.sauna} objectId={_objectData?.id}/>
        }
      </div>}
    </div>
  );
};

export default Facilities;
