import { t } from "i18next";
import { useDeletionContext } from "../../contexts/DeletionProvider";
import Button from "../Generics/Button";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";

const DeleteSearch = () => {

    const { setSearchDeleteConfirmOpen, deleteSearch } = useDeletionContext();

    const [answer, setAnswer] = useState("");

    return (
    <div className="px-2 py-8 flex flex-col justify-center items-center">
        <p className="text-xl text-allimmoDark text-center">{t('Löschen.SucheFrage')}</p>
        <div className="flex flex-col space-y-3 md:space-y-0 my-10">
            <FormControlLabel className="w-full px-3" control={<Checkbox checked={answer === "found"} value={answer === "found"} onChange={() => setAnswer("found")} />} label={t('DeleteQuestions.SearchFound')}/>
            <FormControlLabel className="w-full px-3" control={<Checkbox checked={answer === "notNeeded"} value={answer === "notNeeded"} onChange={() => setAnswer("notNeeded")} />} label={t('DeleteQuestions.SearchNotNeeded')}/>
            <FormControlLabel className="mb-5 w-full px-3" control={<Checkbox checked={answer === "other"} value={answer === "other"} onChange={() => setAnswer("other")}/>} label={t('DeleteQuestions.Other')}/>
        </div>
        <div className="flex flex-row justify-center items-center space-x-2">
          <Button isDisabled={answer === ""} text={t('General.Löschen')} clickFunction={() => deleteSearch(answer)}/>
          <Button text={t('General.Abbrechen')} clickFunction={() => setSearchDeleteConfirmOpen(false)}/>
        </div>
    </div>
  )
}

export default DeleteSearch