import { useEffect, useState } from 'react';

const MapButton = ({ text, clickFunction, isDisabled, icon, color, selected = false }) => {

    const [itemColor, setItemColor] = useState();
  
    useEffect(() => {
      if(isDisabled) setItemColor('disabled');
      if(!isDisabled) setItemColor(color);
    }, [isDisabled]);
  
    const colorScheme = {
      disabled: `border-gray-400 bg-gray-100 text-gray-500 cursor-default opacity-30`,
      fuchsia: `border-fuchsia-600 bg-fuchsia-100 text-fuchsia-500 ${isDisabled ? 'cursor-default opacity-30' : selected ? 'bg-fuchsia-600 hover:bg-fuchsia-500 text-white' : 'hover:bg-fuchsia-200'}`,
      purple: `border-purple-600 bg-purple-100 text-purple-500 ${isDisabled ? 'cursor-default opacity-30' : selected ? 'bg-purple-600 hover:bg-purple-500 text-white' : 'hover:bg-purple-200'}`,
      green: `border-green-600 bg-green-100 text-green-500 ${isDisabled ? 'cursor-default opacity-30' : selected ? 'bg-green-600 hover:bg-green-500 text-white' : 'hover:bg-green-200'}`,
      red: `border-red-600 bg-red-100 text-red-500 ${isDisabled ? 'cursor-default opacity-30' : selected ? 'bg-red-600 hover:bg-red-500 text-white' : 'hover:bg-red-200'}`,
      blue: `border-blue-600 bg-blue-100 text-blue-500 ${isDisabled ? 'cursor-default opacity-30' : selected ? 'bg-blue-600 hover:bg-blue-500 text-white' : 'hover:bg-blue-200'}`,
      orange: `border-orange-600 bg-orange-100 text-orange-500 ${isDisabled ? 'cursor-default opacity-30' : selected ? 'bg-orange-600 hover:bg-orange-500 text-white' : 'hover:bg-orange-200'}`
    }
  
    return (
      <div onClick={isDisabled ? null : clickFunction} className={`w-map h-map sm:w-20 sm:h-20 gap-1 flex flex-col justify-center items-center rounded-lg shadow cursor-pointer border ${colorScheme[itemColor]} p-2`}>
          {icon}
          <p className="text-xs sm:text-sm">{text}</p>
      </div>
    );
  };

    export default MapButton;