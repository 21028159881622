import { TextField } from '@mui/material';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { usePopupContext } from '../../contexts/PopupProvider';
import { buySearches } from '../../helper/functions';

const BuySearch = () => {

    const {setBuySearchOpen} = usePopupContext();
    const { customer, setIsLoading } = useStateContext();

    const [searchAmount, setSearchAmount] = useState(1);

    const handleBuyClick = async () => {
      
        setIsLoading(true);
        await buySearches({amount: searchAmount})
        .then(() => {
            setBuySearchOpen(false);
        })
        setIsLoading(false);
    };

  return (
    <div className='flex flex-col justify-center items-center p-5 select-none w-full'>
        <p className='mb-8 w-full text-xl'>{t("BuySearch.Header")}</p>
        <p className='mb-5 w-full'>{t("BuySearch.Text")}</p>
        <div className='flex flex-row justify-center items-center space-x-3 w-full'>
            <TextField autoComplete='off' type='number' label={t("BuySearch.Anzahl")} size='small' className='w-1/4' value={searchAmount} onChange={(e) => setSearchAmount(e.target.value)}/>
            <p className='w-3/4 text-end pr-1'>{`${t('General.Kosten')}: ${(searchAmount * 2).toLocaleString()} Credits`}</p>
        </div>
        <div className='flex flex-row space-x-2 justify-end mt-8 w-full'>
          {(customer?.credits >= (parseInt(searchAmount) * 2))   && <button className='bg-allimmoDark/80 rounded text-white px-3 py-2 cursor-pointer hover:bg-allimmoDark active:bg-allimmoDark/80' onClick={handleBuyClick}>{t('General.Bestätigen')}</button>}
          <button className='bg-red-500 rounded text-white px-3 py-2 cursor-pointer hover:bg-red-600 active:bg-red-500' onClick={() => setBuySearchOpen(false)}>{t('General.Abbrechen')}</button>
        </div>
    </div>
  )
}

export default BuySearch