import { t } from "i18next";
import AnimationIcon from "../../Generics/AnimationIcon";
import SearchCheckBox from "./SearchCheckBox";
import { green, pink, red } from "@mui/material/colors";
import { useCreationContext } from "../../../contexts/CreationProvider";
import StageLabel from "../../Objects/Creation/StageLabel";
import Button from "../../Generics/Button";
import { useEffect } from "react";
import NavMenu from "../../Generics/NavMenu";

const OpenAreaType = ({next, back}) => {

    const { searchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-3/4 md:w-1/2 mdd:w-1/3 mt-10 gap-1">
        <StageLabel labelText={t('CreateSearch.OpenAreaInfosLabel')}/>
        
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-2 h-14`}>
          <SearchCheckBox _size={1.3} name="balcony" _color={green[400]}/>
          <div className='grow'/>
          {searchData.balcony && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/balcony.json`)}/>}
        </div>

        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-2 h-14`}>
          <SearchCheckBox _size={1.3} name="roofTop" _color={green[400]}/>
          <div className='grow'/>
          {searchData.roofTop && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/roofTop.json`)}/>}
        </div>

        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-2 h-14`}>
          <SearchCheckBox _size={1.3} name="garden" _color={green[400]}/>
          <div className='grow'/>
          {searchData.garden && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/garden.json`)}/>}
        </div>

        <div className={`flex flex-row bg-slate-200 cursor-pointer items-center w-full rounded px-2 h-14 mt-6  `}>
          <SearchCheckBox _size={1.3} name="noPublicAreas" _color={pink[400]} dynamicText/>
          <div className='grow'/>
          {searchData.noPublicAreas && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/noPublicAreas.json`)}/>}
        </div>

        <NavMenu _back={back} nextBtn _next={next} />
    </div>
  )
}

export default OpenAreaType
