import React from 'react'

const Home = () => {
  return (
    <div className='flex flex-col w-full items-center mt-32'>
        <p className='text-allimmoDark text-3xl font-bold'>Match Administration Tools</p>
        <p className='text-lg -mt-2 font-bold text-allimmoDark/50'>version 0.0.1</p>
    </div>
  )
}

export default Home