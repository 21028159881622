import React from 'react'

const SearchDepthSquares = ({value}) => {
    const squares = [];

    for (let i = 3; i <= 9; i++) {
        squares.push(<Square key={i} opac={i === value} index={i}/>);
    }

    return <div className='flex flex-row items-center gap-1'>{squares}</div>;
}

const Square = ({ index, opac }) => (
    <div className={`w-5 h-5 ${index === 3 ? "bg-depthSehrUngenau" : index === 4 ? "bg-depthUngenau" : index === 5 ? "bg-depthNormal" : index === 6 ? "bg-depthNormal" : [7,8].includes(index) ? "bg-depthGenau" : index === 9 ? "bg-depthSehrGenau" : ""} border flex flex-row justify-center items-center border-black ${opac ? "opacity-100" : "opacity-15"}`}>
    </div>
);

export default SearchDepthSquares
