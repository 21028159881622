import { t } from "i18next";
import { usePopupContext } from "../contexts/PopupProvider";
import { useStateContext } from "../contexts/ContextProvider";
import AnimationIcon from "../components/Generics/AnimationIcon.jsx";
import { auth } from "../firebase.config";
import { useEffect } from "react";
import { ArrowLongDown } from "../helper/icons";

const About = () => {
  const { setVideoOpen } = usePopupContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col items-center mt-6 mb-10 px-1 sm:px-2 w-full ssm:mt-8">

      <p className="text-lg text-center">{t('About.Entdecke')}</p>
      <p className="text-2xl font-bold text-center -mt-1">
        {t('About.Slogan')}
      </p>
      <p onClick={() => setVideoOpen(true)} className="text-center bg-slate-200 text-allimmoDark underline cursor-pointer hover:text-allimmoDark/70">{t("About.Erklärungsvideo")}</p>
      <div className="flex flex-col items-start py-4 px-2 border-2 border-allimmoDark rounded-2xl shadow-xl bg-white w-full ssm:w-500 mt-8">
        <div className="flex flex-col items-center gap-3 w-full">
          <img
            width={60}
            src={require("../assets/houseDashboard.gif")}
            alt=""/>
          <p className="text-lg ssm:text-xl text-center text-slate-600">{t("About.SuchprofilAnlegen")}</p>
          {/* <p onClick={() => setSearchVideoOpen(true)} className="text-center -mt-2 bg-slate-200 text-allimmoDark underline cursor-pointer hover:text-allimmoDark/70">Erklärungsvideo</p> */}
        </div>

          <div className="h-1px bg-allimmoDark w-5/6 my-3 self-center"/>
          <div className="flex flex-col justify-center items-center gap-3 w-full">
              <img
                width={60}
                src={require("../assets/zoomDashboard.gif")}
                alt=""/>
              <p className="text-lg ssm:text-xl text-center text-slate-600">{t("About.AnbieterInserieren")}</p>
              {/* <p onClick={() => setObjectVideoOpen(true)} className="text-center -mt-2 bg-slate-200 text-allimmoDark underline cursor-pointer hover:text-allimmoDark/70">Erklärungsvideo</p> */}
          </div>
        </div>

      <div className="flex flex-row items-center justify-center my-4 w-full ssm:w-500 gap-20">
        <ArrowLongDown size={80} className="text-slate-600 -rotate-30"/>
        <ArrowLongDown size={80} className="text-slate-600 rotate-30"/>
      </div>

      <div className="flex flex-col justify-center items-center gap-3 p-4 border-2 border-allimmoDark rounded-2xl shadow-xl bg-white w-full ssm:w-500">
          <img
            width={80}
            src={require("../assets/gearloop.gif")}
            alt=""/>
          <p className="text-lg ssm:text-xl text-center text-slate-600">{t("About.AlgorithmusVergleicht")}</p>
      </div>

      <div className="flex flex-row items-center justify-center my-4">
        <ArrowLongDown size={80} className="text-slate-600"/>
      </div>

      <div className="flex flex-col justify-center items-center gap-3 p-4 border-2 border-allimmoDark rounded-2xl shadow-xl bg-white ssm:w-500">
          <AnimationIcon
            animate
            _color
            _iconSize={80}
            iconSrc={require("../assets/animations/confetti.json")}
          />
          <p className="text-lg ssm:text-xl text-center text-slate-600">{t("About.ÜbereinstimmungKontaktieren")}</p>
      </div>

      <div className="flex flex-row items-center justify-center my-4">
        <ArrowLongDown size={80} className="text-slate-600"/>
      </div>

      <div className="flex flex-col justify-center items-center gap-3 p-4 border-2 border-allimmoDark rounded-2xl shadow-xl bg-white ssm:w-500">
          <AnimationIcon
            animate
            _color
            _iconSize={80}
            iconSrc={require("../assets/animations/searchCreation/searchOrder.json")}
          />
          <p className="text-lg ssm:text-xl text-center text-slate-600">{t('About.SuchenderEntscheidet')}</p>
      </div>

      {!auth.currentUser &&
      <div className="flex flex-row items-center justify-center my-4">
        <ArrowLongDown size={80} className="text-slate-600"/>
      </div>}

      {!auth.currentUser && <StartNowButton />}
    </div>
  );
};

const StartNowButton = () => {
  const { setLoginHandlerState, setLoginOpen } = useStateContext();

  const handleClick = () => {
    setLoginHandlerState("login");
    setLoginOpen(true);
  };

  return (
    <div
      onClick={handleClick}
      className="flex shadow-2xl hover:shadow-sm flex-row gap-3 justify-center items-center px-3 py-2 cursor-pointer rounded-lg border-allimmoDark bg-white border-2 hover:bg-slate-50"
    >
      <AnimationIcon
        iconSrc={require("../assets/animations/fireworks.json")}
        _iconSize={50}
        _color={false}
      />
      <p className="text-allimmoDark text-lg">{t("About.HierStarten")}</p>
    </div>
  );
};

export default About;
