import { t } from "i18next";
import { SwingCheckmark } from "../helper/icons";

export const FacilityCheck = ({facility, state}) => {

    return (
      <div className={`flex flex-row w-full items-center ${state === true ? 'block' : 'hidden'}`}>
        <SwingCheckmark size={16} className='mr-2 text-allimmoDark/70'/>
        <p>{t(`Facilities.${facility}`)}</p>
      </div>
    )
};

export default FacilityCheck;