import { useCreationContext } from '../../../contexts/CreationProvider';
import SquareButton from '../../Generics/SquareButton';
import AnimationIcon from '../../Generics/AnimationIcon';

const ThreeWaySelection = ({prop, options, _next, withProp = false, iconSize = 70, ways = 3}) => {

    const { searchData, setSearchData } = useCreationContext();

    const handleSelection = (newValue) => {
        setSearchData({...searchData, [prop]: newValue});
        setTimeout(() => {
            !withProp ? _next() : _next(newValue);
        }, "150");
    }; 

  return (
        <div className={`grid grid-cols-2 ssm:grid-cols-${ways} place-items-center justify-center gap-2 md:gap-3 items-center mt-3`}>
            <SquareButton selected={searchData[prop] === options[0].value} clickFunction={() => handleSelection(options[0].value)} icon={<AnimationIcon _color={false} _iconSize={iconSize} iconSrc={require(`../../../assets/animations/searchCreation/${options[0].animation}.json`)}/>} label={options[0].label}/>
            {ways === 3 && 
            <SquareButton selected={searchData[prop] === options[1].value} clickFunction={() => handleSelection(options[1].value)} icon={<AnimationIcon _color={false} _iconSize={iconSize} iconSrc={require(`../../../assets/animations/searchCreation/${options[1].animation}.json`)}/>} label={options[1].label}/>}
            {ways === 2 && 
            <SquareButton selected={searchData[prop] === options[2].value} clickFunction={() => handleSelection(options[2].value)} icon={<AnimationIcon _color={false} _iconSize={iconSize} iconSrc={require(`../../../assets/animations/searchCreation/${options[2].animation}.json`)}/>} label={options[2].label}/>}
            {ways === 3 &&
            <div className="col-span-2 ssm:col-span-1 place-self-center">
                <SquareButton selected={searchData[prop] === options[2].value} clickFunction={() => handleSelection(options[2].value)} icon={<AnimationIcon _color={false} _iconSize={iconSize} iconSrc={require(`../../../assets/animations/searchCreation/${options[2].animation}.json`)}/>} label={options[2].label}/>
            </div>}
        </div>
  )
};

export default ThreeWaySelection
