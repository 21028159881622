import { t } from "i18next";
import TextProperty from "./Components/TextProperty";
import { Checkmark, Dot } from "../../helper/icons";
import FromToElement from "./Components/FromToElement";

const Commercial = ({searchData}) => {
  return (
    <div className={`bg-white rounded flex flex-col w-full pb-2 border z-0 border-allimmoDark/70`}>
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 px-1.5 py-0.5 rounded-t">
        <p>{t('SearchDetails.Commercial.Header')}</p>
      </div>

      <div className="flex flex-col px-2">
        <div className="flex flex-row items-center justify-between">
          <p>{t('SearchDetails.Commercial.Typelabel')}</p>
          <p>{t(`SearchDetails.Commercial.Types.${searchData?.commercialType}`, 'MISSING_KEY')}</p>
        </div>
        <TextProperty property="activeBusiness" searchData={searchData} section="Commercial" />

        {!["building", "hall"].includessearchData?.commercialType &&
        <FromToElement label={t("SearchDetails.Commercial.VacancyRatio")} unit="%" property="vacancy" searchData={searchData} />}

        {searchData?.licensing && searchData?.licensing.length > 0 &&
        <div className="flex flex-col border-t py-2 mt-2">
          <p>{t('SearchDetails.Commercial.LicensesLabel')}</p>
            <div className="flex flex-col mt-1 pl-3">
              {searchData?.licensing.map((l, i) => {
                return (
                  <div key={i} className="flex flex-row items-center gap-1">
                    <Checkmark size={15} className="text-green-500"/>
                    <p>{t(`CreateObject.Industries.${l}`)}</p>
                  </div>
                )
              })}
            </div>
        </div>}
        {searchData?.industrialLicense &&
        <div className="flex flex-col border-t py-2">
          <div className="flex flex-row items-center gap-1">
            <Dot size={15} className="text-allimmoDark/70"/>
            <p>{t(`SearchDetails.Commercial.industrialLicense`)}</p>
          </div>
        </div>}
      </div>
    </div>
  )
};

export default Commercial
