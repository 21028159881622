import i18n from '../i18n';
import Socials from './Socials';

const Footer = () => {
  return (
    <div className='flex flex-col items-center w-full absolute bottom-0 pb-1.5'>
        <Socials />
        <a className='text-lg text-allimmoDark/80 w-full text-center hover:underline' href='https://www.allimmo.at' target='_blank'>www.allimmo.at</a>
        <LanguageSwitcher />
    </div>
  )
};

const LanguageSwitcher = () => {

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  };

  // console.log("LANGAUGES i18/navigator:", i18n.language, navigator.language)

  return (
    <div className='flex flex-row gap-2 items-center text-allimmoDark'>
      <p onClick={() => changeLanguage("de")} className={`cursor-pointer hover:opacity-50 ${["de", "de-DE"].includes(i18n.language) ? "opacity-100 font-bold" : "opacity-30"}`}>DE</p>
      <p>|</p>
      <p onClick={() => changeLanguage("en")} className={`cursor-pointer hover:opacity-50 ${["en", "en-GB"].includes(i18n.language) ? "opacity-100 font-bold" : "opacity-30"}`}>EN</p>
    </div>
  )
};

export default Footer
