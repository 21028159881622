import { useState } from "react";
import { useDeletionContext } from "../../contexts/DeletionProvider";
import Button from "../Generics/Button";
import { t } from "i18next";
import { Checkbox, FormControlLabel } from "@mui/material";

const DeleteObject = () => {

    const {setObjectDeleteConfirmOpen, deleteObject } = useDeletionContext();

    const [answer, setAnswer] = useState("");

  return (
    <div className="px-2 py-8 flex flex-col justify-center items-centerr">
        <p className="text-xl text-allimmoDark text-center">{t('Löschen.ObjektFrage')}</p>
        <p className="mb-5 px-2 w-full text-center text-sm">{t('Löschen.ObjektText')}</p>
        <div className="flex flex-col space-y-3 md:space-y-0 mb-10">
          <FormControlLabel className="w-full px-3" control={<Checkbox checked={answer === "found"} value={answer === "found"} onChange={() => setAnswer("found")} />} label={t('DeleteQuestions.ObjectFound')}/>
          <FormControlLabel className="w-full px-3" control={<Checkbox checked={answer === "notNeeded"} value={answer === "notNeeded"} onChange={() => setAnswer("notNeeded")} />} label={t('DeleteQuestions.ObjectNotNeeded')}/>
          <FormControlLabel className="mb-5 w-full px-3" control={<Checkbox checked={answer === "other"} value={answer === "other"} onChange={() => setAnswer("other")}/>} label={t('DeleteQuestions.Other')}/>
        </div>
        <div className="flex flex-row justify-center items-center space-x-2">
          <Button isDisabled={answer === ""} text={t('General.Löschen')} clickFunction={() => deleteObject(answer)}/>
          <Button text={t('General.Abbrechen')} clickFunction={() => setObjectDeleteConfirmOpen(false)}/>
        </div>
    </div>
  )
}

export default DeleteObject
