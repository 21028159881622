import { t } from 'i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useCreationContext } from '../../../contexts/CreationProvider';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from './StageLabel';

const Notes = ({next, back}) => {

    const { objectData, setObjectData } = useCreationContext();

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-3/4 md:w-1/2 mdd:w-1/3 mt-10 gap-5 select-none">
        <StageLabel headline={t('CreateObject.NotesLabel')} labelText={t('CreateObject.NotesSub')}/>
        <ReactQuill style={{}} theme="snow" className="w-full mb-16 bg-white h-48 border-b-0" value={objectData?.notes} onChange={value => setObjectData({...objectData, notes: value})}/>
        <NavMenu marginTop={0} _back={back} nextBtn _next={next} />
    </div>
  )
}

export default Notes
