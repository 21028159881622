import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import StageLabel from '../../Objects/Creation/StageLabel';
import RangeNumbers from './RangeNumbers';
import { useEffect } from 'react';
import validator from 'validator';
import NavMenu from '../../Generics/NavMenu';

const RenovationRatio = ({next, back}) => {

    const { searchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const validation = () => {
        return !validator.isInt(searchData?.renovationRatio[0].toString()) || !validator.isInt(searchData?.renovationRatio[1].toString()) ||
        parseInt(searchData?.renovationRatio[1]) > 100 ||
        searchData?.renovationRatio.some(el => parseInt(el) < 0) ||
        (parseInt(searchData?.renovationRatio[0]) > parseInt(searchData?.renovationRatio[1]));
    };

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-2/3 md:w-1/2 mdd:w-1/3">
        <StageLabel color={false} stageIcon={require('../../../assets/animations/searchCreation/renovation.json')} headline={t('CreateSearch.RenovationRatio')} labelText={t('CreateSearch.RenovationRatioLabel')}/>
        <RangeNumbers
          isUnlimitedPossible={false}
          label={t("CreateSearch.RenovationRatio")}
          prop="renovationRatio"
          resetValues={[0 , 100]}
          unit='%'
        />
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={validation()}/>
    </div>
  )
};

export default RenovationRatio
