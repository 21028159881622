import StageLabel from '../../Objects/Creation/StageLabel';
import NavMenu from '../../Generics/NavMenu';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { useEffect } from 'react';
import { t } from 'i18next';
import SquareButton from '../../Generics/SquareButton';
import AnimationIcon from '../../Generics/AnimationIcon';

const CommercialSearchType = ({back, next}) => {

    const { searchData, setSearchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const handleCommercialType = (newType) => {
        setSearchData({...searchData, commercialType: newType});
        setTimeout(() => {
          next();
      }, "150");
    };

  return (
    <div className='w-full flex flex-col justify-center items-center py-2 sm:py-5 gap-2'>
        <StageLabel headline={t('CreateObject.CommercialType.Headline')} color={false} labelText={t('CreateObject.CommercialType.Label')}/>
        <div className={`grid grid-cols-2 md:grid-cols-4 place-items-center justify-center gap-2 items-center mt-5`}>
          <SquareButton selected={searchData?.commercialType === "salesroom"} clickFunction={() => handleCommercialType("salesroom")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/salesroom.json')}/>} label={t('ObjectDetails.CommercialTypes.salesroom')} subText={t('CreateSearch.CommercialTypeSub.salesroom')} />
          <SquareButton selected={searchData?.commercialType === "apartment"} clickFunction={() => handleCommercialType("apartment")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/commercialApartment.json')}/>} label={t('ObjectDetails.CommercialTypes.apartment')} subText={t('CreateSearch.CommercialTypeSub.apartment')} />
          <SquareButton selected={searchData?.commercialType === "building"} clickFunction={() => handleCommercialType("building")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/commercialBuilding.json')}/>} label={t('ObjectDetails.CommercialTypes.building')} subText={t('CreateSearch.CommercialTypeSub.building')} />
          <SquareButton selected={searchData?.commercialType === "hall"} clickFunction={() => handleCommercialType("hall")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/commercialHall.json')}/>} label={t('ObjectDetails.CommercialTypes.hall')} subText={t('CreateSearch.CommercialTypeSub.hall')} />
      </div>
        <NavMenu _back={back} />
    </div>
  )
};

export default CommercialSearchType
