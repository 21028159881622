import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { auth } from '../firebase.config';
import { convertShortTimestamp, encryptString, updateFirestoreDoc } from '../helper/functions';
import { CloseX, HeartMinus } from '../helper/icons';

const SavedObjects = () => {

    const { customer } = useStateContext();

    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const savedObjectsSearchNames = new Set(customer.savedObjects.map(obj => obj.searchName));
    const uniqueNamesArray = Array.from(savedObjectsSearchNames);

    const removeFavourite = (e, objectIdToRemove) => {
        e.stopPropagation();
        if(isSaving) return;
        setIsSaving(true);
        updateFirestoreDoc("customers", auth.currentUser.uid, {savedObjects: customer.savedObjects.filter(e => e.objectId !== objectIdToRemove)})
        .then(() =>  {
          setIsSaving(false)
        })
        .catch(() => {
          setIsSaving(false);
        });
    };

    const openSavedObjectDetails = (data) => {
        window.open(`/objects/${data?.objectId}?id=${encryptString(data?.searchId)}&search=${encryptString(data?.searchName)}`, "_blank");
    };

  return (
    <div className='flex flex-col items-center px-1 sm:px-3 select-none gap-10 w-full mmd:w-600 py-10'>
        {uniqueNamesArray.length === 0 ? <p className='text-xl text-allimmoDark pb-2 text-center'>{t('SavedObjects.NoObjects')}</p> :
        uniqueNamesArray.map((name, i) => {
            return (
                <div className='flex flex-col w-full' key={i}>
                    <p className='border border-green-500 text-green-500 rounded px-2 py-1 bg-green-50'>{`${t('SavedObjects.Search')} "${name}"`}</p>
                    <div className='flex flex-col mt-2 gap-1'>
                        {customer.savedObjects.filter(obj => obj.searchName === name).map((obj, i) => {
                            return (
                                <div key={i} className='flex flex-row items-center gap-1 text-allimmoDark/80 cursor-pointer'>
                                    <div onClick={() => openSavedObjectDetails(obj)} className='flex flex-row items-center gap-3 border border-slate-300 hover:border-slate-400 hover:bg-slate-100 rounded grow px-2'>
                                        <HeartMinus size={18} />
                                        <div className='flex flex-col grow'>
                                            <p className='truncate'>{`${obj.address.streetNumber}${obj.address.cityZip !== "" ? ", " + obj.address.cityZip : ""}`}</p>
                                            <p className='-mt-1 text-xs italic text-slate-400'>{t('SavedObjects.SavedAt') + " " + convertShortTimestamp(obj.created.toDate())}</p>
                                        </div>
                                    </div>
                                    <CloseX onClick={(e) => removeFavourite(e, obj.objectId)} className='cursor-pointer border border-red-100 text-red-300 bg-red-50 hover:border-red-500 hover:text-red-500 rounded h-full' size={26} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        })}
    </div>
  )
};

export default SavedObjects
