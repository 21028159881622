import { collection, onSnapshot, query, where } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { firestore } from "../../../firebase.config";
import { useSelectionContext } from "../../../contexts/SelectionProvider";

const SearchDetailsContext = createContext();

export const SearchDetailsProvider = ({ children }) => {
    const { selectedSearchID } = useSelectionContext();
    const [searchContacts, setSearchContacts] = useState([]);

    useEffect(() => {
        if(selectedSearchID === undefined) return;
        const offersQuery = query(collection(firestore, "contacts"), where("search", "==", selectedSearchID));
        onSnapshot(offersQuery, (result) => {
          setSearchContacts(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
      });
      // eslint-disable-next-line
    }, []);

    return (
        <SearchDetailsContext.Provider
            value={{ 
                searchContacts,
                setSearchContacts
            }}>
                {children}
        </SearchDetailsContext.Provider>
    )
};

export const useSearchDetailsContext = () => useContext(SearchDetailsContext)
