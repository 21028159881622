import { t } from "i18next";
import { useCreationContext } from "../../../contexts/CreationProvider";
import { Checkbox } from "@mui/material";

const UnlimitedCheckBox = ({name, _color, isDisabled = false, _size = 1}) => {
    
    const { searchData, setSearchData } = useCreationContext();
  
    return (
      <div onClick={() => setSearchData({...searchData, [name]: !searchData[name]})} className='grow flex flex-row gap-1 ssm:gap-2 items-center'>
        <Checkbox style={{transform: `scale(${_size})`}} sx={{color: _color, '&.Mui-checked': {color: _color}}} disabled={isDisabled} disableRipple disableTouchRipple value={searchData[name]} checked={searchData[name]} />
        <p className='text-allimmoDark'>{t(`General.Unlimitiert`)}</p>
      </div>
      )
  };

  export default UnlimitedCheckBox;