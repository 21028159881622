import { CircularProgress } from "@mui/material";
import { Timestamp, arrayUnion } from "firebase/firestore";
import { t } from "i18next";
import { useRef, useState } from "react";
import { updateFirestoreDoc } from "../../helper/functions";
import { BurgerMenu, Checkmark, Dot, Update } from "../../helper/icons";
import useOutsideClick from "../../hooks/useOutsideClick";
import BooleanProperty from "./Components/BooleanProperty";
import FromToElement from "./Components/FromToElement";
import TextProperty from "./Components/TextProperty";
import { multiHeatingOptions } from "../../helper/arrays";

const BaseInfos = ({ _searchData }) => {

  return (
    <div className={`bg-white rounded flex flex-col w-full pb-2 border z-0 border-allimmoDark/70`}>
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 px-1.5 py-0.5 rounded-t">
        <p>{t('General.AllgemeineInfos')}</p>
      </div>

      <div className="flex flex-col px-2">
        <FromToElement unit="€" property="price" searchData={_searchData}
        label={_searchData?.deal === "rent" ? _searchData?.type === "commercial" ? t("General.Nettomiete") : t("General.Gesamtmiete") : _searchData?.deal === "lease" ? t("General.Pacht") : t("General.Preis")} />
        <FromToElement unit="m²" property="size" searchData={_searchData}
        label={t("General.Wohnfläche")} />
        {["land", "house", "multi", "commercial"].includes(_searchData?.type) &&
        <FromToElement unit="m²" property="land" searchData={_searchData}
        label={t("General.Grundfläche")} />}
        
        {((["house", "apartment"].includes(_searchData?.type)) || (_searchData?.type === "commercial" && ["apartment", "salesroom"].includes(_searchData?.commercialType))) &&
        <FromToElement property="rooms" searchData={_searchData}
        label={t("General.Räume")} />}
        
        {(("floor" in _searchData && _searchData?.exactFloor === true) && 
        ((_searchData?.type === "commercial" && ["apartment", "salesroom"].includes(_searchData?.commercialType)) || ["house", "apartment"].includes(_searchData?.type))) &&
        <FromToElement property="floor" searchData={_searchData}
        label={t("General.Stockwerk")} />}

        {(_searchData?.openAreas === "yes" && _searchData?.areas) &&
        <FromToElement unit="m²" property="areas" searchData={_searchData}
        label={t("SearchDetails.BaseInfos.Freiflächen")} />}
        {(_searchData?.type === "multi" && _searchData?.units) &&
        <FromToElement property="units" searchData={_searchData}
        label={t("SearchDetails.BaseInfos.Einheiten")} />}
        {(_searchData?.type === "multi" && _searchData?.vacancy) &&
        <FromToElement unit="%" property="vacancy" searchData={_searchData}
        label={t("SearchDetails.BaseInfos.VacancyRatio")} />}
        {(_searchData?.type === "multi" && _searchData?.renovationRatio) &&
        <FromToElement unit="%" property="renovationRatio" searchData={_searchData}
        label={t("SearchDetails.BaseInfos.RenovationRatio")} />}
        {_searchData?.type !== "land" &&
        <div className="border-t mt-2 pt-1 flex flex-col">
          {(_searchData?.type !== "commercial" && _searchData?.deal === "rent") && 
          <BooleanProperty property="pets" searchData={_searchData} />}
          <BooleanProperty property="accessible" searchData={_searchData} />
          {_searchData?.type !== "commercial" &&
          <BooleanProperty property="shortTermRent" searchData={_searchData} />}
        </div>}
        
        <div className="border-t mt-2 pt-1 flex flex-col">
          {!["yes", true].includes(_searchData?.groundFloor) && <TextProperty property="attic" searchData={_searchData} />}
          {!["yes", true].includes(_searchData?.attic) && <TextProperty property="groundFloor" searchData={_searchData} />}
          <TextProperty property="furnished" searchData={_searchData} />
          {_searchData?.type === "house" && 
          <TextProperty property="isolated" searchData={_searchData} />}
          {_searchData?.type !== "land" &&
          <BuildingProperty searchData={_searchData} />}
        </div>        
        <div className="pt-1 flex flex-col">
          {(_searchData?.comission || _searchData?.noGas || _searchData?.avoidCompensation) &&
          <div className="border-t mt-2 pt-1 flex flex-col">
            <BooleanProperty property="comission" searchData={_searchData} propertyOnly />
            <BooleanProperty property="avoidCompensation" searchData={_searchData} propertyOnly />
            <BooleanProperty property="noGas" searchData={_searchData} propertyOnly />
            {_searchData?.openAreas === "no" &&
            <div className="flex flex-row items-center gap-2">
              <Dot size={12} className="text-allimmoDark/30" />
              <p>{t(`SearchDetails.OpenAreas.Avoid`)}</p>
            </div>}
        </div>}
        {(_searchData?.type === "multi" && _searchData?.heatingMulti !== undefined) &&
            <div className="border-t mt-2 pt-1 flex flex-col">
              <p className="text-sm sm:text-base">Gewünschte Heizsysteme</p>
              <div className="flex flex-row items-center gap-2 mt-2">
                {multiHeatingOptions.sort((b,a) => _searchData?.heatingMulti?.[a.name] - _searchData?.heatingMulti?.[b.name]).map((o, i) => {  // filter(e => _searchData?.heatingMulti?.[e.name] === true)
                  return (
                    <div key={i} className={`${_searchData?.heatingMulti?.[o.name] ? "text-allimmoDark/80" : "opacity-10"}`}>{o.icon}</div>
                  )
                })}
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
};

const HeatingProperty = ({objectData}) => {

  const [editMode, setEditMode] = useState(false);
  const [newValue, setNewValue] = useState(objectData?.heating);
  const [isSaving, setIsSaving] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setNewValue(objectData?.heating);
    setEditMode(false);
  });

  const handleSave = async () => {
    if(newValue === objectData?.heating) {
      setEditMode(false);
    } else {
      setIsSaving(true);
      await updateFirestoreDoc("objects", objectData?.id, {heating: newValue, edited: Timestamp.fromDate(new Date()), editedData: arrayUnion({
        updateID: objectData.editedData ? objectData.editedData.length + 1 : 1, 
        propertyName: "heating", 
        oldValue: objectData?.heating, 
        newValue: newValue,
        created: Timestamp.fromDate(new Date()),
      })})
      .then(() => {
        setIsSaving(false);
        setEditMode(false);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        setIsSaving(false);
      });
    }
  };

  return (
    <div className="flex flex-row justify-between">
      <p>{t("General.Heizung")}</p>
      <div ref={wrapperRef}>
        {!editMode && <p className="cursor-pointer hover:bg-allimmoDark/10" onClick={() => setEditMode(true)}>{t(`Facilities.${objectData?.heating}`)}</p>}
        {editMode && 
        <div className="flex flex-row items-center gap-1">
          {!isSaving && <Checkmark onClick={handleSave} size={15} className="cursor-pointer text-green-600 hover:text-green-800"/>}
          <select autoFocus={editMode} className="px-1.5 border h-6 rounded border-black w-44" value={newValue} onChange={(e) => setNewValue(e.target.value)}>
              <option value="fern">{t('Facilities.fern')}</option>
              <option value="gas">{t('Facilities.gas')}</option>
              {["house", "multi"].includes(objectData.type) && <option value="oil">{t('Facilities.oil')}</option>}
              <option value="electric">{t('Facilities.electric')}</option>
              {["house", "multi"].includes(objectData.type) && <option value="pump">{t('Facilities.pump')}</option>}
              {["house", "multi"].includes(objectData.type) && <option value="pellets">{t('Facilities.pellets')}</option>}
              <option value="otherHeating">{t('Facilities.otherHeating')}</option>
          </select>
        </div>}
      </div>
    </div>
  )
};

const BuildingProperty = ({searchData, editable = false}) => {

    const [isSaving, setIsSaving] = useState(false);
    
    const handleChange = async () => {
      if(!editable) return;
      setIsSaving(true);
      await updateFirestoreDoc("searches", searchData?.id, {building: "old", edited: Timestamp.fromDate(new Date()), editedData: arrayUnion({
        updateID: searchData?.editedData ? searchData?.editedData.length + 1 : 1, 
        propertyName: "building", 
        oldValue: searchData?.building, 
        newValue: searchData?.building,
        created: Timestamp.fromDate(new Date()),
      })})
      .then(() => {
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        setIsSaving(false);
      });
    };
  
    return (
      <div className={`flex flex-row items-center justify-between`}>
        <p>{t(`SearchDetails.BaseInfos.Building`)}</p>
        {!isSaving &&
        <div className="flex flex-row gap-1 items-center">
          {editable && <Update size={12} className="text-allimmoDark/30" />}
          <p className={`${editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}`} onClick={editable ? handleChange : null}>{searchData?.building === "old" ? t('SearchDetails.BaseInfos.Altbau') : searchData?.building === "new" ? t('SearchDetails.BaseInfos.Neubau') : t('SearchDetails.BaseInfos.Egal')}</p>
        </div>}
        {isSaving && <CircularProgress size={15} />}
      </div>
    )
  };

const Menu = ({objectData, _setPropToAdd}) => {

  const [menuOpen, setMenuOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setMenuOpen(false);
  });

  const handleMenuClick = (prop) => {
    setMenuOpen(false);
    _setPropToAdd(prop);
  };

  return (
    <div ref={wrapperRef} className={`relative ${(parseFloat(objectData?.utilityBill) > 0 && parseFloat(objectData?.compensation) > 0 && (objectData?.deal !== "sale" ? parseFloat(objectData?.deposit) > 0 : true)) ? 'hidden' : 'block'}`}>
      <BurgerMenu onClick={() => setMenuOpen(!menuOpen)} size={18} className="cursor-pointer hover:text-allimmoDark/30 text-allimmoDark/80" />
      {menuOpen && 
      <div className="absolute bg-white z-10 rounded top-5 right-0 shadow-xl border border-allimmoDark/80 text-sm w-32">
        <p className="bg-allimmoDark/20 w-full p-1 border-b border-allimmoDark/80 mb-2">Hinzufügen</p>
        {parseFloat(objectData?.utilityBill) <= 0 && 
        <p onClick={() => handleMenuClick("utilityBill")} className="cursor-pointer hover:bg-allimmoDark/10 p-1 rounded">{t("ObjectListing.Betriebskosten")}</p>}
        {parseFloat(objectData?.compensation) <= 0 && 
        <p onClick={() => handleMenuClick("compensation")} className="cursor-pointer hover:bg-allimmoDark/10 p-1 rounded">{t("CreateObject.Ablöse")}</p>}
        {/* {parseFloat(objectData?.comission) <= 0 && <p className="cursor-pointer hover:bg-allimmoDark/10 p-1 rounded">{t("General.Provision")}</p>} */}
        {(parseFloat(objectData?.deposit) <= 0 && objectData?.deal !== "sale") && 
        <p onClick={() => handleMenuClick("deposit")} className="cursor-pointer hover:bg-allimmoDark/10 p-1 rounded mb-1">{t("General.Kaution")}</p>}
      </div>}
    </div>
  )
};

export default BaseInfos
