import { createContext, useContext, useState } from "react";

const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
    
    const [selectedMenu, setSelectedMenu] = useState("main");
    const [selectedSub, setSelectedSub] = useState("");

    return (
        <AdminContext.Provider
            value={{ 
                selectedMenu,
                selectedSub,
                setSelectedMenu,
                setSelectedSub,
            }}>
                {children}
        </AdminContext.Provider>
    )
};

export const useAdminContext = () => useContext(AdminContext)
