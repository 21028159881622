import { green } from "@mui/material/colors";
import { t } from "i18next";
import { useEffect } from "react";
import { useCreationContext } from "../../../contexts/CreationProvider";
import AnimationIcon from "../../Generics/AnimationIcon";
import NavMenu from "../../Generics/NavMenu";
import StageLabel from "../../Objects/Creation/StageLabel";
import SearchCheckBox from "./SearchCheckBox";
import { Collapse, TextField } from "@mui/material";

const OtherInfos = ({next, back}) => {

    const { searchData, setSearchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-3/4 md:w-1/2 mdd:w-1/3 mt-10 gap-1">
        <StageLabel labelText={t('CreateSearch.OtherLabel')}/>
        {((searchData.deal === "rent" && !["land", "multi", "commercial"].includes(searchData.type)) || (searchData?.type === "commercial" && ["apartment", "salesroom"].includes(searchData.commercialType))) &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14 mt-5`}>
          <SearchCheckBox _size={1.3} name="pets" _color={green[400]}/>
          <div className='grow'/>
          {searchData.pets && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/pets.json`)}/>}
        </div>}

        {searchData.type !== "land" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="shortTermRent" _color={green[400]}/>
          <div className='grow'/>
          {searchData.shortTermRent && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/shortTermRent.json`)}/>}
        </div>}

        {searchData.type !== "land" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="accessible" _color={green[400]}/>
          <div className='grow'/>
          {searchData.accessible && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/accessible.json`)}/>}
        </div>}

        {!["land", "multi"].includes(searchData.type) &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="avoidCompensation" _color={green[400]}/>
          <div className='grow'/>
          {searchData.avoidCompensation && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/avoid.json`)}/>}
        </div>}

        {searchData.type === "land" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="developed" _color={green[400]}/>
          <div className='grow'/>
          {searchData.developed && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/developed.json`)}/>}
        </div>}

        {searchData.type === "land" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="noOldGrowth" _color={green[400]}/>
          <div className='grow'/>
          {searchData.noOldGrowth && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/noOldGrowth.json`)}/>}
        </div>}

        {searchData.type === "land" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="buildingSite" _color={green[400]}/>
          <div className='grow'/>
          {searchData.buildingSite && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/buildingSite.json`)}/>}
        </div>}

        {searchData.type === "commercial" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="industrialLicense" _color={green[400]}/>
          <div className='grow'/>
          {searchData?.industrialLicense && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/industrialLicense.json`)}/>}
        </div>}

        {searchData.type === "commercial" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <SearchCheckBox _size={1.3} name="parkingLots" _color={green[400]}/>
          <div className='grow'/>
          {searchData?.parkingLots && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/facilities/industrialLicense.json`)}/>}
        </div>}

        {(searchData.type === "commercial" && searchData?.parkingLots) &&
        <div className="pt-1 w-full">
          <TextField className="bg-white rounded w-full" label={t('CreateSearch.Other.ParkingLotsMin')} type="number" value={searchData?.parkingLotsMin} onChange={(e) => setSearchData({...searchData, parkingLotsMin: e.target.value})}/>
        </div>}

        <NavMenu _back={back} nextBtn _next={next} />
    </div>
  )
}

export default OtherInfos
