import { Checkbox, FormControlLabel } from '@mui/material';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { t } from 'i18next';

const SearchCheckBox = ({name, _color, isDisabled = false, _size = 1, dynamicText = false}) => {

    const { searchData, setSearchData } = useCreationContext();

  return (
    <div onClick={() => setSearchData({...searchData, [name]: !searchData[name]})} className='flex flex-row items-center gap-1 ssm:gap-2'>
      <Checkbox style={{transform: `scale(${_size})`}} sx={{color: _color, '&.Mui-checked': {color: _color}}} disabled={isDisabled} disableRipple disableTouchRipple value={searchData[name]} checked={!isDisabled && searchData[name]}/>
      <p className={`text-allimmoDark ${dynamicText ? 'text-xs sm:text-sm ssm:text-base' : ''}`}>{t(`Facilities.${name}`)}</p>
    </div>
  )
}

export default SearchCheckBox