import { t } from 'i18next';
import React from 'react';
import Popup from '../components/Generics/Popup';
import { SearchDetailsProvider } from '../components/SearchDetails/Contexts/SearchDetailsProvider';
import { useStateContext } from '../contexts/ContextProvider';
import { useCreationContext } from '../contexts/CreationProvider';
import { useDeletionContext } from '../contexts/DeletionProvider';
import { usePopupContext } from '../contexts/PopupProvider';
import { useSearchesContext } from '../contexts/SearchesProvider';
import { useSelectionContext } from '../contexts/SelectionProvider';
import { AddSearch, Apartment, CommercialObject, Delete, Dot, House, Land, MultiIcon, Offers, PlusCircle, Rent, Sale } from '../helper/icons';
import SearchDetails from './SearchDetails';

const Searches = () => {
  const { customer, searchProfilesLeft, handleMenuState } = useStateContext();
  const { resetSearchData } = useCreationContext();
  const { setBuySearchOpen, setSearchDetailsOpen } = usePopupContext();
  const { setSelectedSearchID } = useSelectionContext();
  const { customerSearches } = useSearchesContext();

  const handleNewSearch = () => {
    resetSearchData();
    handleMenuState("newSearch");
  };

  const handleSearchOpen = (data) => {
    setSelectedSearchID(data.id);
    setSearchDetailsOpen(true);
  };
    
  return (
    <div className='w-full flex flex-col items-center px-3 py-5'>
        {customerSearches.length === 0 ? 
        <div className='flex flex-col justify-center items-center'>
            <p className='text-xl text-allimmoDark'>{t('Searches.KeineSuchen')}</p>
        </div> :
        <div className='flex flex-col justify-start items-center w-full md:w-2/3 space-y-1 overflow-auto'>
            {/* <AddSearch onClick={handleNewSearch} className='text-allimmoDark mb-5 cursor-pointer hover:text-sky-700 active:text-allimmoDark' size={30} /> */}
            <div className='flex flex-col space-x-3 items-center w-full mb-2 px-1 ssm:px-0'>
                {searchProfilesLeft > 0 && <AddSearch onClick={handleNewSearch} className='text-allimmoDark cursor-pointer mt-1 hover:text-sky-700 active:text-allimmoDark' size={30} />}
                <p className='ssm:text-lg text-allimmoDark text-start'>{`${t('Searches.Counter')} ${customerSearches.length}/${customer?.maxSearchProfiles}`}</p>
                <p className='text-allimmoDark cursor-pointer hover:underline italic text-sm' onClick={() => setBuySearchOpen(true)}>{`${t('Searches.AddSearches')}`}</p>
                {/* <div className='grow'/> */}
            </div>
            {customerSearches?.map((s, i) => {
                return (
                    <SearchObject key={i} data={s} openDetails={() => handleSearchOpen(s)} />
                )
            })}
        </div>}

        {searchProfilesLeft === 0 && <p className='hover:underline cursor-pointer mt-5 text-allimmoDark' onClick={() => setBuySearchOpen(true)}>{t('Searches.SuchprofileKaufen')}</p>}
    </div>
  )
};

const SearchObject = ({data, openDetails}) => {
    const { pendingContacts } = useStateContext();
    const { setSearchDeleteConfirmOpen, setDeletedSearch } = useDeletionContext();
    const { searchDetailsOpen, setSearchDetailsOpen } = usePopupContext();

    const pendingOffers = pendingContacts.filter(el => el.search === data.id);

    const handleDelete = (e) => {
        e.stopPropagation();
        setDeletedSearch(data.id);
        setSearchDeleteConfirmOpen(true);
    };

    return (
        <div className='w-full border border-allimmoDark/30 py-1.5 px-2 sm:px-2 flex flex-col justify-center items-center rounded cursor-pointer hover:border-allimmoDark/80 bg-white'>
            <div onClick={openDetails} className={`flex flex-row justify-start items-center w-full h-6 ${!data.active ? 'opacity-30' : ''}`}>
                <Dot size={20} className={`cursor-pointer ${data.active ? 'text-green-600' : "text-red-600"}`}/>
                {data.type === "apartment" && <Apartment size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {data.type === "commercial" && <CommercialObject size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {data.type === "multi" && <MultiIcon size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {data.type === "house" && <House size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {data.type === "land" && <Land size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {["rent", "lease"].includes(data.deal) && <Rent size={18} className={`cursor-pointer text-allimmoDark`}/>}
                {data.deal === "sale" && <Sale size={18} className={`cursor-pointer text-allimmoDark`}/>}
                <p className='grow font-medium text-allimmoDark ml-2 text-14 sm:text-15 md:text-16'>{data.name}</p>
                {pendingOffers.length > 0 && <Offers size={18} className={`${pendingOffers.length === 0 ? 'text-blue-500' : (pendingOffers.length > 0 && pendingOffers.length < 6) ? 'text-allimmoDark' : (pendingOffers.length > 5 && pendingOffers.length < 15) ? 'text-blue-500' : 'text-green-500'}`}/>}
                {pendingOffers.length > 0 && <p className='ml-0.5 sm:ml-1 mr-2 sm:mr-5'>{`${pendingOffers.length}`}</p>}
                <Delete size={20} className='text-red-400 cursor-pointer hover:text-red-600' onClick={(e) => handleDelete(e)}/>
            </div> 

            <Popup openState={searchDetailsOpen}
                content={
                <SearchDetailsProvider>
                    <SearchDetails />
                </SearchDetailsProvider>} 
                close={() => setSearchDetailsOpen(false)} />
        </div>
    )
};

export default Searches
