import { t } from 'i18next';
import Button from '../Generics/Button';

const CompleteleyDeleteObjectConfirm = ({confirm, close}) => {
  return (
    <div className="px-2 py-8 flex flex-col justify-center items-centerr">
        <p className="text-xl text-allimmoDark text-center">{t('Löschen.ObjektFrage')}</p>
        <p className="mb-5 mt-1 px-2 w-full text-center text-sm">{t('Löschen.ObjektTextEndgültig')}</p>
        
        <div className="flex flex-row justify-center items-center space-x-2">
          <Button text={t('General.Löschen')} clickFunction={confirm}/>
          <Button text={t('General.Abbrechen')} clickFunction={close}/>
        </div>
    </div>
  )
};

export default CompleteleyDeleteObjectConfirm
