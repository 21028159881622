import { useEffect } from 'react';

const Privacy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='flex flex-col w-full justify-center items-center'>
        <div className={`flex flex-col w-full px-5 pt-10 pb-20`}> 
        <p className='text-xl font-bold mb-5'>DATENSCHUTZERKLÄRUNG</p>

            <p className='text-lg mb-5 font-bold'>I. Informationen über uns als Verantwortliche</p>
            <p className='mb-2'>Verantwortlicher Anbieter im datenschutzrechtlichen Sinne ist:</p>
            <p>Allimmo KG</p>
            <p>Sechshauserstrasse 124, 1150 Wien</p>
            <p>support@allimmo.at</p>


            <p className='text-lg mt-5 font-bold'>II. Allgemeines</p>
            <p>Dies ist die Datenschutzerklär ung für die Allimmo Software „Match". Diese Erklärung erläutert, wie wir die
            personenbezogenen Daten unserer Benutzer sammeln, verwenden und schützen und legt die Bedingungen für die
            Weitergabe an Dritte fest. Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur
            im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen
            Plattform, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</p>
            
            <p>Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur
            „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang
            oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die
            Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die
            Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich
            oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>
            
            <p>Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und
            Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen
            über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu
            Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch
            Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>

            <p className='text-lg mt-5 font-bold'>III. Sammlung Ihrer personenbezogenen Daten</p>
            <p>Wir können Informationen über die Interaktionen der Benutzer mit unserem Service sammeln, einschließlich der
            verwendeten Geräte, der Browsertypen, Daten und der Cookies.</p>

            <p className='text-lg mt-5 font-bold'>IV. Nutzung Ihrer persönlichen Daten</p>
            <p>Wir verwenden Ihre Daten zur Verbesserung und Bedienung unseres Services, zur Analyse des Benutzerverhaltens, zur
            Beantwortung Ihrer Anfragen, zur Durchführung von Überprüfungen, zur Erstellungen von Statistiken und zur
            Einhaltung gesetzlicher Vorschriften.</p>

            <p className='text-lg mt-5 font-bold'>V. Weitergabe von Daten</p>
            <p>"Match“ gibt Ihre persönlichen Daten nicht ohne Ihre ausdrückliche Einwilligung an Dritte weiter.</p>

            <p className='text-lg mt-5 font-bold'>VI. Interner Schutz Ihrer Daten</p>
            <p>Ihre Daten werden intern bei uns aufbewahrt und verschlüsselt geschützt. Wir setzen geeignete physische, technische
            und administrative Verfahren ein, um Ihre Daten vor unautorisiertem Zugriff, Verlust, Missbrauch, Änderung oder
            Zerstörung zu schützen.</p>

            <p className='text-lg mt-5 font-bold'>VII. Recht zur Überprüfung und Löschung</p>
            <p>Sie haben das Recht, Ihre Daten zu überprüfen, zu ändern oder zu löschen. Bitte kontaktieren Sie uns über die unter
            Punkt „IX. Kontaktdaten“ bereitgestellten Kontaktinformationen, um dieses Rechte auszuüben.</p>

            <p className='text-lg mt-5 font-bold'>VIII. Änderungen an dieser Datenschutzerklärung</p>
            <p>Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Wir empfehlen Ihnen, unsere
            Datenschutzerklärung regelmäßig zu überprüfen, um über mögliche Änderungen informiert zu bleiben.</p>

            <p className='text-lg mt-5 font-bold'>IX. Kontaktdaten</p>
            <p>Bei Fragen zu oder Beschwerden über diese Datenschutzerklärung wenden Sie sich bitte an folgende Email: support@allimmo.at</p>

            <p className='text-lg mt-5 font-bold'>X. Weitgehendere Ausführungen</p>
            <p className='mt-3 font-bold'>a. Kontaktanfragen / Kontaktmöglichkeit</p>
            <p>Sofern Sie per Kontaktformular, Plattform, App oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen
            angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und
            Beantwortung Ihre Anfrage erforderlich – ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls
            eingeschränkt beantworten.</p>

            <p className='mt-3 font-bold'>b. Kundenkonto / Registrierungsfunktion</p>
            <p>Falls Sie über unsere Plattformen ein Kundenkonto anlegen, werden wir die von Ihnen bei der Registrierung
            eingegebenen Daten (also bspw. Ihren Namen, Ihre Anschrift oder Ihre E-Mail-Adresse) ausschließlich für
            vorvertragliche Leistungen, für die Vertragserfüllung oder zum Zwecke der Kundenpflege (bspw. um Ihnen eine
            Übersicht über Ihre bisherigen Bestellungen bei uns zur Verfügung zu stellen) erheben und speichern. Gleichzeitig
            speichern wir dann die IP-Adresse und das Datum Ihrer Registrierung. Eine Weitergabe dieser Daten an Dritte erfolgt
            natürlich nicht.</p>
            <p>Im Rahmen des weiteren Anmeldevorgangs wird Ihre Einwilligung in diese Verarbeitung eingeholt und auf diese
            Datenschutzerklärung verwiesen. Die dabei von uns erhobenen Daten werden ausschließlich für die
            Zurverfügungstellung des Kundenkontos verwendet.</p>
            <p>Die insoweit erhobenen Daten werden gelöscht, sobald die Verarbeitung nicht mehr erforderlich ist. Hierbei müssen wir
            aber steuer- und handelsrechtliche Aufbewahrungsfristen beachten.</p>

            <p className='mt-3 font-bold'>c. Serverdaten</p>
            <p>Aus technischen Gründen, insbesondere zur Gewährleistung einer sicheren und stabilen Plattform, werden Daten durch
            Ihren Internet-Browser oder Endgerät an uns bzw. an unseren Server-Provider übermittelt. Mit diesen sog. Server-
            Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf
            unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts oder der Plattform,
            die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem
            aus die Nutzung unseres Internetauftritts erfolgt, erhoben.</p>
            <p>Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>
            <p>Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken
            erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der
            Löschung ausgenommen.</p>

            <p className='mt-3 font-bold'>d. Vertragsabwicklung</p>
            <p>Die von Ihnen zur Inanspruchnahme unseres Waren- und/oder Dienstleistungsangebots übermittelten Daten werden
            von uns zum Zwecke der Vertragsabwicklung verarbeitet und sind insoweit erforderlich. Vertragsschluss und
            Vertragsabwicklung sind ohne Bereitstellung Ihrer Daten nicht möglich.</p>
            <p>Wir löschen die Daten mit vollständiger Vertragsabwicklung, müssen dabei aber die steuer- und handelsrechtlichen
            Aufbewahrungsfristen beachten.</p>

            <p className='mt-3 font-bold'>e. Weitergabe mit Ihrer Einwilligung oder auf Ihre Anweisung hin</p>
            <p>Wenn Sie einwilligen, geben wir Ihre Daten weiter, wie zum Zeitpunkt der Einwilligung beschrieben, z. B. wenn eine
            externe Anwendung oder Unternehmen autorisiert wird, auf Ihre Daten zuzugreifen.</p>

            <p className='mt-3 font-bold'>f. Weitergabe zwischen Nutzern</p>
            <p>Um Interaktionen zwischen Nutzern zu erleichtern (auch wenn die Nutzer sich in Ländern mit unterschiedlichen
            Datenschutzbestimmungen befinden oder Dienstleistungsunternehmen mit Sitz in diesen Ländern nutzen), müssen wir
            unter Umständen bestimmte Daten intern weitergeben. Dieser Weitergabe können Sie jederzeit schriftlich
            widersprechen.</p>

            <p className='mt-3 font-bold'>g. Externe Partnerunternehmen und Integrationen</p>
            <p>Teile von unseren Plattformen können Links oder Schnittstellen zu Drittunternehmen enthalten. Allimmo ist weder
            Eigentümer dieser Unternehmen noch in der Lage, sie zu kontrollieren. Wenn Sie mit diesen Drittunternehmen
            interagieren und sich dafür entscheiden, deren Dienste zu nutzen, geben Sie Ihre Daten an diese weiter. Ihre Nutzung
            dieser Dienste unterliegt den Datenschutzbestimmungen der jeweiligen Unternehmen, also beispielsweise der
            Datenschutzrichtlinie von Google, Amazon oder Stripe.</p>

            <p className='mt-3 font-bold'>h. Verwaltung Ihrer Daten</p>
            <p>Sie können in Ihren Kontoeinstellungen auf einige Ihrer personenbezogenen Daten zugreifen und diese aktualisieren.
            Sie sind dafür verantwortlich, Ihre personenbezogenen Daten immer auf dem aktuellen Stand zu halten.</p>

            <p className='mt-3 font-bold'>i. Datenzugriff und -übertragung</p>
            <p>An einigen Standorten kann das geltende Recht Sie dazu berechtigen, Kopien Ihrer personenbezogenen Daten oder
            Daten darüber, wie wir mit Ihren personenbezogenen Daten umgehen, anzufordern. Sie sind möglicherweise auch
            berechtigt, Kopien personenbezogener Daten, die Sie uns zur Verfügung gestellt haben, in einem strukturierten,
            gängigen und maschinenlesbaren Format anzufordern, und/oder zu verlangen, dass wir diese Daten an ein anderes
            Dienstleistungsunternehmen übermitteln (soweit technisch machbar).</p>

            <p className='mt-3 font-bold'>j. Abschlussbestimmung</p>
            <p>Sollten einzelne Bestimmungen dieser Datenschutzerklärung unwirksam sein oder werden, bleibt die Wirksamkeit der
            übrigen Erklärung unberührt. Die unwirksame Erklärung soll durch die Vertragsparteien durch eine Erklärung ersetzt
            werden, die dem vom Nutzer und Allimmo gewollten Ergebnis am nächsten kommt. Gleiches gilt im Falle einer
            Regelungslücke.</p>

            <p className='mt-3 font-bold'>Indem Sie Allimmo „Match“ weiterhin nutzen, erklären Sie sich mit dieser Datenschutzerklärung
            einverstanden. Wir schätzen Ihr Vertrauen in uns und verpflichten uns, Ihre Privatsphäre zu respektieren und zu schützen.</p>
        </div>
    </div>
  )
}

export default Privacy