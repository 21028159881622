import { t } from 'i18next';
import AnimationIcon from '../Generics/AnimationIcon';

const Vacancy = ({searchData}) => {

  const allVacancies = searchData?.limited && searchData?.vacant && searchData?.unlimited;

  return (
    <div className={`bg-white rounded flex flex-col w-full pb-2 border z-0 border-allimmoDark/70`}>
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 px-1.5 py-0.5 rounded-t">
        <p>{t('SearchDetails.Vacancy.Header')}</p>
      </div>
      <div className="flex flex-row justify-evenly gap-1 ssm:gap-2 mt-1 px-2">
        {(searchData?.vacant || allVacancies) && <VacancyElement state="vacant" />}
        {(searchData?.limited || allVacancies) && <VacancyElement state="limited" />}
        {(searchData?.unlimited || allVacancies) && <VacancyElement state="unlimited" />}
      </div>
    </div>
  )
};

const VacancyElement = ({state}) => {
    return (
      <div className={`flex flex-col items-center rounded-lg border border-allimmoDark/40 p-2 w-full shadow`}>
        <AnimationIcon _color _iconSize={30} iconSrc={require(`../../assets/animations/searchCreation/${state}.json`)}/>
        <p className="text-xs">{t(`SearchDetails.Vacancy.States.${state}`)}</p>
      </div>
    )
};

export default Vacancy
