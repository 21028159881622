import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { Bed } from '../../../helper/icons';
import SquareButton from '../../Generics/SquareButton';
import AnimationIcon from '../../Generics/AnimationIcon';
import StageLabel from './StageLabel';

const Type = ({next}) => {

    const { objectData, setObjectData } = useCreationContext();
    const { screenSize } = useStateContext();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const handleType = (newType) => {
        setObjectData({...objectData, type: newType});
        setTimeout(() => {
          //next(newType === "house" ? "housetype" : newType === "commercial" ? "commercialType" : "deal");
          next(newType);
      }, "150");
    };

  return (
    <div className="flex flex-col justify-center items-center w-full lg:w-1/2">
      <StageLabel labelText={t('CreateObject.TypeLabel')}/>
      <div className={`grid grid-cols-2 ssm:grid-cols-3 place-items-center justify-center gap-2 items-center mt-5`}>
          <SquareButton selected={objectData?.type === "apartment"} clickFunction={() => handleType("apartment")} icon={<AnimationIcon _color={false} _iconSize={80} iconSrc={require('../../../assets/animations/objectCreation/apartment.json')}/>} label={t('General.Wohnung')}/>
          <SquareButton selected={objectData?.type === "house"} clickFunction={() => handleType("house")} icon={<AnimationIcon _color={false} _iconSize={80} iconSrc={require('../../../assets/animations/objectCreation/house.json')}/>} label={t('General.Haus')}/>
          <SquareButton selected={objectData?.type === "land"} clickFunction={() => handleType("land")} icon={<AnimationIcon _color={false} _iconSize={80} iconSrc={require('../../../assets/animations/objectCreation/land.json')}/>} label={t('General.Grundstück')}/>
          <SquareButton selected={objectData?.type === "multi"} clickFunction={() => handleType("multi")} icon={<AnimationIcon _color={false} _iconSize={80} iconSrc={require('../../../assets/animations/objectCreation/multi.json')}/>} label={t('General.Mehrparteien')}/>
          <SquareButton selected={objectData?.type === "commercial"} clickFunction={() => handleType("commercial")} icon={<AnimationIcon _color={false} _iconSize={80} iconSrc={require('../../../assets/animations/objectCreation/commercial.json')}/>} label={t('CreateSearch.Gewerblich')}/>
          <SquareButton hoverEffect={false} soon selected={objectData.type === "wg"} clickFunction={() => handleType("wg")} icon={<Bed size={screenSize.width > 768 ? 50 : 40} className={`text-allimmoDark`}/>} label={t('CreateSearch.WG')}/>
      </div>
    </div>
  )
}

export default Type
