import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import NavMenu from '../../Generics/NavMenu';
import SquareButton from '../../Generics/SquareButton';
import AnimationIcon from '../../Generics/AnimationIcon';
import StageLabel from '../../Objects/Creation/StageLabel';

const Deal = ({next, back}) => {

    const { searchData, setSearchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

    useEffect(() => {
      setSearchData({...searchData, deal: ""})
    }, []);

    const handleDealType = (newDeal) => {
        setSearchData({...searchData, deal: newDeal});
        setTimeout(() => {
            next();
        }, "150");
    };

  return (
    <div className="flex flex-col justify-center items-center w-full md:w-1/2 mt-10">
        <StageLabel labelText={t('CreateSearch.DealLabel')}/>
        <div className={`flex flex-row justify-center gap-2 sm:gap-3 items-center w-full mt-4`}>
            {searchData.type !== "land" && <SquareButton dynamic={false} selected={searchData.deal === "rent"} clickFunction={() => handleDealType("rent")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/deal_rent.json')}/>} label={t('Deals.rent')}/>}
            {searchData.type === "land" && <SquareButton dynamic={false} selected={searchData.deal === "lease"} clickFunction={() => handleDealType("lease")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/deal_rent.json')}/>} label={t('Deals.lease')}/>}
            <SquareButton dynamic={false} selected={searchData.deal === "sale"} clickFunction={() => handleDealType("sale")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/deal_sale.json')}/>} label={t('Deals.sale')}/>                
        </div>
        <NavMenu _back={back} />
    </div>
  )
}

export default Deal
