import { Dialog } from '@mui/material';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { t } from 'i18next';
import { useState } from 'react';
import { Transition, updateFirestoreDoc } from '../../helper/functions';
import { CloseX, DeleteIcon, Plus, Upload } from '../../helper/icons';

const Pictures = ({_open, pics, objectId, close}) => {

    const [files, setFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [progressPercentPics, setProgressPercentPics] = useState(0);

    const storage = getStorage();

    const picsArray = pics === undefined ? [] : pics?.filter(p => typeof p === "string" ? (p?.endsWith(".jpg") || p?.endsWith(".png") || p?.endsWith(".jpeg")) : (p?.path?.endsWith(".jpg") || p?.path?.endsWith(".png") || p?.path?.endsWith(".jpeg")));

    const amountLimit = pics?.length + files.length > 10;
    const sizeLimit = files?.some((file) => file.size > 1024 * 1024 * 5);

    const deletePic = async (e, url, name, isRemote) => {
        e.stopPropagation();
        if(name !== "" && !isRemote) {
            const fileRef = ref(storage, `objects/${objectId}/pictures/${name}`);
            await deleteObject(fileRef);
            await updateFirestoreDoc("objects", objectId, {pictureLinks: arrayRemove({url: url, path: name})});
        } else {
            await updateFirestoreDoc("objects", objectId, {pictureLinks: arrayRemove(url)});
        }
    };

    const uploadPics = async () => {
        files?.forEach((file) => {
            if (!file) return;
            if (file.size > 1024 * 1024 * 5) return;
            if (pics?.length + files.length > 10) return;
      
            setIsUploading(true);
      
            const storageRef = ref(storage,`objects/${objectId}/pictures/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
      
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgressPercentPics(progress);
              },
              (error) => {
                console.log(error);
                setIsUploading(false);
            },
              async () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                  await updateFirestoreDoc("objects", objectId, { pictureLinks: arrayUnion({url: downloadURL, path: file.name})});
                });
                setTimeout(() => {
                  setProgressPercentPics(0);
                  setFiles([]);
                  setIsUploading(false);
                }, "500");
              }
            );
          });
    };

    const backdropClose = (event, reason) => {
        if (reason && event === "backdropClick") 
            return;
        close();
    };

  return (
    <Dialog onClose={(e, r) => backdropClose(e,r)} open={_open} TransitionComponent={Transition} fullWidth>
        <div className='flex flex-col p-5 w-full select-none'>
            <div className='flex flex-row justify-between items-center'>
                <p>{t('Pictures.Header')} {`${pics ? picsArray?.length : "0"} ${picsArray.length < 11 ? "/10" : ""}`}</p>
                <CloseX onClick={close} size={30} className='text-allimmoDark/30 cursor-pointer right-1 top-1 hover:text-red-600 self-end'/>
            </div>
            <div className='grid grid-cols-1 ssm:grid-cols-2 md:grid-cols-3 gap-2 overflow-auto mt-3 w-full'>
                {pics && picsArray.map((pic, i) => (
                    <div key={i} className='cursor-pointer relative border border-black h-120 aspect-auto overflow-hidden'>
                        <a className='hover:opacity-6' href={typeof pic === "object" ? pic?.url : pic} target='_blank'>
                            <img src={typeof pic === "object" ? pic?.url : pic} alt="" />
                        </a>
                        <DeleteIcon onClick={(e) => deletePic(e, typeof pic === "object" ? pic?.url : pic, typeof pic === "object" ? pic?.path : "", pic?.path === "REMOTE")} size={20} className='absolute bg-white rounded right-1 top-1 cursor-pointer text-red-600 hover:text-red-400'/>
                    </div>
                ))}
                {(!pics || picsArray?.length < 10) &&
                <div>
                    {(files.length === 0 && !isUploading) &&
                    <label htmlFor='upload' className='cursor-pointer hover:opacity-60 border text-gray-700 border-black flex flex-col justify-center items-center w-full gap-2 p-3 h-120'>
                        <Plus size={40} />
                        <p>{t('ObjectDetails.Pictures.FotoHinzufügen')}</p>
                        <input multiple id="upload" hidden onChange={(e) => setFiles([...files, ...Array.from(e.target?.files)])} type="file" accept=".jpg, .png, .jpeg" />
                    </label>}
                    {(files.length > 0 && !isUploading) &&
                    <div className='flex flex-col gap-1 h-120'>
                        {!sizeLimit && !amountLimit &&
                        <div onClick={uploadPics} className='cursor-pointer hover:opacity-60 border text-gray-700 border-black flex flex-col justify-center items-center w-full gap-1 p-2 grow'>
                            <Upload size={30} />
                            <p>{files?.length} {files?.length === 1 ? t('Pictures.Bild') : t('Pictures.Bilder')} {t('Pictures.Hochladen')}</p>
                        </div>}
                        {sizeLimit ?
                        <Warning text={t('Pictures.SizeWarning')} clickFunction={() => setFiles([])} /> :
                        amountLimit ? 
                        <Warning text={t('Pictures.AmountWarning')} clickFunction={() => setFiles([])} /> :
                        <p className='border border-black p-1 text-center cursor-pointer hover:bg-allimmoDark/10' onClick={() => setFiles([])}>{t('General.Abbrechen')}</p>}
                    </div>}
                    {isUploading && 
                    <div onClick={uploadPics} className='border-2 border-black flex flex-col justify-center items-center w-full gap-1 p-2 grow h-120'>
                        <p className='text-lg font-semibold'>{progressPercentPics}%</p>
                    </div>}
                </div>}
            </div>

        </div>
    </Dialog>
  )
};

export default Pictures

const Warning = ({text, clickFunction}) => {
    return (
        <div className='flex flex-col gap-1 h-full text-sm'>
            <div className='flex rounded flex-row justify-center items-center border border-red-500 text-red-500 p-2 text-center cursor-pointer bg-red-100 grow'>
                <p>{text}</p>
            </div>
            <p onClick={clickFunction} className='border rounded border-black p-1 text-center cursor-pointer hover:bg-allimmoDark/10 align-middle'>OK</p>  
        </div>
    )
};
