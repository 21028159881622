import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import StageLabel from './StageLabel';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import NavMenu from '../../Generics/NavMenu';

const GaragePrice = ({next, back}) => {

    const {objectData, setObjectData, resetZeroInput } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <div className='w-full sm:w-2/3 md:w-1/2 flex flex-col justify-center items-center py-2 sm:py-5'>
        <StageLabel color={false} stageIcon={require('../../../assets/animations/objectCreation/facilities/garage.json')} labelText={t('CreateObject.GaragePrice.Sub')}/>
        <TextField autoComplete='off' onClick={() => resetZeroInput("garagePrice")} className='w-full rounded bg-white' size='medium' label={t('CreateObject.GaragePrice.Label')} type='number' value={objectData?.garagePrice} onChange={(e) => setObjectData({...objectData, garagePrice: e.target.value})}/>
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={objectData?.garagePrice < 0 || ["", "-"].includes(objectData?.garagePrice)}/>
    </div>
  )
};

export default GaragePrice
