import { useState } from 'react';
import { convertShortTimestamp, hideString } from '../../helper/functions';
import { useStateContext } from '../../contexts/ContextProvider';
import { Checkbox, Collapse } from '@mui/material';
import { t } from 'i18next';
import { EyeOff } from '../../helper/icons';

const Prospect = ({data, select, selection, index, showSymbol}) => {

    const { screenSize } = useStateContext();

    const depthBorderColors = {
        3: "border-depthUngenau",
        4: "border-depthUngenau",
        5: "border-depthNormal",
        6: "border-depthNormal",
        7: "border-depthGenau",
        8: "border-depthGenau",
        9: "border-depthGenau",
        undefined: "border-gray-300",
    };

    const depthTextColors = {
        3: "text-depthUngenau",
        4: "text-depthUngenau",
        5: "text-depthNormal",
        6: "text-depthNormal",
        7: "text-depthGenau",
        8: "text-depthGenau",
        9: "text-depthGenau",
        undefined: "text-gray-300",
    };

    return (
        <div onClick={() => select(data.id)} className={`flex flex-row items-center w-full rounded border-2 active:bg-slate-50 pl-3 py-0.5 ${index !== 0 ? 'mt-1' : ''} ${depthBorderColors[data?.depth]} bg-white`}>
            
            <p className={`mr-2 sm:mr-3 text-allimmoDark`} size={18}>{index + 1}</p>
            <div className={`flex flex-col w-full`}>
            
                <div className={`flex flex-row items-center w-full`}>
                    <p className='grow'>{hideString(data?.userName, 2)}</p>
                    {showSymbol && <p className={`text-sm mr-3 ${depthTextColors[data?.depth]}`}>{t(`SearchDepth.${data?.depth}`)}</p>}
                    {screenSize.width > 500 && <p className='ml-2'>{convertShortTimestamp(data?.created.toDate())}</p>}

                    <Checkbox 
                        size='small'
                        disableRipple
                        disableTouchRipple
                        value={selection.includes(data.id)} 
                        checked={selection.includes(data.id)} 
                        onChange={() =>{} }
                    />
                </div>
                <div className='flex flex-row text-sm gap-3'>
                        {data?.urgency === "urgent" &&
                        <div className='flex flex-row space-x-2 items-center'>
                            <img width={20} src={require('../../assets/turkey-med.gif')} alt=""/>
                            <p className='text-allimmoDark'>{`${t("ObjectDetails.Urgent")}`}</p>
                        </div>}

                        {(data?.searchOrder && data?.searchOrder !== undefined) &&
                        <div className='flex flex-row space-x-2 items-center'>
                            <img width={20} src={require('../../assets/smile.gif')} alt=""/>
                            <p className='text-allimmoDark'>{`${t("ObjectDetails.SearchOrder")}`}</p>
                        </div>}
                    </div>
            </div>
            
        </div>
    )
};

export default Prospect
