import { createContext, useContext, useState } from "react";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    
    const [loadingText, setLoadingText] = useState("");
    const [isCreating, setIsCreating] = useState(false);

    return (
        <LoadingContext.Provider
            value={{ 
                loadingText,
                isCreating,
                setLoadingText,
                setIsCreating
            }}>
                {children}
        </LoadingContext.Provider>
    )
}

export const useLoadingContext = () => useContext(LoadingContext);