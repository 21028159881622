import { Timestamp, arrayUnion } from "firebase/firestore";
import { useState } from "react";
import { Dot, Update } from "../../../helper/icons";
import { CircularProgress } from "@mui/material";
import { t } from "i18next";
import { updateFirestoreDoc } from "../../../helper/functions";

const BooleanProperty = ({property, searchData, propertyOnly = false, editable = false}) => {

    const [isSaving, setIsSaving] = useState(false);
    
    const handleChange = async () => {
      if(!editable) return;
      setIsSaving(true);
      await updateFirestoreDoc("searches", searchData?.id, {[property]: !searchData[property], edited: Timestamp.fromDate(new Date()), editedData: arrayUnion({
        updateID: searchData?.editedData ? searchData?.editedData.length + 1 : 1, 
        propertyName: property, 
        oldValue: searchData?.[property], 
        newValue: !searchData?.[property],
        created: Timestamp.fromDate(new Date()),
      })})
      .then(() => {
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        setIsSaving(false);
      });
    };
  
    return (
      <div className={`flex flex-row items-center ${propertyOnly ? "gap-2" : "justify-between"} ${propertyOnly && !searchData?.[property] ? "hidden" : "block"}`}>
        {propertyOnly && <Dot size={12} className="text-allimmoDark/30" />}
        <p className="text-sm sm:text-base">{t(`Facilities.${property}`)}</p>
        {(!isSaving && !propertyOnly) &&
        <div className="flex flex-row gap-1 items-center">
          {editable && <Update size={12} className="text-allimmoDark/30" />}
          <p className={`${editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}`} onClick={editable ? handleChange : null}>{searchData?.[property] ? t('General.Ja') : t('SearchDetails.BaseInfos.Egal')}</p>
        </div>}
        {isSaving && <CircularProgress size={15} />}
      </div>
    )
  };

export default BooleanProperty
