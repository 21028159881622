import { Dialog, TextField } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useCreationContext } from '../../../contexts/CreationProvider'
import { Transition } from '../../../helper/functions'
import AnimationIcon from '../../Generics/AnimationIcon'
import Button from '../../Generics/Button'
import NavMenu from '../../Generics/NavMenu'
import CustomFacilityBox from './CustomFacilityBox'
import FacilityBox from './FacilityBox'
import StageLabel from './StageLabel'

const Facilities = ({back, next}) => {

  const { objectData, setObjectData } = useCreationContext();

  const [newFacility, setNewFacility] = useState("");
  const [newFacilityOpen, setNewFacilityOpen] = useState(false);

  const addValidation = (newFacility === "") || (newFacility.length > 15);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  useEffect(() => {
    setNewFacility("");
  }, [newFacilityOpen]);
  
  const handleClose = (event, reason) => {
      if (reason && event === "backdropClick") 
          return;
      setNewFacilityOpen(false);
  };

  const addFacility = () => {
      setObjectData({...objectData, customFacilities: [...objectData.customFacilities, {name: newFacility, id:  Math.floor((Math.random() * 10000) + 1)}]});
      setNewFacilityOpen(false);
  };

  const deleteFacility = (id) => {
      setObjectData({...objectData, customFacilities: objectData.customFacilities.filter(el => el.id !== id)});
  };

  const handleEnterPress = (event) => {
    if ((event.keyCode === 13 || event.which === 11) && !addValidation) {
      addFacility();
    }
  };

  return (
    <div className='flex flex-col justify-center items-center py-2 sm:py-5'>
      <StageLabel labelText={objectData.type === "multi" ? t('CreateObject.FacilitiesLabelMulti') : t('CreateObject.FacilitiesLabel')}/>
      <div className='grid grid-cols-2 ssm:grid-cols-3 md:grid-cols-5 gap-1 mt-2'>
        <FacilityBox name={"cellar"}/>
        <FacilityBox name={"elevator"}/>
        <FacilityBox name={"garage"}/>
        {objectData.type !== "multi" && 
        <>
          <FacilityBox name={"kitchen"}/>
          {objectData.commercialType !== "hall" && <FacilityBox name={"storageRoom"}/>}
          <FacilityBox name={"alarm"}/>
        </>}
        <FacilityBox name={"blinds"}/>
        <FacilityBox name={"airCondition"}/>
        <FacilityBox name={"solar"}/>
        {!["multi", "commercial"].includes(objectData.type) && 
        <>
          <FacilityBox name={"door"}/>
          <FacilityBox name={"bathtub"}/>
        </>}
        {((objectData.type === "commercial" && objectData.commercialType === "building") || objectData.type !== "commercial") &&
        <>
          <FacilityBox name={"pool"}/>
          <FacilityBox name={"bike"}/>
          <FacilityBox name={"fitness"}/>
          <FacilityBox name={"laundry"}/>
        </>}
        {objectData.customFacilities.map((f, i) => {
          return (
            <CustomFacility key={i} name={f.name} _deleteFacility={() => deleteFacility(f.id)}/>
          )
        })
        }
        <CustomFacilityBox clickFunction={() => setNewFacilityOpen(true)}/>
      </div>

      <NavMenu _back={back} nextBtn _next={next} />
      
      <Dialog onClose={(e, r) => handleClose(e,r)} open={newFacilityOpen} fullWidth={false} TransitionComponent={Transition} className='select-none'>
        <div className='flex flex-col justify-center items-center p-5'>
          <p className='mb-2 text-allimmoDark text-lg'>{t('CreateObject.CustomFacilityLabel')}</p>
          <TextField 
          onKeyDown={(e) => handleEnterPress(e)} 
          className='w-full rounded bg-white' 
          required 
          value={newFacility} 
          onChange={(e) => setNewFacility(e.target.value)} 
          size='medium' 
          autoComplete='off'/>
          <p className='mb-2 text-allimmoDark text-sm w-full italic'>{t('CreateObject.CustomFacilityLimit')}</p>
          <div className='w-full flex flex-row justify-center items-center gap-2 mt-5'>
            <Button text={t('General.Schliessen')} clickFunction={() => setNewFacilityOpen(false)}/>
            <Button isDisabled={addValidation} text={t('General.Hinzufügen')} clickFunction={addFacility}/>
          </div>
        </div>
      </Dialog>
    </div>
  )
};

const CustomFacility = ({name, _deleteFacility}) => {

  return (
    <div onClick={_deleteFacility} className={`flex flex-col bg-white hover:shadow-lg cursor-pointer justify-center items-center border-2 border-allimmoDark p-1 aspect-square h-32 rounded-xl`}>
      <AnimationIcon _color={false} _iconSize={60} iconSrc={require(`../../../assets/animations/objectCreation/facilities/custom.json`)}/>
      <p className='text-allimmoDark w-full text-center text-12 ssm:text-sm md:text-base'>{name}</p>
      <p className='text-red-400 w-full text-center text-11 italic'>{t('CreateObject.CustomFacilityDelete')}</p>
    </div>
  )
};

export default Facilities
