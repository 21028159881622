import { green } from '@mui/material/colors';
import { t } from 'i18next';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import StageLabel from '../../Objects/Creation/StageLabel';
import RangeNumbers from './RangeNumbers';
import { useEffect } from 'react';
import NavMenu from '../../Generics/NavMenu';

const Price = ({next, back}) => {

  const { searchData, setSearchData } = useCreationContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (searchData.deal === "rent") {
      if(parseFloat(searchData.price[0]) === 0 && parseFloat(searchData.price[1]) === 300000) {
        setSearchData({ ...searchData, price: [0, 500] });
      }
    } else {
      if(parseFloat(searchData.price[0]) === 0 && parseFloat(searchData.price[1]) === 500) {
        setSearchData({ ...searchData, price: [0, 300000] });
      }
    }
    // eslint-disable-next-line
  }, []);

    const validation = () => {
      return [null, "NaN", NaN, undefined, "undefined", ""].includes(searchData.price[0]) || 
      ([null, "NaN", NaN, undefined, "undefined", ""].includes(searchData.price[1]) && !searchData.priceUnlimited) ||
      parseFloat(searchData.price[1]) === NaN ||
      (!searchData.priceUnlimited && parseFloat(searchData?.price[0]) > parseFloat(searchData?.price[1]));
    };

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-2/3 md:w-1/2 mdd:w-1/3">
        <StageLabel color={false} stageIcon={require('../../../assets/animations/searchCreation/price.json')} labelText={searchData.deal === "rent" ? searchData.type === "commercial" ? t('CreateSearch.CommercialRentLabel') : t('CreateSearch.RentLabel') : t('CreateSearch.PriceLabel')}/>
        <RangeNumbers 
          label={searchData.deal === "sale"
                    ? t("General.Preis")
                    : searchData.type === "land"
                    ? t("General.Pacht")
                    : t("CreateSearch.Gesamtkosten")}
          prop="price"
          unit="€"
          resetValues={searchData.deal === "rent" ? [0, 500] : [0, 300000]}
        />

               
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={validation()}/>
    </div>
  )
};

export default Price
