import { green } from '@mui/material/colors'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useCreationContext } from '../../../contexts/CreationProvider'
import Button from '../../Generics/Button'
import AnimationIcon from '../../Generics/AnimationIcon'
import StageLabel from '../../Objects/Creation/StageLabel'
import SearchCheckBox from './SearchCheckBox'
import NavMenu from '../../Generics/NavMenu'

const Vacancy = ({next, back}) => {

    const { searchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-3/4 md:w-2/3 lg:w-1/3 mt-10">
        <StageLabel labelText={t('CreateSearch.VacancyLabel')}/>
        <p className='text-xs italic text-allimmoDark -mt-1'>{t('CreateSearch.Mehrfachauswahl')}</p>
        <div className="w-full flex flex-col justify-center items-center gap-1.5 mt-6">
            <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
              <SearchCheckBox _size={1.3} name="vacant" _color={green[400   ]}/>
              <div className='grow'/>
              {searchData.vacant && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/vacant.json`)}/>}
            </div>

            <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
              <SearchCheckBox _size={1.3} name="limited" _color={green[500]}/>
              <div className='grow'/>
              {searchData.limited && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/limited.json`)}/>}
            </div>

            <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
              <SearchCheckBox _size={1.3} name="unlimited" _color={green[600]}/>
              <div className='grow'/>
              {searchData.unlimited && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/unlimited.json`)}/>}
            </div>
        </div>
        <NavMenu _back={back} nextBtn _next={next} />
    </div>
  )
}

export default Vacancy
