import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import StageLabel from './StageLabel';
import { InputAdornment, TextField } from '@mui/material';
import { useEffect } from 'react';
import { NextArrowDouble } from '../../../helper/icons';
import NavMenu from '../../Generics/NavMenu';

const Units = ({next, back}) => {

    const {objectData, setObjectData, resetCustomInput } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <div className='w-full ssm:w-2/3 md:w-1/2 flex flex-col justify-center items-center py-2 sm:py-5'>
        <StageLabel color={false} stageIcon={require('../../../assets/animations/objectCreation/units.json')} labelText={t('CreateObject.UnitsLabel')}/>
        <TextField autoComplete='off' onClick={() => resetCustomInput("units", 2)} required className='w-full rounded bg-white' size='medium' label={t('CreateObject.Units')} type='number' value={objectData?.units} onChange={(e) => setObjectData({...objectData, units: e.target.value})}/>
        <div className='w-full flex flex-row items-center gap-1 mt-2.5'>
          <TextField autoComplete='off' onClick={() => resetCustomInput("unitSizeMin", 0)} InputProps={{endAdornment: <InputAdornment disableTypography position="end">m²</InputAdornment>}} className='w-full rounded bg-white' size='medium' label={t('CreateObject.UnitSizeMin')} type='number' value={objectData?.unitSizeMin} onChange={(e) => setObjectData({...objectData, unitSizeMin: e.target.value})}/>
          <TextField autoComplete='off' onClick={() => resetCustomInput("unitSizeMax", 0)} InputProps={{endAdornment: <InputAdornment disableTypography position="end">m²</InputAdornment>}} className='w-full rounded bg-white' size='medium' label={t('CreateObject.UnitSizeMax')} type='number' value={objectData?.unitSizeMax} onChange={(e) => setObjectData({...objectData, unitSizeMax: e.target.value})}/>
        </div>

        <NavMenu _back={back} nextBtn _next={next} nextDisabled={parseInt(objectData.units) <= 1 || objectData.units.length === 0}/>
    </div>
  )
}

export default Units