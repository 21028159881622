import { MenuItem, TextField } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { DeleteIcon, Plus } from '../../../helper/icons';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from './StageLabel';

const Licensing = ({back, next}) => {

    const { objectData, setObjectData } = useCreationContext();

    const [showOther, setShowOther] = useState(false);
    const [newLicense, setNewLicense] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const addLicense = (license) => {
        setShowOther(false);
        if(license === "") return;
        if(objectData?.licensing?.some(el => el.name === license)) return;
        if(license === "Other") {
            setShowOther(true);
            return;
        }

        setObjectData({...objectData, licensing: [...objectData.licensing, {name: license, custom: false}]});
        };

    const deleteLicense = (license) => {
        setObjectData({...objectData, licensing: objectData.licensing.filter(item => item.name !== license.name)});
    };

    const resetLicenses = () => {
        setObjectData({...objectData, licensing: []});
    };

    const addCustomLicense = () => {
        if(newLicense.trim() === "") return;
        setObjectData({...objectData, licensing: [...objectData.licensing, {name: newLicense, custom: true}]});
        setNewLicense("");
        setShowOther(false);
    };

    const handleEnterPress = (event) => {
        if (event.keyCode === 13 || event.which === 13) {
          addCustomLicense();
        }
    };
  
  return (
    <div className='w-full ssm:w-2/3 md:w-1/2 flex flex-col justify-center items-center py-2 sm:py-5 gap-2'>
        <StageLabel headline={t('CreateObject.Licensing.Headline')} color={false} labelText={t('CreateObject.Licensing.Label')}/>
        <div className='w-full px-0.5'>
            <TextField value="-" select className='w-full bg-white' onChange={(e) => addLicense(e.target.value)} label={t('CreateObject.Licensing.SelectLicense')}>
                <MenuItem value="-"><p className='font-semibold'>{t('CreateObject.Licensing.SelectLicense')}</p></MenuItem>
                {!objectData.licensing.some(el => el.name === "Handel") && <MenuItem value="Handel">{t('CreateObject.Industries.Handel')}</MenuItem>}
                {!objectData.licensing.some(el => el.name === "Office") && <MenuItem value="Office">{t('CreateObject.Industries.Office')}</MenuItem>}
                {!objectData.licensing.some(el => el.name === "Gastro") && <MenuItem value="Gastro">{t('CreateObject.Industries.Gastro')}</MenuItem>}
                {!objectData.licensing.some(el => el.name === "Hotel") && <MenuItem value="Hotel">{t('CreateObject.Industries.Hotel')}</MenuItem>}
                {!objectData.licensing.some(el => el.name === "Logistik") && <MenuItem value="Logistik">{t('CreateObject.Industries.Logistik')}</MenuItem>}
                {!objectData.licensing.some(el => el.name === "Produktion") && <MenuItem value="Produktion">{t('CreateObject.Industries.Produktion')}</MenuItem>}
                {!objectData.licensing.some(el => el.name === "Landwirtschaft") && <MenuItem value="Landwirtschaft">{t('CreateObject.Industries.Landwirtschaft')}</MenuItem>}
                {!objectData.licensing.some(el => el.name === "Storage") && <MenuItem value="Storage">{t('CreateObject.Industries.Storage')}</MenuItem>}
                <MenuItem value="Other">{t('CreateObject.Industries.Sonstiges')}</MenuItem>
            </TextField>
            {(objectData.licensing.length === 0 && !showOther) && <p className='text-allimmoDark text-lg w-full text-center mt-3'>{t('CreateObject.Licensing.NoLicense')}</p>}
            <div className='flex flex-col w-full mt-3'>
                {objectData.licensing.map((item, index) => (
                    <div key={index} className='flex flex-row items-center gap-2'>
                        <p>{index + 1}.</p>
                        <p className='grow'>{item.custom ? item.name : t(`CreateObject.Industries.${item.name}`)}</p>
                        <DeleteIcon className='text-red-500 cursor-pointer hover:text-red-300' size={20} onClick={() => deleteLicense(item)}/>
                    </div>
                    )
                )}
            </div>
            {showOther && 
            <div className='flex flex-row items-center gap-2'>
                <p>{objectData.licensing.length + 1}.</p>
                <input className='px-2 border py-1 border-allimmoDark outline-none' autoFocus={true} onChange={(e) => setNewLicense(e.target.value)} value={newLicense} onKeyDown={handleEnterPress} />
                <div className='flex flex-row items-center gap-1'>
                    <Plus size={20} className='cursor-pointer hover:text-green-400 text-green-600' onClick={addCustomLicense}/>
                    <Plus size={21} className='cursor-pointer hover:text-red-400 text-red-600 rotate-45' onClick={() => setShowOther(false)}/>
                </div>
            </div>}
            {objectData.licensing.length > 0 && <p className='text-red-500 cursor-pointer hover:text-red-300 mt-3' onClick={resetLicenses}>{t('CreateObject.Licensing.Reset')}</p>}
        </div>
        <NavMenu _back={back} nextBtn _next={next} />
    </div>
  )
};

export default Licensing
