import { t } from 'i18next'
import { useStateContext } from '../../contexts/ContextProvider'
import Button from '../Generics/Button';
import { useEffect, useState } from 'react';
import Prospect from './Prospect';
import { contactMatched } from '../../helper/functions';
import { collection, documentId, onSnapshot, query, where } from 'firebase/firestore';
import { firestore } from '../../firebase.config';
import { Dot, HideText, Info, ShowText } from '../../helper/icons';
import { Checkbox, FormControlLabel } from '@mui/material';

const Matches = ({ objectData }) => {

    const { customer, handleMenuState } = useStateContext();

    const [isLoading, setIsLoading] = useState(false);
    const [selection, setSelection] = useState([]);
    const [contactDisabled, setContactDisabled] = useState(false);
    const [matchingSearches, setMatchingSearches] = useState([]);
    const [processingMatches, setProcessingMatches] = useState([]);
    const [symbolMode, setSymbolMode] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectMax, setSelectMax] = useState(false);
    const [selectTen, setSelectTen] = useState(false);

    const matches = matchingSearches.filter(m => m !== "123");

    useEffect(() => {
        const prospectsRef = query(collection(firestore, "searches"), where(documentId(), "in", [...objectData?.matches, "123"]));
        onSnapshot(prospectsRef, (result) => {
            setMatchingSearches(result.docs.map((doc) => ({...doc.data(), id: doc.id})));
        });
        // eslint-disable-next-line
    }, [objectData]);

    const contact = async () => {
        if(selection.length > 0 && selection.length <= customer.credits) {
            setProcessingMatches([...processingMatches, ...selection]);
            setContactDisabled(true);

            setIsLoading(true);
            await contactMatched({objectId: objectData?.id, selectionArray: selection})
            setContactDisabled(false);
            setIsLoading(false);
            setProcessingMatches([]);
            setSelection([]);
        }
    };

    const handleSelection = (item) => {
        if(selection.find(el => el === item)) {
            setSelection(selection.filter(el => el !== item));
            if(selectAll) setSelectAll(false);
            if(selectMax) setSelectMax(false);
            if(selectTen) setSelectTen(false);
        } else {
            setSelection([...selection, item]);
        }
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelection(selectAll ? [] : matches.map(m => m.id));
    };

    const handleSelectMax = () => {
        setSelectMax(!selectMax);
        setSelection(selectMax ? [] : matches.slice(0, customer.credits).map(m => m.id));
    };

    const handleSelectTen = () => {
        setSelectTen(!selectTen);
        setSelection(selectTen ? [] : matches.slice(0, 10).map(m => m.id));
    };

  return (
    <div className='flex flex-col items-center justify-center w-full mt-2 mb-1'>
        {matches.length > 0 && 
        <>
            <div className='flex flex-row items-center w-full'>
                <p className='text-14 grow'>{`${t('ObjectDetails.InteressentenHeader')} ${matches.length}`}</p>
            </div>
            <div className='flex flex-col items-center w-full border rounded border-allimmoDark max-h-64 overflow-auto p-1'>
                <div className='flex flex-col md:flex-row md:gap-4 md:items-center w-full mb-2 mt-1 pl-1 text-sm'>
                    <div className='flex flex-row items-center gap-1.5'>
                        <Info onClick={() => setShowDescription(!showDescription)} className='text-blue-500 cursor-pointer hover:opacity-50' size={18}/>
                        <p>{t("General.Genauigkeit")}:</p>
                    </div>
                    <div className='flex flex-row items-center gap-4'>
                        <div className='flex flex-row items-center gap-1 text-depthGenau'>
                            <Dot />
                            <p>{t("SearchDepth.7")}</p>
                        </div>

                        <div className='flex flex-row items-center gap-1 text-depthNormal'>
                            <Dot />
                            <p>{t("SearchDepth.5")}</p>
                        </div>

                        <div className='flex flex-row items-center gap-1 text-depthUngenau'>
                            <Dot />
                            <p ><p>{t("SearchDepth.4")}</p></p>
                        </div>

                        <div className='flex flex-row items-center ml-3 text-allimmoDark'>
                            {!symbolMode && <ShowText size={20} onClick={() => setSymbolMode(true)} className='cursor-pointer p-0.5 hover:opacity-70 border-allimmoDark rounded border'/>}
                            {symbolMode && <HideText size={20} onClick={() => setSymbolMode(false)} className='cursor-pointer p-0.5 hover:opacity-70 border-allimmoDark rounded border'/>}
                        </div>
                    </div>
                    
                    
                    <div className='flex flex-row items-center md:justify-end w-full gap-1'>
                        <p className='mr-5 text-base'>{t('General.Auswahl')}</p>

                        {(matches.length >= 10 && customer.credits >= 10) &&
                        <FormControlLabel
                        control={
                            <Checkbox
                            disabled={selectMax || selectAll || matches.length === 0 || matches.length < 10 || customer.credits < 10}
                            size='small'
                            disableRipple
                            disableTouchRipple
                            value={selectTen}
                            checked={selectTen}
                            onChange={handleSelectAll}
                            />
                        }
                        label={
                            <p className={`text-sm -ml-1 ${selectMax || selectAll || matches.length === 0 || matches.length < 10 || customer.credits < 10 ? "opacity-50" : "text-allimmoDark"}`}>
                            {"10 " + t("General.Objekte")}
                            </p>
                        }
                        />}

                        <FormControlLabel
                        control={
                            <Checkbox
                            disabled={selectAll || selectTen || matches.length === 0}
                            size='small'
                            disableRipple
                            disableTouchRipple
                            value={selectMax}
                            checked={selectMax}
                            onChange={handleSelectMax}
                            />
                        }
                        label={
                            <p className={`text-sm -ml-1 ${selectAll || selectTen || matches.length === 0 ? "opacity-50" : "text-allimmoDark"}`}>
                            {t("General.Max")}
                            </p>
                        }
                        />

                        <FormControlLabel
                        control={
                            <Checkbox
                            disabled={selectMax || selectTen || matches.length === 0 || matches.length > customer.credits}
                            size='small'
                            disableRipple
                            disableTouchRipple
                            value={selectAll}
                            checked={selectAll}
                            onChange={handleSelectAll}
                            />
                        }
                        label={
                            <p className={`text-sm -ml-1 ${selectMax || selectTen || matches.length === 0 || matches.length > customer.credits ? "opacity-50" : "text-allimmoDark"}`}>
                            {t("General.Alle")}
                            </p>
                        }
                        />
                    </div>
                </div>
                {showDescription && <p className='w-full text-xs pl-1 italic -mt-1 mb-2'>{t("SearchDepth.Description")}</p>}
                {matches.filter(m => !processingMatches.includes(m)).sort((a,b) => a?.created.toDate() - b?.created.toDate()).map((p, i) => {
                return (
                    <Prospect key={i} data={p} select={handleSelection} selection={selection} index={i} showSymbol={symbolMode} />
                )
                })}
                 <p className='italic mt-1 text-xs w-full text-allimmoDark/70'>{t('ObjectDetails.CreditsInfo')}</p>
            </div>
            <div className='flex flex-col ssm:flex-row ssm:items-center w-full mt-1'>
                <Button loading={isLoading} isDisabled={selection.length === 0 || selection.length > customer.credits || contactDisabled} clickFunction={contact} text={`${t('ObjectDetails.Kontaktieren')} (${selection.length})`}/>
                <div className='flex flex-row ssm:justify-end items-center grow'>
                    <p className='text-allimmoDark mr-1'>{`${t('ObjectDetails.Kosten')}:`}</p>
                    <p className='text-allimmoDark'>{`${selection.length}`}</p>
                    <p className='text-allimmoDark'>{`/`}</p>
                    <p className={`text-allimmoDark mr-1`}>{`${customer.credits.toLocaleString()} Credits`}</p>
                    {customer?.credits <= 2 && <img onClick={() => handleMenuState("shop")} width={30} src={require('../../assets/cart.gif')} alt=""/>}
                </div>
            </div>
        </>}
    </div>
  )
}

export default Matches
