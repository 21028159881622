import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'

const Tips = () => {
  return (
    <div className={`flex flex-col justify-center items-center w-screen px-2 md:px-5 py-5`}>
        {/* <div className='flex flex-col mmd:flex-row justify-center items-center gap-2 md:gap-4 px-2 md:px-5 py-5 w-full'> */}
        <div className='grid grid-cols-1 mmd:grid-cols-2 mdd:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4 px-2 md:px-5 py-5 w-full'>
            <TipElement title={"Immobiliensuche heute und morgen"} image={"https://md-immo.at/wp-content/uploads/2021/04/MD_Immo-Hero-1200x675.jpg"} text={"f,sdbfkjn .fjnlakn.n. IFNK FJNJf k n fn. k kbN K k bN DNKF ijdsdlnfkDF iuifuhÖJAJDAÖS"}/>
            <TipElement title={"Immobiliensuche heute und morgen"} image={"https://md-immo.at/wp-content/uploads/2024/02/MD-B-Merbodgasse-1-1200x675.jpg"} text={"Die Beheizung erfolgt zentral und effizient per Gas, während die hochwertige Ausstattung für ein rundum behagliches Wohngefühl sorgt. Neben der Wohnfläche erwartet Sie eine großzügige, sonnige Terrasse mit etwa 45 m2 – der ideale Ort für entspannte Stunden. Abgerundet wird dieses Angebot durch eine Tiefgarage, geräumige Kellerabteile und 10 überdachte Abstellplätze für die Allgemeinheit. Dieses tolle Wohnung in guter Lage von Bregenz bietet die perfekte Gelegenheit, ein neues Kapitel zu beginnen. Vereinbaren Sie noch heute einen Besichtigungstermin und überzeugen Sie sich selbst von diesem fantastischen Angebot!"}/>
            <TipElement title={"Immobiliensuche heute und morgen"} image={"https://md-immo.at/wp-content/uploads/2021/04/MD_Immo-Hero-1200x675.jpg"} text={"f,sdbfkjn .fjnlakn.n. IFNK FJNJf k n fn. k kbN K k bN DNKF ijdsdlnfkDF iuifuhÖJAJDAÖS"}/>   
        </div>
    </div>
  )
};

const TipElement = ({title, image, text}) => {

    const [loading, setLoading] = useState(true);
    const [imageSrc, setImageSrc] = useState();

    useEffect(() => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          setLoading(false); // Ladezustand beenden
          setImageSrc(image); // Aktualisieren Sie den Bildquellpfad auf das eigentliche Bild
        };
      }, [image]);

  return (
    <div className={`border rounded-xl shadow-xl flex flex-col items-center hover:opacity-60 cursor-pointer w-full max-w-500 bg-white h-300 md:h-340`}>
        {loading && <CircularProgress size={60} className='m-auto'/>}
        <img src={image} alt={title} className={`w-fit h-200 object-cover rounded-t-xl ${loading ? 'hidden' : 'block'}`}/>
        <h1 className='text-lg text-allimmoDark px-2 truncate self-start'>{title}</h1>
        <p className='text-sm line-clamp-3 px-2 mb-1 text-justify self-start'>{text}</p>
    </div>
  )
}

export default Tips