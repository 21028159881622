import { t } from "i18next";
import { createContext, useContext, useEffect, useState } from "react";
import calculateGeohashes from "../components/Searches/Creation/functions/calculateGeohashes";

const CreationContext = createContext();

export const CreationProvider = ({ children }) => {

    const initialSearchData = {
        accessible: false,
        activeBusiness: "-",
        airCondition: false,
        areas: [1,100],
        areasUnlimited: false,
        attic: "",
        availableFrom: "",
        avoidCompensation: false,
        balcony: false,
        bathtub: false,
        bike: false,
        blinds: false,
        building: "",
        buildingSite: false,
        cellar: false,
        chimney: false,
        comission: false,
        commercialType: "",
        cooling: false,
        country: "AT",
        deal: "",
        developed: false,
        elevator: false,
        first: false,
        fitness: false,
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false,
        floorUnlimited: false,
        furnished: "",
        garage: false,
        garden: false,
        geohashes: [], // "u2e9f", "u2e9g", "u2e9u", "u2ed4", "u2ed5", "u2edh"
        groundFloor: "",
        heatingMulti: {
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        industrialLicense: false,
        isolated: "",
        kitchen: false,
        land: [0,30000],
        landUnlimited: false,
        laundry: false,
        licensing: [],
        limited: false,
        minimumFloor: 0, 
        name: `Match ${Math.floor((Math.random() * 100000) + 1)}`,
        new: false,
        noGas: false,
        noOldGrowth: false,
        noPublicAreas: false,
        openAreas: "",
        pets: false,
        params: 0,
        parkingLots: false,
        parkingLotsMin: 1,
        polygon: [],
        pool: false,
        presentIndustry: "?",
        price: [0,1000],
        priceUnlimited: false,
        renovation: false,
        renovationRatio: [0,100],
        roofTop: false,
        rooms: [1,5],
        roomsUnlimited: false,
        searchOrder: false,
        sauna: false,
        size: [0,100],
        sizeUnlimited: false,
        shortTermRent: false,
        squarePrice: {
            use: false,
            price: 0,
        },
        storageRoom: false,
        type: "",
        units: [2,10],
        unitsUnlimited: false,
        unlimited: false,
        urgency: "",
        vacancy: ["0", "100"],
        vacant: false,
    };

    const initialObjectData = {
        accessible: false,
        activeBusiness: false,
        address: {
            city: "",
            country: "",
            street: "",
            number: "",
            streetNumber: "",
            cityZip: ""
        },
        airCondition: false,
        alarm: false,
        attic: false,
        available: true,
        availableFrom: "",
        bathtub: false,
        bike: false,
        blinds: false,
        building: "-",
        buildingSite: false,
        cellar: false,
        chimney: false,
        condition: "-",
        comission: 0,
        comissionUnit: "",
        comissionContract: "link",
        comissionContractLink: "",
        commercialType: "",
        commercialVacancy: 0,
        compensation: 0,
        cooling: false,
        country: "AT",
        customFacilities: [],
        deal: "",
        deposit: 0,
        developed: false,
        door: false,
        elevator: false,
        energyPass: {},
        exposeLink: "",
        fitness: false,
        flatshareCapable: false,
        floor: "-",
        floorHeating: false,
        floors: 1,
        fromInterface: false,
        furnished: "",
        garage: false,
        garagePrice: 0,
        groundFloor: false,
        heating: "-",
        houseType: "",
        industrialLicense: false,
        kitchen: false,
        land: 0,
        landClassification: { // NEU
            buildingSite: false,
            green: false,
            protected: false,
            special: false,
        }, 
        laundry: false,
        licensing: [],
        location: {
            lat: 0,
            lng: 0,
        },
        maisonette: false,
        multiLivingSpace: 0,
        notes: "",
        oldGrowth: false,
        openAreas: [],
        pets: false,
        pictureLinks: [],
        pool: false,
        presentIndustry: "-",
        presentIndustryOther: "",
        price: 0,
        rentedUntil: 0,
        rooms: 1,
        sauna: false,
        shareable: false,
        shortTermRent: false,
        size: 0,
        solar: false,
        storageRoom: false,
        tourLink: "",
        type: "",
        units: 2,
        unitsCondition: {
            first: "0",
            new: "0",
            renovation: "0",
        },
        unitSizeMax: 0,
        unitSizeMin: 0,
        unitsState: {
            vacant: "0",
            limited: "0",
            unlimited: "0",
        },
        utilityBill: 0,
        vacancy: "-",
        yearOfConst: 0,
    };

    const [createSearchOpen, setCreateSearchOpen] = useState(false);
    const [searchData, setSearchData] = useState(initialSearchData);
    const [searchState, setSearchState] = useState("urgency");

    const [createObjectOpen, setCreateObjectOpen] = useState(false);
    const [objectData, setObjectData] = useState(initialObjectData);
    const [objectState, setObjectState] = useState("what");
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [objectComissionContractFile, setObjectComissionContractFile] = useState(null);

    // useEffect(() => {
    //   console.log(searchData)
    // }, [searchData]);

    // useEffect(() => {
    //     console.log(objectData)
    // }, [objectData]);

    const resetSearchData = () => {
        setSearchData(initialSearchData);
        setSearchState("urgency");
    };

    const resetObjectData = () => {
        setObjectData(initialObjectData);
        setObjectState("what");
    };

    const getSearchParameters = () => {
        let params = 4; // country, zip, type, deal

        if([searchData.new, searchData.first, searchData.project, searchData.renovation].some(el => el === true)) {
            params++;
        }

        if(searchData.pets) {
            params++;
        }

        return params;
    };

    const resetZeroInput = (prop) => {
        if([NaN, "NaN", "", 0, "0"].includes(objectData[prop])) {
          setObjectData({...objectData, [prop]: ""});
        }
    };

    const resetSearchZeroInputArray = (prop, index) => {
        if([NaN, "NaN", "", 0, "0"].includes(searchData[prop][index])) {
          setSearchData({...searchData, [prop]: [index === 0 ? "" : searchData[prop][0], index === 1 ? "" : searchData[prop][1]]});
        }
    };

    const resetOneInput = (prop) => {
        if([NaN, "NaN", "", 0, "0"].includes(objectData[prop])) {
          setObjectData({...objectData, [prop]: 1});
        }
    };

    const resetCustomArrayOnBlur = (prop, index, resetNumber) => {
        if([NaN, "NaN", "", 0, "0"].includes(objectData[prop])) {
          setObjectData({...objectData, [prop]: [index === 0 ? "" : searchData[prop][0], index === 1 ? "" : searchData[prop][1]]});
        }
    };

    const resetCustomInput = (prop, resetNumber) => {
        if([NaN, "NaN", "", 0, "0"].includes(objectData[prop])) {
          setObjectData({...objectData, [prop]: resetNumber});
        }
    };

    return (
        <CreationContext.Provider
            value={{ 
                searchData,
                objectData,
                searchState,
                objectState,
                createObjectOpen,
                createSearchOpen,
                uploadedFiles,
                objectComissionContractFile,
                initialSearchData,
                setObjectComissionContractFile,
                setUploadedFiles,
                resetZeroInput,
                resetSearchZeroInputArray,
                resetOneInput,
                resetCustomInput,
                setCreateObjectOpen,
                setCreateSearchOpen,
                setObjectState,
                getSearchParameters,
                setSearchState,
                setObjectData,
                resetSearchData,
                resetObjectData,
                setSearchData
            }}>
                {children}
        </CreationContext.Provider>
    )
}

export const useCreationContext = () => useContext(CreationContext);