import AnimationIcon from "../../Generics/AnimationIcon";

const StageLabel = ({
  labelText,
  iconSize = 70,
  stageIcon = null,
  delay = "2000",
  color = true,
  headline = "",
}) => {
  return (
    <div className={`flex flex-col items-center justify-center ${stageIcon !== null ? "ssm:mb-3" : "mb-3"} px-2 w-full`}>
      {headline !== "" && (
        <p className="text-xl ssm:text-2xl font-bold text-gray-800 w-full text-center ">{headline}</p>
      )}
      <p className={`text-allimmoDark text-sm ssm:text-base italic w-full text-center mb-1 ${stageIcon !== null ? "ssm:mb-5" : ""}`}>
        {labelText}
      </p>
      {stageIcon !== null && (
        <AnimationIcon
          iconSrc={stageIcon}
          _iconSize={iconSize}
          _delay={delay}
          _color={color}
        />
      )}
    </div>
  );
};

export default StageLabel;
