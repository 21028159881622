import { t } from "i18next";
import { useEffect } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useCreationContext } from "../../../contexts/CreationProvider";
import NavMenu from "../../Generics/NavMenu";
import StageLabel from "../../Objects/Creation/StageLabel";
import ThreeWaySelection from "./ThreeWaySelection";

const OpenAreas = ({next, back, _withProp = false}) => {

    const { searchData, setSearchData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
        setSearchData({...searchData, openAreas: ""})
    }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full mdd:w-2/3 mt-10 gap-1">
        <StageLabel headline={searchData.type === "multi" ? t('CreateSearch.FreiflächenMulti') : t('CreateSearch.Freiflächen')} labelText={searchData.type === "multi" ? t('CreateSearch.OpenAreasLabelMulti') : t('CreateSearch.OpenAreasLabel')}/>
        <ThreeWaySelection
        withProp={_withProp} 
        prop="openAreas" 
        options={
            [
                {
                    value: "-",
                    animation: "egal",
                    label: t("General.Egal"),
                },
                {
                    value: "yes",
                    animation: "thumbUp",
                    label: t("CreateSearch.Unbedingt"),
                },
                {
                    value: "no",
                    animation: "thumbDown",
                    label: t("CreateSearch.Vermeiden"),
                },
            ]
        } 
        _next={(e) => next(e)}/>

        <NavMenu _back={back} />
    </div>
  )
};

export default OpenAreas
