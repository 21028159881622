import { Timestamp, arrayUnion } from "firebase/firestore";
import { t } from "i18next";
import { useRef, useState } from "react";
import { updateFirestoreDoc } from "../../helper/functions";
import { BurgerMenu, Checkmark, Update } from "../../helper/icons";
import useOutsideClick from "../../hooks/useOutsideClick";
import { CircularProgress } from "@mui/material";
import { auth } from "../../firebase.config";

const BaseInfos = ({ _objectData, editable = true }) => {

  const [propToAdd, setPropToAdd] = useState("");

  return (
    <div className={`bg-white rounded flex flex-col w-full pb-2 border z-0 border-allimmoDark/70`}>
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 px-1.5 py-0.5 rounded-t">
        <p>{t('General.AllgemeineInfos')}</p>
        {(_objectData?.type !== "land" && editable) && 
        <Menu objectData={_objectData} _setPropToAdd={setPropToAdd}/>}
      </div>

      <div className="flex flex-col px-2">
        <PropertyElement
        _editable={editable}
        label={_objectData?.deal === "rent" ? _objectData?.type === "commercial" ? t("General.Nettomiete") : t("General.Gesamtmiete") : _objectData?.deal === "lease" ? t("General.Pacht") : t("General.Preis")} 
        unit="€" 
        property="price" 
        objectData={_objectData} />

        {(_objectData?.utilityBill > 0 || propToAdd === "utilityBill") &&
        <PropertyElement _editable={editable} label={t("ObjectListing.Betriebskosten")} unit="€" property="utilityBill" objectData={_objectData} add={propToAdd} resetPropToAdd={() => setPropToAdd("")}/>}

        {(_objectData?.garage &&  _objectData?.deal === "rent" && parseFloat(_objectData?.garagePrice) > 0) &&
        <PropertyElement _editable={editable} label={t("ObjectListing.GaragePrice")} unit="€" property="garagePrice" objectData={_objectData} add={propToAdd} resetPropToAdd={() => setPropToAdd("")}/>}

        {_objectData?.type !== "land" && 
        <PropertyElement _editable={editable} label={_objectData?.type === "commercial" ? t("General.Fläche") : t("General.Wohnfläche")} unit="m²" property="size" objectData={_objectData} />}

        {(["land", "house", "commercial"].includes(_objectData?.type) && (_objectData?.type === "commercial" ? ["hall", "building"].includes(_objectData?.commercialType) : true)) &&
        <PropertyElement _editable={editable} label={t("General.Grundfläche")} unit="m²" property="land" objectData={_objectData} />}

        {(["apartment", "house", "commercial"].includes(_objectData?.type) && (_objectData?.type === "commercial" ? _objectData?.commercialType !== "hall" : true)) &&
        <PropertyElement _editable={editable} label={t("General.Räume")} property="rooms" objectData={_objectData} />}
        
        {(_objectData?.type !== "land" && [0, "0", ""].includes(_objectData?.yearOfConst)) &&
        <PropertyElement _editable={editable} label={t("CreateObject.Jahr")} property="yearOfConst" objectData={_objectData} isFloat={false} />}

        {_objectData?.type === "multi" && 
        <PropertyElement _editable={editable} label={t("CreateObject.Einheiten")} property="units" objectData={_objectData} isFloat={false} />}

        {(parseFloat(_objectData?.compensation) > 0 || propToAdd === "compensation") &&
        <PropertyElement _editable={editable} label={t("CreateObject.Ablöse")} property="compensation" objectData={_objectData} unit="€" add={propToAdd} resetPropToAdd={() => setPropToAdd("")} />}
        
        {![0, "0", "", undefined].includes(_objectData?.comission) &&
        <PropertyElement isFloat={false} _editable={editable} label={t("CreateObject.Provision")} property="comission" objectData={_objectData} unit={_objectData?.fromInterface === true ? "" : _objectData?.comissionUnit} />}

        {(parseFloat(_objectData?.deposit) > 0 || propToAdd === "deposit") && 
        <PropertyElement _editable={editable} label={t("General.Kaution")} property="deposit" objectData={_objectData} unit="€" add={propToAdd} resetPropToAdd={() => setPropToAdd("")} />}

        {_objectData?.heating !== "-" &&
        <HeatingProperty _editable={false} objectData={_objectData} />}

        {([true, false].includes(_objectData?.cooling) && _objectData.type === "commercial") &&
        <BooleanProperty _editable={editable} property="cooling" objectData={_objectData} />}
      </div>
    </div>
  );
};

const HeatingProperty = ({objectData, _editable = true}) => {

  const [editMode, setEditMode] = useState(false);
  const [newValue, setNewValue] = useState(objectData?.heating);
  const [isSaving, setIsSaving] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setNewValue(objectData?.heating);
    setEditMode(false);
  });

  const handleSave = async () => {
    if(!_editable) return;
    if(objectData?.customer !== auth.currentUser.uid) return;

    if(newValue === objectData?.heating) {
      setEditMode(false);
    } else {
      setIsSaving(true);
      await updateFirestoreDoc("objects", objectData?.id, {heating: newValue, edited: Timestamp.fromDate(new Date()), editedData: arrayUnion({
        updateID: objectData.editedData ? objectData.editedData.length + 1 : 1, 
        propertyName: "heating", 
        oldValue: objectData?.heating, 
        newValue: newValue,
        created: Timestamp.fromDate(new Date()),
      })})
      .then(() => {
        setIsSaving(false);
        setEditMode(false);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        setIsSaving(false);
      });
    }
  };

  return (
    <div className="flex flex-row justify-between">
      <p>{t("General.Heizung")}</p>
      <div ref={wrapperRef}>
        {!editMode && <p className={`${_editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}`} onClick={() => _editable ? setEditMode(true) : null}>{t(`Facilities.${objectData?.heating}`)}</p>}
        {(editMode && _editable) &&
        <div className="flex flex-row items-center gap-1">
          {!isSaving && <Checkmark onClick={handleSave} size={15} className="cursor-pointer text-green-600 hover:text-green-800"/>}
          <select autoFocus={editMode} className="px-1.5 border h-6 rounded border-black w-44" value={newValue} onChange={(e) => setNewValue(e.target.value)}>
              <option value="fern">{t('Facilities.fern')}</option>
              <option value="gas">{t('Facilities.gas')}</option>
              {["house", "multi"].includes(objectData.type) && <option value="oil">{t('Facilities.oil')}</option>}
              <option value="electric">{t('Facilities.electric')}</option>
              {["house", "multi"].includes(objectData.type) && <option value="pump">{t('Facilities.pump')}</option>}
              {["house", "multi"].includes(objectData.type) && <option value="pellets">{t('Facilities.pellets')}</option>}
              <option value="otherHeating">{t('Facilities.otherHeating')}</option>
          </select>
        </div>}
      </div>
    </div>
  )
};

const BooleanProperty = ({property, objectData, _editable = true}) => {

  const [isSaving, setIsSaving] = useState(false);
  
  const handleChange = async () => {
    if(!_editable) return;
    if(objectData?.customer !== auth.currentUser.uid) return;

    setIsSaving(true);
    await updateFirestoreDoc("objects", objectData?.id, {[property]: !objectData?.[property], edited: Timestamp.fromDate(new Date()), editedData: arrayUnion({
      updateID: objectData.editedData ? objectData.editedData.length + 1 : 1, 
      propertyName: property, 
      oldValue: objectData[property], 
      newValue: !objectData[property],
      created: Timestamp.fromDate(new Date()),
    })})
    .then(() => {
      setIsSaving(false);
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
      setIsSaving(false);
    });
  };

  return (
    <div className="flex flex-row justify-between">
      <p>{t(`Facilities.${property}`)}</p>
      {!isSaving && 
      <div className="flex flex-row gap-1 items-center">
        {_editable && <Update size={12} className="text-allimmoDark/30" />}
        <p className={`${_editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}`} onClick={_editable ? handleChange : null}>{objectData?.[property] ? t('General.Ja') : t('General.Nein')}</p>
      </div>}
      {isSaving && <CircularProgress size={15} />}
    </div>
  )
};

const PropertyElement = ({property, objectData, label, resetPropToAdd = null, unit = "", isFloat = true, add = "", _editable = true}) => {

  const addProp = useRef(add);

  const [editMode, setEditMode] = useState(false);
  const [newValue, setNewValue] = useState(addProp.current !== "" ? "" : objectData?.[property]);
  const [isSaving, setIsSaving] = useState(false);
  
  const invalidInput = isNaN(newValue);
  const valueMissing = ["undefined", "missing"].includes(objectData?.[property]);
  const valueUnknown = objectData?.[property] === "unknown";

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setNewValue(objectData?.[property]);
    if(addProp.current !== "") resetPropToAdd();
    setEditMode(false);
  });

  const handleSave = async () => {
    if(!_editable) return;
    if(objectData?.customer !== auth.currentUser.uid) return;
    if(invalidInput) return;

    if(parseFloat(newValue) === parseFloat(objectData?.[property])) {
      setEditMode(false);
    } else {
      setIsSaving(true);
      await updateFirestoreDoc("objects", objectData?.id, {[property]: newValue.length > 0 ? newValue : 0, edited: Timestamp.fromDate(new Date()), editedData: arrayUnion({
        updateID: objectData?.editedData ? objectData?.editedData.length + 1 : 1, 
        propertyName: property, 
        oldValue: objectData[property], 
        newValue: newValue.length > 0 ? newValue : 0,
        created: Timestamp.fromDate(new Date()),
      })})
      .then(() => {
        if(addProp.current !== "") resetPropToAdd();
        addProp.current = "";
        setIsSaving(false);
        setEditMode(false);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        setIsSaving(false);
      });
    }
  };

  const handleEnterPress = (event) => {
    if (invalidInput) return;
    if (event.keyCode === 13 || event.which === 13) {
      handleSave();
    }
  };

  return (
    <div className="flex flex-row justify-between">
      <p>{label}</p>
      <div ref={wrapperRef}>
        {(!editMode && addProp.current !== property) && 
        <p className={`text-end ${_editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}${valueMissing ? "animate-pulse text-red-500" : ""}`} onClick={() => _editable ? setEditMode(true) : null}>{(valueMissing || valueUnknown) ? t("MissingData.defaultValue") : (isFloat ? parseFloat(objectData?.[property])?.toLocaleString() : (typeof objectData?.[property] === "string" && objectData?.[property]?.endsWith(".")) ? objectData?.[property]?.slice(0, -1) : objectData?.[property]) + unit}</p>}
        {(editMode || addProp.current === property) && 
        <div className="flex flex-row items-center gap-1">
          {(!isSaving && !invalidInput && _editable) && <Checkmark onClick={handleSave} size={20} className="cursor-pointer text-green-600 hover:text-green-800"/>}
          <input autoFocus={editMode || addProp.current === property} onKeyDown={(e) => _editable ? handleEnterPress(e) : null} className="text-right px-1.5 border h-6 rounded border-black w-24" value={newValue} onChange={(e) => setNewValue(e.target.value)}/>
        </div>}
      </div>
    </div>
  )
};

const Menu = ({objectData, _setPropToAdd}) => {

  const [menuOpen, setMenuOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setMenuOpen(false);
  });

  const handleMenuClick = (prop) => {
    setMenuOpen(false);
    _setPropToAdd(prop);
  };

  return (
    <div ref={wrapperRef} className={`relative ${(parseFloat(objectData?.utilityBill) > 0 && parseFloat(objectData?.compensation) > 0 && (objectData?.deal !== "sale" ? parseFloat(objectData?.deposit) > 0 : true)) ? 'hidden' : 'block'}`}>
      <BurgerMenu onClick={() => setMenuOpen(!menuOpen)} size={18} className="cursor-pointer hover:text-allimmoDark/30 text-allimmoDark/80" />
      {menuOpen && 
      <div className="absolute bg-white z-10 rounded top-5 right-0 shadow-xl border border-allimmoDark/80 text-sm w-32">
        <p className="bg-allimmoDark/20 w-full p-1 border-b border-allimmoDark/80 mb-2">Hinzufügen</p>
        {parseFloat(objectData?.utilityBill) <= 0 && 
        <p onClick={() => handleMenuClick("utilityBill")} className="cursor-pointer hover:bg-allimmoDark/10 p-1 rounded">{t("ObjectListing.Betriebskosten")}</p>}
        {parseFloat(objectData?.compensation) <= 0 && 
        <p onClick={() => handleMenuClick("compensation")} className="cursor-pointer hover:bg-allimmoDark/10 p-1 rounded">{t("CreateObject.Ablöse")}</p>}
        {/* {parseFloat(objectData?.comission) <= 0 && <p className="cursor-pointer hover:bg-allimmoDark/10 p-1 rounded">{t("General.Provision")}</p>} */}
        {(parseFloat(objectData?.deposit) <= 0 && objectData?.deal !== "sale") && 
        <p onClick={() => handleMenuClick("deposit")} className="cursor-pointer hover:bg-allimmoDark/10 p-1 rounded mb-1">{t("General.Kaution")}</p>}
      </div>}
    </div>
  )
};

export default BaseInfos
