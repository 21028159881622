import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import AnimationIcon from '../../Generics/AnimationIcon';

const FacilityBox = ({name, size = 60, boxHeight = "h-32", disabled = false}) => {

    const { objectData, setObjectData } = useCreationContext();

    const handleClick = () => {
      if(disabled) return;
      setObjectData({...objectData, [name]: !objectData[name]});
    };

  return (
    <div onClick={handleClick} className={`flex flex-col ${disabled ? 'opacity-10' : objectData[name] ? `opacity-100 bg-blue-200 hover:bg-blue-100` : 'opacity-30 hover:bg-blue-50'} bg-white hover:shadow-xl ${disabled ? "" : "cursor-pointer"} justify-center items-center border-2 border-allimmoDark p-1 aspect-square ${boxHeight} rounded-xl`}>
        <AnimationIcon animate={objectData[name]} _color={false} _iconSize={size} iconSrc={require(`../../../assets/animations/objectCreation/facilities/${name}.json`)}/>
        <p className='text-allimmoDark w-full text-center text-12 ssm:text-sm md:text-base'>{t(`Facilities.${name}`)}</p>
    </div>
  )
};

export default FacilityBox
