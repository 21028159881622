import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import SquareButton from '../../Generics/SquareButton';
import StageLabel from './StageLabel';
import AnimationIcon from '../../Generics/AnimationIcon';
import { useEffect } from 'react';
import NavMenu from '../../Generics/NavMenu';

const Deal = ({next, back}) => {

    const { objectData, setObjectData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleDealType = (newDeal) => {
        setObjectData({...objectData, deal: newDeal});
        setTimeout(() => {
            next();
        }, "150");
    };

  return (
    <div className="flex flex-col justify-center items-center w-full md:w-1/2">
        <StageLabel labelText={t('CreateObject.DealLabel')}/>
        <div className={`flex flex-row justify-center gap-2 sm:gap-3 items-center w-full mt-5`}>
            {objectData.type !== "land" && <SquareButton dynamic={false} selected={objectData.deal === "rent"} clickFunction={() => handleDealType("rent")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/deal_rent.json')}/>} label={t('Deals.rent')}/>}
            {objectData.type === "land" && <SquareButton dynamic={false} selected={objectData.deal === "lease"} clickFunction={() => handleDealType("lease")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/deal_rent.json')}/>} label={t('Deals.lease')}/>}
            <SquareButton dynamic={false} selected={objectData.deal === "sale"} clickFunction={() => handleDealType("sale")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/deal_sale.json')}/>} label={t('Deals.sale')}/>                
        </div>

        <NavMenu _back={back} />
    </div>
  )
}

export default Deal