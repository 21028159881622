import { t } from "i18next";
import Button from "../Generics/Button";
import { useStateContext } from "../../contexts/ContextProvider";
import { useEffect, useState } from "react";
import { collection, documentId, getDocs, query, where } from "firebase/firestore";
import { auth, firestore } from "../../firebase.config";
import { CircularProgress } from "@mui/material";
import { useObjectsContext } from "../Object/Contexts/ObjectsProvider";

const ActivateAutoContactSetting = ({confirm, close}) => {

    const { customer } = useStateContext();

    const [isLoading, setIsLoading] = useState(false);
    const [matchesCount, setMatchesCount] = useState(0);
    const [insufficientCredits, setInsufficientCredits] = useState(true);
   
    useEffect(() => {
      getNumberOfMatches();
    }, []);

    useEffect(() => {
      setInsufficientCredits(parseInt(customer.credits) < parseInt(matchesCount));
    }, [matchesCount]);

    const getNumberOfMatches = async () => {
        setIsLoading(true);
        const wantNormalSearches = [undefined, true].includes(customer?.settings?.autoContactSearchDepth?.normal);
        const wantAccurateSearches = [undefined, true].includes(customer?.settings?.autoContactSearchDepth?.genau);
        const wantInaccurateSearches = [undefined, true].includes(customer?.settings?.autoContactSearchDepth?.ungenau);

        const objectsRef = query(collection(firestore, "objects"), where("customer", "==", auth.currentUser.uid), where("matches", "!=", []));
        const objects = await getDocs(objectsRef);

        const objectsSearches = objects.docs.map(object => [...object.data()?.matches]);

        // OPTIMIZE: This can be optimized when match collection is ready for production by not querying newly from the db but using the depth value saved in match document instead
        const searches = query(collection(firestore, "searches"), where(documentId(), "in", [...objectsSearches.flat(), "123"]));
        const searchResults = await getDocs(searches);

        const accurateMatchesToContact = wantAccurateSearches ? (searchResults.docs.filter(search => search.data().depth >= 7).length) : 0;
        const normalMatchesToContact = wantNormalSearches ? (searchResults.docs.filter(search => search.data().depth >= 5 && search.data().depth <= 6).length) : 0;
        const inaccurateMatchesToContact = wantInaccurateSearches ? (searchResults.docs.filter(search => search.data().depth <= 4).length) : 0;

        setMatchesCount(accurateMatchesToContact + normalMatchesToContact + inaccurateMatchesToContact);
        setIsLoading(false);
    };
    

  return (
    <div className="px-2 py-8 flex flex-col justify-center items-center">
        <p className="text-lg text-allimmoDark text-center">{t('Einstellungen.AutoContactFrage')}</p>
        <p className="mt-1 px-8 w-full text-center text-sm">{t('Einstellungen.AutoContactTextAktivieren')}</p>
        {isLoading && <CircularProgress className="mt-5 mb-3" size={25} />}

        {(!isLoading && matchesCount > 0) &&
        <div className="flex flex-col items-center justify-center mt-5 mb-2 border rounded-lg px-8 py-1.5 border-dashed border-allimmoDark/50">
            <div className="text-allimmoDark text-sm sm:text-lg w-full justify-center flex gap-1 flex-row items-center">
                <p>{t("General.DerzeitHastDu")}</p>
                {!isLoading && <p className="text-center font-bold">{matchesCount.toLocaleString()}</p>}
                {isLoading && <CircularProgress size={20} />}
                <p>{t("General.PassendeMatches")}</p>
            </div>

            <div className="text-allimmoDark text-sm sm:text-lg w-full justify-center flex gap-1 flex-row items-center -mt-1">
                <p className="italic">{t("General.Und")}</p>
                <p className={`font-bold text-center ${insufficientCredits ? "text-red-500 animate-pulse" : ""}`}>{customer.credits.toLocaleString()}</p>
                <p className="italic">{t("General.Credits")}</p>
            </div>
        </div>}

        <div className="flex flex-row justify-center items-center space-x-2 mt-5">
            <Button isDisabled={insufficientCredits || isLoading} text={t('General.Aktivieren')} clickFunction={confirm}/>
            <Button text={t('General.Abbrechen')} clickFunction={close}/>
        </div>
    </div>
  )
}

export default ActivateAutoContactSetting;
