import { t } from "i18next";
import { updateFirestoreDoc } from "../helper/functions";

export const FacilityEdit = ({facility, state, objectId}) => {

  const handleChange = async () => {
    await updateFirestoreDoc("objects", objectId, {[facility]: !state});
  };

    return (
      <div className={`flex flex-row w-full items-center gap-2`} onClick={handleChange}>
        {/* <SwingCheckmark size={16} className='mr-2 text-allimmoDark/70'/> */}
        {/* <Checkbox size="small" checked={state} value={state}/> */}
        <input type="checkbox" checked={state} value={state} />
        <p>{t(`Facilities.${facility}`)}</p>
      </div>
    )
};

export default FacilityEdit
