import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { HouseIso, HouseRow, HouseSemi } from '../../../helper/icons';
import SquareButton from '../../Generics/SquareButton';
import StageLabel from './StageLabel';
import Button from '../../Generics/Button';
import AnimationIcon from '../../Generics/AnimationIcon';
import NavMenu from '../../Generics/NavMenu';

const HouseType = ({next, back}) => {

    const { objectData, setObjectData } = useCreationContext();
    const { screenSize } = useStateContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

    const handleSubType = (newSubType) => {
        setObjectData({...objectData, houseType: newSubType});
        setTimeout(() => {
          next();
      }, "150");
    };

  return (
    <div className="flex flex-col justify-center items-center lg:w-1/2">
      <StageLabel labelText={t('CreateObject.HouseTypeLabel')}/>
      <div className={`grid grid-cols-2 mmd:grid-cols-3 place-items-center justify-center gap-2 md:gap-3 items-center mb-10 mt-5`}>
          <SquareButton selected={objectData.houseType === "isolated"} clickFunction={() => handleSubType("isolated")} icon={<AnimationIcon _color={false} _iconSize={screenSize.width > 500 ? 70 : 50} iconSrc={require('../../../assets/animations/objectCreation/isolated.json')}/>} label={t('Facilities.isolated')}/>
          <SquareButton selected={objectData.houseType === "semi"} clickFunction={() => handleSubType("semi")} icon={<AnimationIcon _color={false} _iconSize={screenSize.width > 500 ? 70 : 50} iconSrc={require('../../../assets/animations/objectCreation/half.json')}/>} label={t('Facilities.semi')}/>
          <SquareButton selected={objectData.houseType === "row"} clickFunction={() => handleSubType("row")} icon={<AnimationIcon _color={false} _iconSize={screenSize.width > 500 ? 70 : 50} iconSrc={require('../../../assets/animations/objectCreation/row.json')}/>} label={t('Facilities.row')}/>
      </div>
      <NavMenu _back={back} />
    </div>
  )
}

export default HouseType