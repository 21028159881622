import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import NavMenu from '../../Generics/NavMenu';
import AnimationIcon from '../../Generics/AnimationIcon';
import StageLabel from '../../Objects/Creation/StageLabel';

const Facilities = ({back, next}) => {

  const { searchData } = useCreationContext();

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div className='w-full flex flex-col justify-center items-center py-2 sm:py-5'>
      <StageLabel headline={t('CreateSearch.FacilitiesHeadline')} labelText={t('CreateSearch.FacilitiesLabel')}/>
      <div className='grid grid-cols-2 ssm:grid-cols-3 md:grid-cols-4 gap-1 mt-2 sm:mt-4'>
        <FacilityBox name={"cellar"}/>
        <FacilityBox name={"elevator"}/>
        <FacilityBox name={"garage"}/>
        {searchData?.type !== "multi" && <FacilityBox name={"kitchen"}/>}
        {searchData?.type === "apartment" && <FacilityBox name={"storageRoom"}/>}
        {["multi", "house"].includes(searchData?.type) && <FacilityBox name={"pool"}/>}
        <FacilityBox name={"blinds"}/>
        <FacilityBox name={"airCondition"}/>
        {!["multi", "commercial"].includes(searchData?.type) &&<FacilityBox name={"bathtub"}/>}
        {["multi", "apartment"].includes(searchData?.type) && <FacilityBox name={"laundry"}/>}
        {["multi", "apartment"].includes(searchData?.type) && <FacilityBox name={"bike"}/>}
        {["multi", "apartment"].includes(searchData?.type) && <FacilityBox name={"fitness"}/>}
        {["multi", "apartment", "house"].includes(searchData?.type) && <FacilityBox name={"sauna"}/>}
        {(searchData?.type === "commercial" && searchData?.commercialType === "building") && <FacilityBox name={"sauna"}/>}
      </div>
      <NavMenu _back={back} _next={next} nextBtn/>
    </div>
  )
};

const FacilityBox = ({name, size = 60, boxHeight = "h-32"}) => {

    const { searchData, setSearchData } = useCreationContext();

  return (
    <div onClick={() => setSearchData({...searchData, [name]: !searchData[name]})} className={`flex flex-col ${searchData[name] ? `opacity-100 bg-blue-200 hover:bg-blue-100` : 'opacity-30 hover:bg-blue-50'} bg-white hover:shadow-xl cursor-pointer justify-center items-center border-2 border-allimmoDark p-1 aspect-square ${boxHeight} rounded-xl`}>
        <AnimationIcon animate={searchData[name]} _color={false} _iconSize={size} iconSrc={require(`../../../assets/animations/searchCreation/facilities/${name}.json`)}/>
        <p className='text-allimmoDark w-full text-center text-12 ssm:text-sm md:text-base'>{t(`Facilities.${name}`)}</p>
    </div>
  )
};

export default Facilities
