import { t } from 'i18next';
import AnimationIcon from '../Generics/AnimationIcon';
import BooleanProperty from './Components/BooleanProperty';

const OpenAreas = ({searchData}) => {

    const allAreas = searchData?.garden && searchData?.balcony && searchData?.roofTop;

  return (
    <div className={`bg-white rounded flex flex-col w-full pb-2 border z-0 border-allimmoDark/70`}>
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 px-1.5 py-0.5 rounded-t">
        <p>{t('SearchDetails.OpenAreas.Header')}</p>
      </div>
      <div className="flex flex-row justify-evenly gap-1 ssm:gap-2 mt-1 px-2">
        {(searchData?.balcony || allAreas) && <OpenAreaElement type="balcony" />}
        {(searchData?.roofTop || allAreas) && <OpenAreaElement type="roofTop" />}
        {(searchData?.garden || allAreas) && <OpenAreaElement type="garden" />}
      </div>
      
      {searchData?.noPublicAreas &&
      <div className="mt-2 pl-2 flex flex-col">
        <BooleanProperty property="noPublicAreas" searchData={searchData} propertyOnly />
      </div>}
    </div>
  )
};

const OpenAreaElement = ({type}) => {
    return (
      <div className={`flex flex-col items-center rounded-lg border border-allimmoDark/40 p-2 w-full shadow`}>
        <AnimationIcon _color _iconSize={30} iconSrc={require(`../../assets/animations/searchCreation/facilities/${type}.json`)}/>
        <p className="text-xs">{t((`SearchDetails.OpenAreas.AreaTypes.${type}`))}</p>
      </div>
    )
};

export default OpenAreas
