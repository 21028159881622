import { createContext, useContext, useState } from "react";

const SearchesContext = createContext();

export const SearchesProvider = ({ children }) => {
    
    const [customerSearches, setCustomerSearches] = useState([]);

    return (
        <SearchesContext.Provider
            value={{ 
                customerSearches,
                setCustomerSearches,
            }}>
                {children}
        </SearchesContext.Provider>
    )
};

export const useSearchesContext = () => useContext(SearchesContext)
