import { collection, getCountFromServer, getDocs, query, where } from 'firebase/firestore';
import React, { useState } from 'react'
import { firestore } from '../../../firebase.config';
import { CircularProgress } from '@mui/material';
import { deactivateSearch, updateFirestoreDoc } from '../../../helper/functions';

const DeleteUserSearches = () => {

    const [searchCount, setSearchCount] = useState(0);
    const [inputData, setInputData] = useState({
        userID: "",
        email: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [searchDone, setSearchDone] = useState(false);

    const countSearches = async () => {
        setIsLoading(true);
        let q;

        if(inputData.userID.trim() !== "") {
            q = query(collection(firestore, "searches"), where("customer", "==", inputData.userID), where("active", "==", true), where("deleted", "==", false));
        }

        // Get searches from firestore
        const queryCount = await getCountFromServer(q);
        setSearchCount(queryCount.data().count);
        setSearchDone(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    const deactivateSearches = async () => {
        setIsLoading(true);
        let q;

        if(inputData.userID.trim() !== "") {
            q = query(collection(firestore, "searches"), where("customer", "==", inputData.userID), where("active", "==", true), where("deleted", "==", false));
        }

        // Get searches from firestore
        const querySnapshot = await getDocs(q);
        
        for(const search of querySnapshot.docs) {
            await deactivateSearch({searchId: search.id});
        }

        countSearches();

        setIsLoading(false);
    };

    return (
        <div className='w-full flex flex-col justify-center items-center'>
            <p className='italic text-s mt-12 mb-10 w-600 text-center'>Deaktiviere alle Suchprofile eines bestimmten Users. Du kannst den User entweder anhand seiner UserID oder anhand seiner Email Adresse suchen.</p>
            
            <div className={`w-full flex flex-col justify-center items-center ${isLoading ? "hidden" : "block"}`}>
                <input value={inputData.userID} onChange={(e) => setInputData({...inputData, userID: e.target.value})} type="text" placeholder="UserID eingeben..." className={`w-300 text-center p-1 border rounded border-allimmoDark`}/>
                <p className='italic my-3'>ODER</p>
                <input disabled value={inputData.email} onChange={(e) => setInputData({...inputData, email: e.target.value})} type="text" placeholder="Email Eingabe in Entwicklung!" className={`w-300 text-center p-1 border rounded border-allimmoDark`}/>
            </div>

            {isLoading && <CircularProgress size={60} />}

            {((searchCount > 0 || searchDone) && !isLoading) && 
            <div className='w-full flex flex-col justify-center items-center'>
                <p className='text-allimmoDark mt-10'>Aktive Suchprofile</p>
                <p className='text-allimmoDark font-bold text-lg'>{searchCount}</p>
            </div>}

            <div className='flex flex-row gap-3 mt-8'>
                <button onClick={countSearches} disabled={inputData.userID.trim() === ""} className={`border border-allimmoDark px-3 py-1 rounded ${inputData.userID.trim() === "" ? "opacity-50" : "hover:bg-blue-300 bg-blue-200 cursor-pointer"}`}>Suchprofile Anzeigen</button>
                <button onClick={deactivateSearches} disabled={searchCount === 0 || isLoading} className={`border border-allimmoDark px-3 py-1 rounded ${(searchCount === 0 || isLoading) ? "opacity-50" : "hover:bg-blue-300 bg-blue-200 cursor-pointer"}`}>Suchprofile Deaktivieren {(searchCount > 0 && !isLoading) ? "(" + searchCount + ")" : ""}</button>
            </div>
        </div>
    )
}

export default DeleteUserSearches
