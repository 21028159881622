import { Checkbox } from '@mui/material';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import AnimationIcon from '../../Generics/AnimationIcon';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from '../../Objects/Creation/StageLabel';
import UnlimitedCheckBox from './UnlimitedCheckbox';
import { pink } from '@mui/material/colors';
import validator from 'validator';

const Floor = ({back, next}) => {

    const { setSearchData, searchData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const validation = () => {
      return searchData?.floor[0] > searchData?.floor[1] || searchData?.floor[0] < 0 || searchData?.floor[1] < 0 || searchData?.floor[0] === "" || searchData?.floor[1] === "" || !validator.isNumeric(searchData?.floor[0].toString()) || !validator.isNumeric(searchData?.floor[1].toString()) || (searchData?.groundFloor === "no" && [0, "0"].includes(searchData?.floor[0])); // groundfloor "no" kein 0 möglich
    };
    
  return (
    <div className="flex flex-col justify-center items-center w-full md:w-1/2 mt-10">
      <StageLabel headline={t('CreateSearch.Floor.Headline')} labelText={t('CreateSearch.Floor.Label')} />
      <div onClick={() => setSearchData({...searchData, exactFloor: !searchData?.exactFloor})} className={`flex flex-row bg-white cursor-pointer items-center w-full rounded pl-1 pr-2 h-14`}>
          <Checkbox style={{transform: `scale(1.3)`}} sx={{color: "#00547B", '&.Mui-checked': {color: "#00547B"}}} disableRipple disableTouchRipple value={!searchData?.exactFloor} checked={!searchData?.exactFloor}/>
          <p className={`text-allimmoDark`}>{t(`CreateSearch.Floor.Whatever`)}</p>
          <div className='grow'/>
          {!searchData?.exactFloor && 
          <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/exactFloor.json`)}/>}
      </div>
      {searchData.exactFloor &&
      <RangeNumbers 
          label={t("General.Stockwerk")}
          prop="floor"
          resetValues={[0, 10]}
        />}
        
      <NavMenu _back={back} nextBtn _next={next} nextDisabled={searchData?.exactFloor ? validation() : false}/>
    </div>
  )
};

const RangeNumbers = ({prop, unit = "", resetValues = [1, 100], isUnlimitedPossible = true}) => {

  const { searchData, setSearchData, resetSearchZeroInputArray } = useCreationContext();

  const inputContainerStyles = 'h-14 w-full border-allimmoDark border-2 rounded shadow-lg flex flex-row items-center bg-white px-4 gap-5';
  const inputStyles = 'h-full grow text-2xl w-full text-allimmoDark/80 bg-transparent select-none outline-none rounded';
  const unlimitedProp = (prop + "Unlimited").toString();

  const handleBlur = (index) => {
    if(index === 0 && searchData?.[prop][0] === "") {
      setSearchData({...searchData, [prop]: [resetValues[0], searchData?.[prop][1]]});
      
    }
    if(index === 1 && searchData?.[prop][1] === "") {
      setSearchData({...searchData, [prop]: [searchData?.[prop][0], resetValues[1]]});
    }
  };

  return (
    <div className='flex flex-col items-center w-full'>
      <div className='flex flex-row items-baseline gap-2 w-full mb-0.5 pl-1 text-allimmoDark/80 italic'>
        <p className='text-xl'>{t('General.Von')}</p>
        {(searchData?.groundFloor === "no" && [0, "0"].includes(searchData?.floor[0]) && searchData?.exactFloor) && 
        <p className='text-red-500 text-xs'>{t('CreateSearch.Floor.ZeroNotPossible')}</p>}
        {searchData?.groundFloor !== "no" &&
        <p className='text-xs'>{t('CreateSearch.Floor.ZeroForGround')}</p>}
      </div>
      <div className={inputContainerStyles}> 
        <input onBlur={() => handleBlur(0)} type='number' onClick={() => resetSearchZeroInputArray(prop, 0)} value={searchData?.[prop][0]} onChange={(e) => setSearchData({...searchData, [prop]: [e.target.value.trim(), searchData?.[prop][1]]})} className={inputStyles}/>
        <p className='text-2xl text-allimmoDark/80'>{unit}</p>
      </div>

      <p className='text-xl text-allimmoDark/80 my-0.5 w-full italic pl-1'>{t('General.Bis')}</p>

      {!searchData[unlimitedProp] &&
      <div className={inputContainerStyles}> 
        <input onBlur={() => handleBlur(1)} type='number' onClick={() => resetSearchZeroInputArray(prop, 1)} value={searchData?.[prop][1]} onChange={(e) => setSearchData({...searchData, [prop]: [searchData?.[prop][0], e.target.value.trim()]})} className={inputStyles}/>
        <p className='text-2xl text-allimmoDark/80'>{unit}</p>
      </div>}

      {isUnlimitedPossible &&
      <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-1 h-14 ${!searchData[unlimitedProp] ? 'mt-1 border-0' : 'border-2 border-allimmoDark shadow-lg'}`}>
        <div className='grow'>
          <UnlimitedCheckBox name={unlimitedProp} _size={1.3} _color={pink[700]}/>
        </div>
        {searchData[unlimitedProp] && <AnimationIcon _delay='no' _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/number_unlimited.json`)}/>}
      </div>}
    </div>
  )
};

export default Floor
