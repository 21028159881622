import { t } from "i18next";
import { convertMonthYearTimestamp } from "../../helper/functions";
import { Dot, EditPen } from "../../helper/icons";

const Vacancy = ({ _objectData }) => {
  return (
    <div className={`${(_objectData?.deal === "sale" && _objectData?.type !== "land") ? "block" : "hidden"} bg-white rounded flex flex-col w-full pb-2 border border-allimmoDark/70`}>
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 px-1.5 py-0.5 rounded-t">
        <p>{t('ObjectDetails.VacancyLabel')}</p>
      </div>
      <div className="flex flex-col px-2">
        {_objectData?.vacancy === "free" && (
          <div className="flex flex-row gap-2 items-center">
            <Dot size={15} className="text-allimmoDark" />
            <p className="grow">{t(`CreateObject.Leer`)}</p>
          </div>
        )}

        {_objectData?.vacancy === "unlimited" && (
          <div className="flex flex-row gap-2 items-center">
            <Dot size={15} className="text-allimmoDark" />
            <p className="grow">{t(`ObjectListing.Unlimitiert`)}</p>
          </div>
        )}

        {_objectData?.vacancy === "limited" && (
          <div className="flex flex-row gap-1 items-center">
            <Dot size={15} className="text-allimmoDark mr-1" />
            <p>{t(`ObjectListing.Limitiert`)}</p>
            <p>{convertMonthYearTimestamp(_objectData?.rentedUntil?.toDate())}</p>
          </div>
        )}

        {_objectData?.available && (
          <div className="flex flex-row gap-1 items-center">
            <Dot size={15} className="text-allimmoDark mr-1" />
            <p>{t(`Facilities.available`)}</p>
          </div>
        )}

        {!_objectData?.available &&
          !["", 0].includes(_objectData?.availableFrom) && (
            <div className="flex flex-row gap-1 items-center">
              <Dot size={15} className="text-allimmoDark mr-1" />
              <p>{t(`CreateObject.VerfügbarAb`)}</p>
              <p>
                {typeof _objectData?.availableFrom === "string" ? _objectData?.availableFrom : convertMonthYearTimestamp(_objectData?.availableFrom?.toDate())}
              </p>
            </div>
          )}

        <div className={`flex flex-row gap-1 items-center ${!_objectData?.onlineInspection ? "hidden" : ""}`}>
          <Dot size={15} className="text-allimmoDark mr-1" />
          {_objectData?.onlineInspection && (
            <p>{t("Facilities.onlineInspection")}</p>
          )}
        </div>

        {![undefined, ""].includes(_objectData?.tourLink) && (
        <div className="flex flex-row gap-1 items-center">
          <Dot size={15} className="text-allimmoDark mr-1" />
          <a className="hover:underline italic text-allimmoDark cursor-pointer" href={_objectData?.tourLink} target="_blank">
            {t("ObjectDetails.TourÖffnen")}
          </a>
        </div>)}
      </div>
    </div>
  );
};

export default Vacancy;
