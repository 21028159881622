import React from 'react'

const Faq = () => {
  return (
    <div className='flex flex-col px-5 pt-2 pb-10'>
      <p className='text-xl font-bold mb-8'>FRAGEN UND ANTWORTEN</p>
         <p className='mb-1 text-lg'>1. Wie finde ich passende Immobilien? </p>
        <p>Erstelle unter "Ich suche eine Immobilie" ein Suchprofil. Du erhältst dann alles weitere
        automatisch und wirst benachrichtigt, sobald eine passende Immobilie gefunden wurde.</p>
        
        <p className='mb-1 mt-5 text-lg'>2. Wie finde ich passende Interessenten?</p>
        <p>Erstelle unter „Ich suche Interessenten“ ein kostenloses Inserat für deine Immobilie und gib so
        detaillierte Informationen wie möglich an. Dadurch wird die Zuordnung zu passenden
        Interessenten erleichtert. Sobald ein Suchprofil mit deiner Immobilie übereinstimmt, hast du die
        Möglichkeit, denjenigen für nur 1 Credit zu kontaktieren.</p>
        
        <p className='mb-1 mt-5 text-lg'>3. Wie kann ich Kontakt mit einem
        Immobilien-Anbieter oder Interessenten aufnehmen?</p>
        <p>Sobald ein perfektes Match zwischen einem Suchprofil und der Immobilie besteht, hast du die
        Möglichkeit, Kontakt aufzunehmen und das Objekt anzuschauen. Der Anbieter sendet eine
        Anfrage, und der Interessent muss diese akzeptieren. Danach könnt ihr miteinander chatten,
        Kontaktdaten austauschen und das Objekt besichtigen.</p>
        
        {/* <p className='mb-1 mt-5 text-lg'>4. Was genau macht Allimmo Match?</p> */}
        {/* Erklärungslink */}
        
        <p className='mb-1 mt-5 text-lg'>4. Wie lange dauert es, bis eine
        Immobilie oder ein Interessent gefunden wird?</p>
        <p>Die Dauer, bis eine passende Immobilie oder ein Interessent gefunden wird, hängt immer von
        den individuellen Anforderungen ab. Je spezifischer diese sind, desto seltener kann es zu einem
        "Match" kommen. Deine Geduld wird jedoch belohnt, wenn eine perfekt passende Immobilie oder
        ein passender Interessent gefunden wird.</p>
        <p className='mb-1 mt-5 text-lg'>5. Wann zahle ich Credits?</p>
        <p className='mb-1 mt-1 text-lg'>Möglichkeit 1: </p>
        <p>Für jede Kontaktaufnahme mit einem Interessenten wird dir als Immobilien-Anbieter 1 Credit
        abgebucht. Sollte der Interessent innerhalb von 7 Tagen deine Anfrage nicht akzeptieren, wird dir
        der Credit wieder gutgeschrieben.</p>
        <p className='mb-1 mt-5 text-lg'>Möglichkeit 2:</p>
        <p>Wenn du mehr als 10 gleichzeitige Suchprofile erstellen möchtest, kostet jedes Weitere 2
        Credits. Dieser „Suchprofil-Platz“ bleibt für immer erhalten. Bedeutet, wenn du beispielsweise
        eines zusätzlich erwirbst, hast du ab dann immer die Möglichkeit 11 gleichzeitig zu nutzen.
        Um Credits zu sparen, hast du die Möglichkeit Suchprofile zu löschen, um im Rahmen der 10
        Kostenlosen zu bleiben.</p>
        <p className='mb-1 mt-5 text-lg'>6. Was passiert, wenn ich als Anbieter
        mehrere Interessenten kontaktiert habe, aber bereits die Immobilie verkauft/vermietet habe oder die
        Immobilie deaktivieren möchte?</p>
        <p>Wenn du ein Objekt löschst und offene Anfragen hast, werden dies Anfragen storniert und die
        Credits verfallen.</p>
        <p>Wenn du ein Objekt deaktivierst, bleiben bereits gestellte Anfragen offen, Interessenten
        können weiterhin diese Anfragen annehmen und dich (z.B. für Besichtigungen) kontaktieren. Sollte
        ein Interessent innerhalb von 7 Tagen die Anfrage nicht annehmen, wird der Credit ebenfalls
        zurückerstattet.</p>
        <p className='mb-1 mt-5 text-lg'>7. Wie werden mehrere Interessenten
        für ein Objekt sortiert? </p>
        <p>„First come, first serve“</p>
        <p>Das älteste Suchprofil wird an oberster Stelle angezeigt, und das jüngste Suchprofil unten.</p>
        <p className='mb-1 mt-5 text-lg'>8. Welche Arten von Immobilien sind
        verfügbar?</p>
        <p>Aktuell kann man Wohnungen, Häuser und Grundstücke suchen und anbieten. In Erstellung
        sind ebenso Gewerbeimmobilien und WG`s - bitte noch um etwas Geduld.</p>
        <p className='mb-1 mt-5 text-lg'>9. Wann bekommt jemand meine
        Kontaktdaten?</p>
        <p>Deine Daten sind bei uns sicher. Nur wenn du explizit einen Interessenten anfragst oder ein
        Immobilienangebot ansehen möchtest, werden deine Informationen an die Gegenseite
        übermittelt. </p>
        <p className='mb-1 mt-5 text-lg'>10. Wie kann ich eine Besichtigung
        der Immobilie vereinbaren?</p>
        <p>Sobald ein "Match" erfolgt ist, besteht die Möglichkeit, im Chat oder über die ausgetauschten
        Kontaktdaten alles Weitere zu besprechen und zu vereinbaren, wie beispielsweise einen
        Besichtigungstermin.</p>
        <p className='mb-1 mt-5 text-lg'>11. Wie wird sichergestellt, dass der
        Interessent meine Provisionsvereinbarung akzeptiert, bevor er Detailinformationen erhält? (Aus Sicht
        eines Maklers)</p>
        <p>Wenn du bei der Objekterstellung angibst, dass dein Objekt provisionspflichtig ist und eine
        Provisionsvereinbarung hochgeladen hast, wird diese Vereinbarung dem Interessenten vor der
        Einsicht angezeigt.
        Der Interessent kann das Objekt, inklusive Adresse, erst nach Akzeptanz und Durchsicht der
        Vereinbarung einsehen. </p>
        <p>Alternativ kannst du ebenso einen Link zu deiner Website oder Provisionsvereinbarung
        hinterlegen, welche stattdessen angezeigt wird. </p>
        <p className='mb-1 mt-5 text-lg'>12. Was ist der Unterschied zu regulären Immobilienplattformen?</p>
        <p>Aus Interessenten-Sicht: </p>
        <p>- Nur 100% übereinstimmende Objekte werden angezeigt.</p>
        <p>- Du musst keine Zeit damit verschwenden, Exposés zu durchsuchen, da das übermittelte
        Objekt deinen angegeben Anforderungen bereits entspricht.</p>
        <p>- Du musst nicht kontinuierlich nach Objekten suchen, da passende Objekte automatisch zu dir
        kommen.</p>
        <p>- Sobald du das Objekt ansiehst, musst du nicht auf den Erhalt von Exposés oder
        Detailinformationen wie die Adresse warten, da diese sofort einsehbar sind.</p>
        <p>Aus Anbieter-Sicht:</p>
        <p>- Du zahlst nur, wenn du einen passenden Interessenten kontraktierst.</p>
        <p>- Inserieren und einsehen, wie viele Interessenten nach deinem Objekt suchen, ist immer
        kostenlos.</p>
        <p>- Kunden suchen funktioniert automatisch und perfekt zugeschnitten.</p>
        <p>- Die Chance, Geld zu sparen, ist enorm, da in der Regel weniger als 10 passende
        Interessenten kontaktiert werden müssen. Dadurch sparst du bei jedem Inserat Geld.</p>
        <p>- Du kannst selbst entscheiden, wie viele Interessenten du kontaktieren möchtest, und bist
        nicht darauf angewiesen, dass diese dein Inserat finden und Zeit für die Suche aufwenden
        müssen.</p>
        {/* <p className='mb-1 mt-5 text-lg'>13. Was passiert, wenn ein
        Interessent zwei Suchprofile hat und beide mit dem selben Objekt übereinstimmen?</p>
        <p>In diesem Fall wird dem Anbietenden nur 1 Credit berechnet und der Interessent erhält die
        Information, dass das Objekt bereits bei einem anderen Suchprofil "gematched" wurde.</p> */}
        <p className='mb-1 mt-5 text-lg'>13. Wie funktioniert das Rücktrittsrecht?</p>
        <p>Sie haben das Recht, innerhalb von 14 Tagen ohne Angabe von Gründen vom Kauf zurückzutreten,
        vorausgesetzt, die erworbenen Credits wurden nicht verwendet. Es ist zu beachten, dass immer die
        zuletzt gekauften Credits zuerst verwendet werden.</p>
  {/* </div> */}
</div>
  )
};

export default Faq
