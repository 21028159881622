import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import StageLabel from '../../Objects/Creation/StageLabel';
import RangeNumbers from './RangeNumbers';
import { useEffect } from 'react';
import NavMenu from '../../Generics/NavMenu';

const Rooms = ({next, back}) => {

    const { searchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const validation = () => {
        return [null, "NaN", NaN, undefined, "undefined", "", 0, "0"].includes(searchData.rooms[0]) || 
        ([null, "NaN", NaN, undefined, "undefined", ""].includes(searchData.rooms[1]) && !searchData.roomsUnlimited) || 
        (!searchData.roomsUnlimited && parseFloat(searchData?.rooms[0]) > parseFloat(searchData?.rooms[1]));
    };

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-2/3 md:w-1/2 mdd:w-1/3">
        <StageLabel color={false} stageIcon={require('../../../assets/animations/searchCreation/rooms.json')} labelText={t('CreateSearch.RoomsLabel')}/>
        <RangeNumbers 
          label={t("General.Räume")}
          prop="rooms"
          resetValues={[0, 10]}
        />
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={validation()} />
    </div>
  )
};

export default Rooms
