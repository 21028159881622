import { TextField } from '@mui/material';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import { t } from 'i18next';
import StageLabel from './StageLabel';
import { useEffect } from 'react';
import NavMenu from '../../Generics/NavMenu';

const Address = ({next, back}) => {

    const { objectData, setObjectData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div className='w-full md:w-1/2 flex flex-col justify-center items-center py-5'>
        <StageLabel headline={t('General.Adresse')} color={false} stageIcon={require('../../../assets/animations/objectCreation/address.json')} labelText={t('CreateObject.AddressLabel')}/>
        <TextField required className="w-full ssm:w-2/3 bg-white rounded" label={t('CreateObject.AddressInputLabel')} autoComplete='off' size="small" value={objectData.address.streetNumber} onChange={(e) => setObjectData({...objectData, address: {...objectData.address, streetNumber: e.target.value}})}/>
        <div className='mt-2'/>
        <TextField required className="w-full ssm:w-2/3 bg-white rounded" label="PLZ/Stadt" autoComplete='off' size="small" value={objectData.address.cityZip} onChange={(e) => setObjectData({...objectData, address: {...objectData.address, cityZip: e.target.value}})}/>
        <p className='italic text-sm mt-1 text-center w-full'>{t('CreateObject.AdresseEingebenInfo')}</p>
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={objectData.address.cityZip.length < 3 || objectData.address.streetNumber.length < 3}/>
    </div>
  )
}

export default Address