import { Button, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import { ftpConnectionTest, getUserFromEmail, testMatching, deleteAllInterfaceObjects, deactivateSearch, globalMatching, checkSearches, accountObjectMatching, createGeohashForAllObjects, createGeohashesForAllSearches, testFunction, scanToGoObjects, matchUpdatedObjects, sendSearchesNewMatchesEmails } from '../helper/functions';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../firebase.config';

const Matching = () => {

    const [search, setSearch] = useState({
        accessible: false,
        activeBusiness: "-",
        airCondition: false,
        areas: [1,100],
        areasUnlimited: false,
        attic: "",
        availableFrom: "",
        avoidCompensation: false,
        balcony: false,
        bathtub: false,
        bike: true,
        blinds: false,
        building: "-",
        buildingSite: false,
        cellar: false,
        chimney: false,
        comission: false,
        cooling: true,
        deal: "",
        developed: false,
        elevator: false,
        first: false,
        fitness: false,
        exactFloor: false,
        floor: [0, 10],
        floorHeating: false,
        floorUnlimited: false,
        furnished: "",
        garage: false,
        garden: false,
        groundFloor: "",
        isolated: "",
        kitchen: false,
        land: [0,30000],
        landUnlimited: false,
        laundry: false,
        licensing: ["Storage"],
        limited: false,
        heatingMulti: {
            gas: true,
            fern: true,
            electric: true,
            oil: true,
            pump: true,
            pellets: true,
            otherHeating: true,
        },
        name: `Suche ${Math.floor((Math.random() * 10000) + 1)}`,
        new: false,
        noGas: false,
        noOldGrowth: false,
        noPublicAreas: false,
        openAreas: "",
        pets: false,
        params: 0,
        parkingLots: false,
        parkingLotsMin: 2,
        places: [],
        polygon: [{
            center: {
                lat: 48.1950212703621,
                lng: 16.384599000596545,
            },
            type: "circle",
            radius: 17653.577451105615,
            coordinates: {
                lat: 48.1950212703621,
                lng: 16.384599000596545,
            }
        }],
        pool: false,
        presentIndustry: "?",
        price: [0,400000],
        priceUnlimited: false,
        project: false,
        renovation: false,
        renovationRatio: [0,100],
        roofTop: false,
        rooms: [1,5],
        roomsUnlimited: false,
        sauna: false,
        searchOrder: false,
        size: [0,100],
        sizeUnlimited: false,
        shortTermRent: false,
        storageRoom: false,
        street: "",
        type: "commercial",
        units: [5, 10],
        unitsUnlimited: false,
        unlimited: false,
        urgency: "",
        vacancy: [0, 100],
        vacant: false,
    });

    const obj = {
        accessible: false,
        activeBusiness: false,
        address: {
            streetNumber: "",
            cityZip: ""
        },
        airCondition: false,
        alarm: false,
        available: true,
        availableFrom: "",
        bathtub: false,
        bike: true,
        blinds: false,
        building: "-",
        buildingSite: false,
        cellar: false,
        chimney: false,
        comission: 0,
        comissionUnit: "€",
        comissionContract: "link",
        comissionContractLink: "",
        compensation: 0,
        condition: "-",
        cooling: true,
        customFacilities: [],
        deal: "",
        deposit: 0,
        developed: false,
        door: false,
        elevator: false,
        exposeLink: "",
        fitness: false,
        floor: 10,
        floorHeating: false,
        floors: 100,
        furnished: "",
        garage: false,
        heating: "gas",
        houseType: "",
        kitchen: false,
        land: 0,
        laundry: false,
        licensing: ["Storage"],
        location: {
            lat: 48.195953825234284,
            lng: 16.352448828403418,
        },
        maisonette: false,
        multiLivingSpace: 0,
        notes: "",
        oldGrowth: false,
        openAreas: [{
            id: 1,
            type: "parking",
            size: 2,
            public: false,
        }],
        pets: false,
        pool: false,
        presentIndustry: "-",
        price: 400000,
        rentedUntil: 0,
        rooms: 1,
        sauna: false,
        size: 0,
        shortTermRent: false,
        solar: false,
        storageRoom: false,
        tourLink: "",
        type: "commercial",
        units: 8,
        unitsCondition: {
            first: "0",
            new: "0",
            renovation: "1",
        },
        unitSizeMax: 0,
        unitSizeMin: 0,
        unitsState: {
            vacant: "1",
            limited: "0",
            unlimited: "0",
        },
        utilityBill: 0,
        vacancy: "-",
        yearOfConst: 0,
    };

    const [result, setResult] = useState("-");
    const [isLoading, setIsLoading] = useState(false);
    const [newZip, setNewZip] = useState("");
    const [duration, setDuration] = useState();

    const matching = async () => {
        const areasPublic = obj.openAreas.filter((area) => area?.public);
        const areasPrivate = obj.openAreas.filter((area) => !area?.public);
        //####################
        const objectVacancyRatio = obj.type === "multi" ? (parseInt(obj.unitsState.vacant) / parseInt(obj.units)) * 100 : 0;
        const objectRenovationRatio = obj.type === "multi" ? (parseInt(obj.unitsCondition.renovation) / parseInt(obj.units)) * 100 : 0;
        //####################
    
        const publicSize = areasPublic.reduce((acc, o) => {
            return acc + parseFloat(o.size);
        }, 0);
    
        const privateSize = areasPrivate.reduce((acc, o) => {
            return acc + parseFloat(o.size);
        }, 0);
    
        const checkIfInsidePolygon = (polygon, point) => {
            let result = false;
            for (let i = 0, j = polygon.length - 1; i < polygon.length; i++) {
              if (
                polygon[i][1] > point[1] !== polygon[j][1] > point[1] &&
                point[0] <
                  ((polygon[j][0] - polygon[i][0]) * (point[1] - polygon[i][1])) /
                    (polygon[j][1] - polygon[i][1]) +
                    polygon[i][0]
              ) {
                result = !result;
              }
              j = i;
            }
            return result;
        };
    
        const checkIfInsideCircle = (spotCoordinates, center, radius) => {
            const newRadius = distanceInKmBetweenEarthCoordinates(spotCoordinates.lat, spotCoordinates.lng, center.lat, center.lng);
    
            if (newRadius < radius) {
                // point is inside the circle
                return true;
            }
            else if (newRadius > radius) {
                // point is outside the circle
                return false;
            }
            else {
                // point is on the circle
                return true;
            }
        };
    
        const distanceInKmBetweenEarthCoordinates = (lat1, lon1, lat2, lon2) => {
          const earthRadiusKm = 6371;
    
          const dLat = degreesToRadians(lat2-lat1);
          const dLon = degreesToRadians(lon2-lon1);
    
          lat1 = degreesToRadians(lat1);
          lat2 = degreesToRadians(lat2);
    
          const a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
          return earthRadiusKm * c;
        };
    
        const degreesToRadians = (degrees) => {
          return degrees * Math.PI / 180;
        };
    
        const getCoords = (coords) => {
            const coordsArray = [];
            for (let i = 0; i < coords.length; i++) {
              coordsArray.push([coords[i].lat, coords[i].lng]);
            }
            return coordsArray;
        };
    
        const checkInside = () => {
            const circleCheck = search.polygon.filter((p) => p.type === "circle").some((p) => {
                return checkIfInsideCircle(obj.location, p.coordinates, p.radius/1000);
            });
    
            const polygonCheck = search.polygon.filter((p) => p.type === "polygon").some((p) => {
                return checkIfInsidePolygon(getCoords(p.coordinates), [obj.location.lat, obj.location.lng]);
            });
    
            return circleCheck || polygonCheck;
        };
    
        return (
            checkInside() &&
            (search.type === obj.type) &&
            (search.deal === obj.deal) &&
            (search.openAreas === "no" ? areasPrivate.length === 0 : true) &&
            (search.openAreas === "yes" ? obj.openAreas.length > 0 : true) &&
            ((search.balcony && !search.garden && !search.roofTop) ? obj.openAreas.some((a) => a.type === "balcony") : true) &&
            ((search.garden && !search.balcony && !search.roofTop) ? obj.openAreas.some((a) => a.type === "garden") : true) &&
            ((search.roofTop && !search.balcony && !search.garden) ? obj.openAreas.some((a) => a.type === "roofTop") : true) &&
            ((search.balcony && search.garden && !search.roofTop) ? obj.openAreas.some((a) => ["balcony", "garden"].includes(a.type)) : true) &&
            ((search.garden && !search.balcony && search.roofTop) ? obj.openAreas.some((a) => ["garden", "roofTop"].includes(a.type)) : true) &&
            ((search.roofTop && search.balcony && !search.garden) ? obj.openAreas.some((a) => ["balcony", "roofTop"].includes(a.type)) : true) &&
            (search.openAreas === "yes" ? (search.noPublicAreas ? privateSize >= Math.min(...search.areas) : privateSize + publicSize >= Math.min(...search.areas)) : true) &&
            ((search.openAreas === "yes" && !search.areasUnlimited) ? (search.noPublicAreas ? privateSize <= Math.max(...search.areas) : privateSize + publicSize <= Math.max(...search.areas)) : true) &&
            (parseFloat(Math.min(...search.price)) <= parseFloat(obj.price)) &&
            (!search.priceUnlimited ? parseFloat(Math.max(...search.price)) >= parseFloat(obj.price) : true) &&
            (!search.roomsUnlimited ? parseFloat(Math.max(...search.rooms)) >= parseFloat(obj.rooms) : true) &&
            (parseFloat(Math.min(...search.rooms)) <= parseFloat(obj.rooms)) &&
            (parseFloat(Math.min(...search.size)) <= parseFloat(obj.size)) &&
            (!search.sizeUnlimited ? parseFloat(Math.max(...search.size)) >= parseFloat(obj.size) : true) &&
            (parseFloat(Math.min(...search.land)) <= parseFloat(obj.land)) &&
            (!search.landUnlimited ? parseFloat(Math.max(...search.land)) >= parseFloat(obj.land) : true) &&
            (search.type === "multi" ? (
                (parseInt(Math.min(...search.units)) <= parseInt(obj.units)) &&
                (!search.unitsUnlimited ? parseInt(Math.max(...search.units)) >= parseInt(obj.units) : true) &&
                (parseInt(Math.min(...search.renovationRatio)) <= objectRenovationRatio) &&
                (parseInt(Math.max(...search.renovationRatio)) >= objectRenovationRatio) &&
                (obj.heating === "gas" ? search.heatingMulti.gas : true) &&
                (obj.heating === "fern" ? search.heatingMulti.fern : true) &&
                (obj.heating === "electric" ? search.heatingMulti.electric : true) &&
                (obj.heating === "oil" ? search.heatingMulti.oil : true) &&
                (obj.heating === "pump" ? search.heatingMulti.pump : true) &&
                (obj.heating === "pellets" ? search.heatingMulti.pellets : true) &&
                (obj.heating === "otherHeating" ? search.heatingMulti.otherHeating : true)
            ) : true) &&

            (search.type === "commercial" ? (
                (obj.commercialType === search.commercialType) && //
                (search.presentIndustry === "?" ? true : obj.presentIndustry === search.presentIndustry) && //
                (obj.cooling ? true : !search.cooling) && //
                (obj.industrialLicense ? true : !search.industrialLicense) && //
                (search.licensing.every(e => obj.licensing.includes(e))) && //
                (obj.activeBusiness ? search.activeBusiness !== "no" : search.activeBusiness !== "yes") && //
                (search.parkingLots ? obj.openAreas.some((a) => a.type === "parking" && a.size >= parseInt(search.parkingLotsMin)) : true) //
            ) : true) &&


            ((search.type === "multi" || (search.type === "commercial" && search.commercialType === "building")) ? (
                (parseInt(Math.min(...search.vacancy)) <= objectVacancyRatio) &&
                (parseInt(Math.max(...search.vacancy)) >= objectVacancyRatio)
            ) : true) &&

            (search.type === "land" ? (
                (obj.buildingSite ? true : !search.buildingSite) &&
                (obj.developed ? search : !search.developed) &&
                (obj.compensation > 0 ? !search.avoidCompensation : true) &&
                (obj.oldGrowth ? !search.noOldGrowth : true)
            ) : true) &&

            (search.type === "apartment" ? (
                (obj.floor === "attic" ? search.attic !== "no" : true) &&
                (obj.floor === "groundFloor" ? search.groundFloor !== "no" : true) &&
                (("exactFloor" in search && "floor" in search) ?
                (search.exactFloor === true ? true : (
                    (!search.floorUnlimited ? parseFloat(Math.max(...search.floor)) >= parseFloat(obj.floor) : true) &&
                    (parseFloat(Math.min(...search.floor)) <= parseFloat(obj.floor)))
                ) : true)
            ) : true) &&

            (search.type !== "land" ? (
                (obj.bike ? true : !search.bike) &&
                (obj.fitness ? true : !search.fitness) &&
                (obj.laundry ? true : !search.laundry) &&
                (obj.airCondition ? true : !search.airCondition) &&
                (obj.bathtub ? true : !search.bathtub) &&
                (obj.blinds ? true : !search.blinds) &&
                (obj.cellar ? true : !search.cellar) &&
                (obj.sauna ? true : !search.sauna) &&
                (obj.elevator ? search : !search.elevator) &&
                (obj.chimney ? true : !search.chimney) &&
                (obj.accessible ? true : !search.accessible) &&
                (obj.garage ? true : !search.garage) &&
                (obj.kitchen ? true : !search.kitchen) &&
                (obj.floorHeating ? true : !search.floorHeating) &&
                (obj.pets ? true : !search.pets) &&
                (obj.storageRoom ? true : !search.storageRoom) &&
                (obj.shortTermRent ? true : !search.shortTermRent) &&
                (obj.pool ? true : !search.pool) &&
                (obj.furnished !== "-" ? search.furnished !== "no" : search.furnished !== "yes") &&
                (search.building !== "-" ? obj.building === search.building : true) &&
                (search.noGas ? obj.heating !== "gas" : true) &&
                (search.isolated === "yes" ? obj.houseType === "isolated" : true) &&
                ((search.new && !search.first && !search.renovation) ? obj.condition !== "renovation" : true) &&
                ((!search.new && search.first && !search.renovation) ? obj.condition === "first" : true) &&
                ((!search.new && !search.first && search.renovation) ? obj.condition === "renovation" : true) &&
                ((search.new && search.first && !search.renovation) ? obj.condition !== "renovation" : true) &&
                ((!search.new && search.first && search.renovation) ? obj.condition !== "new" : true) &&
                ((search.new && !search.first && search.renovation) ? obj.condition !== "first" : true) &&
                ((search.vacant && !search.limited && !search.unlimited) ? obj.vacancy === "free" : true) &&
                ((!search.vacant && search.limited && !search.unlimited) ? obj.vacancy === "limited" : true) &&
                ((!search.vacant && !search.limited && search.unlimited) ? obj.vacancy === "unlimited" : true) &&
                ((!search.vacant && search.limited && search.unlimited) ? obj.vacancy !== "free" : true) &&
                ((search.vacant && !search.limited && search.unlimited) ? obj.vacancy !== "limited" : true) &&
                ((search.vacant && search.limited && !search.unlimited) ? obj.vacancy !== "unlimited" : true)
            ) : true)
        );
    };

    const match = async () => {
        const startTime = new Date().getTime();
        setIsLoading(true);
        setResult("-");
        await matching().then((res) => {
            if(res) {
                setResult("MATCH");
            } else {
                setResult("NO MATCH");
            }
            
            setDuration(new Date().getTime() - startTime);
            setIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
    };

    const addZip = () => {
        if(newZip !== "") {
            setSearch({...search, zips: [...search.zips, newZip]});
            setNewZip("");
        }
    };

    const testServerMatch = async () => {
        await testMatching({objectID: "fyoKxu6Mke4kaQTfu1CQ", searchID: "B653jSjbvzqY4UY02D3x"})
    };

    const testFTPConnection = async () => {
        await ftpConnectionTest();
    };

    const userFromEmail = async () => {
        await getUserFromEmail({email: "office@kirschner.immo"});
    };

    const deleteInterfaceObjects = async () => {
        await deleteAllInterfaceObjects();
    };

    const deactivateUsersSearches = async () => {
        const q = query(collection(firestore, "searches"), where("customer", "==", "oWKHEJ9JcrgYqZmrNXstkmVUnUA3"), where("active", "==", true));
        const querySnapshot = await getDocs(q);

        console.log("FOUND SEARCHES:", querySnapshot.docs.length);

        querySnapshot.docs.forEach(async (doc) => {
            // doc.data() is never undefined for query doc snapshots
            await deactivateSearch({searchId: doc.id});
        });
    };

    const startGlobalMatching = async () => {
        await globalMatching();
    };

    const startSearchesCheck = async () => {
        await checkSearches();
    };

    const startAccounObjectMatching = async () => {
        await accountObjectMatching({customerId: "PcnmdcvykcaRyQL5KXLXJQMyHjU2"})
    };

    const startTestFunction = async () => {
        await testFunction();
        return;
        await matchUpdatedObjects();
        return;
        
        await sendSearchesNewMatchesEmails();
        return;
        await testMatching({objectID: "z9ZDXpH6cA6ky8kLddjR", searchID: "YGBzYXVvStUOTvM0sTqb"});
        return;
        await createGeohashesForAllSearches();
        return;
    };

  return (
    <div className='w-full px-10 md:px-20 flex flex-col items-center mt-32'>
        <p className='cursor-pointer hover:underline mb-2' onClick={testServerMatch}>TEST MATCH</p>
        <p className='cursor-pointer hover:underline mb-2' onClick={testFTPConnection}>FTP TEST</p>
        <p className='cursor-pointer hover:underline mb-2' onClick={userFromEmail}>GET EMAIL</p>
        {/* <p className='cursor-pointer hover:underline mb-2' onClick={deleteInterfaceObjects}>DELETE ALL INTERFACE OBJECTS</p> */}
        <p className='cursor-pointer hover:underline mb-2' onClick={deactivateUsersSearches}>DEACTIVATE SEARCHES</p>
        <p className='cursor-pointer hover:underline mb-2' onClick={startSearchesCheck}>CHECK SEARCHES</p>
        <p className='cursor-pointer hover:underline mb-2' onClick={startGlobalMatching}>GLOBAL MATCHING</p>
        <p className='cursor-pointer hover:underline mb-2' onClick={startAccounObjectMatching}>ACCOUNT OBJECT MATCHING</p>
        <p className='cursor-pointer hover:underline mb-2' onClick={startTestFunction}>TEST FUNCTION</p>
        <p className='p-2 border border-allimmoDark cursor-pointer hover:bg-allimmoDark/5 w-1/5 rounded text-center' onClick={match}>MATCH</p>
        {result !== "-" && <p className='italic text-center text-xs mt-3 -mb-1'>{duration}ms</p>}
        <p className='text-allimmoDark text-lg'>{isLoading ? "LOADING..." : result}</p>
        {result !== "-" && <p onClick={() => setResult("-")} className='text-xs italic text-center cursor-pointer hover:underline -mt-1'>reset</p>}
        <div className='flex flex-row mb-20 w-full space-x-10 mt-5'>
            <div className=' flex flex-col w-full h-60vh border border-allimmoDark p-3 overflow-auto'> 
                <p className='text-lg text-allimmoDark mb-5'>Search:</p>
                {Object.keys(search).map((k,i) => {
                    return (
                        <div key={i} className='flex flex-row w-full mt-1 items-center space-x-2'>
                            <p className='w-2/3'>{k}</p>
                            {!["price", "size", "land", "rooms", "areas", "zips"].includes(k) &&
                            <TextField select={[true, false].includes(search[k])} className='w-1/3' size='small' label={k === "urgency" ? "normal/urgent" : ""} value={search[k].toString()} onChange={(e) => setSearch({...search, [k]: e.target.value})}>
                                {[true, false].includes(search[k]) && <MenuItem value={true}>true</MenuItem>}
                                {[true, false].includes(search[k]) && <MenuItem value={false}>false</MenuItem>}
                            </TextField>}
                            {["price", "size", "land", "rooms", "areas"].includes(k) &&
                            <div className='flex flex-row space-x-1'>
                                <TextField size='small' className='w-1/2' value={Math.min(...search[k])} label="min." onChange={(e) => setSearch({...search, [k]: [e.target.value, search[k][1]]})}/>
                                <TextField size='small' className='w-1/2' value={Math.max(...search[k])} label="max." onChange={(e) => setSearch({...search, [k]: [search[k][0], e.target.value]})}/>
                            </div>}

                            {k === "zips" &&
                            <div className='flex flex-row space-x-2'>
                                <div className='flex-flex-col'>
                                    <TextField size='small' value={newZip} onChange={(e) => setNewZip(e.target.value)}/>
                                    <p className='text-xs'>{search.zips.map(el => {return el + ", "})}</p>
                                </div>
                                <Button variant='outlined' onClick={addZip}>+</Button>
                            </div>}
                        </div>
                    )
                })}

            </div>

            <div className='flex flex-col w-full p-3 border overflow-auto border-allimmoDark h-60vh'>
                <p className='text-lg text-allimmoDark mb-5 '>Objekt:</p>
                {Object.keys(obj).map((k,i) => {
                    return (
                    <div key={i} className={`flex ${["openAreas", "place"].includes(k) ? "flex-col mb-2" : 'flex-row'} w-full`}>
                        <p className='grow'>{k}</p>
                        {!["openAreas", "place"].includes(k) && <p>{obj[k].toString()}</p>}
                        {k === "openAreas" && 
                        <div>
                            {obj[k].map(((ar,ai) => {
                                return (
                                    <div key={ai} className='flex flex-row w-1/2 ml-3 italic text-sm bg-allimmoDark/10 px-2 rounded'>
                                        <p className='mr-2'>-</p>
                                        <p className='grow'>{ar.type}</p>
                                        <p className='mr-2'>{ar.size}m²</p>
                                        <p className=''>{ar.public ? "allg." : "priv."}</p>
                                    </div>
                                )
                            }))}
                        </div>}
                        {k === "place" && 
                        <div className='flex flex-row w-3/4 ml-3 italic text-sm bg-allimmoDark/10 px-2 rounded'>
                            <p className='mr-2'>-</p>
                            <p className='mr-2'>{obj.place.zip}</p>
                            <p className='mr-2'>{obj.place.administration.name}</p>
                            <p>{obj.place.district.name}</p>
                        </div>}
                    </div>
                    )
                })}

            </div>
        </div>
    </div>
  )
}

export default Matching
