import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

const LoadingWindow = () => {

  const loadingStates = [t('LoadingStates.One'), t('LoadingStates.Two'), t('LoadingStates.Three'), t('LoadingStates.Four')];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < loadingStates.length - 1) {
        const getRandomTimeout = () => (Math.floor(Math.random() * (1400 - 400 + 1)) + 400).toString();
        const timeoutId = setTimeout(() => {
          setCurrentIndex(prevIndex => prevIndex + 1);
        }, getRandomTimeout());
  
        return () => clearTimeout(timeoutId);
      }
  }, [currentIndex, loadingStates.length]);

  return (
    <div className="flex flex-col justify-center items-center px-5 sm:px-8 py-10 space-y-3 select-none sm:w-300">
        <CircularProgress size={50} />
        <p className='text-center text-sm sm:text-base'>{loadingStates[currentIndex]}</p>
    </div>
  )
}

export default LoadingWindow