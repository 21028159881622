import { Checkbox } from "@mui/material";
import { red } from "@mui/material/colors";
import { t } from "i18next";
import { useEffect } from "react";
import { useCreationContext } from "../../../contexts/CreationProvider";
import { AirHeating, ElectricHeating, FernHeating, GasHeating, OilHeating, OtherHeatingIcon, PelletsHeating } from "../../../helper/icons";
import Button from "../../Generics/Button";
import StageLabel from "../../Objects/Creation/StageLabel";
import NavMenu from "../../Generics/NavMenu";

const HeatingMulti = ({back, next}) => {

  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-3/4 md:w-1/2 mdd:w-1/3 mt-10 gap-1">
        <StageLabel headline={t('CreateSearch.Heizsysteme')} labelText={t('CreateSearch.HeatingLabelMulti')}/>
        <HeatingSelection _size={1.3} _icon={<FernHeating size={32}/>} _name="fern" _color={'#109173'}/>
        <HeatingSelection _size={1.3} _icon={<ElectricHeating size={32}/>} _name="electric" _color={'#109173'}/>
        <HeatingSelection _size={1.3} _icon={<PelletsHeating size={32}/>} _name="pellets" _color={'#109173'}/>
        <HeatingSelection _size={1.3} _icon={<AirHeating size={32}/>} _name="pump" _color={'#109173'}/>
        <HeatingSelection _size={1.3} _icon={<OilHeating size={32}/>} _name="oil" _color={'#109173'}/>
        <HeatingSelection _size={1.3} _icon={<GasHeating size={32}/>} _name="gas" _color={'#109173'}/>
        <HeatingSelection _size={1.3} _icon={<OtherHeatingIcon size={32}/>} _name="otherHeating" _color={'#109173'}/>
        <NavMenu _back={back} nextBtn _next={next}/>
    </div>
  )
};

const HeatingSelection = ({_size, _name, _icon, _color, dynamicText = false, isDisabled = false}) => {

  const { searchData, setSearchData } = useCreationContext();

  return (
    <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
      <div onClick={() => setSearchData({...searchData, heatingMulti: {...searchData.heatingMulti, [_name]: !searchData.heatingMulti[_name]}})} className='flex flex-row items-center gap-1 ssm:gap-2'>
        <Checkbox style={{transform: `scale(${_size})`}} sx={{color: _color, '&.Mui-checked': {color: _color}}} disabled={isDisabled} disableRipple disableTouchRipple value={searchData.heatingMulti[_name]} checked={!isDisabled && searchData.heatingMulti[_name]}/>
        <p className={`text-allimmoDark ${dynamicText ? 'text-xs sm:text-sm ssm:text-base' : ''}`}>{t(`Facilities.${_name}`)}</p>
      </div>
      <div className='grow'/>
      {searchData.heatingMulti[_name] && 
      // <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/${_icon}.json`)}/>
      <div style={{color: _color}}>{_icon}</div>
      }
    </div>
  )
}

export default HeatingMulti;