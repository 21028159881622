import { green } from '@mui/material/colors'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useCreationContext } from '../../../contexts/CreationProvider'
import NavMenu from '../../Generics/NavMenu'
import AnimationIcon from '../../Generics/AnimationIcon'
import StageLabel from '../../Objects/Creation/StageLabel'
import SearchCheckBox from './SearchCheckBox'

const Condition = ({next, back}) => {

    const { searchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);


  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-3/4 md:w-2/3 lg:w-1/3 mt-10">
        <StageLabel labelText={searchData.type === "multi" ? t('CreateSearch.ConditionLabelMulti') : t('CreateSearch.ConditionLabel')}/>
        <p className='text-xs italic text-allimmoDark -mt-1'>{t('CreateSearch.Mehrfachauswahl')}</p>
        <div className="w-full flex flex-col justify-center items-center gap-1.5 mt-6">
            <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
              <SearchCheckBox _size={1.3} name="first" _color={green["A700"]}/>
              <div className='grow'/>
              {searchData.first && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/first.json`)}/>}
            </div>

            <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
              <SearchCheckBox _size={1.3} name="new" _color={green["A700"]}/>
              <div className='grow'/>
              {searchData.new && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/new.json`)}/>}
            </div>

            <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
              <SearchCheckBox _size={1.3} name="renovation" _color={green["A700"]}/>
              <div className='grow'/>
              {searchData.renovation && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/renovation.json`)}/>}
            </div>
        </div>
        <NavMenu nextBtn _next={next} _back={back} />
    </div>
  )
}

export default Condition
