import { t } from 'i18next';
import React, { useState } from 'react'
import { usePopupContext } from '../../contexts/PopupProvider';
import { TextField } from '@mui/material';
import { useSelectionContext } from '../../contexts/SelectionProvider';
import { updateFirestoreDoc } from '../../helper/functions';

const EditSearchName = () => {
    const { setEditSearchNameOpen } = usePopupContext();
    const { selectedSearch } = useSelectionContext();

    const [newName, setNewName] = useState(selectedSearch.name)

    const handleSave = async () => {
        if(newName !== "" && newName.length <= 50) {
            await updateFirestoreDoc("searches", selectedSearch.id, {name: newName})
            .then(() => {
                setEditSearchNameOpen(false);
            })
        }
    };

    const handleEnterPress = (event) => {
        if (event.keyCode === 13 || event.which === 13) {
          handleSave();
        }
      };

  return (
    <div className='flex flex-col justify-center items-center p-5 select-none w-full'>
        <p className='mb-8 w-full text-xl text-center'>{t("EditSearchName.Header")}</p>
        <TextField onKeyDown={handleEnterPress} error={newName === "" || newName.length > 50} size='small' autoComplete='off' value={newName} onChange={(e) => setNewName(e.target.value)}/>
        
        <div className='flex flex-row justify-center mt-8 w-full space-x-2'>
            <button className='bg-allimmoDark/80 rounded text-white px-3 py-2 cursor-pointer hover:bg-red-600 active:bg-red-500' onClick={() => setEditSearchNameOpen(false)}>{t('General.Abbrechen')}</button>
            <button className='bg-allimmoDark/80 rounded text-white px-3 py-2 cursor-pointer hover:bg-allimmoDark active:bg-allimmoDark/80' onClick={handleSave}>{t('General.Speichern')}</button>
        </div>
    </div>
  )
}

export default EditSearchName