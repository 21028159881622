import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import StageLabel from '../../Objects/Creation/StageLabel';
import RangeNumbers from './RangeNumbers';
import { useEffect } from 'react';
import NavMenu from '../../Generics/NavMenu';

const Land = ({next, back}) => {

    const { searchData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

    const validation = () => {
        return [null, "NaN", NaN, undefined, "undefined", ""].includes(searchData.land[0]) || 
        [null, "NaN", NaN, undefined, "undefined", ""].includes(searchData.land[1]) || 
        (!searchData.landUnlimited && parseFloat(searchData?.land[0]) > parseFloat(searchData?.land[1]));
    };

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-2/3 md:w-1/2 mdd:w-1/3">
        <StageLabel color={false} stageIcon={require('../../../assets/animations/searchCreation/land.json')} labelText={t('CreateSearch.LandLabel')}/>
        <RangeNumbers 
          label={t("General.Grundfläche")}
          prop="land"
          resetValues={[0, 100000]}
        />
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={validation()}/>
    </div>
  )
};

export default Land;