import { InputAdornment, TextField } from '@mui/material';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from './StageLabel';
import validator from 'validator';

const Size = ({back, next}) => {

    const { objectData, setObjectData, resetZeroInput, resetOneInput } = useCreationContext();

    const verification = () => {
       if(objectData.type === "apartment") return !(parseFloat(objectData.size) > 0) || !(parseFloat(objectData.rooms) > 0);
       if(objectData.type === "house") return !(parseFloat(objectData.size) > 0) || !(parseFloat(objectData.rooms) > 0) || !(parseFloat(objectData.units) > 0) || !(parseFloat(objectData.floors) > 0)  || !(parseFloat(objectData.land) > 0);
       if(objectData.type === "land") return !(parseFloat(objectData.land) > 0);
       if(objectData.type === "multi") return (!(parseFloat(objectData.size) > 0) || !(parseFloat(objectData.multiLivingSpace) > 0) || (parseFloat(objectData.multiLivingSpace) > parseFloat(objectData.size)) || !(parseFloat(objectData.land) > 0));
       
       if(objectData.type === "commercial") {
        if(["apartment", "salesroom"].includes(objectData?.commercialType)) return !validator.isNumeric(objectData.size.toString()) || !validator.isNumeric(objectData.rooms.toString()) || !(parseFloat(objectData.size) > 0) || !(parseFloat(objectData.rooms) >= 1);
        return !validator.isNumeric(objectData.size.toString()) || !validator.isNumeric(objectData.land.toString()) || parseFloat(objectData.size) === 0 || parseFloat(objectData.land) === 0;
       }
    };

    const commercialRoomsVerification = 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className='w-full ssm:w-3/4 md:w-1/2 flex flex-col justify-center items-center ssm:py-5 gap-2'>
        <StageLabel color={false} stageIcon={require('../../../assets/animations/objectCreation/size.json')} labelText={t('CreateObject.SizeLabel')}/>
        {objectData.type !== "land" &&
        <TextField
            required
            type='number'
            label={objectData.type === "multi" ? t("General.Nutzfläche") : objectData.type === "commercial" ? t("General.Nutzfläche") : t("General.Wohnfläche")}
            onClick={() => resetZeroInput("size")}
            value={objectData.size}
            onChange={(e) => setObjectData({...objectData, size: parseFloat(e.target.value)})}
            size='medium'
            className='w-full bg-white rounded'
            autoComplete='off'
            InputProps={{endAdornment: <InputAdornment disableTypography position="end">m²</InputAdornment>}}
        />}

        {objectData.type === "multi" &&
        <TextField
            required
            type='number'
            label={t("CreateObject.DavonWohnfläche")}
            onClick={() => resetZeroInput("multiLivingSpace")}
            value={objectData.multiLivingSpace}
            onChange={(e) => setObjectData({...objectData, multiLivingSpace: parseFloat(e.target.value)})}
            size='medium'
            className='w-full bg-white rounded'
            autoComplete='off'
            InputProps={{endAdornment: <InputAdornment disableTypography position="end">m²</InputAdornment>}}
        />}

        {(["house", "land", "multi"].includes(objectData.type) || (objectData.type === "commercial" && ["building", "hall"].includes(objectData.commercialType))) &&
        <TextField
            required
            type='number'
            label={t("General.Grundfläche")}
            onClick={() => resetZeroInput("land")}
            value={objectData.land}
            onChange={(e) => setObjectData({...objectData, land: parseFloat(e.target.value)})}
            size='medium'
            className='w-full  rounded bg-white'
            autoComplete='off'
            InputProps={{endAdornment: <InputAdornment disableTypography position="end">m²</InputAdornment>}}
        />}

        <div className='flex flex-row items-center gap-1 sm:gap-1.5 w-full'>
            {(!["multi", "land"].includes(objectData?.type) || (objectData?.type === "commercial" && ["apartment", "salesroom"].includes(objectData?.commercialType))) &&
            <TextField
                required
                type='number'
                label={t("General.Zimmer")}
                onClick={() => resetOneInput("rooms")}
                value={objectData.rooms}
                onChange={(e) => setObjectData({...objectData, rooms: parseFloat(e.target.value)})}
                size='medium'
                className='w-full bg-white rounded'
                autoComplete='off'
            />}

            {(objectData.type === "house") &&
            <TextField
                required
                onClick={() => resetZeroInput("floors")}
                type='number'
                label={t("General.Floors")}
                value={objectData.floors}
                onChange={(e) => setObjectData({...objectData, floors: parseFloat(e.target.value)})}
                size='medium'
                className='w-full bg-white rounded'
                autoComplete='off'
            />}
        </div>

        <NavMenu 
        _back={back} 
        nextBtn 
        _next={next} 
        nextDisabled={verification()}/>
    </div>
  )
}

export default Size