import { t } from 'i18next';
import StageLabel from './StageLabel';
import AnimationIcon from '../../Generics/AnimationIcon';
import { Collapse, TextField } from '@mui/material';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { red } from '@mui/material/colors';
import ObjectCheckBox from './ObjectCheckBox';
import Button from '../../Generics/Button';
import { useEffect } from 'react';
import validator from 'validator';
import NavMenu from '../../Generics/NavMenu';

const Inspection = ({next, back}) => {

    const { objectData, setObjectData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-3/4 md:w-1/2 mdd:w-1/3 mt-10 gap-1 select-none">
        <StageLabel headline={t('CreateObject.InspectionLabel')} labelText={t('CreateObject.InspectionSub')}/>

        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14 mt-5 mb-1.5 border border-gray-300`}>
          <ObjectCheckBox _size={1.3} name="onlineInspection" _color={red[500]}/>
            {objectData.onlineInspection && (
              <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/objectCreation/facilities/onlineInspection.json`)}/>
            )}
        </div>
        <TextField value={objectData?.tourLink} onChange={(e) => setObjectData({...objectData, tourLink: e.target.value})} autoComplete='off' placeholder='https://' label={t('CreateObject.TourLink')} className="w-full h-14 bg-white rounded" size="medium"/>

        <div className='flex flex-col w-full mt-4'>
          <div className={`mb-3 ml-2 ${!objectData?.available ? 'opacity-50' : ''}`}>
            <ObjectCheckBox _size={1.3} name="available" _color={red[500]}/>
          </div>
          {!objectData?.available && <TextField InputLabelProps={{ shrink: true }} label={t('CreateObject.VerfügbarAb')} value={objectData?.availableFrom} onChange={(e) => setObjectData({...objectData, availableFrom: e.target.value})} autoComplete='off' type='month' className="w-full bg-white rounded" size="medium"/>}
        </div>

        <NavMenu _back={back} nextBtn _next={next} nextDisabled={(!objectData?.available && objectData?.availableFrom === "") || objectData?.tourLink.replace(/\s/g, '') !== "" && !validator.isURL(objectData?.tourLink, {protocols: ['https'], require_protocol: true})}/>
    </div>
  )
}

export default Inspection