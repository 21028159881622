import { t } from 'i18next';
import FacilityCheck from '../FacilityCheck';
import { facilities } from '../../helper/arrays';

const Facilities = ({searchData}) => {
  return (
    <div className={`bg-white rounded flex flex-col w-full pb-2 border z-0 border-allimmoDark/70`}>
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 px-1.5 py-0.5 rounded-t">
        <p>{t('SearchDetails.Facilities.Header')}</p>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-1 w-full p-2'>
        {facilities.map((f, i) => (
            <FacilityCheck key={i} facility={f.name} state={searchData?.[f.name] && f.usage.includes(searchData?.type)} />
        ))}
      </div>
    </div>
  )
};

export default Facilities
