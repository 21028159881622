import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { Bed } from '../../../helper/icons';
import NavMenu from '../../Generics/NavMenu';
import SquareButton from '../../Generics/SquareButton';
import AnimationIcon from '../../Generics/AnimationIcon';
import StageLabel from '../../Objects/Creation/StageLabel';

const Type = ({next, back}) => {

    const { searchData, setSearchData, initialSearchData } = useCreationContext();

    useEffect(() => {
        setSearchData({
            ...initialSearchData, 
            name: searchData.name,
            searchOrder: searchData.searchOrder,
            urgency: searchData.urgency, 
        });
        window.scrollTo(0, 0);
    }, []);

    const handleType = (newType) => {
        setSearchData({...searchData, type: newType});
        setTimeout(() => {
            next(newType);
        }, "150");
    };

  return (
    <div className="flex flex-col justify-center items-center w-full lg:w-1/2 mt-1 ssm:mt-10">
        <StageLabel labelText={t('CreateSearch.WhatLabel')}/>
        <div className={`grid grid-cols-2 ssm:grid-cols-3 place-items-center justify-center gap-2 md:gap-3 items-center mt-3 ssm:mt-0 mb-2 ssm:mb-10`}>
            <SquareButton dynamic selected={searchData.type === "apartment"} clickFunction={() => handleType("apartment")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/apartment.json')}/>} label={t('General.Wohnung')}/>
            <SquareButton dynamic selected={searchData.type === "house"} clickFunction={() => handleType("house")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/house.json')}/>} label={t('General.Haus')}/>
            <SquareButton dynamic selected={searchData.type === "land"} clickFunction={() => handleType("land")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/land.json')}/>} label={t('General.Grundstück')}/>
            <SquareButton selected={searchData.type === "multi"} clickFunction={() => handleType("multi")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/multi.json')}/>} label={t('General.Mehrparteien')}/>
            <SquareButton selected={searchData.type === "commercial"} clickFunction={() => handleType("commercial")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/commercial.json')}/>} label={t('CreateSearch.Gewerblich')}/>
            <SquareButton hoverEffect={false} soon selected={searchData.type === "wg"} clickFunction={() => handleType("wg")} icon={<Bed size={50} className={`text-allimmoDark`}/>} label={t('CreateSearch.WG')}/>
        </div>
        <NavMenu _back={back} />
    </div>
  )
}

export default Type
