import { Collapse } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { usePopupContext } from '../../contexts/PopupProvider';
import { useSelectionContext } from '../../contexts/SelectionProvider';
import { storage } from '../../firebase.config';
import { convertShortTimestamp, deactivateSearch, updateFirestoreDoc } from '../../helper/functions';
import { BurgerMenu, DeleteIcon, Dot, DotOpen, Update } from '../../helper/icons';
import useOutsideClick from '../../hooks/useOutsideClick';
import Confirm from '../Generics/Confirm';

const SearchMenu = ({ searchData }) => {
  const { setSelectedObjectID,  } = useSelectionContext();
  const { setObjectDetailsOpen } = usePopupContext();

  const [extended, setExtended] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const latestChange = searchData?.editedData ? searchData?.editedData.reduce((acc, value) => {
    return (acc = acc.created > value.created ? acc : value);
  }, 0) : null;

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setExtended(false);
  });

  const handleMenuClick = () => {
    setExtended(!extended);
  };

  const handleActivation = async () => {
    setExtended(false);
    await updateFirestoreDoc("searches", searchData?.id, {active: true});
  };

  const handleDeactivation = async () => {
    setExtended(false);
    await deactivateSearch({searchId: searchData?.id});
  };

  const handleDeletion = async () => {
    setObjectDetailsOpen(false);
    setSelectedObjectID("");
    await updateFirestoreDoc("searches", searchData?.id, {deleted: true, active: false, deletionDate: Timestamp.fromDate(new Date()), matches: []});
  };

  return (
    <div className='w-full flex flex-row mb-1 items-center justify-end relative z-50'>
      <Dot size={20} className={`${searchData?.active ? 'text-green-600' : 'text-red-500'}`}/>
      <BurgerMenu onClick={handleMenuClick} size={25} className={`cursor-pointer hover:text-allimmoDark/70 text-allimmoDark rounded ${extended ? 'bg-slate-200' : null}`}/>
      {extended &&
      <div ref={wrapperRef} className='flex flex-col bg-white p-1 absolute right-1 top-7 rounded shadow-2xl border border-allimmoDark'>
        {/* <MenuItem _label="TERMINE" _icon={<Calendar size={25}/>} shortcut="STRG+T"/> */}
        {/* <MenuItem _label="PREISCHECK" _icon={<Checkmark size={25}/>} shortcut="STRG+C"/> */}
        {/* <MenuItem _label="UMGEBUNG" _icon={<MapMarker size={25}/>} shortcut="STRG+V"/> */}
        <MenuItem _clickfunction={searchData?.active ? handleDeactivation : handleActivation} _label={searchData?.active ? t('General.Deaktivieren').toUpperCase() : t('General.Aktivieren').toUpperCase()} _icon={<DotOpen size={25} />} shortcut="STRG+A" />
        <MenuItem _clickfunction={() => setDeleteConfirmOpen(true)} _label={t('General.Löschen').toUpperCase()} _icon={<DeleteIcon size={25}/>} shortcut="STRG+D"/>
        {/* <MenuItem _label="HILFE" _icon={<BsQuestionCircle size={25}/>} shortcut="STRG+H"/> */}
        {/* <MenuItem _label="FEEDBACK" _icon={<Star size={25}/>} shortcut="STRG+R"/> */}
        {/* {(searchData?.editedData && searchData?.editedData.length > 0) &&
        // <div className='flex flex-col items-center border-black border-opacity-40 text-xs italic pl-3 pr-1 pt-1.5 pb-0.5 opacity-60 border-t mt-2'>
        //   <div className='flex flex-row items-center justify-center gap-1'>
        //     {/* <Update size={14} className=''/> */}
        {/* //     <p className='font-semibold grow text-right'>{searchData?.editedData.length}</p>
        //     <p>{t('SearchDetails.Menu.ChangesLabel')}</p>
        //   </div>
        //   <div className='flex flex-row items-center justify-center gap-1'>
        //     <p>{t('SearchDetails.Menu.LastChange')}</p>
        //     <p>{convertShortTimestamp(latestChange?.created.toDate())}</p>
        //   </div>
        // </div>*/}

      </div>}
        <Confirm _open={deleteConfirmOpen} confirm={handleDeletion} cancel={() => setDeleteConfirmOpen(false)} confirmBtnText={t('General.Löschen')} confirmText={t('Searches.SearchDelete')}/>
    </div>
  )
};

const MenuItem = ({_icon, _label, _clickfunction, shortcut, expandable = false, subMenu = null, subDisabled = false}) => {
  const { screenSize } = useStateContext();
  const [expanded, setExpanded] = useState(false);

  const handleSubMenuClick = () => {
    setExpanded(false);
  };

    return (
      <div className='flex flex-col'>
        <div onClick={!subDisabled && expandable ? () => setExpanded(!expanded) : _clickfunction} className={`flex flex-row gap-3 items-center cursor-pointer p-1.5 ${expanded ? 'bg-allimmoDark/30 rounded-t' : 'bg-white rounded'} hover:bg-allimmoDark/70 text-alliomoDark/70 hover:text-white`}>
            {_icon}
            <p className='text-xs grow min-w-28'>{_label}</p>
            {/* {screenSize.width > 900 && <p className='text-xs w-20 text-end'>{shortcut}</p>} */}
        </div>
        {expandable &&
        <Collapse in={expanded}>
          <div onClick={handleSubMenuClick}>{subMenu}</div>
        </Collapse>}
      </div>
    )
};

export default SearchMenu
