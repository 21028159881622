import { Timestamp, arrayUnion } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import validator from "validator";
import { updateFirestoreDoc } from "../../../helper/functions";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { t } from "i18next";

const FromToElement = ({property, searchData, label, unit = "", editable = false}) => {
  
    const [editModeFrom, setEditModeFrom] = useState(false);
    const [editModeTo, setEditModeTo] = useState(false);
    const [newValue, setNewValue] = useState(searchData?.[property]);
    const [isSaving, setIsSaving] = useState(false);
    
    const invalidInput = false;

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
      setNewValue(searchData[property]);
      setEditModeFrom(false);
      setEditModeTo(false);
    });
  
    const handleSave = async () => {
      if(invalidInput) return;
      if(newValue === searchData[property]) {
        setEditModeFrom(false);
        setEditModeTo(false);
      } else {
        setIsSaving(true);
        await updateFirestoreDoc("searches", searchData?.id, {[property]: newValue, edited: Timestamp.fromDate(new Date()), editedData: arrayUnion({
          updateID: searchData?.editedData ? searchData?.editedData.length + 1 : 1, 
          propertyName: property, 
          oldValue: searchData[property], 
          newValue: [newValue.min, newValue.max],
          created: Timestamp.fromDate(new Date()),
        })})
        .then(() => {
          setIsSaving(false);
          setEditModeFrom(false);
          setEditModeTo(false);
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
          setIsSaving(false);
        });
      }
    };
  
    const handleEnterPress = (event) => {
      if (invalidInput) return;
      if (event.keyCode === 13 || event.which === 13) {
        handleSave();
      }
    };

    const activateEditModeFrom =() => {
      setEditModeFrom(true);
      setEditModeTo(false);
    };

    const activateEditModeTo =() => {
      setEditModeTo(true);
      setEditModeFrom(false);
    };
  
    return (
      <div className="flex flex-row justify-between">
        <p>{label}</p>
        <div ref={wrapperRef}>
          {!isSaving && 
          <div className="flex flex-row gap-0.5 items-baseline">
            {searchData[`${property}Unlimited`] === true && <p className="text-xs mr-1 opacity-60">{"min."}</p>}
            
            {!editModeFrom && <NumericFormat displayType="text" thousandSeparator className={`${editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}`} onClick={() => editable ? activateEditModeFrom() : null} value={searchData?.[property][0]}/>}
            
            {editModeFrom && <input type="number" onKeyDown={(e) => handleEnterPress(e)} className="border pl-1 rounded border-black w-24" autoFocus value={newValue.min} onChange={(e) => setNewValue({...newValue, min: e.target.value})}/>}
            
            {[false, null, undefined].includes(searchData[`${property}Unlimited`]) && <p>-</p>}
            
            {(!editModeTo && !searchData?.[`${property}Unlimited`]) && 
              <NumericFormat displayType="text" thousandSeparator className={`${editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}`} onClick={() => editable ? activateEditModeTo() : null} value={Math.max(...searchData?.[property])}/>}
            
            {editModeTo && <input type="number" onKeyDown={(e) => handleEnterPress(e)} className="border pl-1 rounded border-black w-24" autoFocus value={newValue.max} onChange={(e) => setNewValue({...newValue, max: e.target.value})}/>}
            
            <p>{unit}</p>
          </div>}
          {isSaving && <p className="text-xs">{t('SearchDetails.Saving')}</p>}
        </div>
      </div>
    )
  };

  export default FromToElement
