import { MenuItem, TextField } from '@mui/material';
import { orange } from '@mui/material/colors';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { CloseCircle, Gastro, Hotel, Landwirtschaft, Logistik, Office, OtherIndustry, Production, ShoppingBag, Storage } from '../../../helper/icons';
import NavMenu from '../../Generics/NavMenu';
import AnimationIcon from '../../Generics/AnimationIcon';
import ObjectCheckBox from './ObjectCheckBox';
import StageLabel from './StageLabel';

const PresentIndustry = ({next, back}) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {objectData, setObjectData } = useCreationContext();

  return (
    <div className='w-full ssm:w-2/3 md:w-1/2 flex flex-col justify-center items-center py-2 sm:py-5 gap-2'>
        <StageLabel headline={t('CreateObject.PresentIndustry.Headline')} color={false} labelText={t('CreateObject.PresentIndustry.Label')}/>
        <TextField select autoComplete='off' className='w-full rounded bg-white' size='medium' label={t('CreateObject.PresentIndustry.Gewerbe')} value={objectData?.presentIndustry} onChange={(e) => setObjectData({...objectData, presentIndustry: e.target.value})}>
            <MenuItem value={"-"}><MenuItemElement icon={<CloseCircle className='text-orange-500' size={25} />} label='No' /></MenuItem>
            <MenuItem value={"Office"}><MenuItemElement icon={<Office className='text-orange-500' size={25} />} label='Office' /></MenuItem>
            <MenuItem value={"Gastro"}><MenuItemElement icon={<Gastro className='text-orange-500' size={25} />} label='Gastro' /></MenuItem>
            <MenuItem value={"Handel"}><MenuItemElement icon={<ShoppingBag className='text-orange-500' size={25} />} label='Handel' /></MenuItem>
            <MenuItem value={"Hotel"}><MenuItemElement icon={<Hotel className='text-orange-500' size={25} />} label='Hotel' /></MenuItem>
            <MenuItem value={"Logistik"}><MenuItemElement icon={<Logistik className='text-orange-500' size={25} />} label='Logistik' /></MenuItem>
            <MenuItem value={"Produktion"}><MenuItemElement icon={<Production className='text-orange-500' size={25} />} label='Produktion' /></MenuItem>
            <MenuItem value={"Landwirtschaft"}><MenuItemElement icon={<Landwirtschaft className='text-orange-500' size={25} />} label='Landwirtschaft' /></MenuItem>
            <MenuItem value={"Storage"}><MenuItemElement icon={<Storage className='text-orange-500' size={25} />} label='Storage' /></MenuItem>
            <MenuItem value={"Other"}><MenuItemElement icon={<OtherIndustry className='text-orange-500' size={25} />} label='Sonstiges' /></MenuItem>
        </TextField>
        {objectData?.presentIndustry === "Other" && <TextField autoComplete='off' required className='w-full rounded bg-white' size='medium' label={t('CreateObject.PresentIndustry.GewerbeName')} value={objectData?.presentIndustryOther} onChange={(e) => setObjectData({...objectData, presentIndustryOther: e.target.value})}/>}
        
        {objectData?.presentIndustry !== "-" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
            <ObjectCheckBox _size={1.3} name="activeBusiness" _color={orange[600]}/>
            <div className='grow'/>
            {objectData.activeBusiness && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/objectCreation/activeBusiness.json`)}/>}
        </div>}
        
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={objectData?.presentIndustry === "Other" && objectData?.presentIndustryOther.trim() === ""}/>
    </div>
  )
};

const MenuItemElement = ({icon, label}) => (
    <div className='flex flex-row gap-5 items-center'>
        {icon}
        <p className='text-allimmoDark'>{t(`CreateObject.Industries.${label}`)}</p>
    </div>

);

export default PresentIndustry
