import { t } from "i18next";
import Button from "../../Generics/Button";
import StageLabel from "../../Objects/Creation/StageLabel";
import ThreeWaySelection from "./ThreeWaySelection";
import { useEffect } from "react";
import NavMenu from "../../Generics/NavMenu";

const Isolated = ({ next, back }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className="flex flex-col justify-center items-center lg:w-1/2 mt-10">
      <StageLabel labelText={t("CreateSearch.IsolatedLabel")} />
      <ThreeWaySelection
        ways={2}
        prop="isolated"
        options={[
          {
            value: "-",
            animation: "egal",
            label: t("General.Egal"),
          },
          {
            value: "no",
            animation: "thumbDown",
            label: t("CreateSearch.Vermeiden"),
          },
          {
            value: "yes",
            animation: "thumbUp",
            label: t("CreateSearch.Unbedingt"),
          },
        ]}
        _next={next}
      />

      <NavMenu _back={back} />
    </div>
  );
};

export default Isolated;
