import { t } from 'i18next';
import { updateFirestoreDoc } from '../../helper/functions';
import { Timestamp, arrayUnion } from 'firebase/firestore';
import { CheckmarkCircle, CloseX } from '../../helper/icons';
import { useState } from 'react';
import { auth } from '../../firebase.config';

const ShortInfo = ({ _objectData }) => {
  return (
    <div className='flex flex-col mt-1'>
        <p className='text-lg'>{`${t(`SearchDetails.${_objectData?.type}`)} - ${t(`Deals.${_objectData?.deal}`, `${t(`AllimmoObject.deal`)} ${t('MissingData.defaultValue')}` )}`}</p>
        {(_objectData?.type === "house" && _objectData?.houseType) ? ["", "missing"].includes(_objectData?.houseType) ? <p className='italic text-sm'>{`${t(`AllimmoObject.houseType`)} ${t(`MissingData.defaultValue`)}`}</p> : <p className='italic text-sm'>{`${t(`Facilities.${_objectData?.houseType}`)}`}</p> : null}
        <div className='flex flex-row items-center gap-1'>
            {_objectData?.type === "apartment" && <p>{`${["attic", "groundFloor"].includes(_objectData?.floor) ? t(`Facilities.${_objectData?.floor}`) : _objectData?.floor === "undefined" ? `${t('AllimmoObject.floor')} ${t(`MissingData.defaultValue`)}` : _objectData?.floor + ". " + t('General.Stock')}`}</p>}
            {_objectData?.type === "house" && <p>{`${["0", 0, ""].includes(_objectData?.floors) ? "" : `${_objectData?.floors} - ${t('General.Stöckig')} |`}`}</p>}
            {(_objectData?.type !== "land" && (_objectData?.type === "commercial" ? _objectData?.commercialType !== "hall" : true)) && <p>{`${_objectData?.building === "new" ? t(`Facilities.isNewBuilding`) : t(`Facilities.isOldBuilding`)}`}</p>}
            {(_objectData?.type !== "land" && !["unknown", "missing", "undefined"].includes(_objectData?.condition)) && <p>{`| ${t(`Facilities.${_objectData?.condition}`)}`}</p>}
        </div>
        <div className='flex flex-row items-center gap-2'>
          <FurnishedProperty _editable={false} objectData={_objectData} />
          {_objectData?.maisonette && 
          <div className='flex flex-row items-center gap-2'>
            <p className='italic text-sm'>{t(`ObjectListing.ShortInfo.Maisonette`)}</p>
          </div>}
        </div>
    </div>
  )
};

const FurnishedProperty = ({objectData, _editable = true}) => {
  
  const options = ["furnished", "partFurnished", "notfurnished"];
  const [newValue, setNewValue] = useState("");

  const handleEdit = async () => {
    if(newValue === objectData?.furnished) return;
    if(!_editable) return;
    if(!objectData?.customer !== auth.currentUser.uid) return;
    
    await updateFirestoreDoc("objects", objectData?.id, {
      furnished: options[(options.indexOf(objectData?.furnished) + 1) % 3],
      edited: Timestamp.fromDate(new Date()), 
      editedData: arrayUnion({
          updateID: objectData?.editedData ? objectData?.editedData.length + 1 : 1, 
          propertyName: "furnished",
          oldValue: objectData?.furnished, 
          newValue: options[(options.indexOf(objectData?.furnished) + 1) % 3],
          created: Timestamp.fromDate(new Date()),
      })})
      .then(() => setNewValue(""));
  };

  const changeValue = () => {
    const index = options[(options.indexOf(newValue) + 1) % 3] === objectData?.furnished ? 2 : 1;
    setNewValue(options[(options.indexOf(newValue) + index) % 3]);
  };
  
  return (
    <div className={`flex flex-row items-center gap-1 ${["", "-", "undefined"].includes(objectData?.furnished) ? "hidden" : "block"}`}>
      <p onClick={_editable ? changeValue : null} className={`italic text-sm ${_editable ? "hover:bg-allimmoDark/10 cursor-pointer" : ""} ${newValue !== "" ? "text-allimmoDark/40" : ""}`}>{t(`Facilities.${objectData?.furnished}`)}</p>
      {newValue !== "" && <p className='ml-2 mr-1 text-green-500 text-lg'>{">>"}</p>}
      {newValue !== "" && <p className='italic text-sm'>{newValue !== "" ? t(`Facilities.${newValue}`) : ""}</p>}
      {newValue !== "" && <CheckmarkCircle onClick={handleEdit} size={22} className='text-green-500 cursor-pointer hover:text-green-700 ml-2 rounded bg-green-100'/>}
      {newValue !== "" && <CloseX onClick={() => setNewValue("")} size={22} className='text-red-500 cursor-pointer hover:text-red-700 rounded bg-red-100'/>}
      {objectData?.maisonette && <p className='italic text-sm ml-1'>{"|"}</p>}
    </div>
  )
};

export default ShortInfo
