import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { auth } from '../firebase.config';

const Shop = () => {

    const { setIsLoading, adminEmail } = useStateContext();

    const [intervalId, setIntervalId] = useState(null);

    useEffect(() => {
        return () => {
          if (intervalId) {
            clearInterval(intervalId);
          }
        };
    }, [intervalId]);
    
    const startTransaction = async (link, amount) => {
        setIsLoading(true);
        const paymentLink = `https://buy.stripe.com/${link}?prefilled_email=${auth.currentUser.email}&client_reference_id=${auth.currentUser.uid}-${amount}`;
        const newWindow = window.open(paymentLink, '_blank');

        const id = setInterval(() => {
            if (newWindow.closed) {
              setIsLoading(false);
              clearInterval(id); // Stop the polling
            }
          }, 100);
          setIntervalId(id);
    };

  return (
    <div className='flex flex-col w-full items-center px-3 pb-10'>
        <div className='flex flex-row space-x-2 mb-5 md:mb-10 mt-5'>
            <p className='text-xl'>LIMITIERTES</p>
            <p className='text-orange-400 text-xl font-bold'>BETA</p>
            <p className='text-xl'>ANGEBOT</p>
        </div>
        <div className='flex flex-col md:flex-row w-full md:justify-center items-center space-y-4 md:space-x-3 md:space-y-0 pb-5'>
            {auth.currentUser.email === adminEmail && <ShopItem normalPrice="1.50" name="TEST" price={"0.50"} amount={"100"} color={"bg-shop1"} buy={() => startTransaction("cN25m96WIfoI0Mg005", 110)}/>}
            <ShopItem normalPrice="19.90" name={t('Shop.Bronze')} price={"9.90"} amount={"10"} color={"bg-bronze"} buy={() => startTransaction("bIYcOBa8UfoI0Mg3cc", 10)}/>
            <ShopItem normalPrice="99.50" freeAmount={3} name={t('Shop.Silber')} price={"49.50"} amount={"50"} color={"bg-silver"} buy={() => startTransaction("9AQg0N0yk5O83Ys6op", 53)}/>
            <ShopItem normalPrice="199.00" freeAmount={10} name={t('Shop.Gold')} price={"99.00"} amount={"100"} color={"bg-gold"} buy={() => startTransaction("4gwg0Nfte90k8eI9AC", 110)}/>
            <ShopItem normalPrice="999.00" freeAmount={75} name={t('Shop.Platin')} price={"495.00"} amount={"500"} color={"bg-platin"} buy={() => startTransaction("eVabKx0ykccw2Uo9AD", 575)}/>
            <ShopItem normalPrice="1,990.00" freeAmount={200} name={t('Shop.Diamant')} price={"990.00"} amount={"1000"} color={"bg-diamond"} buy={() => startTransaction("fZe3e1epadgA1QkeUY", 1200)}/>
        </div>
        <div className='italic text text-center w-full text-slate-600 my-5'>
            <p>Die Preise verstehen sich inklusive Mehrwertsteuer.</p>
            <p>Credits sind ab Kaufdatum lebenslang gültig.</p>
            <p>Es besteht ein 14-tägiges Rücktrittsrecht.</p>
            <p>Dieses Rücktrittsrecht verfällt bei Nutzung der Credits.</p>
        </div>

        {/* <div className='flex flex-col justify-center items-center italic text text-center w-full text-slate-600 mb-5'>
            <AnimationIcon _iconSize={screenSize.width > 500 ? 60 : 50} _color={false} iconSrc={require('../assets/animations/taxfree.json')}/>
            <p>Für eine MwSt-freie Rechnung (Nur in Europäischen Ländern ausgenommen Österreich möglich) kontaktiere uns bitte unter:</p>
            <p className='font-bold'>support@allimmomatch.com</p>
        </div> */}
    </div>
  )
};

const ShopItem = ({amount, price, color, buy, name, normalPrice, freeAmount = 0, paymentLink}) => {
    return (
        <div onClick={() => buy(paymentLink)} className={`flex flex-col items-center justify-start rounded-xl border-white border shadow px-2 py-1 w-48 h-56 cursor-pointer hover:border-black active:shadow-2xl bg-white`}>
            <div className={`${color} h-1 w-full mb-2 mt-1 rounded-xl`}/>
            <div className='flex flex-col justify-center items-center grow w-full'>
                <p className='text-xl'>{name}</p>
                <p className='text-2xl font-bold'>{amount}</p>
                <p className='text-sm font-bold w-3/4 text-center mb-2 pb-2 border-slate-300 border-b'>{"credits"}</p>
                <p className='italic line-through'>{normalPrice} €</p>
                <p className='italic text-xl'>{price} €</p>
                {freeAmount > -1 && <p className={`text-base ssm:text-sm mdd:text-lg font-bold mt-3 ${freeAmount === 0 ? 'text-white' : 'text-allimmoDark'} text-center`}>{`+${freeAmount} credits ${t('Shop.Gratis')}`}</p>}
            </div>
        </div>
    )
};

export default Shop