import { createContext, useContext, useState } from "react";

const ObjectsContext = createContext();

export const ObjectsProvider = ({ children }) => {
    
    const [customerObjects, setCustomerObjects] = useState([]);  

    return (
        <ObjectsContext.Provider
            value={{ 
                customerObjects,
                setCustomerObjects,
            }}>
                {children}
        </ObjectsContext.Provider>
    )
};

export const useObjectsContext = () => useContext(ObjectsContext)
