import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import AnimationIcon from '../../Generics/AnimationIcon';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from '../../Objects/Creation/StageLabel';

const SquarePrice = ({back, next}) => {

  const { searchData, setSearchData } = useCreationContext();

  useEffect(() => {
    if(searchData.squarePrice.price === "")
    setSearchData({...searchData, squarePrice: {use: false, price: 0}})
  }, [searchData.squarePrice.price]);  

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-2/3 md:w-1/2 mdd:w-1/3">
        <StageLabel headline={t('CreateSearch.SquarePrice.Headline')} labelText={t('CreateSearch.SquarePrice.Label')} />
        <div className='flex flex-row gap-3 items-center justify-center mt-5'>
          <div onClick={() => setSearchData({...searchData, squarePrice: {use: false, price: 0}})} className={`flex flex-col cursor-pointer ${!searchData?.squarePrice.use ? 'bg-sky-200' : 'bg-white'} justify-center items-center border-2 border-opacity-20 shadow-2xl border-allimmoDark/20 bg-opacity-50 border-allimmoDark text-allimmoDark aspect-square h-36 rounded-xl`}>
              <AnimationIcon _color={false} _iconSize={60} iconSrc={require(`../../../assets/animations/searchCreation/egal.json`)}/>
              <p className={`w-full overflow-hidden text-center mt-2 text-16`}>{t('CreateSearch.SquarePrice.Egal')}</p>
          </div>
          
          <div className={`flex flex-col p-2 gap-1 justify-between items-center border-2 border-opacity-20 shadow-2xl border-allimmoDark/20 bg-opacity-50 border-allimmoDark text-allimmoDark aspect-square h-36 rounded-xl`}>
              <p className='text-xs text-center'>{t('CreateSearch.SquarePrice.MaxPreis')}</p>
              <input value={searchData?.squarePrice.price === 0 ? "" : searchData?.squarePrice.price} autoFocus type="number" className="w-full text-center rounded-lg bg-transparent outline-none border h-20 border-allimmoDark text-2xl" onChange={(e) => setSearchData({...searchData, squarePrice: {use: true, price: e.target.value}})}/>
          </div>
        </div>
        <NavMenu _back={back} nextBtn _next={next} />
    </div>
  )
};

export default SquarePrice
