
const Flags = () => {
  return (
    <div className='flex flex-rwo gap-1.5 items-center my-1.5'>
        <img className='rounded-full border-2 border-black opacity-80 border-opacity-80' src={require('../assets/aut.png')} alt="Austria" width={22}/>
        <img className='rounded-full border-2 border-black opacity-80 border-opacity-80' src={require('../assets/ger.png')} alt="Germany" width={22}/>
        <img className='rounded-full border-2 border-black opacity-80 border-opacity-80' src={require('../assets/sui.png')} alt="Switzerland" width={22}/>
    </div>
  )
};

export default Flags
