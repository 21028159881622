import StageLabel from './StageLabel';
import NavMenu from '../../Generics/NavMenu';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { useEffect } from 'react';
import { t } from 'i18next';
import SquareButton from '../../Generics/SquareButton';
import { useStateContext } from '../../../contexts/ContextProvider';
import AnimationIcon from '../../Generics/AnimationIcon';

const CommercialType = ({back, next}) => {

    const { objectData, setObjectData } = useCreationContext();
    const { screenSize } = useStateContext();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const handleCommercialType = (newType) => {
        setObjectData({...objectData, commercialType: newType});
        setTimeout(() => {
          next();
      }, "150");
    };

  return (
    <div className='w-full flex flex-col justify-center items-center py-2 sm:py-5 gap-2'>
        <StageLabel headline={t('CreateObject.CommercialType.Headline')} color={false} labelText={t('CreateObject.CommercialType.Label')}/>
        <div className={`grid grid-cols-2 md:grid-cols-4 place-items-center justify-center gap-2 items-center mt-5`}>
          <SquareButton subText={t('CreateSearch.CommercialTypeSub.salesroom')} selected={objectData?.commercialType === "salesroom"} clickFunction={() => handleCommercialType("salesroom")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/salesroom.json')}/>} label={t('General.Lokal')}/>
          <SquareButton subText={t('CreateSearch.CommercialTypeSub.apartment')} selected={objectData?.commercialType === "apartment"} clickFunction={() => handleCommercialType("apartment")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/commercialApartment.json')}/>} label={t('General.Geschäftsräume')}/>
          <SquareButton subText={t('CreateSearch.CommercialTypeSub.building')} selected={objectData?.commercialType === "building"} clickFunction={() => handleCommercialType("building")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/commercialBuilding.json')}/>} label={t('General.Gebäude')}/>
          <SquareButton subText={t('CreateSearch.CommercialTypeSub.hall')} selected={objectData?.commercialType === "hall"} clickFunction={() => handleCommercialType("hall")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/commercialHall.json')}/>} label={t('General.Halle')}/>
      </div>
        <NavMenu _back={back} />
    </div>
  )
};

export default CommercialType
