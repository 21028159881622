import { Dialog } from '@mui/material';
import { Transition, updateFirestoreDoc } from '../../helper/functions';
import { t } from 'i18next';
import { CloseX, Edit, Plus } from '../../helper/icons';
import { useState } from 'react';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../../firebase.config';

const UploadExpose = ({close, _open, objectData}) => {

    const [file, setFile] = useState(null);
    const [progressPercent, setProgressPercent] = useState(0);
    const [finished, setFinished] = useState(true);

    const backdropClose = (event, reason) => {
        if (reason && event === "backdropClick") 
            return;
        close();
        setFile(null);
    };

    const handleExposeSubmit = () => {
        if (!file) return;
        if (file.size > 1024 * 1024 * 5) return;
        if (
          ![
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(file.type)
        ) return;

        setFinished(false);
        
        const storageRef = ref(storage, `objects/${objectData?.id}/expose`);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgressPercent(progress);
          },
          (error) => {
            console.log(error);
            setFinished(true);
        },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
              // save expose Link to firestore
              await updateFirestoreDoc("objects", objectData?.id, {
                exposeLink: downloadURL,
              });
    
              setTimeout(() => {
                setFile(null);
                setProgressPercent(0);
                setFinished(true);
                close();
              }, "500");
            });
          }
        );
      };

  return (
    <Dialog onClose={(e, r) => backdropClose(e,r)} open={_open} TransitionComponent={Transition} fullWidth>
        <div className='p-5 flex flex-col justify-center items-center w-full select-none'>
            <div className='flex flex-row justify-end items-center w-full mb-2'>
                <CloseX onClick={close} className='cursor-pointer text-allimmoDark/30 hover: text-red-500' size={25}/>
            </div>
            <div className='flex flex-col gap-2 justify-center items-center'>
                <p className='text-allimmoDark text-lg font-semibold'>{t("ObjectDetails.Expose.NeuesExpose")}</p>
                <label htmlFor='upload' className='italic text-allimmoDark/70 underline cursor-pointer'><Plus size={40} className='text-allimmoDark/30 hover:text-allimmoDark/80 cursor-pointer'/></label>
                <input multiple id="upload" hidden onChange={(e) => setFile(e.target?.files[0])} type="file" accept=".pdf, .doc, .docx" />
                {file && <p className='cursor-pointer text-allimmoDark underline text-lg italic' onClick={handleExposeSubmit}>{t('ObjectDetails.Expose.HierHochladen')}</p>}
            </div>
            {finished ?
            // <div className='flex flex-col gap-2 justify-center items-center'>
            //     <a target='_blank' href={objectData?.exposeLink} className='text-allimmoDark text-lg font-semibold italic hover:underline cursor-pointer'>{t("ObjectDetails.Expose.ExposeAnsehen")}</a>
            //     <label htmlFor='upload' className='italic text-allimmoDark/70 underline cursor-pointer'>{t('ObjectDetails.Expose.NeuesExpose')}</label>
            //     <input multiple id="upload" hidden onChange={(e) => setFile(e.target?.files[0])} type="file" accept=".pdf, .doc, .docx" />
            //     {file && <p className='cursor-pointer' onClick={handleExposeSubmit}>neues hochladen</p>}
            // </div> 
            <p></p>:
            <p className='text-allimmoDark text-lg font-semibold text-center'>{progressPercent}%</p>}
        </div>
    </Dialog>
  )
}

export default UploadExpose
