import { t } from 'i18next';
import { updateFirestoreDoc } from '../../helper/functions';
import { usePopupContext } from '../../contexts/PopupProvider';
import { auth } from '../../firebase.config';
import { useStateContext } from '../../contexts/ContextProvider';

const RefundInfo = () => {

    const { setRefundInfoOpen } = usePopupContext();
    const { customer } = useStateContext();

    const handleClose = () => {
        updateFirestoreDoc("customers", auth.currentUser.uid, {refund: 0, systemRefund: false});
        setRefundInfoOpen(false);
    };

  return (
    <div className='flex flex-col justify-center items-center p-5 select-none w-full'>
        {customer?.systemRefund ?
        <p className='mb-8 w-full text-xl text-center'>{t("Refund.SystemHeader")}</p> :
        <p className='mb-8 w-full text-xl text-center'>{t("Refund.Header")}</p>}
        <div className='flex flex-col items-center'>
            {!customer?.systemRefund && <p className="text-center w-full">{t("Refund.TextAnfang")}</p>}
            {customer?.systemRefund && <p className="text-center w-full">{t("Refund.SystemRefundText")}</p>}
            <p className='font-bold text-lg text-center w-full'>{customer?.refund}</p>
            <p className="text-center w-full">{t("Refund.TextEnde")}</p>
        </div>
        
        <div className='flex flex-row justify-center mt-8 w-full'>
            <button className='bg-allimmoDark/80 rounded text-white px-3 py-2 cursor-pointer hover:bg-allimmoDark active:bg-allimmoDark/80' onClick={handleClose}>{t('General.Ok')}</button>
        </div>
    </div>
  )
}

export default RefundInfo