import { TextField } from "@mui/material";
import { t } from "i18next";
import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import {
  FeatureGroup,
  MapContainer,
  Marker,
  TileLayer,
  useMap
} from "react-leaflet";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useCreationContext } from "../../../contexts/CreationProvider";
import { CircleIcon, DeleteIcon, Edit, PolygonIcon } from "../../../helper/icons";
import Button from "../../Generics/Button";
import NavMenu from "../../Generics/NavMenu";
import MapButton from "./components/MapButton";
import calculateGeohashes from "./functions/calculateGeohashes";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

const SearchMap = ({ next, back }) => {
  const { searchData, setSearchData } = useCreationContext();
  const { screenSize } = useStateContext();

  const drawTextInfoOptions = {
    start: t('CreateSearch.Map.DrawStartInfo'),
    edit: t('CreateSearch.Map.EditInfo'),
    delete: t('CreateSearch.Map.DeleteInfo'),
    polygon: t('CreateSearch.Map.PolygonInfo'),
    circle: t('CreateSearch.Map.CircleInfo'),
  };

  const locations = {
    at: [47.59215925257782, 14.265993260120704],
    de: [51.1257904790071, 10.00878689614948],
  };

  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState([
    [
      locations[searchData.country.toLowerCase()][0],
      locations[searchData.country.toLowerCase()][1],
    ],
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [markerData, setMarkerData] = useState(null);
  const [deletePolygons, setDeletePolygons] = useState(false);
  const [savePolygons, setSavePolygons] = useState(false);
  const [allShapesDeleted, setAllShapesDeleted] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const [drawModePoly, setDrawModePoly] = useState(false);
  const [drawModeCircle, setDrawModeCircle] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [noShapeDrawn, setNoShapeDrawn] = useState(true);
  const [savePolygonsBack, setSavePolygonsBack] = useState(false);
  const [drawInfoText, setDrawInfoText] = useState(drawTextInfoOptions.start);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const globalZoom = 8;
  const customIcon = new L.Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/854/854853.png",
    iconSize: [38, 38],
    iconAnchor: [6, 38],
  });

  const DrawHandle = () => {
    const map = useMap();
        
    useEffect(() => {
      map.pm.setLang("de");
      if(map.pm.getGeomanDrawLayers().length === 0) setDeleteMode(false);
      
      if(searchData.polygon.length !== 0) {
        searchData.polygon.forEach(p => {
          map.addLayer(p);
        });
        setSearchData({...searchData, polygon: []}); 
      }
      setNoShapeDrawn(map.pm.getGeomanDrawLayers().length === 0 && searchData.polygon.length === 0 && allShapesDeleted);
    }, []);

    useEffect(() => {
      map.on('pm:create', function(event) {
        const { layer } = event;
        layer.on('pm:remove', function(e) {
          const layerCount = map.pm.getGeomanDrawLayers().length;
          if(layerCount === 0) {
            setAllShapesDeleted(true);
            setSearchData({...searchData, polygon: []});
            setDeleteMode(false);
          }
        });
        
        setAllShapesDeleted(false);
        setDrawModePoly(false);
        setDrawModeCircle(false);
        setDrawInfoText(drawTextInfoOptions.start);
      });
    }, []);

    useEffect(() => {
      if(drawModePoly && !drawModeCircle) {
        setDrawInfoText(drawTextInfoOptions.polygon);
        map.pm.enableDraw('Polygon', { snappable: true, snapDistance: 20, finishOn: 'dblclick', allowSelfIntersection: false, pathOptions:{color:'#3b82f6'}, templineStyle: { color: '#3b82f6'}, hintlineStyle: { color: '#3b82f6', dashArray: [5, 5]}});
      }

      if(!drawModePoly && drawModeCircle) {
        setDrawInfoText(drawTextInfoOptions.circle);
        map.pm.enableDraw('Circle', { snappable: true, snapDistance: 20, finishOn: 'dblclick', allowSelfIntersection: false, pathOptions:{color:'#3b82f6'}, templineStyle: { color: '#3b82f6'}, hintlineStyle: { color: '#3b82f6'}});
      }

      if(!drawModeCircle && !drawModePoly && map.pm.globalDrawModeEnabled()) {
        setDrawInfoText(drawTextInfoOptions.start);
        map.pm.disableDraw();
      }
      
    }, [drawModePoly, drawModeCircle]);

    useEffect(() => {
      if(editMode) {
        setDrawInfoText(drawTextInfoOptions.edit);
        map.pm.enableGlobalEditMode({ snappable: true, snapDistance: 20 });
      } else if(map.pm.globalEditModeEnabled()){
        setDrawInfoText(drawTextInfoOptions.start);
        map.pm.disableGlobalEditMode();
      }
    }, [editMode]);

    useEffect(() => {
      if(deleteMode) {
        if(map.pm.getGeomanDrawLayers().length === 1) {
          setDeletePolygons(true);
        } else {
          setDrawInfoText(drawTextInfoOptions.delete);
          map.pm.enableGlobalRemovalMode();
        }
      } else if(map.pm.globalRemovalModeEnabled()){
        setDrawInfoText(drawTextInfoOptions.start);
        map.pm.disableGlobalRemovalMode();
      }
    }, [deleteMode]);

    useEffect(() => {
      if(goBack) {
        setGoBack(false);
        setSavePolygons(true);
        back();
      }
    }, [goBack]);
  };

  const ZoomHandler = () => {
    const map = useMap();

   const flyToPosition = (coordinates, zoom) => {
      if (coordinates && typeof coordinates[0] !== "undefined") {
        map.flyTo(coordinates, zoom, {
          animate: true,
          duration: 1.5,
        });
      }
    };

    useEffect(() => {
      if (markerData) {
        if (typeof markerData !== "undefined") {
          flyToPosition(
            [markerData?.lat, markerData?.lon],
            [
              "region",
              "state",
              "state_district",
              "county",
              "city",
              "city_district",
              "quarter",
              "suburb",
            ].includes(markerData?.addresstype)
              ? 11
              : 14
          );
        }
      }
    }, [markerData]);

    if(markerData) {
      if (["Polygon", "MultiPolygon"].includes(markerData?.geojson?.type)) {
        return (
          <>
            {/* {["region", "state", "state_district", "county", "city", "city_district", "quarter", "suburb", "road"].includes(markerData?.addresstype) ?  */}
            {/* <GeoJSON data={markerData?.geojson} style={{color: "orange"}}/> : */}
            {/* <Marker
              position={[markerData?.lat, markerData?.lon]}
              icon={customIcon}
            /> */}
          </>
        );
      } else {
        return (
          <Marker
            position={[markerData?.lat, markerData?.lon]}
            icon={customIcon}
          />
        );
      }
    }

    if (markerData) {
      return (
        <Marker
          position={[
            markerData?.geojson?.coordinates[1],
            markerData?.geojson?.coordinates[0],
          ]}
          icon={customIcon}
        />
      );
    } else {
      return null;
    }
  };

  const DrawHandler = () => {
    const map = useMap();
    useEffect(() => {
      if (deletePolygons) {
        map.eachLayer((layer) => {
          if (layer._path != null) {
            layer.remove();
          }
        });
        setAllShapesDeleted(true);
        setSearchData({...searchData, polygon: []});
        setDeletePolygons(false);
      }
    }, [deletePolygons]);

    useEffect(() => {
      if(deleted) {
        setDeleted(false);
        let allDeleted = true;
        map.eachLayer((layer) => {
          if (layer._path != null) {
            allDeleted = false;
            return;
          }
        })

       if(allDeleted) {
         setSearchData({...searchData, polygon: []});
       }
     setAllShapesDeleted(allDeleted);
     }
      
    }, [deleted]);

    useEffect(() => {
      setSavePolygons(false);

      if(savePolygons) {
        const newArray = [];
        map.eachLayer((layer) => {
          if (layer._path != null) {
            const isCircle = typeof layer.getRadius === 'function';
            if(!isCircle && layer.getLatLngs()[0].length === undefined) return;
            newArray.push(layer);
          }
        });
        setSearchData({ ...searchData, polygon: newArray});
        next();
      }
    }, [savePolygons]); 
    
    useEffect(() => {
      setSavePolygonsBack(false);

      if(savePolygonsBack) {
        const newArray = [];
        map.eachLayer((layer) => {
          if (layer._path != null) {
            const isCircle = typeof layer.getRadius === 'function';
            if(!isCircle && layer.getLatLngs()[0].length === undefined) return;
            newArray.push(layer);
          }
        });
        setSearchData({ ...searchData, polygon: newArray });
        back();
      }
    }, [savePolygonsBack]); 
  };

  const searchLocation = async () => {
    if (searchInput === "") return;
    setSearchResult([]);
    setIsLoading(true);
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?addressdetails=1&q=${searchInput}&format=jsonv2&polygon_geojson=1&extratags=1&namedetails=1`
    );
    const result = await response.json();
    setSearchResult(result);
    // setSearchInput("");
    setIsLoading(false);
  };

  const handleEnterPress = (event) => {
    if (event.keyCode === 13 || event.which === 11) {
      searchLocation();
    }
  };

  const handleItemClick = (res) => {
    setMarkerData(res);
  };

  const handleNext = () => {
    setSavePolygons(true);
  };

  const handleBack = () => {
    setSavePolygonsBack(true);
  };

  return (
    <div className="flex flex-col w-full mdd:w-3/4">
      <p className="text-2xl font-bold text-gray-800 w-full text-center">{t('CreateSearch.MapLabel')}</p>
      <p className="text-allimmoDark italic text-sm w-full text-center px-3 -mt-1">{t('CreateSearch.MapSub')}</p>
      {/* <p onClick={() => setMapVideoOpen(true)} className="text-red-500 text-sm font-bold w-full text-center cursor-pointer underline">{t('CreateSearch.MapHilfe')}</p> */}
      <div className="w-full flex flex-col justify-center items-center">
        <NavMenu
        marginTop={3}
        _back={handleBack} 
        nextBtn 
        _next={handleNext} 
        nextDisabled={noShapeDrawn || drawModeCircle || drawModePoly || editMode || deleteMode} 
        backDisabled={drawModeCircle || drawModePoly || editMode || deleteMode}
        />
        
        {/* <div className="flex flex-row justify-center items-center gap-1">
          <Button text="Zurück" clickFunction={handleBack} width="100%" isDisabled={drawModeCircle || drawModePoly || editMode || deleteMode}/>
          <Button
            isDisabled={noShapeDrawn || drawModeCircle || drawModePoly || editMode || deleteMode}
            text="Weiter"
            clickFunction={handleNext}
            loading={isLoading}
            width="100%"/>
        </div> */}
      </div>
      <div className="flex flex-row items-center justify-center gap-1 mt-3 mb-1">
          <MapButton color={"blue"} icon={<PolygonIcon size={30}/>} isDisabled={drawModeCircle || editMode || deleteMode} text={t("CreateSearch.Map.Polygon")} clickFunction={() => setDrawModePoly(!drawModePoly)} selected={drawModePoly}/>
          <MapButton color={"blue"} icon={<CircleIcon size={30}/>} isDisabled={drawModePoly || editMode || deleteMode} text={t("CreateSearch.Map.Kreis")} clickFunction={() => setDrawModeCircle(!drawModeCircle)} selected={drawModeCircle}/>
          <MapButton color={"green"} icon={<Edit size={30}/>} isDisabled={drawModeCircle || drawModePoly || noShapeDrawn || deleteMode} text={t("CreateSearch.Map.Bearbeiten")} clickFunction={() => setEditMode(!editMode)} selected={editMode}/>
          <MapButton color={"red"} icon={<DeleteIcon size={30}/>} isDisabled={(noShapeDrawn && searchData.polygon.length === 0) || editMode || drawModeCircle || drawModePoly} text={t("CreateSearch.Map.Löschen")} clickFunction={() => setDeleteMode(!deleteMode)} selected={deleteMode}/>
      </div>
      <p className="text-center w-full self-center px-2 italic text-sm text-slate-500">{drawInfoText}</p>
      <div className="w-full relative h-50vh border-2 border-allimmoDark rounded-2xl overflow-hidden my-2 flex">
        <MapContainer className="z-10" center={searchData?.polygon?.length === 0 ? locations.at : searchData?.polygon[0]?.pm._shape === "Circle" ? searchData?.polygon[0]?.getLatLng() : searchData?.polygon[0]?.getBounds().getCenter()} zoom={globalZoom} zoomControl={false} touchZoom={!drawModeCircle && !drawModePoly && !editMode}>
            <FeatureGroup>
            <ZoomHandler />
            <DrawHandler />
            <DrawHandle />
            </FeatureGroup>
            <TileLayer
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
             
        </MapContainer>
      </div>
      <div className="flex flex-col gap-1 w-full">
        <div className="flex flex-col mmd:flex-row gap-1 w-full">
          <div className="flex flex-row gap-1 w-full">
            <TextField
              autoComplete="off"
              onKeyDown={(e) => handleEnterPress(e)}
              className="w-full bg-white rounded"
              size="small"
              placeholder="Ort eingeben..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <Button
              text="X"
              clickFunction={() => setSearchResult([])}
              width="10%"
            />
          </div>
          <Button
            text={t('CreateSearch.Map.KarteAusrichten')}
            clickFunction={searchLocation}
            loading={isLoading}
            width={screenSize.width < 600 ? "100%" : "40%"}
          />
        </div>
        <div className="flex flex-col items-center w-full overflow-auto h-40vh">
            {searchResult
              .filter(
                (el) =>
                  !["railway", "highway"].includes(el.category) &&
                  ["at", "de", "ch"].includes(el.address?.country_code) &&
                  ![
                    "administrative",
                    "isolated_dwelling",
                    "city_block",
                  ].includes(el.addresstype) &&
                  (el.addresstype === "suburb"
                    ? searchResult.some(
                        (r) => r.addresstype === "city_district"
                      )
                      ? false
                      : true
                    : true)
              )
              .map((res, i) => {
                return (
                  <div
                    key={i}
                    className={`w-full border-b border-black flex flex-col hover:bg-slate-100 cursor-pointer px-2 py-1`}
                  >
                    <div
                      className="flex flex-col"
                      onClick={(e) => handleItemClick(res)}
                    >
                      <p className="grow italic font-bold">
                        {res?.address?.amenity !== undefined
                          ? res?.address?.amenity
                          : ""}
                      </p>
                      <p className="grow">
                        {res?.address?.road}{" "}
                        {res?.address?.house_number !== undefined
                          ? res?.address?.house_number + ", "
                          : ""}{" "}
                        {res?.address?.postcode} {res?.address?.city}{" "}
                        {res?.address?.village} {res?.address?.town}
                      </p>
                      <p className="grow">
                        {res?.address?.county} {res?.address?.municipality}{" "}
                        {res?.address?.city_district} {res?.address?.state}
                      </p>
                      <p className="grow">{res?.address?.country}</p>
                    </div>
                    <div className="flex flex-row grow">
                      {!["state", "building"].includes(res?.addresstype) && (
                        <p className="grow"></p>
                      )}
                      {res?.addresstype === "state" && (
                        <p className="italic text-sm grow">{t('CreateSearch.Map.Bundesland')}</p>
                      )}
                      {res?.addresstype === "building" && (
                        <p className="italic text-sm grow">{t('CreateSearch.Map.Gebäude')}</p>
                      )}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default SearchMap
