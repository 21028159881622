import { t } from "i18next";
import Button from "../../Generics/Button";
import StageLabel from "../../Objects/Creation/StageLabel";
import ThreeWaySelection from "./ThreeWaySelection";
import { useEffect } from "react";
import { useCreationContext } from "../../../contexts/CreationProvider";
import NavMenu from "../../Generics/NavMenu";

const Attic = ({next, back}) => {

    const { setSearchData, searchData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setSearchData({...searchData, attic: ""})
      }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full mdd:w-2/3 mt-10 gap-1">
        <StageLabel headline={t('CreateSearch.Dachgeschoss')} labelText={t('CreateSearch.AtticLabel')}/>
        <ThreeWaySelection
        withProp
        prop="attic" 
        options={
            [
                {
                    value: "-",
                    animation: "egal",
                    label: t("General.Egal"),
                },
                {
                    value: "no",
                    animation: "thumbDown",
                    label: t("CreateSearch.Vermeiden"),
                },
                {
                    value: "yes",
                    animation: "thumbUp",
                    label: t("CreateSearch.Unbedingt"),
                },
            ]
        } 
        _next={next}/>

        <NavMenu _back={back} />
    </div>
  )
};

export default Attic
