import { Player } from '@lordicon/react';
import { useEffect, useRef } from 'react';

const AnimationIcon = ({iconSrc, _iconSize, _color = true, _delay = "2000", animate = true}) => {

    const iconref = useRef(null);

    useEffect(() => {
      if (iconSrc !== null && animate) {
        iconref.current?.playFromBeginning();
      } else {
        iconref.current?.goToFirstFrame();
        iconref.current?.pause();
      }
    }, [animate]);

    const animationDelay = () => {
      if(_delay !== "no") {
        setTimeout(() => {
          iconref.current?.playFromBeginning();
        }, _delay);
      } else {
        iconref.current?.playFromBeginning();
      }
    };
    
  return (
    <Player ref={iconref} icon={iconSrc} colorize={_color ? '#00547B' : null} size={_iconSize} onComplete={animationDelay}/>
  )
}

export default AnimationIcon