import * as geohash from "ngeohash";
import * as turf from "@turf/turf";

const calculateGeohashes = async (searchAreas) => {
    const HASH_PRECISION = 6;
  
    let geometry;
    let geohash = [];

    for(const area of searchAreas) {
        if (area.type === "polygon") {
            const areaCoordinates = area.coordinates.map((point) => [point.lng, point.lat]);

            geometry = {
                type: "Polygon",
                coordinates: [
                [
                    ...areaCoordinates,
                    [area.coordinates[0].lng, area.coordinates[0].lat], // Closing the polygon
                ]
                ],
            };
        }

        if(area.type === "circle") {
            // center coordinates of the circle
            const centerLng = area.center.lng; // Longitude
            const centerLat = area.center.lat; // Latitude

                // radius of the circle in kilometers
                const radius = area.radius / 1000; // for 500 meters, use 0.5 km

                // create the circle geometry
                geometry = turf.circle([centerLng, centerLat], radius, {
                units: 'kilometers',
                steps: 64, // number of sides (higher means smoother circle)
                });
        }

        // calculate the bounding box of the area
        try {
            turf.bbox(geometry);
            const bbox = turf.bbox(geometry);

            console.log("BBOX:", bbox);

            const involvedHashes = await generateGeohashesInBoundingBox(bbox, HASH_PRECISION);
            const intersectingHashes = await filterGeohashesByIntersection(involvedHashes, geometry);
            console.log("INTERSECTED HASHES:", intersectingHashes);

            geohash.push(...intersectingHashes);
        } catch (error) {
            console.log("ERROR CALCULATING BBOX:", error.message);
            continue;
        }
    }

    const geohashSet = new Set(geohash);
    return Array.from(geohashSet);
};

const generateGeohashesInBoundingBox = async (bbox, precision) => {
    // const {minLat, maxLat, minLng, maxLng} = bbox;

    const minLng = Math.min(bbox[0], bbox[2]);
    const minLat = Math.min(bbox[1], bbox[3]);
    const maxLng = Math.max(bbox[0], bbox[2]);
    const maxLat = Math.max(bbox[1], bbox[3]);
    const geohashes = new Set();

    const cellSizeLat = geohash.decode_bbox(geohash.encode(minLat, minLng, precision))[2] -
                        geohash.decode_bbox(geohash.encode(minLat, minLng, precision))[0];
    const cellSizeLng = geohash.decode_bbox(geohash.encode(minLat, minLng, precision))[3] -
                        geohash.decode_bbox(geohash.encode(minLat, minLng, precision))[1];

    for (let lat = minLat; lat <= maxLat; lat += cellSizeLat * 0.8) {
      for (let lng = minLng; lng <= maxLng; lng += cellSizeLng * 0.8) {
        const hash = geohash.encode(lat, lng, precision);
        geohashes.add(hash);
      }
    }

    console.log("GENERATED HASHES:", Array.from(geohashes));
    return Array.from(geohashes);
};

const filterGeohashesByIntersection = async (geohashes, areaGeometry) =>{
    const intersectingGeohashes = [];
  
    for(const hash of geohashes) {
      const [minLat, minLng, maxLat, maxLng] = geohash.decode_bbox(hash);
  
      // Create a GeoJSON polygon for the geohash cell
      const geohashCell = turf.polygon([
        [
          [minLng, minLat],
          [maxLng, minLat],
          [maxLng, maxLat],
          [minLng, maxLat],
          [minLng, minLat], // Closing the polygon
        ],
      ]);
  
      // Check for intersection
      if (turf.booleanIntersects(areaGeometry, geohashCell)) {
        intersectingGeohashes.push(hash);
      }
    }
  
    return intersectingGeohashes;
};

export default calculateGeohashes;
