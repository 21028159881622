import SquareButton from '../../Generics/SquareButton';
import { t } from 'i18next';
import AnimationIcon from '../../Generics/AnimationIcon';
import { useCreationContext } from '../../../contexts/CreationProvider';
import StageLabel from '../../Objects/Creation/StageLabel';
import { useEffect } from 'react';

const Urgency = ({next}) => {

    const { searchData, setSearchData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setSearchData({...searchData, urgency: ""})
    }, []);

    const handleUrgency = (newUrgency) => {
        setSearchData({...searchData, urgency: newUrgency});
        setTimeout(() => {
            next();
        }, "150");
    };

  return (
    <div className="flex flex-col justify-center items-center w-full lg:w-1/2 mt-10">
        <StageLabel headline={t('CreateSearch.UrgencyHeadline')} labelText={t('CreateSearch.UrgencyLabel')}/>
        <div className={`flex flex-row justify-center gap-2 items-center w-full mt-3 ssm:mt-0`}>
            <SquareButton dynamic={false} selected={searchData.urgency === "normal"} clickFunction={() => handleUrgency("normal")} icon={<AnimationIcon _delay='no' _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/turtle.json')}/>} label={t('CreateSearch.Gemütlich')}/>
            <SquareButton dynamic={false} selected={searchData.urgency === "urgent"} clickFunction={() => handleUrgency("urgent")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/searchCreation/turkey.json')}/>} label={t('CreateSearch.Eilig')}/>                
        </div>
    </div>
        
  )
}

export default Urgency
