import { MenuItem, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import StageLabel from './StageLabel'
import { t } from 'i18next'
import Button from '../../Generics/Button'
import { useCreationContext } from '../../../contexts/CreationProvider'
import SquareButton from '../../Generics/SquareButton'
import AnimationIcon from '../../Generics/AnimationIcon'
import { NextArrowDouble } from '../../../helper/icons'
import NavMenu from '../../Generics/NavMenu'

const Style = ({next, back}) => {

    const { objectData, setObjectData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleStyle = (newStyle) => {
        setObjectData({...objectData, building: newStyle});
        setTimeout(() => {
            next();
        }, "150");
    };

  return (
    <div className='flex flex-col'>
        <StageLabel labelText={t('CreateObject.StyleLabel')}/>
        <div className={`flex flex-row justify-center gap-3 items-center w-full mt-5`}>
            <SquareButton dynamic={false} selected={objectData.building === "new"} clickFunction={() => handleStyle("new")} icon={<AnimationIcon _color={false} _iconSize={80} iconSrc={require('../../../assets/animations/objectCreation/style_new.json')}/>} label={t('Facilities.isNewBuilding')}/>
            <SquareButton dynamic={false} selected={objectData.building === "old"} clickFunction={() => handleStyle("old")} icon={<AnimationIcon _color={false} _iconSize={80} iconSrc={require('../../../assets/animations/objectCreation/style_old.json')}/>} label={t('Facilities.isOldBuilding')}/>
        </div>

        <NavMenu _back={back} />
    </div>
  )
}

export default Style