import { Checkbox, FormControlLabel } from '@mui/material';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { t } from 'i18next';

const ObjectCheckBox = ({name, _color, isDisabled = false, _size = 1, _className = ''}) => {

    const { objectData, setObjectData } = useCreationContext();

  return (
    <FormControlLabel className={_className} control={<Checkbox style={{transform: `scale(${_size})`}} sx={{color: _color, '&.Mui-checked': {color: _color}}} disabled={isDisabled} disableRipple disableTouchRipple value={objectData[name]} checked={!isDisabled && objectData[name]} onChange={() => setObjectData({...objectData, [name]: !objectData[name]})}/>} label={t(`Facilities.${name}`)} />
  )
}

export default ObjectCheckBox