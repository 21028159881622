import { Timestamp } from 'firebase/firestore';
import { t } from 'i18next';
import { useRef, useState } from 'react';
import { usePopupContext } from '../../contexts/PopupProvider';
import { useSelectionContext } from '../../contexts/SelectionProvider';
import { getSearchesWithoutPolygon, updateFirestoreDoc } from '../../helper/functions';
import { AutoMode, BurgerMenu, DeleteIcon, DocumentIcon, Dot, DotOpen, LayoutIcon, Picture, Rainbow, Share, Star } from '../../helper/icons';
import useOutsideClick from '../../hooks/useOutsideClick';
import Confirm from '../Generics/Confirm';
import { Collapse } from '@mui/material';
import { deleteObject, ref } from 'firebase/storage';
import { auth, storage } from '../../firebase.config';
import { useStateContext } from '../../contexts/ContextProvider';
import { matchingAnalysis } from '../../helper/test';
import Analysis from './Analysis';

const ObjectMenu = ({ objectData, openPics, openExpose, openPlan }) => {
  const { setSelectedObjectID,  } = useSelectionContext();
  const { setObjectDetailsOpen } = usePopupContext();
  const { customer } = useStateContext();

  const [extended, setExtended] = useState(false); 
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [analysisOpen, setAnalysisOpen] = useState(false);
  const [exposeDeleteConfirm, setExposeDeleteConfirm] = useState(false);
  const [planDeleteConfirm, setPlanDeleteConfirm] = useState(false);
  const [deletingExpose, setDeletingExpose] = useState(false);
  const [deletingPlan, setDeletingPlan] = useState(false);

  const wrapperRef = useRef(null);
  
  useOutsideClick(wrapperRef, () => {
    setExtended(false);
  });

  const handleMenuClick = () => {
    setExtended(!extended);
  };

  const handleActivation = async () => {
    setExtended(false);
    await updateFirestoreDoc("objects", objectData?.id, {active: !objectData?.active});
  };

  const handleDeletion = async () => {
    setObjectDetailsOpen(false);
    setSelectedObjectID("");
    await updateFirestoreDoc("objects", objectData?.id, {deleted: true, active: false, deletionDate: Timestamp.fromDate(new Date()), matches: []});
  };

  const handlePicsOpen = () => {
    setExtended(false);
    openPics();
  };

  const handleExposeOpen = () => {
    setExtended(false);
    openExpose();
  };

  const handlePlanOpen = () => {
    setExtended(false);
    openPlan();
  };

  const handleExposeDelete = async () => {
    setDeletingExpose(true);
    const storageRef = ref(storage, `objects/${objectData?.id}/expose`);
    try {
      await deleteObject(storageRef);
      await updateFirestoreDoc("objects", objectData?.id, {exposeLink: ""});
      setDeletingExpose(false);
      setExposeDeleteConfirm(false);
    } catch (error) {
      console.log(error.message);
      setDeletingPlan(false);
      setPlanDeleteConfirm(false);
    }
  };

  const handlePlanDelete = async () => {
    setDeletingPlan(true);
    const storageRef = ref(storage, `objects/${objectData?.id}/plan`);
    try {
      await deleteObject(storageRef);
      await updateFirestoreDoc("objects", objectData?.id, {planLink: ""});
      setDeletingPlan(false);
      setPlanDeleteConfirm(false);
    } catch (error) {
      console.log(error.message);
      setDeletingPlan(false);
      setPlanDeleteConfirm(false);
    }
  };

  const handleShareable = async () => {
    setExtended(false);
    await updateFirestoreDoc("objects", objectData?.id, {shareable: objectData?.shareable ? false : true});
  };

  const handleMatchingAnalysis = async () => {
    setAnalysisOpen(true);
  };

  const handleWithoutPolygon = async () => {
    setExtended(false);
    const searches = await getSearchesWithoutPolygon();
    console.log("SEARCHES WITHOUT POLYGON:", searches.data);
  };

  const handleAutoMode = async () => {
    setExtended(false);
    await updateFirestoreDoc("objects", objectData?.id, {autoContact: objectData?.autoContact ? false : true});
  };

  const exposeSub = (
    <div className='flex flex-col gap-1 rounded-b bg-allimmoDark/10'>
      {objectData?.exposeLink?.trim() !== "" && 
      <div onClick={() => setExtended(false)} className='w-full'>
        <a target='_blank' href={objectData?.exposeLink} className='w-full px-2 cursor-pointer hover:bg-allimmoDark/10'>{t('ObjectDetails.Expose.ExposeAnsehen')}</a>
        <p onClick={() => setExposeDeleteConfirm(true)} className='px-2 cursor-pointer hover:bg-allimmoDark/10'>{t('ObjectDetails.Expose.ExposeLöschen')}</p>
      </div>}
      <p onClick={handleExposeOpen} className='px-2 cursor-pointer hover:bg-allimmoDark/10'>{t('ObjectDetails.Expose.NeuHochladen')}</p>
    </div>
  )

  const planSub = (
    <div className='flex flex-col gap-1 rounded-b bg-allimmoDark/10'>
      {(objectData?.planLink && objectData?.planLink?.trim() !== "") && 
      <div onClick={() => setExtended(false)} className='w-full'>
        <a target='_blank' href={objectData?.planLink} className='w-full px-2 cursor-pointer hover:bg-allimmoDark/10'>{t('ObjectDetails.Plan.PlanAnsehen')}</a>
        <p onClick={() => setPlanDeleteConfirm(true)} className='px-2 cursor-pointer hover:bg-allimmoDark/10'>{t('ObjectDetails.Plan.PlanLöschen')}</p>
      </div>}
      <p onClick={handlePlanOpen} className='px-2 cursor-pointer hover:bg-allimmoDark/10'>{t('ObjectDetails.Plan.NeuHochladen')}</p>
    </div>
  )

  return (
    <div className='w-full flex flex-row mb-1 items-center justify-between relative z-50'>
      <div className='flex flex-row items-center gap-1'>
        {objectData?.shareable && <Share size={18} className='text-allimmoDark/70' />}
        {(customer?.settings?.autoContact !== undefined && customer?.settings?.autoContact) && 
        <div className='flex flex-row gap-1 items-center text-purple-500 animate-pulse'>
          <AutoMode size={24} />
          <p className='text-xs'>{t("ObjectDetails.Menu.AutoKontakt")}</p>
        </div>}
      </div>
      <div className='flex flex-row items-center'>
      <Dot size={20} className={`${objectData?.active ? 'text-green-600' : 'text-red-500'}`}/>
      <BurgerMenu onClick={handleMenuClick} size={25} className={`cursor-pointer hover:text-allimmoDark/70 text-allimmoDark rounded ${extended ? 'bg-slate-200' : null}`}/>
      {extended &&
      <div ref={wrapperRef} className='flex flex-col bg-white p-1 absolute right-1 top-7 rounded shadow-2xl border border-allimmoDark'>
        <MenuItem _clickfunction={handlePicsOpen} _label={t('Upload.Fotos').toUpperCase()} _icon={<Picture size={25}/>} shortcut="STRG+P"/>
        {objectData.deal === "rent" && <MenuItem subDisabled={objectData?.exposeLink?.trim() === ""} subMenu={exposeSub} expandable _clickfunction={handleExposeOpen} _label={t('Upload.Expose').toUpperCase()} _icon={<DocumentIcon size={25}/>} shortcut="STRG+E"/>}
        {objectData.deal === "rent" && <MenuItem subDisabled={!objectData?.planLink || objectData?.planLink.trim() === ""} expandable subMenu={planSub} _clickfunction={handlePlanOpen} _label={t('ObjectDetails.Menu.Plan')} _icon={<LayoutIcon size={25}/>} shortcut="STRG+F"/>}
        {/* <MenuItem _label="TERMINE" _icon={<Calendar size={25}/>} shortcut="STRG+T"/> */}
        {/* {auth.currentUser.uid === "yQDBpPHJ7TXRwE3jnmnZmNZ0qhe2" && 
        <MenuItem _clickfunction={handleMatchingAnalysis} _label="MATCHING ANALYSE" _icon={<Rainbow size={25}/>} shortcut="STRG+C"/>}*/}
        {/* <MenuItem _label="UMGEBUNG" _icon={<MapMarker size={25}/>} shortcut="STRG+V"/> */}
        {/* <MenuItem _label="KARTE ZEIGEN" _icon={<OnMapIcon size={25}/>} shortcut="STRG+O"/> */}
        {/* <MenuItem _clickfunction={handleAutoMode} _label={(objectData?.autoContact !== undefined && objectData?.autoContact) ? "AUTO-KONTAKT DEAKTIVIEREN" : "AUTO-KONTAKT AKTIVIEREN"} _icon={<AutoMode size={25}/>} shortcut="STRG+K"/> */}
        <MenuItem _clickfunction={handleShareable} _label={objectData?.shareable ? t('ObjectDetails.Menu.Shareable').toUpperCase() : t('ObjectDetails.Menu.NotShareable').toUpperCase()} _icon={<Share size={25}/>} shortcut="STRG+O"/>
        <MenuItem missingParams={objectData?.missingParams} subDisabled={objectData?.missingParams?.length > 0} _clickfunction={handleActivation} _label={objectData?.active ? t('General.Deaktivieren').toUpperCase() : objectData?.missingParams > 0 ? t('General.AktivierenNichtMöglich').toUpperCase() : t('General.Aktivieren').toUpperCase()} _icon={<DotOpen size={25} />} shortcut="STRG+A" />
        <MenuItem _clickfunction={() => setDeleteConfirmOpen(true)} _label={t('General.Löschen').toUpperCase()} _icon={<DeleteIcon size={25}/>} shortcut="STRG+D"/>
        {/* <MenuItem _label="HILFE" _icon={<BsQuestionCircle size={25}/>} shortcut="STRG+H"/> */}
        {/* <MenuItem _clickfunction={handleWithoutPolygon} _label="FEEDBACK" _icon={<Star size={25}/>} shortcut="STRG+R"/> */}
      </div>}
      </div>
      <Analysis _open={analysisOpen} close={() => setAnalysisOpen(false)} object={objectData} />
      <Confirm _open={deleteConfirmOpen} confirm={handleDeletion} cancel={() => setDeleteConfirmOpen(false)} confirmBtnText={t('General.Löschen')} confirmText={t('Objects.ObjectDelete')}/>
      <Confirm loading={deletingExpose} _open={exposeDeleteConfirm} confirm={handleExposeDelete} cancel={() => setExposeDeleteConfirm(false)} confirmBtnText={t('General.Löschen')} confirmText={t('ObjectDetails.Expose.ExposeDeleteConfirmText')}/>
      <Confirm loading={deletingPlan} _open={planDeleteConfirm} confirm={handlePlanDelete} cancel={() => setPlanDeleteConfirm(false)} confirmBtnText={t('General.Löschen')} confirmText={t('ObjectDetails.Plan.PlanDeleteConfirmText')}/>
    </div>
  )
};

const MenuItem = ({_icon, _label, _clickfunction, shortcut, expandable = false, subMenu = null, subDisabled = false, missingParams = 0}) => {
  const { screenSize } = useStateContext();
  const [expanded, setExpanded] = useState(false);

  const handleSubMenuClick = () => {
    setExpanded(false);
  };

    return (
      <div className='flex flex-col'>
        <div onClick={expandable ? () => setExpanded(!expanded) : subDisabled ? null : _clickfunction} className={`flex flex-row gap-3 items-center cursor-pointer p-1.5 ${expanded ? 'bg-allimmoDark/30 rounded-t' : 'bg-white rounded'} hover:bg-allimmoDark/70 text-alliomoDark/70 hover:text-white`}>
            <div className={`${subDisabled ? "opacity-20" : ""}`}>{_icon}</div>
            <div className='flex flex-col'>
              <p className={`text-xs grow min-w-28 ${subDisabled ? "opacity-20" : ""}`}>{_label}</p>
              {missingParams.length > 0 && <p className='text-xs text-red-400 italic'>{`${t('MissingData.Label')} (${missingParams.length})`}</p>}
            </div>
            {/* {screenSize.width > 900 && <p className='text-xs w-20 text-end'>{shortcut}</p>} */}
        </div>
        {expandable && 
        <Collapse in={expanded}>
          <div onClick={handleSubMenuClick}>{subMenu}</div>
        </Collapse>}
      </div>
    )
};

export default ObjectMenu
