import { useEffect, useState } from 'react'
import { useCreationContext } from '../../../contexts/CreationProvider'
import { t } from 'i18next';
import Button from '../../Generics/Button';
import AnimationIcon from '../../Generics/AnimationIcon';
import { useStateContext } from '../../../contexts/ContextProvider';
import { Dialog } from '@mui/material';
import { Transition } from '../../../helper/functions';
import NavMenu from '../../Generics/NavMenu';

const Summary = ({back, _create, _reset}) => {

    const {objectData, resetObjectData } = useCreationContext();

    const [resetConfirmOpen, setResetConfirmOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClose = (event, reason) => {
        if (reason && event === "backdropClick") 
            return;
        setResetConfirmOpen(false);
    };

    const handleReset = () => {
        resetObjectData();
        setResetConfirmOpen(false);
        _reset();
    };

  return (
    <div className="flex flex-col justify-center items-center w-full px-2">
        <AnimationIcon iconSrc={require('../../../assets/animations/objectCreation/summary.json')} _iconSize={70} _color={false}/>
        <p className="text-allimmoDark text-lg text-center">{`"${objectData?.address.streetNumber}, ${objectData?.address.cityZip}" ${t('CreateObject.Fertig')}`}</p>
        <p className="text-red-500 italic mb-10 mt-5 text-center text-sm ssm:text-base w-full">{`${t('CreateObject.WasTun')}`}</p>
            
        <div className="flex flex-row gap-2">
            <SummaryButton delay={"1000"} clickFunction={() => _create(true)} label={"Objekt Aktivieren"} icon={require(`../../../assets/animations/objectCreation/saveandactivate.json`)}/>
        </div>

        <NavMenu _back={back} />
        <p className="italic text-sm hover:underline cursor-pointer mt-3 opacity-80" onClick={() => setResetConfirmOpen(true)}>{t('CreateObject.Zurücksetzen')}</p>
    
        <Dialog onClose={(e, r) => handleClose(e,r)} open={resetConfirmOpen} fullWidth={false} TransitionComponent={Transition} className='select-none'>
            <div className='flex flex-col justify-center items-center py-5 px-8'>
                <p className='text-allimmoDark'>{t('CreateObject.ResetLabel')}</p>
                <p className='mb-2 text-red-600 italic text-sm'>{t('CreateObject.ResetSub')}</p>
                <div className='w-full flex flex-row justify-center items-center gap-2 mt-5'>
                    <Button text={t('General.Abbrechen')} clickFunction={() => setResetConfirmOpen(false)}/>
                    <Button text={t('General.Zurücksetzen')} clickFunction={handleReset}/>
                </div>
            </div>
        </Dialog>
    </div>
  )
};

const SummaryButton = ({label, icon, clickFunction}) => {

    const { screenSize } = useStateContext();

    return (
        <div onClick={clickFunction} className={`flex flex-col hover:bg-blue-100 bg-white hover:shadow-lg cursor-pointer justify-center items-center border-2 border-allimmoDark p-1 aspect-square ${screenSize.width > 768 ? "h-36" : screenSize.width > 355 ? "h-32" : "h-26"} rounded-xl`}>
            <AnimationIcon _color={false} _iconSize={70} iconSrc={icon}/>
            <p className='text-allimmoDark w-full text-center text-12 ssm:text-sm md:text-base'>{label}</p>
        </div>
    )
};

export default Summary