import { t } from 'i18next';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from './StageLabel';
import { useCreationContext } from '../../../contexts/CreationProvider';
import AnimationIcon from '../../Generics/AnimationIcon';
import FacilityBox from './FacilityBox';

const LandProperties = ({back, next}) => {
  
  const { objectData } = useCreationContext();

  return (
    <div className='w-full ssm:w-3/4 md:w-1/2 flex flex-col justify-center items-center ssm:py-5 gap-2'>
        <StageLabel color={false} headline={t('CreateObject.LandPropsHeadline')} labelText={t('CreateObject.LandPropsLabel')}/>
        <div className='grid grid-cols-3 sm:grid-cols-3 gap-1 sm:gap-2'>
            <ClassificationBox boxHeight='h-26 ssm:h-30 md:h-32' name="buildingSite"/>
            <FacilityBox boxHeight='h-26 ssm:h-30 md:h-32' name="developed" disabled={!objectData?.landClassification.buildingSite} />
            <FacilityBox boxHeight='h-26 ssm:h-30 md:h-32' name="oldGrowth" disabled={!objectData?.landClassification.buildingSite} />
            <ClassificationBox boxHeight='h-26 ssm:h-30 md:h-32' name="green"/>
            <ClassificationBox boxHeight='h-26 ssm:h-30 md:h-32' name="protected"/>
            <ClassificationBox boxHeight='h-26 ssm:h-30 md:h-32' name="special"/>
        </div>
        <NavMenu _back={back} nextBtn _next={next}/>
    </div>
  )
};

const ClassificationBox = ({name, size = 60, boxHeight = "h-32"}) => {

  const { objectData, setObjectData } = useCreationContext();

  return (
    <div onClick={() => setObjectData({...objectData, landClassification: {...objectData.landClassification, [name]: !objectData?.landClassification[name]}})} className={`flex flex-col ${objectData?.landClassification[name] ? `opacity-100 bg-blue-200 hover:bg-blue-100` : 'opacity-30 hover:bg-blue-50'} bg-white hover:shadow-xl cursor-pointer justify-center items-center border-2 border-allimmoDark p-1 aspect-square ${boxHeight} rounded-xl`}>
        <AnimationIcon animate={objectData?.landClassification[name]} _color={false} _iconSize={size} iconSrc={require(`../../../assets/animations/objectCreation/facilities/${name}.json`)}/>
        <p className='text-allimmoDark w-full text-center text-12 ssm:text-sm md:text-base'>{t(`Facilities.${name}`)}</p>
    </div>
  )
};

export default LandProperties
