import { Checkbox, Collapse, FormControlLabel, InputAdornment, MenuItem, TextField } from '@mui/material';
import { t } from 'i18next';
import { useEffect } from 'react';
import validator from 'validator';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useCreationContext } from '../../../contexts/CreationProvider';
import AnimationIcon from '../../Generics/AnimationIcon';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from './StageLabel';

const Price = ({back, next}) => {

    const { objectData, setObjectData, resetZeroInput, setObjectComissionContractFile, objectComissionContractFile } = useCreationContext();
    const { customer } = useStateContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  const changeContractType = (newType) => {
    setObjectComissionContractFile(null);
    setObjectData({...objectData, comissionContract: newType});
  };

  const disableNext = (["", undefined, "undefined", null, NaN, "NaN"].includes(objectData?.price) || parseFloat(objectData?.price) === 0 || objectData?.price.length > 10) || 
                        (parseFloat(objectData?.comission) > 0 && (objectData?.comissionContract === "link" && !validator.isURL(objectData?.comissionContractLink, {protocols: ['https'], require_protocol: true}) || (objectData?.comissionContract === "object" && objectComissionContractFile === null)));
    
  return (
    <div className='w-full ssm:w-3/4 md:w-1/2 flex flex-col justify-center items-center ssm:py-5 gap-2'>
        <StageLabel color={false} stageIcon={require('../../../assets/animations/objectCreation/price.json')} labelText={t('CreateObject.PriceLabel')}/>
          <TextField
            required
            onClick={() => resetZeroInput("price")}
            type='number'
            label={objectData?.deal === "rent" ? objectData.type === "commercial" ? t('CreateObject.Price.Nettomiete') : t('CreateObject.Gesamtkosten') : objectData?.deal === "sale" ? t("Deals.price") : t("Deals.lease")}
            value={objectData?.price}
            onChange={(e) => setObjectData({...objectData, price: e.target.value})}
            size='medium'
            className='w-full bg-white rounded'
            autoComplete='off'
            InputProps={{endAdornment: <InputAdornment disableTypography position="end">€</InputAdornment>, inputProps: {min: 0}}}
          />

        {(objectData?.type === "commercial" || (objectData?.deal === "sale" && objectData?.type !== "land")) &&
        <TextField
          type='number'
          onClick={() => resetZeroInput("utilityBill")}
          label={objectData?.deal === "sale" ? objectData?.type === "multi" ?  t('General.Betriebskosten') : t('CreateObject.BetriebskostenMitReperatur') : t('CreateObject.Betriebskosten')}
          value={objectData?.utilityBill}
          onChange={(e) => setObjectData({...objectData, utilityBill: e.target.value})}
          size='medium'
          className='w-full bg-white rounded'
          autoComplete='off'
          InputProps={{endAdornment: <InputAdornment disableTypography position="end">€</InputAdornment>, inputProps: {min: 0}}}
        />}

        <div className='w-full flex flex-row gap-2'>
        {objectData?.deal === "rent" &&
        <TextField
          type='number'
          onClick={() => resetZeroInput("deposit")}
          label={t("General.Kaution")}
          value={objectData?.deposit}
          onChange={(e) => setObjectData({...objectData, deposit: e.target.value})}
          size='medium'
          className='w-full bg-white rounded'
          autoComplete='off'
          InputProps={{endAdornment: <InputAdornment disableTypography position="end">€</InputAdornment>}}
        />}

        {(objectData?.type !== "land" && objectData?.deal !== "sale") &&
        <TextField
          type='number'
          label={t("CreateObject.Ablöse")}
          onClick={() => resetZeroInput("compensation")}
          value={objectData?.compensation}
          onChange={(e) => setObjectData({...objectData, compensation: parseFloat(e.target.value)})}
          size='medium'
          className='w-full bg-white rounded'
          autoComplete='off'
          InputProps={{endAdornment: <InputAdornment disableTypography position="end">€</InputAdornment>}}
        />}
        </div>

        <div className='w-full flex flex-col'>
          <div className='w-full flex flex-row gap-2'>
            <TextField className='w-20 bg-white rounded' select size='medium' value={objectData?.comissionUnit} onChange={(e) => setObjectData({...objectData, comissionUnit: e.target.value})}>
                <MenuItem value="€">€</MenuItem>
                <MenuItem value="%">%</MenuItem>
            </TextField>

            <TextField
              type='number'
              label={t("CreateObject.Provision")}
              onClick={() => resetZeroInput("comission")}
              value={objectData?.comission}
              onChange={(e) => setObjectData({...objectData, comission: e.target.value})}
              size='medium'
              className='w-full bg-white rounded'
              autoComplete='off'
            />
          </div>
            <Collapse className='mt-5' in={parseFloat(objectData?.comission) > 0}>
              <div className='w-full flex flex-col'>
                <p>{t('CreateObject.WelcheVereinbarung')}</p>
                <FormControlLabel value={objectData?.comissionContract} checked={objectData?.comissionContract === "link"} onChange={() => changeContractType("link")} control={<Checkbox />} label={t('CreateObject.LinkVerwenden')} />
                {objectData?.comissionContract === "link" && <TextField autoComplete='off' label={t('CreateObject.EigenerLink')} value={objectData?.comissionContractLink} size='small' className='w-full bg-white rounded' onChange={(e) => setObjectData({...objectData, comissionContractLink: e.target.value})}/>}
                {customer.comissionContractLink !== "" && 
                <div className='flex flex-row items-center space-x-3 w-full'>
                    <FormControlLabel value={objectData?.comissionContract} checked={objectData?.comissionContract === "default"} onChange={() => changeContractType("default")} control={<Checkbox />} label={t('CreateObject.StandardVereinbarung')} />
                    <a target='_blank' href={customer.comissionContractLink} className='grow text-end hover:underline cursor-pointer text-allimmoDark/50 active:bg-allimmoDark/50 hover:text-allimmoDark'>{t('General.Ansehen')}</a>
                </div>}
                <FormControlLabel value={objectData?.comissionContract === "object"} checked={objectData?.comissionContract === "object"} onChange={() => setObjectData({...objectData, comissionContract: "object"})} control={<Checkbox />} label={t('CreateObject.IndividuelleVereinbarung')} />
                <Collapse in={objectData?.comissionContract === "object"}>
                    <FileUploader selectLabel={t('FileUploader.DateiAuswählen')} uploadLabel={t('FileUploader.DateiHochladen')} acceptedFileTypes=".pdf, .doc, .docx"/>
                </Collapse>
              </div>
            </Collapse>
        </div>

        <NavMenu _back={back} nextBtn _next={next} nextDisabled={disableNext}/>
    </div>
  )
};

const FileUploader = ({selectLabel, acceptedFileTypes, _type = "file"}) => {

  const { screenSize } = useStateContext();
  const { setObjectComissionContractFile, objectComissionContractFile } = useCreationContext();

  return (
    <div className="flex-flex-col justify-center items-center w-full">
      <Collapse className="w-full flex flex-col justify-center items-center" in={objectComissionContractFile !== null}>
          <div className=" w-full bg-white py-2 pl-2 pr-1 rounded border border-orange-500 border-dashed flex flex-row justify-center items-center gap-1.5">
            <AnimationIcon animate _color={false} _iconSize={40} iconSrc={require(`../../../assets/animations/objectCreation/doc.json`)}/>
            {objectComissionContractFile !== null && (
              <div className="flex flex-col justify-center grow">
                <p className="text-allimmoDark grow"><a target='_blank' href={URL.createObjectURL(objectComissionContractFile)}>
                  {objectComissionContractFile?.name.substring(
                    0,
                    screenSize.width < 390
                      ? 20
                      : screenSize.width < 390
                      ? 25
                      : 30
                  ) + "..."}</a>
                </p>
                <p className="text-orange-500 italic -mt-1 text-sm">
                  {(objectComissionContractFile?.size / 1024 ** 2).toFixed(2)} MB
                </p>
              </div>
            )}
            <p
              className="text-2xl text-red-400 cursor-pointer hover:text-red-300 active:text-red-400 w-6 text-center"
              onClick={() => setObjectComissionContractFile(null)}
            >
              x
            </p>
          </div>
      </Collapse>
      <div className="flex h-12 flex-row items-center justify-center w-full gap-1 mt-1">
        <input
          id="upload"
          hidden
          onChange={(e) => setObjectComissionContractFile(e.target?.files[0])}
          type={_type}
          accept={acceptedFileTypes}
        />
        <label
          className="w-full flex h-12 flex-row items-center gap-1 rounded bg-white border-allimmoDark border p-2 cursor-pointer hover:bg-slate-100"
          htmlFor="upload">
            <p className="text-center w-full text-allimmoDark ssm:text-lg">{selectLabel}</p>
        </label>
      </div>
    </div>
  )
}

export default Price