import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useStateContext } from '../../../contexts/ContextProvider'
import { useCreationContext } from '../../../contexts/CreationProvider'
import AnimationIcon from '../../Generics/AnimationIcon'
import NavMenu from '../../Generics/NavMenu'
import SquareButton from '../../Generics/SquareButton'
import StageLabel from './StageLabel'

const Furnished = ({next, back}) => {

    const { screenSize } = useStateContext();
    const { objectData, setObjectData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleFurniture = (newOption) => {
        setObjectData({...objectData, furnished: newOption});
        setTimeout(() => {
            next();
        }, "150");
    };

  return (
    <div className="flex flex-col justify-center items-center w-full lg:w-1/2 mt-10">
        <StageLabel  headline={t('CreateObject.FurnishedLabel')} labelText={t('CreateObject.FurnishedKitchenInfo')}/>
        <div className="grid grid-cols-2 ssm:grid-cols-3 mt-6 gap-2">
            <SquareButton dynamic selected={objectData.furnished === "-"} clickFunction={() => handleFurniture("-")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/notFurnished.json')}/>} label={t('Facilities.notfurnished')}/>
            <SquareButton dynamic selected={objectData.furnished === "furnished"} clickFunction={() => handleFurniture("furnished")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/furnished.json')}/>} label={t('Facilities.furnished')}/>
            <div className="col-span-2 ssm:col-span-1 place-self-center">
                <SquareButton dynamic selected={objectData.furnished === "partFurnished"} clickFunction={() => handleFurniture("partFurnished")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/partFurnished.json')}/>} label={screenSize.width > 500 ? t('Facilities.partFurnished') : t('Facilities.partFurnishedShort')}/>
            </div>
        </div>

        <NavMenu _back={back} />
    </div>
  )
};

export default Furnished
