import { CircularProgress } from "@mui/material";
import { Timestamp, arrayRemove, arrayUnion } from "firebase/firestore";
import { t } from "i18next";
import { useRef, useState } from "react";
import { updateFirestoreDoc } from "../../helper/functions";
import { Checkmark, CloseX, Dot, EditPen, Update } from "../../helper/icons";
import useOutsideClick from "../../hooks/useOutsideClick";
import { auth } from "../../firebase.config";

const CommercialInfo = ({_objectData, editable = true}) => {
  return (
    <div className={`bg-white rounded flex flex-col w-full pb-2 border z-0 border-allimmoDark/70`}>
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 px-1.5 py-0.5 rounded-t">
        <p>{t('ObjectDetails.CommercialInfo.Header')}</p>
      </div>

      <div className="flex flex-col px-2">
        <CommercialTypeProperty _editable={editable} objectData={_objectData} />
        <PresentIndustryProperty _editable={editable} objectData={_objectData} />
        <BooleanProperty _editable={editable} property="activeBusiness" objectData={_objectData} />
        <BooleanProperty _editable={editable} property="industrialLicense" objectData={_objectData} />
        <CommercialVacancyProperty _editable={true} objectData={_objectData} />
        <LicensingProperty _editable={editable} property="licensing" objectData={_objectData} />
      </div>
    </div>
  )
};

const PresentIndustryProperty = ({objectData, _editable = true}) => {

    const [editMode, setEditMode] = useState(false);
    const [newValue, setNewValue] = useState(objectData?.presentIndustry);
    const [newValueOther, setNewValueOther] = useState(objectData?.presentIndustryOther);
    const [isSaving, setIsSaving] = useState(false);
  
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
      setNewValue(objectData?.presentIndustry);
      setEditMode(false);
      setNewValueOther("");
    });
  
    const handleSave = async () => {
      if(!_editable) return;
      if(objectData?.customer !== auth.currentUser.uid) return;

      if(newValue === objectData?.presentIndustry && newValue !== "Sonstiges") {
        setEditMode(false);
        setNewValueOther("");
      } else {
        setIsSaving(true);
        await updateFirestoreDoc("objects", objectData?.id, {
            presentIndustry: newValue, 
            presentIndustryOther: newValue === "Sonstiges" ? newValueOther : "", 
            edited: Timestamp.fromDate(new Date()), 
            editedData: arrayUnion({
                updateID: objectData?.editedData ? objectData?.editedData.length + 1 : 1, 
                propertyName: "presentIndustry", 
                oldValue: objectData?.presentIndustry, 
                newValue: newValue === "Sonstiges" ? newValueOther : newValue,
                created: Timestamp.fromDate(new Date()),
            })
        })
        .then(() => {
          setIsSaving(false);
          setEditMode(false);
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
          setIsSaving(false);
        });
      }
    };
  
    return (
      <div className="flex flex-col gap-1">
        <div className="flex flex-row justify-between">
            <p>{t('ObjectDetails.CommercialInfo.PresentIndustry')}</p>
            <div ref={wrapperRef}>
                {!editMode && <p className={`${_editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}`} onClick={() => _editable ? setEditMode(true) : null}>{objectData?.presentIndustry !== "Sonstiges" ? t(`CreateObject.Industries.${objectData?.presentIndustry}`) : objectData?.presentIndustryOther}</p>}
                {(editMode && _editable) &&
                <div className="flex flex-row items-center gap-1">
                    {!isSaving && 
                    <Checkmark onClick={handleSave} size={15} className="cursor-pointer text-green-600 hover:text-green-800"/>}
                    <select autoFocus={editMode} className="px-1.5 border h-6 rounded border-black w-44" value={newValue} onChange={(e) => setNewValue(e.target.value)}>
                        <option value="Leer">{t('CreateObject.Industries.Leer')}</option>
                        <option value="Gastro">{t('CreateObject.Industries.Gastro')}</option>
                        <option value="Büro">{t('CreateObject.Industries.Büro')}</option>
                        <option value="Handel">{t('CreateObject.Industries.Handel')}</option>
                        <option value="Hotel">{t('CreateObject.Industries.Hotel')}</option>
                        <option value="Logistik">{t('CreateObject.Industries.Logistik')}</option>
                        <option value="Produktion">{t('CreateObject.Industries.Produktion')}</option>
                        <option value="Landwirtschaft">{t('CreateObject.Industries.Landwirtschaft')}</option>
                        <option value="Storage">{t('CreateObject.Industries.Storage')}</option>
                        {/* <option value="Sonstiges">{t('CreateObject.Industries.Sonstiges')}</option> */}
                    </select>
                </div>}
            </div>
        </div>

        {/* {newValue === "Sonstiges" && 
        <div className="flex flex-row items-center justify-between">
            <p>{t('ObjectDetails.CommercialInfo.GewerbeName')}</p>
            <div className="flex flex-row gap-1 items-center">
                {(!isSaving && newValueOther.trim() !== "") && 
                <Checkmark onClick={handleSave} size={15} className="cursor-pointer text-green-600 hover:text-green-800"/>}
                <input className="px-1 rounded text-right" autoFocus={newValue === "Sonstiges"} value={newValueOther} onChange={(e) => setNewValueOther(e.target.value)}/>
            </div>
        </div>} */}
    </div>
    )
};

const BooleanProperty = ({property, objectData, _editable}) => {

    const [isSaving, setIsSaving] = useState(false);
    
    const handleChange = async () => {
      if(!_editable) return;
      if(objectData?.customer !== auth.currentUser.uid) return;

      setIsSaving(true);
      await updateFirestoreDoc("objects", objectData?.id, {[property]: !objectData[property], edited: Timestamp.fromDate(new Date()), editedData: arrayUnion({
        updateID: objectData.editedData ? objectData.editedData.length + 1 : 1, 
        propertyName: property, 
        oldValue: objectData[property], 
        newValue: !objectData[property],
        created: Timestamp.fromDate(new Date()),
      })})
      .then(() => {
        setIsSaving(false);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        setIsSaving(false);
      });
    };
  
    return (
      <div className="flex flex-row justify-between">
        <p>{t(`Facilities.${property}`)}</p>
        {!isSaving && 
        <div className="flex flex-row gap-1 items-center">
          {_editable && <Update size={12} className="text-allimmoDark/30" />}
          <p className={`${_editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}`} onClick={_editable ? handleChange : null}>{objectData[property] ? t('General.Ja') : t('General.Nein')}</p>
        </div>}
        {isSaving && <CircularProgress size={15} />}
      </div>
    )
};

const LicensingProperty = ({property, objectData, _editable = true}) => {

    const [editMode, setEditMode] = useState(false);

    return (
        <div className="flex flex-col gap-1 border-t mt-2 pt-1">
            <div className="flex flex-row justify-between items-center">
                <p className="">{t('ObjectDetails.CommercialInfo.IndustrialLicenses')}</p>
                {(!editMode && _editable) && <EditPen onClick={() => setEditMode(true)} size={14} className="cursor-pointer text-allimmoDark/60 hover:text-allimmoDark/30"/>}
                {(editMode && _editable) && <CloseX onClick={() => setEditMode(false)} size={20} className="cursor-pointer text-red-600 hover:bg-red-200 rounded" />}
            </div>
            {!editMode &&
            <div className="pl-3">
                {objectData[property]?.map((item, index) => {
                    return (
                        <div className="flex flex-row gap-2 items-center" key={index}>
                            <Dot size={10} className="text-allimmoDark/80"/>
                            <p>{!item?.custom ? t(`CreateObject.Industries.${item?.name}`) : item?.name}</p>
                        </div>
                    )
                })}
            </div>}
            {editMode &&
            <div className="grid grid-cols-1 ssm:grid-cols-2 pr-3">
                <IndutryEdit industry="Gastro" _objectData={objectData}/>
                <IndutryEdit industry="Handel" _objectData={objectData}/>
                <IndutryEdit industry="Storage" _objectData={objectData}/>
                <IndutryEdit industry="Office" _objectData={objectData}/>
                <IndutryEdit industry="Hotel" _objectData={objectData}/>
                <IndutryEdit industry="Produktion" _objectData={objectData}/>
                <IndutryEdit industry="Logistik" _objectData={objectData}/>
                <IndutryEdit industry="Landwirtschaft" _objectData={objectData}/>
                {objectData?.licensing.filter(e => e.custom === true).map((item, index) => {
                    return (
                        <IndutryEdit key={index} industry={item?.name} _objectData={objectData}/>
                    )
                })}
                
            </div>}
            {editMode && <IndustryAdd _objectData={objectData}/>}
        </div>
    )
};

const IndutryEdit = ({industry, _objectData}) => {
    const hasLicense = _objectData?.licensing.some(el => el.name === industry);
    const isCustom = _objectData?.licensing.some(el => el.name === industry && el.custom === true);

    const handleChange = async (forced = false) => {
        if (isCustom && !forced) return;
        await updateFirestoreDoc("objects", _objectData?.id, {
            licensing: hasLicense ? _objectData?.licensing.filter(el => el.name !== industry) : arrayUnion({ name: industry, custom: false}),
            edited: Timestamp.fromDate(new Date()), 
            editedData: arrayUnion({
                updateID: _objectData?.editedData ? _objectData?.editedData.length + 1 : 1, 
                propertyName: "licensing",
                oldValue: {name: industry, custom: isCustom}, 
                newValue: false,
                created: Timestamp.fromDate(new Date()),
            })
        });
    };
  
      return (
        <div className={`flex flex-row w-full items-center gap-2`} onClick={() => handleChange()}>
          <input type="checkbox" checked={hasLicense} onClick={() => handleChange(true)}/>
          <p>{isCustom ? industry : t(`CreateObject.Industries.${industry}`)}</p>
        </div>
      )
};

const IndustryAdd = ({_objectData}) => {

    const [newValue, setNewValue] = useState("");
    const [isChecked, setIsChecked] = useState(false);

    const handleAdd = async () => {
        if (newValue.trim() === "") return 
        await updateFirestoreDoc("objects", _objectData?.id, {
            licensing: arrayUnion({ 
                name: newValue, 
                custom: true
            }),
            edited: Timestamp.fromDate(new Date()), 
            editedData: arrayUnion({
                updateID: _objectData?.editedData ? _objectData?.editedData.length + 1 : 1, 
                propertyName: "licensing",
                oldValue: false, 
                newValue: {name: newValue, custom: true},
                created: Timestamp.fromDate(new Date()),
            })
        })
        .then(() => {
            setNewValue("");
            setIsChecked(false);
        });
    };

    const handleEnterPress = (event) => {
        if (event.keyCode === 13 || event.which === 13) {
          handleAdd();
        }
    };
  
      return (
        <div className={`flex flex-row items-center gap-2 mt-4`}>
            <input type="checkbox" checked={isChecked} value={isChecked} onClick={() => setIsChecked(!isChecked)}/>
            {!isChecked && <p className="cursor-pointer" onClick={() => setIsChecked(true)}>{t('ObjectDetails.CommercialInfo.SonstigeBerechtigung')}</p>}
            {isChecked && <input onKeyDown={(e) => handleEnterPress(e)} className="pl-1" autoFocus={isChecked} placeholder={t('ObjectDetails.CommercialInfo.SonstigeBerechtigung')} value={newValue} onChange={(e) => setNewValue(e.target.value)} />}
            {(isChecked && newValue.trim().length > 0) && <Checkmark onClick={handleAdd} size={20} className="cursor-pointer text-green-600 hover:text-green-800"/>}
        </div>
      )
};

const CommercialTypeProperty = ({objectData, _editable = true}) => {
    return (
      <div className={`flex flex-col gap-1`}>
          <div className="flex flex-row justify-between">
              <p>{t('ObjectDetails.CommercialInfo.CommercialType')}</p>
              <p>{t(`ObjectDetails.CommercialTypes.${objectData?.commercialType}`)}</p>
          </div>
      </div>
    )
};

const CommercialVacancyProperty = ({objectData, _editable = true}) => {

  const [editMode, setEditMode] = useState(false);
  const [newValue, setNewValue] = useState(objectData?.commercialVacancy);

  const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, () => {
      setNewValue(objectData?.presentIndustry);
      setEditMode(false);
  });

  const handleChange = async () => {
    if(!_editable) return;
    if(objectData?.customer !== auth.currentUser.uid) return;
    if (newValue.trim().length === 0) return;
    if(newValue === objectData?.commercialVacancy) return;

    await updateFirestoreDoc("objects", objectData?.id, {
        commercialVacancy: newValue,
        edited: Timestamp.fromDate(new Date()), 
        editedData: arrayUnion({
            updateID: objectData?.editedData ? objectData?.editedData.length + 1 : 1, 
            propertyName: "commercialVacancy",
            oldValue: objectData?.commercialVacancy, 
            newValue: newValue,
            created: Timestamp.fromDate(new Date()),
        })
    })
    .then(() => {
      setEditMode(false);
    });
  };

  const handleEnterPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      handleChange();
    }
  };

  return (
    <div className={`flex flex-col gap-1 ${(objectData?.type === "commercial" && ["hall", "building"].includes(objectData?.commercialType) && objectData?.deal !== "rent") ? 'block' : 'hidden'}`}>
      <div className={`flex flex-row justify-between`}>
        <p className="grow">{t('General.Leerstandsquote')}</p>
        {!editMode &&
        <p onClick={() => _editable ? setEditMode(true) : null} className={`${_editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}`}>{objectData?.commercialVacancy}%</p>}
        {(editMode && _editable) &&
        <div ref={wrapperRef} className="flex flex-row items-center gap-1">
          <Checkmark onClick={_editable ? handleChange : null} size={20} className="cursor-pointer text-green-600 hover:text-green-800"/>
          <input onKeyDown={(e) => handleEnterPress(e)} className="border rounded text-right pr-1" value={newValue} onChange={(e) => setNewValue(e.target.value)} autoFocus={editMode}/>
        </div>}
      </div>
    </div>
  )
};

export default CommercialInfo
