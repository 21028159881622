import { Gastro, Hotel, Landwirtschaft, Logistik, Production, ShoppingBag, Office, Storage, GasHeating, FernHeating, ElectricHeating, PelletsHeating, AirHeating, OilHeating, OtherHeatingIcon } from "./icons";

    export const industries = [
        {name: "Handel", icon: <ShoppingBag className='text-orange-500 opacity-80' size={20} />},
        {name: "Office", icon: <Office className='text-orange-500 opacity-80' size={20} />},
        {name: "Gastro", icon: <Gastro className='text-orange-500 opacity-80' size={20} />},
        {name: "Hotel", icon: <Hotel className='text-orange-500 opacity-80' size={20} />},
        {name: "Logistik", icon: <Logistik className='text-orange-500 opacity-80' size={20} />},
        {name: "Produktion", icon: <Production className='text-orange-500 opacity-80' size={20} />},
        {name: "Landwirtschaft", icon: <Landwirtschaft className='text-orange-500 opacity-80' size={20} />},
        {name: "Storage", icon: <Storage className='text-orange-500 opacity-80' size={20} />},
    ];

    export const facilities = [
        {name: "airCondition", usage: ["apartment", "house", "commercial"], deal: "all"},
        {name: "alarm", usage: ["apartment", "house", "commercial"], deal: "all"},
        {name: "bathtub", usage: ["apartment", "house", "commercial"], deal: "all"},
        {name: "bike", usage: ["apartment", "commercial", "multi"], deal: "all"},
        {name: "buildigSite", usage: ["land"], deal: "all"},
        {name: "door", usage: ["apartment", "house"], deal: "all"},
        {name: "alarm", usage: ["apartment", "house", "commercial"], deal: "all"},
        {name: "blinds", usage: ["apartment", "house", "commercial", "multi"], deal: "all"},
        {name: "camera", usage: ["apartment", "house", "commercial"], deal: "all"},
        {name: "cellar", usage: ["apartment", "house", "commercial", "multi"], deal: "all"},
        {name: "chimney", usage: ["apartment", "house", "commercial"], deal: "all"},
        {name: "cooling", usage: ["commercial"], deal: "all"},
        {name: "developed", usage: ["land"], deal: "all"},
        {name: "noOldGrowth", usage: ["land"], deal: "all"},
        {name: "elevator", usage: ["apartment", "multi", "commercial"], deal: "all"},
        {name: "fitness", usage: ["apartment", "multi", "commercial"], deal: "all"},
        {name: "garage", usage: ["apartment", "house", "commercial", "multi"], deal: "all"},
        {name: "kitchen", usage: ["apartment", "house", "commercial"], deal: "all"},
        {name: "laundry", usage: ["apartment", "multi", "commercial"], deal: "all"},
        {name: "pool", usage: ["apartment", "house", "multi"], deal: "all"},
        {name: "storageRoom", usage: ["apartment", "commercial"], deal: "all"},
        {name: "cooling", usage: ["commercial"], deal: "all"},
        {name: "floorHeating", usage: ["apartment", "commercial", "house", "multi"], deal: "all"},
        {name: "buildingSite", usage: ["land"], deal: "all"},
        {name: "sauna", usage: ["apartment", "house", "multi"], deal: "all"},
        {name: "pets", usage: ["apartment", "house", "multi"], deal: "rent"},
        {name: "solar", usage: ["apartment", "house", "multi", "commercial"], deal: "all"},
        {name: "shortTermRent", usage: ["apartment", "house", "multi"], deal: "all"},
    ];

    export const multiHeatingOptions = [
        {name: "gas", icon: <GasHeating size={28} />},
        {name: "fern", icon: <FernHeating size={28} />},
        {name: "electric", icon: <ElectricHeating size={28} />},
        {name: "pellets", icon: <PelletsHeating size={28} />},
        {name: "pump", icon: <AirHeating size={28} />},
        {name: "oil", icon: <OilHeating size={28} />},
        {name: "otherHeating", icon: <OtherHeatingIcon size={28} />}
    ];

    export const allimmoObjectDataTypes = {
        accessible: "bool",
        activeBusiness: "bool",
        airCondition: "facility",
        alarm: "facility",
        attic: "bool",
        available: "bool",
        availableFrom: "string",
        bathtub: "facility",
        bike: "facility",
        blinds: "facility",
        building: ["old", "new"],
        buildingSite: "bool",
        camera: "facility",
        cellar: "facility",
        chimney: "facility",
        cityZip: "string",
        comission: "string",
        condition: ["first", "new", "renovation"],
        deal: ["rent", "sale", "lease"],
        deposit: "string",
        developed: "bool",
        door: "facility",
        elevator: "facility",
        energyPass: "facility",
        fitness: "facility",
        flatshareCapable: "bool",
        floor: "string",
        floorHeating: "facility",
        floors: "string",
        furnished: ["furnished", "partFurnished", "-"],
        garage: "facility",
        garagePrice: "string",
        groundFloor: "bool",
        heating: ["gas", "fern", "electric", "pellets", "pump", "oil", "otherHeating"],
        houseType: ["isolated", "semi", "row"],
        kitchen: "facility",
        laundry: "facility",
        lat: "string",
        lng: "string",
        number: "string",
        openAreas: "objectArray",
        pets: "bool",
        pool: "facility",
        presentIndustry: ["Handel", "Office", "Gastro", "Hotel", "Logistik", "Produktion", "Landwirtschaft", "Storage", "Sonstige"],
        price: "string",
        rooms: "string",
        sauna: "facility",
        size: "string",
        solar: "facility",
        storageRoom: "facility",
        street: "string",
        streetNumber: "string",
        type: ["apartment", "house", "commercial", "land", "multi"],
        units: "string",
        utilityBill: "string",
        yearOfConst: "string",
    };

    export const objectTypes = ["apartment", "house", "commercial", "land", "missing"];
    
    