import { t } from "i18next";
import Button from "../../Generics/Button";
import StageLabel from "../../Objects/Creation/StageLabel";
import ThreeWaySelection from "./ThreeWaySelection";
import { useCreationContext } from "../../../contexts/CreationProvider";
import { useEffect } from "react";
import NavMenu from "../../Generics/NavMenu";

const Furnished = ({next, back}) => {

    const { setSearchData, searchData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setSearchData({...searchData, furnished: ""})
      }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full mdd:w-2/3 mt-10 gap-1">
        <StageLabel headline={t('CreateSearch.Möblierung')} labelText={t('CreateSearch.FurnishedLabel')}/>
        <ThreeWaySelection 
        prop="furnished" 
        options={
            [
                {
                    value: "-",
                    animation: "egal",
                    label: t("General.Egal"),
                },
                {
                    value: "no",
                    animation: "thumbDown",
                    label: t("CreateSearch.Vermeiden"),
                },
                {
                    value: "yes",
                    animation: "thumbUp",
                    label: t("CreateSearch.Unbedingt"),
                },
            ]
        } 
        _next={next}/>
        
        <NavMenu _back={back} />
    </div>
  )
}

export default Furnished;