import { createContext, useContext, useEffect, useState } from "react";

const StateContext = createContext();

const initialNavbarState = {
  chat: false,
  chart: false,
  userProfile: false,
  notification: false,
};

const initialMenuState = {
  admin:false,
  dashboard: false,
  objects: false,
  object: false,
  searches: false,
  settings: false,
  chats: false,
  shop: false,
  account: false,
  contact: false,
  stats: false,
  about: false,
  privacy: false,
  agb: false,
  rating: false,
  updates: false,
  newObject: false,
  newSearch: false,
  affiliate: false,
  impressum: false,
  faq: false,
  support: false,
  team: false,
  calendar: false,
  saved: false,
};

const initialSidebarExpandState = {
  objects: false,
  conversations: false,
  employees: false,
  tickets: false,
  renters: false,
  rooms: false,
  manager: false,
};

const getCurrentDimension = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

const countries = ["AT", "DE"];

export const ContextProvider = ({ children }) => {
  const [navbarState, setNavbarState] = useState(initialNavbarState);
  const [menuState, setMenuState] = useState({
    ...initialMenuState,
    
  });
  const [sidebarExpandState, setSidebarExpandState] = useState();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [customer, setCustomer] = useState({ credits: 0 });
  const [affiliate, setAffiliate] = useState({});
  const [maintenanceInfo, setMaintenanceInfo] = useState({});
  const [selectedObject, setSelectedObject] = useState();
  const [customerObjects, setCustomerObjects] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [customerSearches, setCustomerSearches] = useState([]);
  const [objectsContacts, setObjectsContacts] = useState([]);
  const [customerChats, setCustomerChats] = useState([]);
  const [pendingContacts, setPendingContacts] = useState([]);
  const [infoOpen, setInfoOpen] = useState(false);
  const [infoData, setInfoData] = useState({
    header: "",
    text: "",
  });
  const [toggleMenu, setToggleMenu] = useState(false);

  const [activeMenu, setActiveMenu] = useState(false);
  const [activeNewsbar, setActiveNewsbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [changeDisplayNameOpen, setChangeDisplayNameOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [loginHandlerState, setLoginHandlerState] = useState("login");
  const [orientation, setOrientation] = useState("");
  const [loadingText, setLoadingText] = useState("");

  const screenTransition = 800;
  const adminEmail = "all.immo.at@gmail.com";
  const searchProfilesLeft = customer?.maxSearchProfiles - customerSearches.length;

  const handleNavbarState = (clicked) => {
    setNavbarState({ ...initialNavbarState, [clicked]: true });
  };

  const handleMenuState = (clicked) => {
    setMenuState({ ...initialMenuState, [clicked]: true });
    setToggleMenu(false);
  };

  const handleSidebarExpand = (clicked) => {
    if (sidebarExpandState[clicked]) {
      setSidebarExpandState((prevState) => ({
        ...prevState,
        [clicked]: false,
      }));
    } else {
      setSidebarExpandState((prevState) => ({ ...prevState, [clicked]: true }));
    }
  };

  const resetSidebarExpand = () => {
    setSidebarExpandState(initialSidebarExpandState);
  };

  useEffect(() => {
    window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
      const portrait = e.matches;
      portrait ? setOrientation("portrait") : setOrientation("landscape");
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <StateContext.Provider
      value={{
        activeMenu,
        navbarState,
        menuState,
        screenSize,
        screenTransition,
        isLoading,
        customerObjects,
        activeNewsbar,
        customer,
        customerSearches,
        selectedObject,
        countries,
        customerChats,
        changeDisplayNameOpen,
        loginOpen,
        infoOpen,
        infoData,
        adminEmail,
        pendingContacts,
        searchProfilesLeft,
        loginHandlerState,
        updates,
        maintenanceInfo,
        toggleMenu,
        affiliate,
        orientation,
        loadingText,
        objectsContacts,
        setObjectsContacts,
        setLoadingText,
        setAffiliate,
        setToggleMenu,
        setMaintenanceInfo,
        setUpdates,
        setLoginHandlerState,
        setPendingContacts,
        setInfoOpen,
        setInfoData,
        setLoginOpen,
        setChangeDisplayNameOpen,
        setCustomerChats,
        setSelectedObject,
        setCustomerSearches,
        setCustomer,
        setActiveNewsbar,
        setActiveMenu,
        handleNavbarState,
        handleMenuState,
        handleSidebarExpand,
        setScreenSize,
        setIsLoading,
        setCustomerObjects,
        resetSidebarExpand,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
