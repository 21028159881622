import { red } from '@mui/material/colors';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import NavMenu from '../../Generics/NavMenu';
import AnimationIcon from '../../Generics/AnimationIcon';
import ObjectCheckBox from './ObjectCheckBox';
import StageLabel from './StageLabel';

const OtherInfos = ({next, back}) => {

    const { objectData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-3/4 md:w-1/2 mdd:w-1/3 mt-10 gap-1">
        <StageLabel labelText={t('CreateObject.OtherLabel')}/>
        {(objectData.deal === "rent" && !["multi", "commercial"].includes(objectData.type)) &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14 mt-5`}>
          <ObjectCheckBox _size={1.3} name="pets" _color={red[400]}/>
          <div className='grow'/>
          {objectData.pets && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/objectCreation/facilities/pets.json`)}/>}
        </div>}

        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <ObjectCheckBox _size={1.3} name="accessible" _color={red[400]}/>
          <div className='grow'/>
          {objectData.accessible && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/objectCreation/facilities/accessible.json`)}/>}
        </div>

        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <ObjectCheckBox _size={1.3} name="shortTermRent" _color={red[400]}/>
          <div className='grow'/>
          {objectData.shortTermRent && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/objectCreation/facilities/shortTermRent.json`)}/>}
        </div>

        {objectData.type === "commercial" &&
        <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-4 h-14`}>
          <ObjectCheckBox _size={1.3} name="industrialLicense" _color={red[400]}/>
          <div className='grow'/>
          {objectData.industrialLicense && <AnimationIcon _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/objectCreation/facilities/industrialLicense.json`)}/>}
        </div>}
        
        <NavMenu _back={back} nextBtn _next={next} />
    </div>
  )
}

export default OtherInfos;