import { Collapse } from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { storage } from "../../../firebase.config";
import { updateFirestoreDoc } from "../../../helper/functions";
import NavMenu from "../../Generics/NavMenu";
import AnimationIcon from "../../Generics/AnimationIcon";
import StageLabel from "./StageLabel";

const Expose = ({ objectID, next }) => {
  const { screenSize } = useStateContext();

  const [progressPercent, setProgressPercent] = useState(0);
  const [exposeError, setExposeError] = useState(false);
  const [exposeLink, setExposeLink] = useState("");
  const [file, setFile] = useState(null);
  const [finished, setFinished] = useState(true);

  // const exposePathReference = ref(storage, `objects/${objectID}/expose`);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //   getDownloadURL(exposePathReference)
    //     .then((url) => {
    //       setExposeLink(url);
    //     })
    //     .catch((error) => {});
    // }, []);

  const handleExposeSubmit = () => {
    if (!file) return;
    if (file.size > 1024 * 1024 * 5) {
      alert(t("Upload.DateiZuGroß"));
      return;
    }

    if (
      ![
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ].includes(file.type)
    ) {
      setExposeError(true);
      return;
    }
    setFinished(false);
    setExposeError(false);

    const storageRef = ref(storage, `objects/${objectID}/expose`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressPercent(progress);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setExposeLink(downloadURL);

          // expose Link to DB
          await updateFirestoreDoc("objects", objectID, {
            exposeLink: downloadURL,
          });

          setTimeout(() => {
            setFile(null);
            setProgressPercent(0);
            setFinished(true);
          }, "500");
        });
      }
    );
  };

  return (
    <div className="flex-flex-col justify-center items-center w-full ssm:w-2/3 md:w-1/2 mdd:w-1/3">
      <StageLabel
        headline={t("General.Expose")}
        iconSize={screenSize.width > 500 ? 60 : 40}
        color={false}
        stageIcon={require("../../../assets/animations/objectCreation/files.json")}
        labelText={t("CreateObject.ExposeLabel")}
      />
      <div className="w-full flex flex-col justify-center items-center py-2 px-3">
        <div className="flex flex-col md:flex-row w-full items-center justify-center md:space-x-3 mb-5 text-sm sm:text-lg">
          <p>
            {exposeLink === ""
              ? ""
              : t("Upload.ExposeHochgeladen")}
          </p>
          {exposeLink === "" ? (
            <p className="italic text-allimmoDark">{t("Upload.KeinExpose")}</p>
          ) : (
            <a
              href={exposeLink}
              target="_blank"
              className="underline  font-bold text-allimmoDark hover:text-slate-600 cursor-pointer"
            >
              {t("Upload.ExposeAnsehen")}
            </a>
          )}
        </div>
        <Collapse
          className="w-full mb-2 flex flex-col justify-center items-center"
          in={file !== null}
        >
          <div className=" w-full bg-white py-2 pl-2 pr-1 rounded border border-orange-500 border-dashed flex flex-row justify-center items-center gap-1.5">
            <AnimationIcon
              animate
              _color={false}
              _iconSize={40}
              iconSrc={require(`../../../assets/animations/objectCreation/doc.json`)}
            />
            {file !== null && (
              <div className="flex flex-col justify-center grow">
                <p className="text-allimmoDark grow">
                  {file?.name.substring(
                    0,
                    screenSize.width < 390
                      ? 20
                      : screenSize.width < 390
                      ? 25
                      : 30
                  ) + "..."}
                </p>
                <p className="text-orange-500 italic -mt-1 text-sm">
                  {(file?.size / 1024 ** 2).toFixed(2)} MB
                </p>
              </div>
            )}
            <p
              className="text-2xl text-red-400 cursor-pointer hover:text-red-300 active:text-red-400 w-6 text-center"
              onClick={() => setFile(null)}
            >
              x
            </p>
          </div>
        </Collapse>
        <div className="flex h-12 flex-row items-center justify-center w-full gap-1">
          <input
            id="upload"
            hidden
            onChange={(e) => setFile(e.target?.files[0])}
            type="file"
            accept=".pdf, .doc, .docx"
          />
          <label
            className="w-full flex h-12 flex-row items-center gap-1 rounded bg-white border-allimmoDark border p-2 cursor-pointer hover:bg-allimmoDark/5"
            htmlFor="upload"
          >
            <p className="text-center w-full text-allimmoDark text-sm ssm:text-lg">
              {t("Upload.DateiAuswählen")}
            </p>
          </label>
          {file !== null && progressPercent === 0 && (
            <button
              onClick={handleExposeSubmit}
              className="w-full flex h-12 flex-row items-center gap-1 rounded bg-white border-allimmoDark border p-2 cursor-pointer hover:bg-allimmoDark/5"
            >
              <p className="text-center w-full text-allimmoDark text-sm ssm:text-lg">
                {t("Upload.DateiHochladen")}
              </p>
            </button>
          )}
          {progressPercent !== 0 && file !== null && (
            <div className="w-full flex h-12 flex-row items-center gap-1 rounded border-allimmoDark border p-2 cursor-pointer hover:bg-allimmoDark/5">
              <p className="text-lg text-allimmoDark w-full text-center">
                {progressPercent}%
              </p>
            </div>
          )}
          {exposeError && (
            <p className="text-red-500 text-sm bg-red-100 mt-1 px-1">
              {t("Upload.Error")}
            </p>
          )}
        </div>
        
      </div>

      <NavMenu backBtn={false} nextBtn _next={next} nextDisabled={file !== null || !finished} />
      
    </div>
  );
};

export default Expose
