import { SwingCheckmark } from "../helper/icons";

export const CustomFacilityCheck = ({name}) => {

    return (
      <div className='flex flex-row w-full items-center'>
        <SwingCheckmark size={16} className='mr-2 text-allimmoDark/70'/>
        <p>{name}</p>
      </div>
    )
};

export default CustomFacilityCheck;