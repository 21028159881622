import { t } from 'i18next';
import AnimationIcon from '../Generics/AnimationIcon';

const Condition = ({searchData}) => {

  const allConditions = searchData?.renovation && searchData?.first && searchData?.new;

  return (
    <div className={`bg-white rounded flex flex-col w-full pb-2 border z-0 border-allimmoDark/70`}>
      <div className="w-full flex flex-row justify-between items-center mb-1.5 bg-slate-200 px-1.5 py-0.5 rounded-t">
        <p>{t('SearchDetails.BaseInfos.ConditionLabel')}</p>
      </div>
      <div className="flex flex-row justify-evenly gap-1 ssm:gap-2 mt-1 px-2">
        {(searchData?.first || allConditions) && <ConditionElement icon="first" label={t('Zustände.Erstbezug')} />}
        {(searchData?.new || allConditions) && <ConditionElement icon="new" label={t('Zustände.Neuwertig')} />}
        {(searchData?.renovation || allConditions) && <ConditionElement icon="renovation" label={t('Zustände.Sanierung')} />}
      </div>
    </div>
  )
};

const ConditionElement = ({icon, label}) => {
    return (
      <div className={`flex flex-col items-center rounded-lg border border-allimmoDark/40 p-2 w-full shadow`}>
        <AnimationIcon _color _iconSize={30} iconSrc={require(`../../assets/animations/objectCreation/${icon}.json`)}/>
        <p className="text-xs text-center">{label}</p>
      </div>
    )
};

export default Condition
