import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import StageLabel from './StageLabel';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import NavMenu from '../../Generics/NavMenu';

const ConstructionYear = ({next, back}) => {

    const {objectData, setObjectData, resetZeroInput } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <div className='w-full sm:w-2/3 md:w-1/2 flex flex-col justify-center items-center py-2 sm:py-5'>
        <StageLabel color={false} stageIcon={require('../../../assets/animations/objectCreation/year.json')} labelText={t('CreateObject.YearLabel')}/>
        <TextField autoComplete='off' onClick={() => resetZeroInput("yearOfConst")} required className='w-full rounded bg-white' size='medium' label={t('CreateObject.Jahr')} type='number' value={objectData?.yearOfConst} onChange={(e) => setObjectData({...objectData, yearOfConst: e.target.value})}/>
        <p className='italic text-sm mt-1 text-center w-full sm:w-3/4'>{t('CreateObject.ConstructionYearInfo')}</p>
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={objectData.yearOfConst.toString().length !== 4}/>
    </div>
  )
};

export default ConstructionYear
