import { Dialog } from '@mui/material';
import { Transition } from '../../helper/functions';
import { CloseCircle } from '../../helper/icons';
import MatchLogo from '../../assets/MatchLogo.png';
import { useStateContext } from '../../contexts/ContextProvider';

const Popup = ({openState, header = "", content, close, full = true, showLogo = true}) => {

  const { screenSize } = useStateContext();

  return (
    <Dialog className='select-none' open={openState} fullScreen={full} TransitionComponent={Transition}>
      <div className='flex flex-col items-center w-full grow bg-white'>
        {showLogo &&
        <div className='flex flex-row items-center w-full border-b border-allimmoDark p-3 bg-sky-50 z-10'>
          <img width={100} src={MatchLogo} alt='ALLIMMO'/>
          <p className='font-bold text-sm text-orange-400'>beta</p>
          <p className='grow text-center text-allimmoDark text-xl'>{screenSize.width > 768 ? header : ""}</p>
          <CloseCircle className='text-allimmoDark hover:text-red-400 active:text-red-300 cursor-pointer' onClick={close} size={30} />
        </div>}
        <div className='w-full flex-row grow justify-center items-center mt-2'>
          {content}
        </div>
      </div>
    </Dialog>
  )
}

export default Popup