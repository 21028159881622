import { Collapse, TextField } from '@mui/material';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useCreationContext } from '../../../contexts/CreationProvider';
import NavMenu from '../../Generics/NavMenu';
import SquareButton from '../../Generics/SquareButton';
import AnimationIcon from '../../Generics/AnimationIcon';
import StageLabel from './StageLabel';

const State = ({back, next}) => {

    const { objectData, setObjectData } = useCreationContext();
    const { screenSize } = useStateContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleVacancy = (newState) => {
        setTimeout(() => {
            setObjectData({...objectData, vacancy: newState});
            if(["unlimited", "free"].includes(newState)) next();
        }, "150");
    };

  return (
    <div className="flex flex-col justify-center items-center select-none">
        <StageLabel labelText={t('CreateObject.StateLabel')}/>
        <div className="grid grid-cols-2 ssm:grid-cols-3 mt-5 gap-2 mb-2">
            <SquareButton selected={objectData?.vacancy === "free"} clickFunction={() => handleVacancy("free")} icon={<AnimationIcon _color={false} _iconSize={screenSize.width > 500 ? 70: 50} iconSrc={require('../../../assets/animations/objectCreation/vacant.json')}/>} label={t('CreateObject.Leer')}/>
            <SquareButton lowerLineHeight selected={objectData?.vacancy === "unlimited"} clickFunction={() => handleVacancy("unlimited")} icon={<AnimationIcon _color={false} _iconSize={screenSize.width > 500 ? 70: 50} iconSrc={require('../../../assets/animations/objectCreation/rented_unlimited.json')}/>} label={t('CreateObject.Unbefristet')}/>
            <div className="col-span-2 ssm:col-span-1 place-self-center">
                <SquareButton selected={!["free", "unlimited", "-"].includes(objectData?.vacancy)} clickFunction={() => handleVacancy("limited")} icon={<AnimationIcon _color={false} _iconSize={screenSize.width > 500 ? 70: 50} iconSrc={require('../../../assets/animations/objectCreation/rented_limited.json')}/>} label={t('CreateObject.Befristet')}/>
            </div>
        </div>

        <Collapse in={!["free", "unlimited", "-"].includes(objectData.vacancy)}>
            <div className={`flex flex-row bg-white cursor-pointer items-center pr-1 pl-3 py-1 rounded gap-2 ssm:gap-4`}>
                <p className='text-sm ssm:text-base'>{t('CreateObject.VermietetBis')}</p>
                <div className='grow flex flex-row justify-center'>
                    <AnimationIcon _color={false} _iconSize={40} iconSrc={require('../../../assets/animations/objectCreation/arrowright.json')}/>
                </div>
                <TextField
                autoComplete='off'
                type='month' 
                size='medium' 
                value={objectData.rentedUntil} 
                onChange={(e) => setObjectData({...objectData, rentedUntil: e.target.value})} 
                className='rounded bg-white w-44'/>
            </div>
        </Collapse>

        <NavMenu _back={back} nextBtn _next={next} nextDisabled={objectData.vacancy === "-" || (objectData.vacancy === "limited" && [0, "", null, undefined].includes(objectData.rentedUntil))} />
    </div>
  )
}

export default State
