import { t } from "i18next"
import { CloseX, NextArrowDouble } from "../../helper/icons"
import { useStateContext } from "../../contexts/ContextProvider"

const NavMenu = ({_next, _back, _ready, _close, nextDisabled = false, readyDisabled = false, backBtn = true, nextBtn = false, readyBtn = false, closeBtn = false, marginTop = 8, backDisabled = false}) => {
  
  const { screenSize } = useStateContext();

  const iconSize = screenSize.width > 500 ? 50 : 30;
  
  return (
    <div className={`w-full flex flex-row justify-center items-center gap-2 mt-${marginTop}`}>
        {backBtn && 
        <div onClick={!backDisabled ? _back : null} className={`rounded-full border-2 border-allimmoDark/70 p-1 shadow-2xl bg-white ${!backDisabled ? "hover:text-allimmoDark hover:border-allimmoDark text-allimmoDark/70 cursor-pointer" : "text-gray-300 border-gray-300"}`}>
          <NextArrowDouble size={iconSize} />
        </div>}
        {nextBtn && 
        <div onClick={!nextDisabled ? _next : null} className={`rotate-180 rounded-full border-2 border-allimmoDark/70 p-1 shadow-2xl bg-white ${!nextDisabled ? "hover:text-allimmoDark hover:border-allimmoDark text-allimmoDark/70 cursor-pointer" : "text-gray-300 border-gray-300"}`}>
          <NextArrowDouble size={iconSize} />
        </div>}
        {readyBtn &&
        <div onClick={!readyDisabled ? _ready : null} className={`rounded-full border-2 border-allimmoDark/70 p-3.5 shadow-2xl bg-white hover:text-allimmoDark hover:border-allimmoDark text-allimmoDark/70 cursor-pointer`}>
          <p className="font-bold text-xl">{t('General.Fertig').toUpperCase()}</p>
        </div>}
        {closeBtn &&
        <CloseX onClick={_close} size={iconSize - 10} className={`text-allimmoDark/70 cursor-pointer hover:text-allimmoDark`}/>}
    </div>
  )
}

export default NavMenu
