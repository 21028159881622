import { Checkbox, FormControlLabel, Rating } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Timestamp, doc, onSnapshot } from 'firebase/firestore';
import { auth, firestore } from '../firebase.config';
import { setFirestoreColl } from '../helper/functions';

const PlattformRating = () => {

    const [rating, setRating] = useState({
      ux: 0,
      service: 0,
      general: 0,
      design: 0,
      objects: false,
      searches: false,
      disabled: {
        objects: false,
        searches: false
      }
    });
    const [isLoading, setIsLoading] = useState(false);

    const ratingAvg = (rating?.ux + rating?.design + rating?.service + rating?.general);

    useEffect(() => {
      onSnapshot(doc(firestore, "ratings", auth.currentUser.uid), (result) => {
        setRating(result.data());
      });
    }, []);

    
    
    

    const saveRating = async () => {
      setIsLoading(true);
      if(rating?.ux === 0 || rating?.design === 0 || rating?.general === 0 || rating?.service === 0) return;
      await setFirestoreColl("ratings", auth.currentUser.uid, {...rating, updated: Timestamp.fromDate(new Date()), disabled: {...rating.disabled, objects: rating.objects ? true : false, searches: rating.searches ? true : false}})
      .then(() => setIsLoading(false))
    };

    const saveStar = async (category, value) => {
      await setFirestoreColl("ratings", auth.currentUser.uid, {
        updated: Timestamp.fromDate(new Date()),
        [category]: value,
      })
    };

    const saveCheckbox = async (category) => {
      await setFirestoreColl("ratings", auth.currentUser.uid, {
        updated: Timestamp.fromDate(new Date()),
        disabled: false,
      })
    };


  return (
    <div className='flex flex-col p-5 justify-center items-center select-none w-full'>
      <div className='w-full md:w-2/3 border border-allimmoDark rounded p-5 mb-5'>
        <div className='flex flex-row w-full items-center mb-8 opacity-60'>
            <p className='grow'>{t('Rating.Label1')}</p>
            <Rating readOnly value={ratingAvg/4}/>
            {/* <p className='ml-2'>{`(${ratingAvg/4 === "NaN" ? 0 : ratingAvg/4})`}</p> */}
        </div>

        <div className='flex flex-row w-full items-center'>
            <p className='grow'>{t('Rating.Label1')}</p>
            <Rating value={rating?.general}
            onChange={(event, newValue) => {setRating({...rating, general: newValue})}}/>
        </div>

        <div className='flex flex-row w-full items-center'>
            <p className='grow'>{t('Rating.Label2')}</p>
            <Rating value={rating?.design}
            onChange={(event, newValue) => {setRating({...rating, design: newValue})}}/>
        </div>

        <div className='flex flex-row w-full items-center'>
            <p className='grow'>{t('Rating.Label3')}</p>
            <Rating value={rating?.ux}
            onChange={(event, newValue) => {setRating({...rating, ux: newValue})}}/>
        </div>

        <div className='flex flex-row w-full items-center mb-5'>
            <p className='grow'>{t('Rating.Label4')}</p>
            <Rating value={rating?.service}
            onChange={(event, newValue) => {setRating({...rating, service: newValue})}}/>
        </div>

        <div className='flex flex-row w-full items-center'>
            <FormControlLabel disabled={rating?.disabled?.objects} control={<Checkbox value={rating?.objects} checked={rating?.objects} onChange={() => setRating({...rating, objects: !rating?.objects})} disableRipple disableTouchRipple />} label={t('Rating.Label5')}/>
        </div>

        <div className='flex flex-row w-full items-center'>
            <FormControlLabel disabled={rating?.disabled?.searches} control={<Checkbox value={rating?.searches} checked={rating?.searches} onChange={() => setRating({...rating, searches: !rating?.searches})} disableTouchRipple disableRipple/>} label={t('Rating.Label6')}/>
        </div>
      </div>

      <button className='bg-allimmoDark/80 rounded text-white px-3 py-2 cursor-pointer hover:bg-allimmoDark active:bg-allimmoDark/80' onClick={saveRating} disabled={isLoading}>{t('General.Speichern')}</button>
    </div>
  )
}

export default PlattformRating