import { MenuItem, TextField } from '@mui/material';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { industries } from '../../../helper/arrays';
import { CloseCircle, QuestionThick } from '../../../helper/icons';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from '../../Objects/Creation/StageLabel';

const PresentIndustrySearch = ({next, back}) => {

    const {searchData, setSearchData } = useCreationContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        (["-", "!"].includes(searchData?.presentIndustry)) &&
            setSearchData({...searchData, activeBusiness: "-"})
    }, [searchData?.presentIndustry]);

  return (
    <div className='w-full ssm:w-2/3 md:w-1/2 flex flex-col justify-center items-center sm:py-5 gap-3'>
        <StageLabel headline={t('CreateObject.PresentIndustry.Headline')} color={false} labelText={t('CreateSearch.PresentIndustry.Label')}/>
        <TextField select className='w-full rounded bg-white' size='medium' label={t('CreateObject.PresentIndustry.Gewerbe')} value={searchData?.presentIndustry} onChange={(e) => setSearchData({...searchData, presentIndustry: e.target.value})}>
            <MenuItem value={"-"}>
                <MenuItemElement icon={<CloseCircle className='text-orange-500' size={25} />} label='No' />
            </MenuItem>

            {industries.sort((a,b) => a.name.localeCompare(b.name)).map((industry, i) => {
                return (
                    <MenuItem key={i} value={industry.name}>
                        <MenuItemElement icon={industry.icon} label={industry.name}/>
                    </MenuItem>
                )
            })}

            <MenuItem value={"?"}>
                <MenuItemElement icon={<QuestionThick className='text-orange-400' size={25} />} label='Egal' />
            </MenuItem>          
        </TextField>
        
        {!["!", "-"].includes(searchData?.presentIndustry) &&
        <TextField select autoComplete='off' className='w-full rounded bg-white' size='medium' label={t('CreateSearch.PresentIndustry.ActiveBusiness')} value={searchData?.activeBusiness} onChange={(e) => setSearchData({...searchData, activeBusiness: e.target.value})}>
            <MenuItem value={"-"}>{t('CreateSearch.PresentIndustry.ActiveOptions.however')}</MenuItem>
            <MenuItem value={"yes"}>{t('CreateSearch.PresentIndustry.ActiveOptions.yes')}</MenuItem>
            <MenuItem value={"no"}>{t('CreateSearch.PresentIndustry.ActiveOptions.no')}</MenuItem>
        </TextField>}
        
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={searchData?.presentIndustry === "Other" && searchData?.presentIndustryOther.trim() === ""}/>
    </div>
  )
};

const MenuItemElement = ({icon, label}) => (
    <div className='flex flex-row gap-5 items-center'>
        {icon}
        <p className='text-allimmoDark'>{t(`CreateObject.Industries.${label}`)}</p>
    </div>

);

export default PresentIndustrySearch
