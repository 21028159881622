import { Player } from '@lordicon/react';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useCreationContext } from '../../../contexts/CreationProvider';
import { Delete } from '../../../helper/icons';
import NavMenu from '../../Generics/NavMenu';
import StageLabel from './StageLabel';

const OpenAreasMulti = ({back, next}) => {

    const { objectData, setObjectData } = useCreationContext();

    const [newArea, setNewArea] = useState({
        id: 0,
        size: 0,
        type: "roofTop",
        public: true,
    });
    const iconref = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const addArea = () => {
        if(newArea.size > 0) {
            setObjectData({...objectData, openAreas: [...objectData.openAreas, {...newArea, id: Math.floor((Math.random() * 10000) + 1)}]});
            setNewArea({...newArea, size: 0, public : false});
        }
    };

    const deleteArea = (id) => {
        setObjectData({...objectData, openAreas: objectData.openAreas.filter(a => a.id !== id)})
    };

    const resetZeroInput = () => {
        if(parseFloat(newArea.size) === 0) {
          setNewArea({...newArea, size: ""});
        }
    };

    const animationStart = () => {
        if(![0, "0", "", NaN, "NaN"].includes(newArea.size)) {
            iconref.current?.playFromBeginning();
        }
    };

    const animationEnd = () => {
        iconref.current?.goToFirstFrame(); 
        iconref.current?.pause()
    };

    const changeUsage = (areaID) => {
        setObjectData({...objectData, openAreas: objectData.openAreas.map(el => {
            if(el.id === areaID) {
                return {...el, public: !el.public}
            } else {
                return el;
            }
        })})
    };

  return (
    <div className='w-full ssm:w-2/3 mdd:w-1/3 flex flex-col justify-center items-center gap-2.5 mt-10'>
        <StageLabel delay='no' color={false} stageIcon={require('../../../assets/animations/objectCreation/openAreas.json')} labelText={t('CreateObject.OpenAreasLabelMulti')}/>
        <div className='flex flex-col items-center w-full'>
                <div className='flex flex-col gap-2 w-full'>
                    <TextField className='rounded w-full bg-white' label="Freiflächenart" select size='medium' value={newArea.type} onChange={(e) => setNewArea({...newArea, type: e.target.value})}>
                        <MenuItem value="roofTop">Terrasse</MenuItem>
                        <MenuItem value="garden">Garten</MenuItem>
                    </TextField>
                    <TextField
                    onClick={resetZeroInput}
                    className='rouded w-full bg-white'
                    type='number'
                    label={t('General.Größe')} 
                    size='medium'
                    autoComplete='off'
                    value={newArea.size} 
                    onChange={(e) => setNewArea({...newArea, size: parseFloat(e.target.value)})}
                    InputProps={{endAdornment: <InputAdornment disableTypography position="end">m²</InputAdornment>}}/>
                </div>
                {/* <FormControlLabel className='self-start' control={<Checkbox sx={{color: red[300], '&.Mui-checked': {color: red[300]}}} value={newArea.public} checked={newArea.public} onChange={(e) => setNewArea({...newArea, public: !newArea.public})}/>} label={t('CreateObject.PublicAreaLabel')}/> */}
                <div onClick={addArea} onMouseEnter={animationStart} onMouseLeave={animationEnd} className={`w-full shadow-xl flex flex-row gap-3 justify-center items-center border-2 mt-2 border-allimmoDark rounded py-1 bg-gray-100 ${[0, "0", "", NaN, "NaN"].includes(newArea.size) ? 'opacity-30' : 'opacity-100 cursor-pointer hover:shadow-none'}`}>
                    <Player ref={iconref} size={33} icon={require('../../../assets/animations/objectCreation/plus.json')}/>
                    <p className='text-allimmoDark text-lg'>Hinzufügen</p>
                </div>
            </div>
            {objectData.openAreas.length > 0 && <p className='border-b border-allimmoDark/50 w-full my-1'></p>}
            <div className='flex flex-col gap-1 w-full'>
                {objectData.openAreas.map((area,i) => {
                    return (
                    <div key={i} className='w-full flex flex-row justify-center items-center px-2 py-0.5 rounded border border-allimmoDark bg-slate-100'>
                        <p className='grow'>{t(`Facilities.${area.type}`)}</p>
                        {/* <p onClick={() => changeUsage(area.id)} className='text-sm italic mr-2 underline hover:text-allimmoDark cursor-pointer'>{area.public ? "Allgemeinfläche" : "Privatnutzung"}</p> */}
                        <p className='font-bold w-16 text-right'>{area.size.toLocaleString()}m²</p>
                        <Delete onClick={() => deleteArea(area.id)} className='text-red-400 ml-2 cursor-pointer hover:text-red-700' size={15}/>
                    </div>
                    )
                })}
            </div>
            <NavMenu _back={back} nextBtn _next={next} nextDisabled={parseFloat(newArea.size) > 0}/>
    </div>
  )
}

export default OpenAreasMulti