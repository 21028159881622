import { useEffect } from 'react';

const Agb = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='flex flex-col w-full justify-center items-center'>
      <div className={`flex flex-col w-full px-5 pt-10 pb-20`}>
          <p className='text-xl font-bold mb-8'>ALLGEMEINE GESCHÄFTSBEDINGUNGEN</p>
          <p className='text-lg font-bold'>I. Anwendungsbereich</p>
          <p> Die Allimmo KG (im Folgenden "Allimmo") stellt ihre Dienstleistungen ausschließlich unter Einhaltung der
          nachfolgend dargelegten Allgemeinen Geschäftsbedingungen (im Folgenden "AGB") zur Verfügung.
          Geschäftsbedingungen, die vom Kunden vorgelegt werden, sind für Allimmo unverbindlich, selbst wenn Allimmo diesen
          nicht explizit widersprochen hat oder seine Dienstleistungen ohne Vorbehalte erbringt. Der Vertrag zur
          Auftragsdatenverarbeitung ist fester Bestandteil dieser Vertragskonditionen und wird hiermit gleichfalls anerkannt.
          Für den Fall, dass der Kunde über die Dienste von Allimmo auf Angebote Dritter zugreift, gelten zwischen dem Kunden
          und den Dritten ausschließlich deren jeweiligen Geschäftsbedingungen.</p>
          <p className='text-lg mt-5 font-bold'>II. Leistungsbeschreibung und Definitionen</p>
          <p>Die Dienste von Allimmo Match befähigen den Kunden, seine Immobilien digital und effektiv anzubieten oder
          Objekte finden zu lassen. Dabei werden alle oben genannten Inhalte, Daten und Dokumente sicher aufbewahrt.</p>
          <p>Die Zahlung für diese Leistung erfolgt durch ein "Credit"-System. Die bedeutet, der Nutzer erwirbt eine gewisse
          Menge an „Credits“ und kann diese anschließend nutzen, um Dienstleistungen auf der Allimmo Match Plattform zu
          buchen. </p>
          <p>Der Umfang der Leistungen für den jeweiligen Dienst definiert sich aus der zugehörigen Leistungsbeschreibung,
          welche zum Zeitpunkt der Bestellung des Dienstes vorliegt.</p>
          <p>Für die Nutzung der Dienste können Drittanbieterkosten aufkommen (z.B. Kosten für die Datenverbindung durch
          den mobilen Zugriff über den privaten Mobilfunkanbieter). Es wird präzisiert, dass solche Datenverbindungen
          ausdrücklich nicht im Leistungsumfang von Allimmo enthalten sind.</p>
          <p>Allimmo behält sich zu jeder Zeit das Recht vor, den Umfang ihrer Leistungen zu erweitern, an den technischen
          Fortschritt anzupassen und/oder Verbesserungen einzuführen. Diese Regelung trifft insbesondere dann zu, wenn eine
          Anpassung notwendig ist, um einen Missbrauch zu verhindern oder wenn Allimmo durch gesetzliche Vorschriften zur
          Anpassung der Leistungen verpflichtet ist.</p>
          <p className='text-lg mt-5 font-bold'>III. Vertragsabschluss, Registrierung</p>
          <p>Nutzungsvereinbarung und Zahlungsverpflichtungen:</p>
          <p>Die Nutzung der allgemeinen Dienstleistungen von Allimmo Match ist kostenlos, jedoch fallen Gebühren für die
          Vermittlung von Kontakten zu passenden Interessenten an.</p>
          <p>Registrierung: </p>
          <p>Für die Inanspruchnahme der Dienstleistungen ist eine Registrierung auf der Allimmo Match Plattform erforderlich.
          Zum Zwecke der Bestellung eines kostenpflichtigen Servicepakets sind persönliche Informationen wie Vorname,
          Nachname, E-Mail-Adresse und Anschrift, sowie die Bereitstellung einer Zahlungsmethode notwendig. Durch die
          elektronische Bestätigung und Annahme der Allgemeinen Geschäftsbedingungen und Datenschutzbestimmungen gibt
          der Kunde ein rechtsverbindliches Vertragsangebot bezüglich des ausgewählten Pakets ab. Der kostenpflichtige
          Vertrag wird nach Eingang der Zahlung automatisch wirksam und durch eine separate Bestätigungs-E-Mail bestätigt.</p>
          <p className='text-lg mt-5 font-bold'>IV. Zusatzleistungen</p>
          <p>Es steht jedem Nutzer offen, individuelle Dienstleistungen oder zusätzliche Leistungen und Pakete gegen eine
          Gebühr zu erwerben. Diese Services können an verschiedenen Stellen innerhalb der Anwendung (Allimmo Match)
          erworben werden. </p>
          <p>Das Zustandekommen eines rechtsverbindlichen Angebots zum Abschluss eines Vertrags mit Allimmo gestaltet
          sich folgendermaßen: Der Nutzer wählt die gewünschte Leistung oder das gewünschte Paket aus und bestätigt seine
          Auswahl durch Betätigen des Buttons "Zahlen". Der Vertrag tritt nach erfolgreicher Zahlung in Kraft und die
          entsprechende Leistung wird umgehend bereitgestellt. </p>
          <p className='text-lg mt-5 font-bold'>V. Preise und Zahlungsbedingungen</p>
          <p>Alle aufgeführten Preise schließen die gesetzlich vorgeschriebenen Steuern, einschließlich der (für Österreich bzw. Deutschland)
          derzeitigen Mehrwertsteuer, ein.</p>
          <p>Der Kunde ermächtigt Allimmo, die im Rahmen der Vertragsbeziehung entstehenden Kosten über den
          Drittanbieter-Zahlungsdienst "Stripe" abzubuchen.</p>
          <p>Unabhängig von der Nutzung sind die Gebühren im Voraus und zum Beginn der jeweiligen Abrechnungsperiode
          fällig. </p>
          <p>Allimmo stellt für jede Transaktion eine digitale Rechnung. Falls der Kunde einen Rechnungsversand per Post
          bevorzugt, kann Allimmo für jede ausgestellte Rechnung eine angemessene Gebühr verrechnen.</p>
          <p className='text-lg mt-5 font-bold'>VI. Dauer des Vertrages, Kündigung</p>
          <p>Die Dauer des Vertrages ist abhängig vom gewählten Leistungspaket. In der Regel ermöglicht jeder
          Nutzungsvertrag eine einmalige Nutzung und bemächtigt zur Nutzung einer vordefinierten Menge an „Credits“, dabei
          erlischt das Widerrufsrecht nach Erbringung der Leistung.
          Das Recht, die Nutzung der Plattform zu beenden, ist jederzeit im Kundenkonto möglich. </p>
          <p className='text-lg mt-5 font-bold'>VII. Sperrung von Inhalten oder des Zugangs</p>
          <p>Sollte der Nutzer eine wesentliche Vertragspflicht schuldhaft verletzen, behält sich Allimmo das Recht vor, den
          betroffenen Account oder die entsprechenden Inhalte zeitweise oder dauerhaft zu sperren.</p>
          <p className='text-lg mt-5 font-bold'>VIII. Verantwortlichkeit für Inhalte</p>
          <p>Sofern die über die Allimmo-Dienste eingestellten oder abrufbaren Inhalte nicht Eigentum von Allimmo sind, trägt
          der Nutzer die alleinige, uneingeschränkte Verantwortung für sämtliche Inhalte, die er über die Allimmo-Dienste
          speichert, veröffentlicht oder übermittelt. Der Nutzer verpflichtet sich, zu gewährleisten, dass diese Inhalte weder
          gesetzlichen Bestimmungen noch den Rechten Dritter (beispielsweise Namens-, Persönlichkeits-, Marken-, Urheber- oder
          Datenschutzrechte) widersprechen und nicht gegen die guten Sitten verstoßen.</p>
          <p>Sollten Allimmo aufgrund einer Verletzung der genannten Bestimmungen durch vom Nutzer eingestellten Inhalten
          direkte und/oder indirekte Schäden entstehen, ist der Nutzer dazu verpflichtet, Allimmo diese Schäden zu ersetzen.
          Darüber hinaus behält sich Allimmo das Recht vor, im Verdachtsfall einer Verletzung den Nutzern ohne vorherige
          Begründung unverzüglich die Vermittlung von Inhalten und den Zugang zu den Allimmo-Systemen zu untersagen.</p>
          <p className='text-lg mt-5 font-bold'>IX. Gewährleistung</p>
          <p>Allimmo möchte darauf hinweisen, dass es gemäß dem aktuellen Stand der Technik unerreichbar ist, Soft- und
          Hardware so zu konstruieren, dass sie unter allen Anwendungsbedingungen fehlerlos funktionieren oder gegen
          sämtliche Manipulationen von Dritten immun sind. Zudem kann Allimmo nicht garantieren, dass die angebotenen
          Dienste permanent den individuellen Anforderungen der Nutzer entsprechen oder jederzeit störungs- und fehlerfrei
          zugänglich sind.</p>
          <p>Die Zuverlässigkeit der Datenbank, im Sinne des Einstellens und Abrufens von Inhalten, garantiert Allimmo zu
          mehr als 95% im Jahresdurchschnitt. Ausgenommen von der Definition der Verfügbarkeit sind notwendige
          Wartungsarbeiten und Unterbrechungen für sicherheitsrelevante Offline-Backups, vorausgesetzt die Dauer ist
          angemessen. Ebenso fallen Ausfälle infolge höherer Gewalt oder anderen unvermeidlichen Ursachen, die nicht im
          Einflussbereich von Allimmo liegen, nicht unter den Verfügbarkeitsbegriff. Das betrifft beispielsweise notwendige
          Maßnahmen zur Eindämmung der Verbreitung von Viren. Über diese Zeiten wird Allimmo, wenn möglich, vorab auf der
          Website informieren.</p>
          <p>Es liegt in der alleinigen Verantwortung des Nutzers, die bereitgestellten Funktionen und Dienstleistungen zu
          nutzen. Diese Angebote ersetzen in keiner Weise eine juristische oder professionelle Beratung und sollten nicht als
          solche angesehen werden.</p>
          <p>Hervorzuheben ist, dass die Annahme der Provisionsvereinbarung durch einen Interessenten über die "Match"-
          Plattform lediglich das Setzen eines "Häkchens" zum Akzeptieren erfordert. Allimmo garantiert hierbei nur, dass die
          Daten des hinterlegten Objekts erst angezeigt werden, wenn das Akzeptieren erfolgt ist. Jegliche Konsequenzen, sei es
          rechtlicher oder anderer Art, obliegen allein der Verantwortung des Maklers. Wenn der Makler den Verdacht hegt, dass
          die Provisionszahlung seitens des potenziellen Interessenten verweigert werden könnte, ist er verpflichtet, seine
          Provisionsvereinbarung nach der Kontaktaufnahme über eine geeignete andere Quelle (entweder physisch per
          Unterschrift oder mit Unterstützung von spezialisierten Dienstleistern für verifizierte Unterschriften) zusätzlich zu
          unterzeichnen.</p>
          <p className='text-lg mt-5 font-bold'>X. Haftung von Allimmo</p>
          <p>Allimmo übernimmt die Verantwortung und Haftung nur im Rahmen der nachfolgenden Bedingungen, ungeachtet
          des zugrunde liegenden Rechtsgrunds:</p>
          <p>Gemäß den gesetzlichen Bestimmungen haftet Allimmo bei Vorsatz und grober Fahrlässigkeit.</p>
          <p>Bei leichter Fahrlässigkeit haftet Allimmo lediglich bei Verletzung einer essenziellen Vertragspflicht, die
          grundlegend für die ordnungsgemäße Durchführung des Vertrages ist und auf deren Einhaltung der Nutzer regelmäßig
          vertrauen kann. In solch spezifischen Fällen beschränkt sich die Haftung von Allimmo auf den vorhersehbaren,
          typischen, vertraglichen Schaden.</p>
          <p>Allimmo schließt alle weitergehenden Schadensersatzansprüche aus leichter Fahrlässigkeit aus, insbesondere bei
          indirekten Schäden, Folgeschäden oder entgangenem Gewinn.</p>
          <p>Die vorstehenden Haftungsbeschränkungen finden keine Anwendung bei Schäden an Leben, Körper oder
          Gesundheit, sowie bei Anwendung des Produkthaftungsgesetzes.</p>
          <p>Die beschriebene Haftungsbeschränkung und Haftungsausschlüsse gelten ebenso für die Haftbarkeit der
          Mitarbeiter, Vertreter, und sonstigen Erfüllungsgehilfen von Allimmo.</p>
          <p>Allimmo lehnt ausdrücklich jede Haftung für Inhalte ab, die von Nutzern und/oder Dritten über die Dienste von
          Allimmo bereitgestellt werden. Dies gilt ebenso für sämtliche Drittanbieterleistungen, auf die im Verlauf der Nutzung
          der Dienste von Allimmo zugegriffen wird.</p>
          <p className='text-lg mt-5 font-bold'>XI. Pflichten des Nutzers</p>
          <p>Der Nutzer versichert Allimmo, dass die übermittelten Daten vollständig und korrekt sind. Im Falle etwaiger
          Änderungen seiner Daten, ergreift der Nutzer sofortige Maßnahmen, um Allimmo davon in Kenntnis zu setzen.
          Relevante Informationen und Willenserklärungen, die für die jeweilige vertragliche Beziehung erheblich sind, darf
          Allimmo an die vom Nutzer angegebene E-Mail-Adresse senden. Der Nutzer verpflichtet sich, seinen E-Mail-
          Posteingang regelmäßig auf neue Nachrichten zu kontrollieren. </p>
          <p>Der Nutzer behandelt seine Passwörter und andere Zugangsdaten gewissenhaft. Er stellt sicher, dass sie geheim
          bleiben und sorgt für ihre regelmäßige Änderung. </p>
          <p>Der Nutzer darf sein Passwort nicht offenlegen, selbst wenn danach gefragt wird. Allimmo weist darauf hin, dass
          Allimmo-Mitarbeiter nicht dazu befugt sind, Nutzer nach ihrem Passwort zu fragen. Im Falle eines Verlusts des
          Passwortes hat der Nutzer über die Funktion "Passwort vergessen" die Möglichkeit, ein neues Passwort über seine
          registrierte E-Mail-Adresse zu erstellen. </p>
          <p>Falls dem Nutzer bekannt wird, dass Dritte sein Passwort widerrechtlich verwenden, es Missbrauch von Allimmo-
          Plattformen gibt oder gegen wesentliche Vertragspflichten verstoßen wird, ist der Nutzer verpflichtet, Allimmo
          unverzüglich zu informieren. Der Nutzer hat alle Schäden zu ersetzen, die auf einen Missbrauch der Passwörter durch
          Dritte oder die Nutzung der Passwörter durch Dritte zurückzuführen sind, sofern er diesen zuzurechnen hat.</p>
          <p className='text-lg mt-5 font-bold'>XII. Datenschutz</p>
          <p>Allimmo hält sich streng an alle relevanten Datenschutzgesetze. Unsere Datenschutzbestimmungen sind auf
          unserer Webseite, auf der Homepage von Allimmo-Match, unter dem Abschnitt "Datenschutz" abrufbar.</p>
          <p>Der Nutzer hat die volle Verantwortung für die Einhaltung aller geltenden Datenschutzbestimmungen bei der
          Verarbeitung von Drittpersonendaten (z.B. Objektdaten). Dies umfasst insbesondere die Einhaltung von Auskunftsund
          Informationspflichten gegenüber Eigentümern und anderen betroffenen Parteien.</p>
          <p>Der Nutzer muss sicherstellen, dass er über die notwendige Berechtigung zur Verarbeitung von Daten Dritter
          verfügt. </p>
          <p className='text-lg mt-5 font-bold'>XIII. Abschließende Bestimmungen</p>
          <p>Das Recht der Republik Österreich findet auf alle rechtlichen Beziehungen zwischen dem Nutzer und Allimmo
          Anwendung.</p>
          <p>Sofern gesetzlich zulässig, ist Wien der vereinbarte Gerichtsstand für alle Streitigkeiten, die zwischen dem Nutzer
          und Allimmo entstehen.</p>
          <p>Sollten bestimmte Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirksam sein oder werden,
          beeinträchtigt dies nicht die Gültigkeit der übrigen Bestimmungen. Die unwirksame Bestimmung wird durch eine
          Bestimmung ersetzt, die dem von den Vertragsparteien beabsichtigten wirtschaftlichen Ergebnis am nächsten kommt.
          Selbiges gilt für etwaige Regelungslücken.</p>
        </div>
    </div>
  )
}

export default Agb