import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import StageLabel from '../../Objects/Creation/StageLabel';
import RangeNumbers from './RangeNumbers';
import { useEffect } from 'react';
import NavMenu from '../../Generics/NavMenu';

const OpenAreaSize = ({next, back}) => {

  const { searchData, setSearchData } = useCreationContext();

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-2/3 md:w-1/2 mdd:w-1/3">
        <StageLabel color={false} stageIcon={require('../../../assets/animations/searchCreation/areaDetails.json')} labelText={searchData.type === "multi" ? t('CreateSearch.OpenAreaSizeLabelMulti') : t('CreateSearch.OpenAreaSizeLabel')}/>
        <RangeNumbers
          unit='m²'
          prop="areas"
          resetValues={[0, 1000]}
        />
        
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={!(parseFloat(searchData.areas[0]) > 0) || !(parseFloat(searchData.areas[1]) > 0) || (!searchData.areasUnlimited && parseFloat(searchData?.areas[0]) > parseFloat(searchData?.areas[1]))} />
    </div>
  )
};

export default OpenAreaSize
