import { Collapse } from '@mui/material';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import BaseInfos from '../components/Object/BaseInfos';
import Contacted from '../components/Object/Contacted';
import { useObjectsContext } from '../components/Object/Contexts/ObjectsProvider';
import Facilities from '../components/Object/Facilities';
import Matches from '../components/Object/Matches';
import ObjectMenu from '../components/Object/ObjectMenu';
import OpenAreas from '../components/Object/OpenAreas';
import Pictures from '../components/Object/Pictures';
import ShortInfo from '../components/Object/ShortInfo';
import Vacancy from '../components/Object/Vacancy';
import { useSelectionContext } from '../contexts/SelectionProvider';
import { auth, firestore } from '../firebase.config';
import { convertShortTimestamp } from '../helper/functions';
import { Plus } from '../helper/icons';
import UploadExpose from '../components/Object/UploadExpose';
import CommercialInfo from '../components/Object/CommercialInfo';
import { facilities } from '../helper/arrays';
import UploadPlan from '../components/Object/UploadPlan';

const ObjectDetails = () => {
    const { selectedObjectID } = useSelectionContext();
    const { customerObjects } = useObjectsContext();

    const objectSelection = customerObjects.find(obj => obj.id === selectedObjectID);

    const [expanded, setExpanded] = useState({info: false, contacted: false});
    const [picsOpen, setPicsOpen] = useState(false);
    const [exposeOpen, setExposeOpen] = useState(false);
    const [planOpen, setPlanOpen] = useState(false);
    const [contacts, setContacts] = useState();

    const activeContacts = contacts?.filter(el => ["pending", "accepted"].includes(el.status));
    const timeoutContacts = contacts?.filter(el => el.status === "timeout");
    // const hasFacilities = objectSelection?.airCondition || objectSelection?.alarm || objectSelection?.noOldGrowth || objectSelection?.buildingSite || objectSelection?.accessible || objectSelection?.bathtub || objectSelection?.bike || objectSelection?.blinds || objectSelection?.cellar || objectSelection?.chimney || objectSelection?.developed || objectSelection?.door || objectSelection?.elevator || objectSelection?.fitness || objectSelection?.floorHeating || objectSelection?.garage || objectSelection?.kitchen || objectSelection?.laundry || objectSelection?.pets || objectSelection?.pool || objectSelection?.shortTermRent || objectSelection?.solar || objectSelection?.storageRoom || objectSelection?.sauna;
    const hasFacilities = objectSelection?.customFacilities?.length > 0 || facilities.some(e => (e.deal !== "all" ? e.deal === objectSelection?.deal : true) && (objectSelection?.[e.name] === true) && (e.usage.includes(objectSelection?.type)))
    
    useEffect(() => {
      const contactsRef = query(collection(firestore, "contacts"), where("object", "==", objectSelection?.id), where("customer", "==", auth.currentUser.uid));
      onSnapshot(contactsRef, (result) => {
        setContacts(result.docs.map((doc) => ({...doc.data(), id: doc.id })));
      });
      // eslint-disable-next-line
  }, []);  

  return (
      <div className={`flex flex-col items-center w-full px-2 pb-10`}>
        {objectSelection &&
        <div className='flex flex-col items-center w-full mdd:w-3/4'>
          <ObjectMenu 
          objectData={objectSelection} 
          openPics={() => setPicsOpen(true)} 
          openExpose={() => setExposeOpen(true)} 
          openPlan={() => setPlanOpen(true)} />

          {/* Object Info */}
          <div className={`flex flex-col justify-center w-full border rounded border-allimmoDark mt-1 relative bg-allimmoDark/5`}>
            <div onClick={() => setExpanded({...expanded, info: !expanded.info})} className='flex flex-row w-full justify-start items-center px-2 pt-2'>
              <Plus size={17} className={`cursor-pointer text-allimmoDark hover:text-slate-400 mr-2`}/>
              <p className='grow cursor-pointer active:text-allimmoDark truncate'>{`${["missing", "undefined"].includes(objectSelection?.address?.streetNumber) ? t('MissingData.streetNumber') : objectSelection?.address?.streetNumber} ${objectSelection?.address?.streetNumber === "missing" ? "" : objectSelection?.address?.cityZip !== "missing" ? ", " + objectSelection?.address?.cityZip : ""}`}</p>
            </div>
            <Collapse in={expanded.info} className='flex flex-col w-full px-2 pb-2'>
              <ShortInfo _objectData={objectSelection} />
              <div className='w-full flex flex-col md:flex-row gap-1 mt-2'>
                {objectSelection?.type === "commercial" && 
                <CommercialInfo _objectData={objectSelection} />}
                <BaseInfos editable={objectSelection?.fromInterface !== true} _objectData={objectSelection} />
                {hasFacilities &&
                <Facilities editable={false} _objectData={objectSelection} />}
              </div>
              <div className='w-full flex flex-col md:flex-row gap-1 mt-1'>
                {/* Open Areas */}
                {objectSelection?.openAreas?.length > 0 &&
                  <OpenAreas _objectData={objectSelection} editable={objectSelection?.fromInterface !== true} />
                }
                {/* Vacancy & Inspection Details */}
                {(objectSelection?.deal === "sale" && objectSelection?.type !== "land") &&
                  <Vacancy _objectData={objectSelection} />
                }
              </div>

              {/* Notes & Additional Information */}
              {objectSelection?.notes && objectSelection?.notes.trim() !== "" &&
              <div className='bg-allimmoDark/5 rounded flex flex-col w-full p-0.5 mt-1 border border-allimmoDark/70'>
                <ReactQuill theme='bubble' readOnly={true} value={objectSelection?.notes}/>
              </div>}

              <div className='flex flex-row w-full items-center gap-2 mt-2'>
                <p className='text-11 italic grow select-text'>{`${t('General.Id')}: ${objectSelection?.id}`}</p>
                <p className='text-12 italic'>{`${convertShortTimestamp(objectSelection?.created?.toDate())}`}</p>
              </div>
            </Collapse>
          </div>
          {/* Used Credits */}
          <div className='flex flex-row w-full justify-end items-center space-x-2 p-0.5 mt-1'>
            <p className='text-12 italic'>{`${t('ObjectDetails.CreditKosten')} ${objectSelection?.usedCredits}`}</p>
          </div>
          {/* Matching Searches */}
          <Matches objectData={objectSelection}/>

          <div className='flex flex-row items-center justify-center w-full mt-5'>
            <p className='text-14 grow'>{`${t('ObjectDetails.AktiveHeader')} ${activeContacts?.length}`}</p>
          </div>

          {/* Contacted Searches */}
          {activeContacts?.length > 0 &&
            <Contacted _activeContacts={activeContacts}/>
          }

          {/* Timeout Contacts */}
          {timeoutContacts?.length > 0 &&
          <div className='flex flex-row items-center justify-center w-full mt-5'>
              <p className='text-14 grow'>{`${t('ObjectDetails.TimeoutHeader')} ${timeoutContacts.length}`}</p>
          </div>}

          {/* Foto Upload Popup */}
          <Pictures objectId={objectSelection?.id} _open={picsOpen} pics={objectSelection?.pictureLinks} close={() => setPicsOpen(false)}/>
 
          {/* Exposé Upload Popup */}
          <UploadExpose objectData={objectSelection} _open={exposeOpen} close={() => setExposeOpen(false)} />

          {/* Plan Upload Popup */}
          <UploadPlan objectData={objectSelection} _open={planOpen} close={() => setPlanOpen(false)} />
        </div>}
      </div>
  )
}

export default ObjectDetails
