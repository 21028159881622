import { CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Check, CloseX, Dot, Update } from "../../../helper/icons";
import { t } from "i18next";
import { Timestamp, arrayUnion } from "firebase/firestore";
import { updateFirestoreDoc } from "../../../helper/functions";
import useOutsideClick from "../../../hooks/useOutsideClick";

const TextProperty = ({property, searchData, possibleValues = ["yes", "no", "-"], propertyOnly = false, section = "BaseInfos", editable = false}) => {

    const [isSaving, setIsSaving] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [newValue, setNewValue] = useState(searchData[property]);

    
    possibleValues.filter(e => e !== searchData?.[property]).splice(0, 0, searchData?.[property]);
    const wrapperRef = useRef(null);

    useOutsideClick(wrapperRef, () => {
      handleReset();
    });

    const handleReset = () => {
      setNewValue(searchData?.[property]);
      setEditMode(false);
    };
    
    const handleChange = async () => {
      if(!editable) return;
      setIsSaving(true);
      await updateFirestoreDoc("searches", searchData?.id, {[property]: newValue, edited: Timestamp.fromDate(new Date()), editedData: arrayUnion({
        updateID: searchData?.editedData ? searchData?.editedData.length + 1 : 1, 
        propertyName: property, 
        oldValue: searchData?.[property], 
        newValue: newValue,
        created: Timestamp.fromDate(new Date()),
      })})
      .then(() => {
        setIsSaving(false);
        setEditMode(false);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        setIsSaving(false);
      });
    };
  
    return (
      <div className={`flex flex-row items-center ${propertyOnly ? "gap-2" : "justify-between"} ${propertyOnly && !searchData?.[property] ? "hidden" : "block"}`}>
        {propertyOnly && <Dot size={12} className="text-allimmoDark/30" />}
        <p>{t(`SearchDetails.${section}.${property}`)}</p>
        {(!isSaving && !propertyOnly && !editMode) &&
        <div className="flex flex-row gap-1 items-center">
          {editable && <Update size={12} className="text-allimmoDark/30" />}
          <p className={`${editable ? "cursor-pointer hover:bg-allimmoDark/10" : ""}`} onClick={() => editable ? setEditMode(true) : null}>{searchData?.[property] === true ? t(`SearchDetails.TextPropertyEditOptions.yes`) : searchData?.[property] === false ? t(`SearchDetails.TextPropertyEditOptions.no`) : t(`SearchDetails.TextPropertyEditOptions.${searchData?.[property]}`)}</p>
        </div>}
        {(editMode && !isSaving) &&
        <div ref={wrapperRef} className="flex flex-row items-center gap-1">
          <CloseX onClick={handleReset} size={20} className="text-red-600 cursor-pointer hover:text-red-400 bg-red-200 rounded p-0.5" />
          <Check onClick={handleChange} size={20} className="text-green-600 cursor-pointer hover:text-green-400 bg-green-200 rounded p-0.5" />
          <select value={newValue} onChange={(e) => setNewValue(e.target.value)} className="border border-allimmoDark/40 rounded">
            {possibleValues.map((value) => <option key={value} value={value}>{t(`SearchDetails.TextPropertyEditOptions.${value}`)}</option>)}
          </select>
        </div>}
        {isSaving && <CircularProgress size={15} />}
      </div>
    )
  };

  export default TextProperty
