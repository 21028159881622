import { createContext, useContext, useEffect, useState } from "react";

const SelectionContext = createContext();

export const SelectionProvider = ({ children }) => {

    const [chatSelection, setChatSelection] = useState("");
    const [chatDetailsOpen, setChatDetailsOpen] = useState(false);
    const [showObjectBtn, setShowObjectBtn] = useState(false);
    const [selectedSearch, setSelectedSearch] = useState("");
    const [objectSelection, setObjectSelection] = useState(null);
    const [objectDetailsOpen, setObjectDetailsOpen] = useState(false);
    const [selectedObjectID, setSelectedObjectID] = useState("");
    const [selectedSearchID, setSelectedSearchID] = useState("");

    return (
        <SelectionContext.Provider
            value={{ 
                chatSelection,
                chatDetailsOpen,
                selectedSearch,
                objectDetailsOpen,
                objectSelection,
                showObjectBtn,
                selectedObjectID,
                selectedSearchID,
                setSelectedSearchID,
                setSelectedObjectID,
                setShowObjectBtn,
                setObjectSelection,
                setObjectDetailsOpen,
                setSelectedSearch,
                setChatDetailsOpen,
                setChatSelection,
            }}>
                {children}
        </SelectionContext.Provider>
    )
}

export const useSelectionContext = () => useContext(SelectionContext);