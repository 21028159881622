import { Checkbox, Collapse, TextField } from '@mui/material';
import { orange } from '@mui/material/colors';
import { t } from 'i18next';
import { useStateContext } from '../../../contexts/ContextProvider';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import SquareButton from '../../Generics/SquareButton';
import AnimationIcon from '../../Generics/AnimationIcon';
import StageLabel from './StageLabel';
import { useEffect } from 'react';
import NavMenu from '../../Generics/NavMenu';

const Floor = ({back, next}) => {

    const { objectData, setObjectData } = useCreationContext();
    const { screenSize } = useStateContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleFloor = (newFloor) => {
        setTimeout(() => {
            setObjectData({...objectData, floor: newFloor});
        }, "150");
    };

  return (
    <div className="flex flex-col justify-center items-center w-full select-none">
        <StageLabel labelText={t('CreateObject.FloorLabel')}/>
        <div className="grid grid-cols-2 ssm:grid-cols-3 gap-2">
            <SquareButton size={"h-36"} selected={objectData.floor === "attic"} clickFunction={() => handleFloor("attic")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/attic.json')}/>} label={t('Facilities.attic')}/>
            <SquareButton lowerLineHeight size={"h-36"} selected={objectData.floor === "groundFloor"} clickFunction={() => handleFloor("groundFloor")} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/groundFloor.json')}/>} label={t('Facilities.isGroundFloor')}/>
            <div className="col-span-2 ssm:col-span-1 place-self-center">
                <SquareButton size={"h-36"} selected={!["attic", "groundFloor", "-"].includes(objectData.floor)} clickFunction={() => handleFloor(1)} icon={<AnimationIcon _color={false} _iconSize={70} iconSrc={require('../../../assets/animations/objectCreation/floor.json')}/>} label={t('General.Stockwerk')}/>
            </div>
        </div>

        <Collapse className='mt-8 w-340' in={!["groundFloor", "attic", "-"].includes(objectData.floor)}>
            <div className={`flex flex-row bg-white cursor-pointer items-center w-full px-3 py-1 rounded`}>
                <p>{t('CreateObject.StockwerkAngeben')}</p>
                <div className='grow flex flex-row justify-center'>
                    <AnimationIcon _color={false} _iconSize={50} iconSrc={require('../../../assets/animations/objectCreation/arrowright.json')}/>
                </div>
                <TextField
                autoComplete='off'
                type='number' 
                size='small' value={objectData.floor} 
                onChange={(e) => setObjectData({...objectData, floor: parseInt(e.target.value)})} 
                className='rounded bg-white w-14'/>
            </div>
        </Collapse>

        <div onClick={() => setObjectData({...objectData, maisonette: !objectData.maisonette})} className={`flex mt-1 flex-row gap-1 bg-white cursor-pointer items-center w-340 rounded px-2 h-14`}>
            <Checkbox style={{transform: `scale(${1.3})`}} sx={{color: orange[800], '&.Mui-checked': {color: orange[800]}}} disableRipple disableTouchRipple value={objectData.maisonette} checked={objectData.maisonette} onChange={() => setObjectData({...objectData, maisonette: !objectData.maisonette})}/>
            <p className='text-allimmoDark'>{t('Facilities.maisonette')}</p>
            <div className='grow'/>
            {objectData.maisonette && <AnimationIcon _color={false} _iconSize={screenSize.width > 500 ? 45: 35} iconSrc={require(`../../../assets/animations/objectCreation/facilities/maisonette.json`)}/>}
        </div>

        <NavMenu nextBtn _next={next} _back={back} nextDisabled={[0, "0", null, NaN, "NaN", "-", ""].includes(objectData.floor)} />
    </div>
  )
}

export default Floor