import { Dialog } from '@mui/material';
import { Transition } from '../../helper/functions';
import { t } from 'i18next';
import Button from './Button';
import { CircularProgress } from '@mui/material';

const Confirm = ({_open, confirmText, confirmBtnText, confirm, cancel, loading = false}) => {
  return (
    <Dialog open={_open} TransitionComponent={Transition}>
      <div className='flex flex-col items-center justify-center p-10'>
        {loading ? <CircularProgress size={40} /> :
          <div className='flex flex-col items-center justify-center gap-5'>
              <p className='text-lg text-center'>{confirmText}</p>
              <div className='flex flex-row gap-3'>
                  <Button clickFunction={confirm} text={confirmBtnText} />
                  <Button clickFunction={cancel} text={t('General.Abbrechen')} />
              </div>
          </div>}
        </div>
    </Dialog>
  )
}

export default Confirm