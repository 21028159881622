import { t } from 'i18next';
import { useEffect } from 'react';

const Impressum = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
        <div className={`flex flex-col items-center w-full px-5 pt-5 ssm:pt-10 py-20 text-center`}> 
            <p className='text-xl font-bold mb-10'>{t('Menu.Impressum')}</p>
            <p className='text-lg'>{t('Impressum.Medieninhaberin')}</p>
            <p className='font-bold'>{t('Impressum.AllimmoKG')}</p>
            <p className='font-bold text-allimmoDark underline'><a target='_blank' href='https://www.allimmo.at'>{t('Impressum.AllimmoURL')}</a></p>

            <p className='text-lg mt-5'>{t('Impressum.Firmensitz')}</p>
            <p className='font-bold'>{t('Impressum.Wien')}</p>

            <p className='text-lg mt-5'>{t('Impressum.FirmenbuchnummerLabel')}</p>
            <p className='font-bold'>{t('Impressum.Firmenbuchnummer')}</p>

            <p className='text-lg mt-5'>{t('Impressum.Firmenbuchgericht')}</p>
            <p className='font-bold'>{t('Impressum.Wien')}</p>

            <p className='text-lg mt-5'>{t('Impressum.UIDLabel')}</p>
            <p className='font-bold'>{t('Impressum.UID')}</p>

            <p className='text-lg mt-5'>{t('Impressum.EmailLabel')}</p>
            <p className='font-bold'>{t('Impressum.Email')}</p>

            <p className='text-lg mt-5'>{t('Impressum.TelefonLabel')}</p>
            <p className='font-bold'>{t('Impressum.Telefon')}</p>

            <p className='text-lg mt-5'>{t('Impressum.IbanLabel')}</p>
            <p className='font-bold'>{t('Impressum.Iban')}</p>

            <p className='text-lg mt-5'>{t('Impressum.GegenstandLabel')}</p>
            <p className='font-bold'>{t('Impressum.Gegenstand1')}</p>
            <p className='font-bold'>{t('Impressum.Gegenstand2')}</p>
            <p className='font-bold'>{t('Impressum.Gegenstand3')}</p>

            <p className='text-lg mt-5'>{t('Impressum.MitgliedschaftenLabel')}</p>
            <p className='font-bold'>{t('Impressum.Mitgliedschaften')}</p>

            <p className='text-lg mt-5'>{t('Impressum.AufsichtLabel')}</p>
            <p className='font-bold'>{t('Impressum.Aufsicht')}</p>

            <p className='text-lg mt-5'>{t('Impressum.RechtsvorschriftenLabel')}</p>
            <p className='font-bold'>{t('Impressum.Rechtsvorschriften')}</p>

            <p className='text-lg mt-5'>{t('Impressum.UrheberhinweisLabel')}</p>
            <p className='font-bold'>{t('Impressum.Urheberhinweis')}</p>

            <p className='text-lg mt-5'>{t('Impressum.HaftungsausschlussLabel')}</p>
            <p className='font-bold'>{t('Impressum.Haftungsausschluss')}</p>
        </div>
  )
}

export default Impressum;

