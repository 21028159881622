import React from 'react'
import StageLabel from './StageLabel'
import { t } from 'i18next'
import { TextField } from '@mui/material'
import { useCreationContext } from '../../../contexts/CreationProvider'
import NavMenu from '../../Generics/NavMenu'
import validator from 'validator'

const CommercialVacancy = ({next, back}) => {

  const { objectData, setObjectData } = useCreationContext();

  const isInvalid = [NaN, "NaN", "", 0, "0"].includes(objectData.commercialVacancy);

  const handleBlur = () => {
    if(isInvalid) {
      setObjectData({...objectData, commercialVacancy: 0});
    }
  };

  const resetInput = () => {
    if(isInvalid) {
      setObjectData({...objectData, commercialVacancy: ""});
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-full ssm:w-3/4 md:w-2/3 lg:w-1/3 mt-10">
        <StageLabel headline={t('CreateObject.CommercialVacancy.Headline')} labelText={t('CreateObject.CommercialVacancy.Label')} />
        <div className="h-14 w-full border-allimmoDark border-2 rounded shadow-lg flex flex-row items-center bg-white px-4 gap-5"> 
            <input onBlur={handleBlur} type='number' onClick={resetInput} value={objectData?.commercialVacancy} onChange={(e) => setObjectData({...objectData, commercialVacancy: e.target.value.trim()})} className="h-full grow text-2xl w-full text-allimmoDark/80 bg-transparent select-none outline-none rounded" />
            <p className='text-2xl text-allimmoDark/80'>%</p>
        </div>
        <NavMenu _back={back} _next={next} nextBtn nextDisabled={parseFloat(objectData?.commercialVacancy > 100) || !validator.isNumeric(objectData.commercialVacancy.toString())} />
    </div>
  )
}

export default CommercialVacancy