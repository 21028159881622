import { pink } from '@mui/material/colors';
import { useCreationContext } from '../../../contexts/CreationProvider';
import AnimationIcon from '../../Generics/AnimationIcon';
import UnlimitedCheckBox from './UnlimitedCheckbox';
import { t } from 'i18next';

const RangeNumbers = ({prop, unit = "", resetValues = [1, 100], isUnlimitedPossible = true}) => {

    const { searchData, setSearchData, resetSearchZeroInputArray } = useCreationContext();

    const inputContainerStyles = 'h-14 w-full border-allimmoDark border-2 rounded shadow-lg flex flex-row items-center bg-white px-4 gap-5';
    const inputStyles = 'h-full grow text-2xl w-full text-allimmoDark/80 bg-transparent select-none outline-none rounded';
    const unlimitedProp = (prop + "Unlimited").toString();

    const handleBlur = (index) => {
      if(index === 0 && searchData?.[prop][0] === "") {
        setSearchData({...searchData, [prop]: [resetValues[0], searchData?.[prop][1]]});
        
      }
      if(index === 1 && searchData?.[prop][1] === "") {
        setSearchData({...searchData, [prop]: [searchData?.[prop][0], resetValues[1]]});
      }
    };

  return (
    <div className='flex flex-col items-center w-full'>
        <p className='text-xl text-allimmoDark/80 mb-0.5 w-full italic pl-1'>{t('General.Von')}</p>
          <div className={inputContainerStyles}> 
            <input onBlur={() => handleBlur(0)} type='number' onClick={() => resetSearchZeroInputArray(prop, 0)} value={searchData?.[prop][0]} onChange={(e) => setSearchData({...searchData, [prop]: [e.target.value.trim(), searchData?.[prop][1]]})} className={inputStyles}/>
            <p className='text-2xl text-allimmoDark/80'>{unit}</p>
          </div>

          <p className='text-xl text-allimmoDark/80 my-0.5 w-full italic pl-1'>{t('General.Bis')}</p>

          {!searchData[unlimitedProp] &&
          <div className={inputContainerStyles}> 
            <input onBlur={() => handleBlur(1)} type='number' onClick={() => resetSearchZeroInputArray(prop, 1)} value={searchData?.[prop][1]} onChange={(e) => setSearchData({...searchData, [prop]: [searchData?.[prop][0], e.target.value.trim()]})} className={inputStyles}/>
            <p className='text-2xl text-allimmoDark/80'>{unit}</p>
          </div>}

          {isUnlimitedPossible &&
          <div className={`flex flex-row bg-white cursor-pointer items-center w-full rounded px-1 h-14 ${!searchData[unlimitedProp] ? 'mt-1 border-0' : 'border-2 border-allimmoDark shadow-lg'}`}>
            <div className='grow'><UnlimitedCheckBox name={unlimitedProp} _size={1.3} _color={pink[700]}/></div>
            {searchData[unlimitedProp] && <AnimationIcon _delay='no' _color={false} _iconSize={45} iconSrc={require(`../../../assets/animations/searchCreation/number_unlimited.json`)}/>}
          </div>}
    </div>
  )
};

export default RangeNumbers
