import { t } from 'i18next';
import { useCreationContext } from '../../../contexts/CreationProvider';
import Button from '../../Generics/Button';
import StageLabel from './StageLabel';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import validator from 'validator';
import NavMenu from '../../Generics/NavMenu';

const UnitsState = ({next, back}) => {

    const {objectData, setObjectData } = useCreationContext();

    useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  const resetZeroInput = (prop) => {
    if([NaN, "NaN", "", 0, "0"].includes(objectData.unitsState[prop])) {
      setObjectData({...objectData, unitsState: {...objectData.unitsState, [prop]: ""}});
    }
  };

  const resetOnBlur = (prop) => {
    if([NaN, "NaN", "", 0, "0"].includes(objectData.unitsState[prop])) {
      setObjectData({...objectData, unitsState: {...objectData.unitsState, [prop]: "0"}});
    }
  };

  const sum = (validator.isInt(objectData?.unitsState?.vacant) ? parseInt(objectData?.unitsState?.vacant) : 0) + (validator.isInt(objectData?.unitsState?.limited) ? parseInt(objectData?.unitsState?.limited) : 0) + (validator.isInt(objectData?.unitsState?.unlimited) ? parseInt(objectData?.unitsState?.unlimited) : 0)
  const vacancyRatio = ((objectData?.unitsState?.vacant / objectData.units) * 100).toLocaleString(undefined, {maximumFractionDigits: 2});

  return (
    <div className='w-full sm:w-2/3 md:w-1/2 flex flex-col justify-center items-center py-2 sm:py-5'>
        <StageLabel color={false} stageIcon={require('../../../assets/animations/objectCreation/vacant.json')} labelText={t('CreateObject.UnitsStateLabel')}/>
        <div className='flex flex-col items-center my-3'>
          <p>{t("CreateObject.VerfügbareWohneinheiten")}</p>
          <p className={`text-xl ${sum <= objectData.units ? 'text-allimmoDark' : 'text-red-500 text-base'} -mt-1`}>{objectData.units - sum >= 0 ? `${objectData.units - sum}/${objectData.units}` : "zu viele Einheiten zugeteilt"}</p>
        </div>
        
        <div className='flex flex-col gap-3 mt-3 w-full'>
          <TextField onBlur={() => resetOnBlur("vacant")} onClick={() => resetZeroInput("vacant")} autoComplete='off' className='w-full rounded bg-white' size='small' label={t('CreateObject.Leer')} type='number' value={objectData?.unitsState.vacant} onChange={(e) => setObjectData({...objectData, unitsState: {...objectData.unitsState, vacant: e.target.value}})}/>
          <TextField onBlur={() => resetOnBlur("limited")} onClick={() => resetZeroInput("limited")} autoComplete='off' className='w-full rounded bg-white' size='small' label={t('CreateObject.Befristet')} type='number' value={objectData?.unitsState.limited} onChange={(e) => setObjectData({...objectData, unitsState: {...objectData.unitsState, limited: e.target.value}})}/>
          <TextField onBlur={() => resetOnBlur("unlimited")} onClick={() => resetZeroInput("unlimited")} autoComplete='off' className='w-full rounded bg-white' size='small' label={t('CreateObject.Unbefristet')} type='number' value={objectData?.unitsState.unlimited} onChange={(e) => setObjectData({...objectData, unitsState: {...objectData.unitsState, unlimited: e.target.value}})}/>
        </div>

        <p className='mt-0.5 italic text-sm w-full text-right px-1'>{`${t('General.Leerstandsquote')}: ${parseFloat(vacancyRatio) > 100 ? "-" : vacancyRatio + " %"}`}</p>
        <NavMenu _back={back} nextBtn _next={next} nextDisabled={sum > objectData.units}/>
    </div>
  )
}

export default UnitsState