import { t } from "i18next";
import { useStateContext } from "../../contexts/ContextProvider";
import { DeleteIcon, Dot } from "../../helper/icons";
import { updateFirestoreDoc } from "../../helper/functions";
import { arrayRemove } from "firebase/firestore";

const Assignments = ({}) => {

    const { screenSize, customer } = useStateContext();

    const removeAssignment = async (assignment) => {
        await updateFirestoreDoc("customers", customer.id, {assignments: arrayRemove(assignment)});
    };

  return (
    <div className={`w-full flex flex-col py-3 ${screenSize.width > 500 ?"px-3" : "px-1"} -mt-2`}>
        <p className='text-lg text-allimmoDark'>{t("Objects.Assignments.Header")}</p>
        <p className='text-sm italic text-allimmoDark'>{t("Objects.Assignments.InfoText")}</p>
        <div className="my-3 border rounded border-allimmoDark/50 p-1">
            {customer?.assignments?.map((assignment, index) => {
                return (
                    <div key={index} className={`flex flex-row items-center hover:bg-allimmoDark/5 rounded pr-1 ${index > 0 ? "mt-0.5" : ""}`}>
                        <Dot className="text-allimmoDark" />
                        <p className="ml-1 grow">{assignment}</p>
                        <DeleteIcon className="text-red-500 cursor-pointer hover:text-red-300 active:text-red-500" onClick={() => removeAssignment(assignment)} />
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default Assignments